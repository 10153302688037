<template lang="pug">
  include /mixins
  +b.SECTION.ds-section.--space_none(
    v-if='packages.length'
  )
    +e.element.hidden
      +b.g-container--variant_default
        +b-context('loyalty-program')
          +e.title-wrapper
            +e.P.title--variant_default.ds-caption--appearance_uppercase.--appearance_left  {{ _('Заголовок таблицы программы лояльности') }}
      +b.g-container--variant_for-loyalty-page
        +b-context('loyalty-program')
          +e.content-wrapper.--variant_1.--decorated
            +e.content.--variant_4
              div(
                v-for='group, group_id in tables'
              )
                +b.loyalty-corporate-program.is-visible-lg
                  ui-loader(
                    v-if='load'
                    :class-name='false'
                  )
                  +b.loyalty-programs
                    +b.loyalty-program-card--large
                      +e.item--simple.--title
                        +b.loyalty-program-title.P {{ group[0] && group[0].props && group[0].props.table && group[0].props.table.props && group[0].props.table.props.title }}
                      template(
                        v-for='(item, i) in conditions'
                      )
                        //- pre {{ hasValuesInCondition(group_id, item.id, item.props.children) }}
                        +e.item--simple.--br-dark(
                          :style='{ "height": !!item.props.children.length ? "auto" : val[group_id] && val[group_id][i]&& val[group_id][i].h }'
                          :class='[`condition_${group_id}_0`, { "is-clear": !item.props.title }]'
                          v-show='hasValuesInCondition(group_id, item.id, item.props.children)'
                        )
                          +b.P.ds-caption.--size_2md(
                            :class='[`condition_parent_${group_id}_${i}`, { "ds-caption--bold": item.props.style }]'
                            ) {{ item.props.title }}
                          template(v-if='item.props.children.length')
                            +e.child--dashed(
                              v-for='(child, idx) in item.props.children'
                              v-show='hasValuesInCondition(group_id, item.id, item.props.children)'
                              :class='[{"is-not-last": idx+1 < item.props.children.length}, `condition_child_${group_id}_0`, { "is-clear": !child.props.title }, { "ds-caption--bold": child.props.style }]'
                              :style='{ "height": valChild[group_id] && valChild[group_id][getIdx(child.id)]&& valChild[group_id][getIdx(child.id)].h }'
                            )
                              +b.ds-caption.--size_2md.--color_semigrey(
                                v-html='child.props.title'
                              )
                    template(
                      v-for='(pack, packIdx) in getPackages(group_id)'
                    )
                      +b.loyalty-program-card(
                        :style='{ "--bg": pack.props.color, "--bg-item": pack.props.pair_color, "--size":  getPackages(group_id).length }'
                      )
                        +e.item.--title.--pretty
                          +b.loyalty-program-pattern
                            +e.title.P {{ pack.props.title }}
                            +e.svg.SVG(xmlns="http://www.w3.org/2000/svg" width="192" height="134" viewBox="0 0 192 134" fill="none")
                              g
                                ellipse(
                                  cx="137.931" cy="103.077" rx="64.3678" ry="64.4231"
                                  :fill='pack.props.pair_color' fill-opacity="0.36"
                                )
                                ellipse(
                                  cx="158.529" cy="38.654" rx="64.3678" ry="64.4231"
                                  :fill='pack.props.pair_color' fill-opacity="0.36"
                                )
                                path(
                                  d="M173.333 25.7692C173.333 60.9941 144.803 89.5486 109.609 89.5486C74.4159 89.5486 45.8851 60.9941 45.8851 25.7692C45.8851 -9.45571 74.4159 -38.0102 109.609 -38.0102C144.803 -38.0102 173.333 -9.45571 173.333 25.7692Z"
                                  :stroke='pack.props.pair_color' stroke-opacity="0.36" stroke-width="1.28736"
                                )
                        template(
                          v-for='(item, i) in conditions'
                        )
                          +e.item.--br-light.--parent(
                            :class='[`condition_${group_id}_${packIdx+1}`, { "has-divider": hasSecondary(pack.id, item.id, group_id), "is-last": conditions[i+1] && !hasValuesInCondition(group_id, conditions[i+1].id, conditions[i+1].props.children) } ]'
                            :style='{ "height": !!item.props.children.length ? "auto" : val[group_id] && val[group_id][i]&& val[group_id][i].h }'
                            v-show="hasValuesInCondition(group_id, item.id, item.props.children)"
                          )
                            template(
                              v-if='!!item.props.children.length'
                            )
                              +e.child-wrap(
                                :style='{ "padding-top": `${parentH[group_id] && parentH[group_id][i]&& parentH[group_id][i].h}px` }'
                              )
                                template(
                                  v-for='(child, idx) in item.props.children'
                                )
                                  +e.child--col(
                                    :class='[{"is-not-last": idx+1 < item.props.children.length}, `condition_child_${group_id}_${packIdx+1}`, {"has-divider": hasSecondary(pack.id, child.id, group_id), "no-br": !idx && (!hasSecondary(pack.id, child.id, group_id)) }]'
                                    :style='{ "height": valChild[group_id] && valChild[group_id][getIdx(child.id)]&& valChild[group_id][getIdx(child.id)].h }'
                                  )
                                    +e.text.P(
                                      :class='{"is-content is-first": hasSecondary(pack.id, child.id, group_id)}'
                                      v-if='hasTitle(pack.id, child.id, group_id)'
                                    ) {{ getValue(pack.id, child.id, group_id, group_id).title }}
                                    +e.small.P(
                                      :class='{"is-content is-first": hasSecondary(pack.id, child.id, group_id), "ds-caption--bold": getValue(pack.id, child.id, group_id, group_id).text_style}'
                                      v-else=''
                                    ) {{ getValue(pack.id, child.id, group_id) && getValue(pack.id, child.id, group_id).text || "-" }}
                                    +e.text.P(
                                      :class='{"is-content": hasPrimary(pack.id, child.id, group_id)}'
                                      v-if='getValue(pack.id, child.id, group_id).secondary_title'
                                    ) {{ getValue(pack.id, child.id, group_id).secondary_title }}
                                    +e.small.P(
                                      :class='{"is-content": hasPrimary(pack.id, child.id), "ds-caption--bold": getValue(pack.id, child.id, group_id).secondary_text_style}'
                                      v-if="!getValue(pack.id, child.id, group_id).secondary_title && getValue(pack.id, child.id, group_id).secondary_text"
                                    ) {{ getValue(pack.id, child.id, group_id).secondary_text }}
                            template(v-else)
                              +e.text.P(
                                v-if='hasTitle(pack.id, item.id, group_id)'
                                :class='{"is-content is-first": hasSecondary(pack.id, item.id, group_id)}'
                              ) {{ getValue(pack.id, item.id, group_id).title }}
                              +e.small.P(
                                v-else=''
                                :class='{"is-content is-first": hasSecondary(pack.id, item.id, group_id), "ds-caption--bold": getValue(pack.id, item.id, group_id).text_style}'
                              ) {{ getValue(pack.id, item.id, group_id) && getValue(pack.id, item.id, group_id).text || "-" }}
                              +e.text--bold.P(
                                v-if='getValue(pack.id, item.id, group_id).secondary_title'
                                :class='{"is-content": hasPrimary(pack.id, item.id, group_id)}'
                              ) {{ getValue(pack.id, item.id, group_id).secondary_title }}
                              +e.small.P(
                                :class='{"is-content": hasPrimary(pack.id, item.id, group_id), "ds-caption--bold": getValue(pack.id, item.id, group_id).secondary_text_style}'
                                v-if="!getValue(pack.id, item.id, group_id).secondary_title && getValue(pack.id, item.id, group_id).secondary_text"
                              ) {{ getValue(pack.id, item.id, group_id) && getValue(pack.id, item.id, group_id).secondary_text }}
                  +b.ds-caption--size_2md.t-content(
                    v-html="group[0] && group[0].props && group[0].props.table && group[0].props.table.props && group[0].props.table.props.description"
                  )

                +b.is-disable-lg.tt-space_mb--4
                  +b.tt-space_mb--4
                    +b.loyalty-program-title.P {{ group[0] && group[0].props && group[0].props.table && group[0].props.table.props && group[0].props.table.props.title }}
                  template(
                    v-for='pack in packages'
                  )
                    ui-accordion(
                      :key='pack.id'
                      :init='false',
                      v-if="hasValuesInPackage(group_id, pack.id)"
                    )
                      template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
                        +b.loyalty-program-card(
                          :class='{"is-active": activeState}'
                          :style='{ "--bg": pack.props.color, "--bg-item": pack.props.pair_color, }'
                        )
                          +b.loyalty-program-pattern
                            +e.title.P {{ pack.props.title }}
                            +e.svg.SVG(xmlns="http://www.w3.org/2000/svg" width="192" height="134" viewBox="0 0 192 134" fill="none")
                              g
                                ellipse(
                                  cx="137.931" cy="103.077" rx="64.3678" ry="64.4231"
                                  :fill='pack.props.pair_color' fill-opacity="0.36"
                                )
                                ellipse(
                                  cx="158.529" cy="38.654" rx="64.3678" ry="64.4231"
                                  :fill='pack.props.pair_color' fill-opacity="0.36"
                                )
                                path(
                                  d="M173.333 25.7692C173.333 60.9941 144.803 89.5486 109.609 89.5486C74.4159 89.5486 45.8851 60.9941 45.8851 25.7692C45.8851 -9.45571 74.4159 -38.0102 109.609 -38.0102C144.803 -38.0102 173.333 -9.45571 173.333 25.7692Z"
                                  :stroke='pack.props.pair_color' stroke-opacity="0.36" stroke-width="1.28736"
                                )
                          transition(name='slidedown')
                            div(v-show="activeState")
                              template(
                                v-for='(item, i) in conditions'
                              )
                                +e.item.--variant_4.--br-dark(
                                  v-show="hasValuesInCondition(group_id, item.id, item.props.children)"
                                )
                                  +e.el--spaced.P.ds-caption.--size_2md(
                                    v-if='item.props.title'
                                    :class="{ 'loyalty-program-card__el--bordered': item.props.children[0] && getValue(pack.id, item.props.children[0].id, group_id).secondary_title || item.props.children[0] && getValue(pack.id, item.props.children[0].id, group_id).secondary_text, 'ds-caption--bold': item.props.style  }"
                                  ) {{ item.props.title }}
                                  template(v-if='item.props.children.length')
                                    template(
                                      v-for='child in item.props.children'
                                    )
                                      +e.el.P.ds-caption.--size_2md-xl(
                                        v-html='child.props.title'
                                        :class='{ "loyalty-program-card__el--bordered": getValue(pack.id, child.id, group_id).secondary_title || getValue(pack.id, child.id, group_id).secondary_text, "ds-caption--bold": child.props.style }'
                                        v-if="child.props.title"
                                      )
                                      .loyalty-cell-wrap
                                        +e.text.P(
                                          v-if='hasTitle(pack.id, child.id, group_id)'
                                          :class='{"is-content is-first": hasSecondary(pack.id, child.id, group_id)}'
                                        ) {{ getValue(pack.id, child.id, group_id).title }}
                                        +e.small.P(
                                          v-else=''
                                          :class='{"is-content is-first": hasSecondary(pack.id, child.id, group_id), "ds-caption--bold": getValue(pack.id, child.id, group_id).text_style}'
                                        ) {{ getValue(pack.id, child.id, group_id) && getValue(pack.id, child.id, group_id).text || "-" }}
                                        +e.text.P(
                                          v-if='getValue(pack.id, child.id, group_id).secondary_title'
                                          :class='{"is-content": hasPrimary(pack.id, child.id, group_id)}'
                                        ) {{ getValue(pack.id, child.id, group_id).secondary_title }}
                                        +e.small.P(
                                          :class='{"is-content": hasPrimary(pack.id, child.id, group_id), "ds-caption--bold": getValue(pack.id, child.id, group_id).secondary_text_style}'
                                          v-if="!getValue(pack.id, child.id, group_id).secondary_title && getValue(pack.id, child.id, group_id).secondary_text"
                                        ) {{ getValue(pack.id, child.id, group_id) && getValue(pack.id, child.id, group_id).secondary_text }}
                                  template(v-else='')
                                    .loyalty-cell-wrap
                                      +e.text.P(
                                        v-if='hasTitle(pack.id, item.id, group_id)'
                                        :class='{"is-content is-first": hasSecondary(pack.id, item.id, group_id)}'
                                      ) {{ getValue(pack.id, item.id, group_id).title }}
                                      +e.small.P(
                                        v-else=''
                                        :class='{"is-content is-first": hasSecondary(pack.id, item.id, group_id), "ds-caption--bold": getValue(pack.id, item.id, group_id).text_style}'
                                      ) {{ getValue(pack.id, item.id, group_id) && getValue(pack.id, item.id, group_id).text || "-" }}
                                      +e.text--bold.P(
                                        v-if='getValue(pack.id, item.id, group_id).secondary_title'
                                        :class='{"is-content": hasPrimary(pack.id, item.id, group_id)}'
                                      ) {{ getValue(pack.id, item.id, group_id).secondary_title }}
                                      +e.small.P(
                                        :class='{"is-content": hasPrimary(pack.id, item.id, group_id), "ds-caption--bold": getValue(pack.id, item.id, group_id).secondary_text_style}'
                                        v-if="!getValue(pack.id, item.id, group_id).secondary_title && getValue(pack.id, item.id, group_id).secondary_text"
                                      ) {{ getValue(pack.id, item.id, group_id) && getValue(pack.id, item.id, group_id).secondary_text }}
                          +b.tt-link--accent.--appearance_underline.--variant_center.ds-caption--size_md.--medium.tt-space_mt--5.A(
                            @click.prevent="toggleActive"
                          ) {{ activeState ? _('Скрыть') : _('Подробнее') }}

</template>
<script>
import { pathOr } from 'ramda'

import {
  valuesListResponse,
  conditionsListResponse,
  packagesListResponse,
} from '@api/loyalty.service'

import {
  groupByOrNull,
  pathGetterArray,
} from '@utils/helpers'

const VALUES_KEY = 'values'
const CONDITIONS_KEY = 'conditions'
const PACKAGES_KEY = 'packages'

export default {
  name: 'LoyaltyList',
  data() {
    return {
      [VALUES_KEY]: [],
      [CONDITIONS_KEY]: [],
      [PACKAGES_KEY]: [],
      val: {},
      valChild: {},
      children: [],
      parentH: {},
      load: false,
      tables: {},
    }
  },
  async created() {
    await this.receiver(valuesListResponse, VALUES_KEY)
    await this.receiver(packagesListResponse, PACKAGES_KEY)
    await this.receiver(conditionsListResponse, CONDITIONS_KEY)
    this.children = this.conditions.reduce((acc, obj) => {
      acc.push(...obj.props.children)
      return acc
    }, [])
    const groups = groupByOrNull(this.values, pathGetterArray(['props', 'table', 'props', 'sort_order'], null), pathGetterArray(['props', 'table', 'id'], null))
    console.log(groups);
    this.tables = groups
    this.$nextTick(() => {
      this.getHeight()
    })
  },
  mounted() {
    this.getHeight()
    this.$nextTick(() => {
      this.getHeight()
    })
  },
  methods: {
    getPackages(groupId) {
      return this.packages.filter(pack => this.hasValuesInPackage(groupId, pack.id))
    },
    hasValuesInCondition(groupId, condition, children) {
      const p = this.tables[groupId].find(e => e.props.condition.id === condition)
      const c = children.some(x => this.tables[groupId].find(e => e.props.condition.id === x.id))

      return !!p || (children.length ? !!c : false)
    },
    hasValuesInPackage(groupId, pack) {
      const p = this.tables[groupId].find(e => e.props.package.id === pack)
      return !!p
    },
    async receiver(resource, key) {
      const { data } = (await resource.execute())
      this[key] = data
    },
    getValue(pack, condition, group) {
      const g = this.tables[group]
      if (!g) return {}
      const v = g.find(e => e.props.condition.id === condition && e.props.package.id === pack)
      return v?.props || {}
    },
    hasTitle(pack, condition, group) {
      const v = this.getValue(pack, condition, group)
      return v?.title
    },
    hasSecondary(pack, condition, group) {
      const v = this.getValue(pack, condition, group)
      return v?.secondary_title || v?.secondary_text
    },
    hasPrimary(pack, condition, group) {
      const v = this.getValue(pack, condition, group)
      return v?.title || v?.text
    },
    getIdx(id) {
      return this.children.findIndex(x => x.id == id)
    },
    getChildIdx(id) {
      const child = this.children.findIndex(x => x.id == id)
      return child
    },
    getHeight() {
      this.load = true
      const one = 1
      const zero = 0
      console.log(this.tables);
      Object.keys(this.tables).forEach(table => {
        this.val = {}
        this.valChild = {}
        const total = this.conditions.length
        let total_child = this.conditions.reduce((acc, obj) => acc += obj.props.children.length, 0)
        total_child += one
        for (let x = zero; x < total; x++) {
          this.val[table] = this.val[table] || []
          this.val[table].push({
            h: 'auto',
          })
        }
        if (this.val[table].length > zero) {
          this.val = JSON.parse(JSON.stringify(this.val))
          this.val[table].forEach((el, i) => {
            el = 'auto'
          })
        }
        for (let x = zero; x < total_child; x++) {
          this.valChild[table] = this.valChild[table] || []
          this.valChild[table].push({
            h: 'auto',
          })
        }
        if (this.valChild[table].length > zero) {
          this.valChild = JSON.parse(JSON.stringify(this.valChild))
          this.valChild[table].forEach((el, i) => {
            el = 'auto'
          })
        }
        const time = 2000
        setTimeout(() => {
          const arr = {}
          const arr2 = {}
          this.parentH = {}
          
          this.conditions.forEach((el, i) => {
            arr[table] = arr[table] || []
            arr[table].push(Array.from(document.getElementsByClassName(`condition_${table}_${i}`)).map((element, index) => {
              console.log(element.scrollHeight);
              return { h: element.scrollHeight } 
            }))
            this.parentH[table] = this.parentH[table] || []
            this.parentH[table].push(Array.from(document.getElementsByClassName(`condition_parent_${table}_${i}`)).map((element, index) => ({ h: element.scrollHeight }))[0])
          })
          console.log(arr[table]);
          for (let t = 0; t <= this.packages.length; t++) {
            arr2[table] = arr2[table] || []
            arr2[table].push(Array.from(document.getElementsByClassName(`condition_child_${table}_${t}`)).map((element, index) => {
              return { h: element.scrollHeight }
            }))
          }

          // })
          this.val = JSON.parse(JSON.stringify(this.val))
          this.val[table] = arr[table][0]
          for (let i = zero; i < arr[table].length; i++) {
            for (let j = zero; j < arr[table][i].length; j++) {
              if (this.val[table][j].h < arr[table][i][j].h) {
                this.val[table][j].h = arr[table][i][j].h
                this.$set(this.val[table][j], 'h', arr[table][i][j].h)
              }
            }
          }
          this.val = JSON.parse(JSON.stringify(this.val || []))
          this.val[table].forEach((el, i) => {
            // el = JSON.parse(JSON.stringify(el))
            el.h = `${el.h}px`
            this.$set(this.val[table][i], 'h', `${el.h}`)
          })
          if (total != this.val[table].length) {
            const other = total - this.val[table].length
            for (let x = zero; x < other; x++) {
              this.val[table].push({
                h: 'auto',
              })
            }
          }
          // 222222222222

          this.valChild = JSON.parse(JSON.stringify(this.valChild))
          this.valChild[table] = arr2[table][0]
          for (let i = zero; i < arr2[table].length; i++) {
            for (let j = zero; j < arr2[table][i].length; j++) {
              if (this.valChild[table][j].h < arr2[table][i][j].h) {
                this.valChild[table][j].h = arr2[table][i][j].h
                this.$set(this.valChild[table][j], 'h', arr2[table][i][j].h)
              }
            }
          }
          this.valChild = JSON.parse(JSON.stringify(this.valChild || []))
          this.valChild[table].forEach((el, i) => {
            // el = JSON.parse(JSON.stringify(el))
            el.h = `${el.h}px`
            this.$set(this.valChild[table][i], 'h', `${el.h}`)
          })
          if (total_child - one != this.valChild[table].length) {
            const other = total_child - this.valChild[table].length
            for (let x = zero; x < other; x++) {
              this.valChild[table].push({
                h: 'auto',
              })
            }
          }
          // this.isPageLoading = false
          this.load = false
        }, time)
      })
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  div
    +b.search-result-item--spaced.tt-link--variant_start(
        :href='link.props.url'
        @click.prevent='goTo(link)'
        v-for='link in results'
      )
        +e.mini-img(
          v-if='link.props.img'
        )
          source(
            type="image/webp"
            :srcset="link.props.img.catalog_preview_webp"
          )
          +e.element.IMG(
            :src='link.props.img.catalog_preview'
          )

        +e.info
          +e.line
          +e.line
            +e.title.ds-caption--size_xs.--appearance_uppercase.--medium.P
              span(v-html='findValueAndHighlightTerm(link.props.title, link.props.title, localsearch)')
          +e.line.tt-space_mt--2
            +e.content.ds-caption--size_2xs.--color-relief.--medium.P
              span(v-html='highlightTerm(link.props.short_description, localsearch )')
          +e.line
            +b.ds-caption--size_xs.P(v-if='link.props.variant.props.weight') {{  link.props.variant.props.weight.props.display  }}
</template>

<script>

import {
  setCookie,
} from '@utils/cookies'

import { ACTIVE_PRODUCT_COOKIE_NAME } from '@utils/directives'

export default {
  props: {
    findValueAndHighlightTerm: {},
    highlightTerm: {},
    results: {},
    localsearch: {},
  },
  methods: {
    goTo(item) {
      setCookie(ACTIVE_PRODUCT_COOKIE_NAME, item.props.variant.id, 1)
      window.location.href = item.props.url
    },
  },
}
</script>

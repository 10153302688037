import { Tabs, Tab } from 'vue-slim-tabs'
import Tag from '@aspectus/vue-tag'
import VueBem from '@aspectus/vue-bem'
import VueGrid from '@aspectus/vue-grid'
// import Slick from 'vue-slick'

import Plugin from '@aspectus/vue-eq'

import VueCountdown from '@chenfengyuan/vue-countdown'

import CustomComponents from '@app/UiElements/Custom'
import HeaderComponents from '@app/UiElements/Header'
import TripsComponents from '@app/UiElements/Trips'
import ControlsComponents from '@app/UiElements/Controls'
import Controls2Components from '@app/UiElements/Controls2'

import ContactsMap from '@app/UiElements/ContactsMap'
import UiTableController from '@app/UiElements/UiTableController'
import ModalWithMap from '@app/UiElements/ModalWithMap'
import VueObserveVisibility from 'vue-observe-visibility'
import { install as RsTabs } from './ResponsiveTabs.js'
import DrawerReceiver from './Drawer/Receiver'
import LoadingWrapper from './LoadingWrapper'
import Lightbox from './Lightbox'
import ModalTrigger from './ModalTrigger'
import SeoText from './SeoText'
import UiAccordion from './UiAccordion'
import UiClickOutside from './UiClickOutside'
import UiTable from './UiTable'
import UiLoader from './UiLoader'
import LightBoxV2 from './LightBoxV2'
import LightboxWithSlider from './LIghtboxWithSlider'
import UiSlider from './UiSlider'
import UiLimitPagination from './Pagination/UiLimitPagination'
import UiYoutubeView from './UiYoutubeView'
import UiYoutubePlayer from './UiYoutubePlayer'
import SpeakersSlider from './SpeakersSlider'
import ContactsBranches from './ContactsBranches'
import BlocksTrigger from './BlocksTrigger'
import NativeSearch from './NativeSearch'
import ShareBySms from './ShareBySms'
import ShareBtn from './ShareBtn'
import ContentToggler from './ContentToggler'
import UiLink from './UiLink'
import PermissionsUpdateBtn from './PermissionsUpdateBtn'
import LoaderBtn from './LoaderBtn'
import GalleryProductView from './GalleryProductView'
import {
  PrettyStars,
} from './Stars.js'

const BrandRatingButtonWrapper = () => import('./BrandRatingButtonWrapper.vue')

export default function install(Vue) {
  Vue.component('Tabs', Tabs)
  Vue.component('Tab', Tab)
  Vue.use(RsTabs)
  Vue.use(Tag)
  Vue.use(VueObserveVisibility)
  Vue.use(TripsComponents)
  Vue.use(HeaderComponents)
  Vue.use(ControlsComponents)
  Vue.use(Controls2Components)
  Vue.use(CustomComponents)

  Vue.use(VueGrid)
  Vue.use(Plugin)
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })

  // Vue.component('Slick', Slick)
  Vue.component('GalleryProductView', GalleryProductView)
  Vue.component('UiLink', UiLink)
  Vue.component('PrettyStars', PrettyStars)
  Vue.component(VueCountdown.name, VueCountdown)
  Vue.component('LightboxWithSlider', LightboxWithSlider)
  Vue.component(ContactsMap.name, ContactsMap)
  Vue.component(DrawerReceiver.name, DrawerReceiver)
  Vue.component(ModalTrigger.name, ModalTrigger)
  Vue.component(UiTableController.name, UiTableController)
  Vue.component(SeoText.name, SeoText)
  Vue.component(UiTable.name, UiTable)
  Vue.component(LoadingWrapper.name, LoadingWrapper)
  Vue.component(Lightbox.name, Lightbox)
  Vue.component(ModalWithMap.name, ModalWithMap)

  Vue.component(BlocksTrigger.name, BlocksTrigger)
  Vue.component(UiAccordion.name, UiAccordion)
  Vue.component(UiLoader.name, UiLoader)
  Vue.component(SpeakersSlider.name, SpeakersSlider)
  Vue.component(LightBoxV2.name, LightBoxV2)
  Vue.component(UiSlider.name, UiSlider)
  Vue.component(UiLimitPagination.name, UiLimitPagination)
  Vue.component(UiYoutubeView.name, UiYoutubeView)
  Vue.component(UiYoutubePlayer.name, UiYoutubePlayer)
  Vue.component(UiClickOutside.name, UiClickOutside)
  Vue.component(NativeSearch.name, NativeSearch)
  Vue.component(ShareBySms.name, ShareBySms)
  Vue.component(ShareBtn.name, ShareBtn)
  Vue.component(ContentToggler.name, ContentToggler)
  Vue.component(ContactsBranches.name, ContactsBranches)
  Vue.component(PermissionsUpdateBtn.name, PermissionsUpdateBtn)
  Vue.component(LoaderBtn.name, LoaderBtn)
  Vue.component('BrandRatingButtonWrapper', BrandRatingButtonWrapper)
}

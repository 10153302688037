<template lang="pug">
  include /mixins
  password-restore(
    :resource='$options.registerResource'
  )
</template>
<script>
import {
  registerResource,
} from '@api/auth.service'

export default {
  name: 'Registration',
  registerResource,
}
</script>

import {
  createResource,
  deleteResource,
  postFormDataPrettifierResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'store/product'

const PRODUCT_CONSUMPTION_URL = prefixAPI('/price-per-serving/{id}/', MODEL)
const PRODUCT_PACKAGES_LIST_URL = prefixAPI('/packages/', MODEL)

const RESULT_CREATE_URL = prefixAPI('/result/create/', MODEL)
const RESULT_LIST_URL = prefixAPI('/result/list/{?id}', MODEL)
const RESULT_IMAGE_CREATE_URL = prefixAPI('/result/image/create/', MODEL)
const RESULT_IMAGE_DESTROY_URL = prefixAPI('/result/image/destroy/{id}/', MODEL)

const LOST_PROFIT_URL = prefixAPI('lost-profit/create-update/')

export const getProductConsumption = createResource(PRODUCT_CONSUMPTION_URL)
export const getPackagesList = createResource(PRODUCT_PACKAGES_LIST_URL)

export const ResultCreate = createResource(RESULT_CREATE_URL, postResource)
export const ResultList = createResource(RESULT_LIST_URL)
export const ResultImageCreate = createResource(RESULT_IMAGE_CREATE_URL, postFormDataPrettifierResource)
export const ResultImageDestroy = createResource(RESULT_IMAGE_DESTROY_URL, deleteResource)

export const LostProfitResource = createResource(LOST_PROFIT_URL, postResource)

<script>
import { mapState, mapActions } from 'vuex'

import {
  addToComparison,
} from '@api/comparison.service'

export default {
  name: 'AddToComparison',
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState('comparison', [
      'comparison_ids',
    ]),
    is_added() {
      return this.comparison_ids.includes(this.id)
    },
  },
  methods: {
    ...mapActions('comparison', [
      'addId',
    ]),
    async add() {
      await addToComparison.execute({}, { product_variant: this.id })
      this.addId(this.id)
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      add: () => this.add(),
      is_added: this.is_added,
    })
  },
}
</script>

<template lang="pug">
  div(class='ds-aspect-ratio ds-aspect-ratio--appearance_cuted ds-aspect-ratio--appearance_filled ds-aspect-ratio--ratio_16x9')
    iframe(
      class="ds-aspect-ratio__body"
      :src="urlCorrect"
      frameborder="0"
      style="border: 0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    )

</template>
<script>
export default {
  name: 'UiYoutubePlayer',
  props: {
    url: {},
  },
  data() {
    return {
      urlCorrect: `//www.youtube.com/embed/${this.getId(this.url)}?modestbranding=0&rel=0&autoplay=0&mute=0&iv_load_policy=3`,
    }
  },
  computed: {
    preview() {
      return `https://img.youtube.com/vi/${this.getId(this.url)}/0.jpg`
    },
  },
  methods: {
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      const match = url.match(regExp)
      const eleven = 11
      if (match && match[2].length == eleven) {
        return match[2]
      }
      return new Error('error parsed link')
    },
  },
}

</script>

<template lang="pug">
  include /mixins

  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.title.ds-caption--size_xs.--size_sm-xl.--light.P.tt-space_mt--8-xl
    slot(name='auth-btn')
    +b.g-row
      +b.g-cell.g-cols--12(:class='`g-cols--${cols}`')
        phone-input(
          v-if='isPhone'
          :rules="'required|phone'",
          :input-label='labels.phone'
          v-model='form.phone',
          ref='phone'
          name='username'
        )
        d-control-input(
          v-else
          :rules="'required|email'",
          :input-label='labels.email'
          v-model='form.email'
          ref='email'
          name='username'
        )
    .relative
      +b.non-field-error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    +b.g-row
      +b.g-cell.g-cols--12.tt-space_mt--5.tt-space_mt--10-xl(:class='`g-cols--${cols}`')
        token-confirmation-check(
          :token='form.phone || form.email'
          :action='goToPasswordSetPage'
          :additional-data='{ way: type == "email" ? type : "default" }'
        )
          template(#default='{ is_confirmed, sendCode }')
            +b.tt-button.BUTTON(
              type='submit'
              :disabled='isLoad',
              @click.prevent='validate(sendCode)'
            )
              +e.title.P {{ _('Далее') }}
</template>
<script>
import loginMixin from '@app/Forms/mixins/login'

const TYPE_PHONE = 'phone'

export default {
  name: 'PasswordRestoreRequest',
  mixins: [loginMixin],
  props: {
    type: {
      type: String,
      default: 'email',
    },
  },
  data() {
    return {
      url: window.password_set_url,
      is_recovery: true,
      pin: '',
    }
  },
  computed: {
    isPhone() {
      return this.type === TYPE_PHONE
    },
  },
  methods: {
    async validate(action) {
      this.$refs.validator.reset()
      this.is_recovery = false
      this.$nextTick(async () => {
        const isValid = (await this.$refs.validator.validate())
        if (!isValid) return
        action()
      })
      // this.$emit('validate', { valid, step })
    },
    clearPin() {
      this.pin = "";
      this.$refs.otpComponent.clearDigits();
    },
    goToPasswordSetPage(id) {
      const data = {
        phone: this.form.phone,
        email: this.form.email,
        id,
      }
      window.location.href = `${this.url}?_state=${btoa(JSON.stringify(data))}`
    },
  },
}
</script>

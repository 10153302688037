<template lang='pug'>
  include /mixins
  div
    aside
      component(
        space="xl"
        :filters="filters"
        @update:filters="changeFilters"
        :pagination="pagination",
        :attributesResource='attributesResource',
        :availableAttributesResource='availableAttributesResource',
        :needPrices='needPrices'
        :needSorting='needSorting'
        :needTitle='needTitle'
        :sortlist='sortlist'
        :needSearch='needSearch'
        @category-hack='$emit("category-hack")'
        :label='label'
        :kwargs='kwargs'
        :needBlocking='needBlocking'
        :initbaseUrl='initbaseUrl'
        is='LineSidebarWithSelects'
      )
    ui-loader(v-if='loading')
    catalog-content(
      :loading="loading"
      :items="result && result.data"
      :pagination="pagination"
      @update:pagination="changePagination"
      :url='initbaseUrl'
    )
      slot
</template>

<script>

import CatalogSidebar from './Sidebar'
import CatalogContent from './Content'
import LineSidebarWithSelects from './LineSidebarWithSelects'

export default {
  components: { CatalogSidebar, CatalogContent, LineSidebarWithSelects },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'availableAttributesResource',
    'needPrices',
    'needSorting',
    'needTitle',
    'needSearch',
    'label',
    'sortlist',
    'selector',
    'needBlocking',
    'initbaseUrl',
    'kwargs',
  ],

  created() {
    // this.search()
  },

  methods: {
    search() {
      this.receive(this.parameters)
    },
  },
}

</script>

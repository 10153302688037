import isShallowEqual from '@wordpress/is-shallow-equal'
import equals from 'ramda/src/equals'
import FilterUi from '@app/Filters/Ui'

export default {
  props: [
    'loading',
    'value',
    'receive',
    'receivePrices',
    'all',
    'prices',
    'available',
    'receiveAvailable',
    'displayed',
    'pagination',
    'needSorting',
    'needPricing',
    'needTitle',
    'needSearch',
    'sortlist',
    'label',
    'baseUrl',
    'isStoreCatalog',
  ],
  components: {
    FilterUi,

  },

  data() {
    return {
      prepared: {},
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (equals(filters, oldFilters)) {
          return
        }

        this.prepare(filters)

        this.receiveAvailable({ filters, label: this.label })
      },
    },
  },

  created() {
    if (this.needPricing) {
      this.receivePrices()
    }
    this.receive({ label: this.label })
  },

  computed: {
    filters() {
      return this.all
    },
    availableMap() {
      if (!this.available) {
        return {}
      }
      return this.available
    },
  },

  methods: {
    prepare(value) {
      this.prepared = value
    },
    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },
    handleInput(value) {
      this.prepare(value)
    },
    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 })
    },
  },
}

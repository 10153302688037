import { createRoutes as createCabinetForUserRoutes } from '@app/Staff/router'

import {
  prefixRoutes,
} from '@app/Routing/helpers'
import { prefixLanguage } from '@utils/urls';

const USER_CABINATE_BASE_URL = '/profile/'

export function createRoutes(options) {
  return [
    prefixRoutes(prefixLanguage(USER_CABINATE_BASE_URL), createCabinetForUserRoutes(options)),
  ]
}

export function createRouter({ Router, Vue }) {
  return new Router({
    mode: 'history',
    base: '/',
    // scrollBehavior(to, from, savedPosition) {
    //   if (savedPosition) {
    //     return savedPosition
    //   }

    //   return {
    //     x: window.pageXOffset,
    //     y: window.pageYOffset,
    //   }
    // },

    routes: createRoutes({ Router, Vue }),
  })
}


import {
  setCookie,
  getCookie,
} from '@utils/cookies'

export const ACTIVE_PRODUCT_COOKIE_NAME = 'TOTIS2.active_product'

export const toggleDetails = {
  bind(el, binding, vnode) {
    el.addEventListener('click', () => {
      const details = document.getElementById('details')
      details.removeAttribute('open')
    })
  },
}

export const toggleWeight = {
  bind(el, binding, vnode) {
    el.addEventListener('click', () => {
      const parent = vnode.elm.closest(el.dataset.parent)
      const needUrlReplace = el.dataset.replace
      const otherTriggers = Array.from(parent.getElementsByClassName('variant-trigger'))
      otherTriggers.forEach(trigger => {
        trigger.classList.remove('is-active')
      })
      el.classList.add('is-active')
      const weight = Array.from(parent.getElementsByClassName('variant-view'))
      vnode.context.date = new Date()
      weight.forEach(w => {
        w.classList.remove('is-active')
        if (w.dataset.weight == binding.value) {
          w.classList.add('is-active')
          parent.dataset[w.dataset.key] = w.dataset.val
          // set not-available class when quantity zero
          if (w.dataset.count) {
            if (w.dataset.count < 1) {
              parent.classList.add('not-available')
              return
            }
            parent.classList.remove('not-available')
          }
        }
      })
      if (!needUrlReplace) return
      setCookie(ACTIVE_PRODUCT_COOKIE_NAME, binding.value, 1)
    })
  },
}

const ZERO = 0
// const OFFSET = 24
const hoverEffect = el => {
  if (el.classList.contains('is-active')) return

  el.classList.add('is-active')
}
const antiHoverEffect = el => {
  const hover = el.getElementsByClassName('simple-hover')[0]
  el.classList.remove('is-active')
  hover.style.marginBottom = ZERO
}
const clearAllHovers = () => new Promise(res => {
  const cards = Array.from(document.getElementsByClassName('product'))
  cards.forEach(el => antiHoverEffect(el))
  res(true)
})

const getDataset = el => el.getElementsByClassName('product__link is-active')[0].dataset

const goToProductPage = (el, e) => {
  if (
    e.target.classList.contains('product__link')
  ) {
    // el.classList.contains('is-active') &&
    const { weight } = getDataset(el)
    setCookie(ACTIVE_PRODUCT_COOKIE_NAME, weight, 1)
    // window.location.href = e.target.href
  }
}

// product card

export const cardHover = {
  bind(el) {
    el.addEventListener('click', e => goToProductPage(el, e))
    // if (
    //   navigator.maxTouchPoints > ZERO ||
    //   navigator.msMaxTouchPoints > ZERO
    // ) {
    //   el.addEventListener('click', e => {
    //     e.preventDefault()
    //     goToProductPage(el, e)
    //     clearAllHovers().then(() => hoverEffect(el, vnode))
    //   })
    //   return
    // }
    el.addEventListener('auxclick', e => goToProductPage(el, e))

    // el.addEventListener('mouseenter', () => {
    //   hoverEffect(el, vnode)
    // })
    // el.addEventListener('mouseleave', () => {
    //   antiHoverEffect(el, vnode)
    // })
  },
}

// fot ui-table
export const toggleActivity = {
  bind(el, binding, vnode) {
    el.addEventListener('click', () => {
      const ref = vnode.context.$refs[binding.value]
      const $s = ref[0]
      if ($s) {
        if ($s.classList.contains('is-active')) {
          $s.classList.remove('is-active')
          return
        }
        $s.classList.add('is-active')
      }
    })
  },
}
// lifehack - dummy directive
export const lazy = {
  bind() {

  },
}

// client/src/pug/templates/staff/profile_detail_base.pug:51
// show phone and email - for analytics

export const toggler = {
  bind(el) {
    el.addEventListener('click', () => {
      const parent = el.parentElement.parentElement
      const [child] = parent.getElementsByClassName('staff-contact__val--full')
      child.classList.add('is-active')
      el.parentElement.classList.remove('is-active')
    })
  },
}

function checkSize(selector) {
  return true
  // return (selector.parentElement && selector.offsetWidth > selector.parentElement.offsetWidth)
}

export const tableWrapper = {
  bind(el, binding, vnode) {
    const tables = Array.from(el.getElementsByTagName('table'))
    if (tables.length) {
      tables.forEach(element => {
        if (checkSize(element)) {
          const parent = element.parentNode
          const wrapper = document.createElement('div')
          wrapper.classList.add('scroller')
          parent.replaceChild(wrapper, element)
          wrapper.appendChild(element)
        }
      })
    }
  },
}

export default {
  toggler,
  lazy,
  toggleActivity,
  toggleDetails,
  cardHover,
  toggleWeight,
  tableWrapper,
}

<script>

const lang = window.language
const levelRequest = '/profile/gifts/'

export default {
  name: 'WheelWinModal',
  props: ['info'],
  data() {
    return {
      pretext: this._('действует до'),
      aftertext: this._('включительно'),
      text: this._('Наш менеджер контакт-центра свяжется с вами'),
    }
  },
  methods: {
    linkGenerator() {
      let link = levelRequest
      if ('ru' !== lang) {
        link = `/${lang}${link}`
      }
      window.location.href = link
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.ds-panel--space_4xl
    +b.close-popup.mdi.mdi-close.A(
      @click='$emit("close")'
    )
    +e.element--offset_full
      +b.wheel-modal-wrapper
        +b.g-row.--align_center.--justify_center.--space_sm
          +b.g-cell.g-cols--narrow
            +b.smile-win.background(
              src="/static/img/Partying.png"
            )
          +b.g-cell.g-cols--12
            +b.wheel-modal-title.ds-caption--appearance_center.--size_md.--size_4xl-xl.--bold.--color_accent {{ info.title }}
          +b.g-cell.g-cols--12
            +b.wheel-modal-subtitle.ds-caption--appearance_center.--size_sm.--color_accent {{ pretext }} {{ info.available_until }} {{ aftertext }}
          +b.g-cell.g-cols
            +b.wheel-modal-description.ds-caption--appearance_center.--size_md.--semibold.--color_accent {{ text }}
          +b.g-cell.g-cols--narrow
            +b.smile-with-heart
              +e.image(
                src="/static/img/Smiling-with-hearts.png"
              )
          +b.g-cell.g-cols--12.tt-space_mt--8
            +b.g-row.--align_center.--justify_center.--space_2xl.--appearance_spaced
              +b.g-cell.g-cols--narrow.tt-space_mt--1(v-if="info.twist_count")
                +b.BUTTON.btn-spin.--modal-variant(
                  @click.prevent='$emit("close")'
                ) {{ _('Крутить еще') }}
              //- +b.g-cell.g-cols--narrow.tt-space_mt--1
              //-   +b.BUTTON.btn-spin.--modal-variant(
              //-       @click="linkGenerator()"
              //-     ) {{ _('Мои подарки') }}
</template>
<template lang="pug">
include /mixins
+b.ds-panel--space_2xl
    +b.close-popup.mdi.mdi-close.BUTTON(
      @click.prevent='$emit("close")'
    )
    +e.element--offset_full
      ui-loader(v-if='isLoad')
      +b.VALIDATION-OBSERVER(
        ref="validator",
        v-slot="{ valid }",
        tag="form",
      )
        +b.site-typo-view
          +e.title.site-title.P {{ _('Повідомлення про помилку, ляп, неточність') }}
          +e.text.P {{ _('Текст, який нам потрібно відредагувати:') }}
          +e.typo.P <<...{{ typo }}...>>
          +b.g-row--justify_center
            +b.g-cell.g-cols--12.tt-space_mt--6
              +b.ds-caption--size_md.--semibold.P {{ _('Ваші коментарі/виправлення:') }}
            +b.g-cell.g-cols--12.tt-space_mt--6
              d-control-textarea.is-textarea(
                :rules="'required'",
                :input-label='_("Ваш коментар")'
                rows='4'
                error-classes='error-label--top'
                v-model='form.message',
                name='message'
              )
            +b.g-cell.g-cols--12.tt-space_mt--6
              +b.tt-button.BUTTON(
                type='submit'
                :disabled='isLoad',
                @click.prevent='prepareData(valid)'
              )
                +e.title.P {{ _('Отправить') }}
</template>

<script>

import { createSiteTypo } from '@api/feedback.service'

import pathOr from 'ramda/src/pathOr'
import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'SiteTypoView',
  mixins: [FormMixin],
  props: {
    typo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        text_typo: this.typo,
        message: '',
        source: window.location.href,
      },
      subject: '',
      subjects: [],

    }
  },

  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return createSiteTypo.execute({}, data)
        .then(res => {
          const message = {
            message: {
              ...res.data.message,
              btn: true,
              btnText: this._('Закрити'),
            },
            isPretty: true,
          }
          AnswerMessage(message, this)
          this.$emit('close')
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>


<template lang="pug" functional>
  include /mixins
  ui-table(
    :table-fields='props.tableFields',
    :items='props.list'
  )
    template(
      #table-body-cell-image='{ item }'
    )
      +b.tt-image.PICTURE
        source(
          type="image/webp"
          :srcset='item.props.img.catalog_preview_webp'
        )
        +e.body.IMG(
          :src='item.props.img.catalog_preview'
        )
    template(
      #table-body-cell-sticker='{ item, value }'
    )
      div(v-if='value')
        +b.tooltip-content(
          v-html='value',
          :id='`sticker${item.id}`'
        )
        p(
          v-html='props.truncate(value, 50)',
          v-tooltip.top="{html: `sticker${item.id}`, class: 'custom-tooltip'}"
        )
    template(
      #table-body-cell-title='{ item, value }'
    )
      +b.tt-link--styling_default.ds-caption--size_xs.A(
        :href='item.props.url'
        target='_blank'
      ) {{ value }}
    template(
      #table-body-cell-short_description='{ item, value }'
    )
      p(v-html='value')
    template(
      #table-body-cell-description='{ item, value }'
    )
      div(v-if='value')
        +b.tooltip-content(
          v-html='value',
          :id='`description${item.id}`'
        )
        p(
          v-tooltip.top="{html: `description${item.id}`, class: 'custom-tooltip'}"
          v-html='props.truncate(value, 50)'
        )
    template(
      #table-body-cell-action='{ item }'
    )
      row(
        appearance='nowrap',
        :align='["center"]'
      )
        cell
          add-to-comparison(
            :id='item.id'
          )
            template(#default='{ add, is_added }')
              +b.circle-btn--accent.--clear.A(
                :href='$options.comparison_url'
                v-if='is_added',
                :title="parent._('Перейти в сравнение')"
              )
                +e.icon.IMG(
                  src="/static/img/libra_full.svg"
                )

              +b.circle-btn--accent.--clear.BUTTON(
                v-else
                @click.prevent='add()',
                :title="parent._('Добавить в сравнение')"
              )
                +e.icon.icon-libra.SPAN
        cell
          likes-view(
            :is_auth='true'
            :id='item.id'
          )
    template(
      #table-body-cell-order='{ item }'
    )
      counter-controller()
        template(#default='{ amount, change, clear }')
          row(
            appearance='nowrap',
            :align='["center"]'
          )
            cell
              +b.tt-space_mb-xl--2.COUNTER(
                :amount='amount'
                @change='change($event)'
              )
            cell

              add-to-cart(
                :id='item.id',
                :type='item.type',
                :amount='amount'
                :name='item.caption',
              )
                template(#default='{ add, load }')
                  +b.tt-link--accent.icon-cart.BUTTON(
                    @click.prevent='add'
                    :disabled='load'
                    :title='parent._("Добавить в корзину")'
                  )

</template>
<script>
export default {
  name: 'DistributorTable',
  methods: {

  },
}
</script>

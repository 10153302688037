var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"g-row relative multisearch-scroll-content"},[(_vm.load)?_c('ui-loader',{staticClass:"mini-loading"}):_vm._e(),_c('div',{staticClass:"g-cell g-cols g-cols--12 g-cols--3-md multisearch-scroll"},[_c('a',{staticClass:"multisearch-result-item tt-link tt-link--variant_start",class:{"is-active": !_vm.activeCategory },on:{"click":function($event){$event.preventDefault();return _vm.setActive()}}},[_c('span',{staticClass:"multisearch-result-item__title"},[_vm._v(_vm._s(_vm._('Все результаты')))]),_c('span',{staticClass:"multisearch-result-item__count"},[_vm._v(_vm._s(_vm.found.total))])]),_vm._l((_vm.categories),function(link){return _c('a',{staticClass:"multisearch-result-item tt-link tt-link--variant_start",class:{"is-active": _vm.activeCategory && _vm.activeCategory.id == link.id },on:{"click":function($event){$event.preventDefault();return _vm.setActive(link);}}},[_c('span',{staticClass:"multisearch-result-item__title"},[_vm._v(_vm._s(link.props.title))]),_c('span',{staticClass:"multisearch-result-item__count"},[_vm._v(_vm._s(link.found.total))])])})],2),(_vm.activeCategory)?_c('div',{staticClass:"g-cell g-cols g-cols--12 g-cols--9-md multisearch-scroll-content"},[_c('div',{staticClass:"multisearch-preview"},[_c('div',{staticClass:"multisearch-preview__list"},[_c('paginated-view',{key:_vm.activeCategory.id,attrs:{"found":_vm.activeCategory.found,"resource":_vm.resource,"search":_vm.localsearch,"limit":_vm.$options.LIMIT_BY_CATEGORY,"category":_vm.activeCategory.id},on:{"load:start":function($event){return _vm.$emit("load:start")},"load:end":function($event){return _vm.$emit("load:end")}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var splicedList = ref.splicedList;
var hasNext = ref.hasNext;
var hasPrev = ref.hasPrev;
var getNext = ref.getNext;
var getPrev = ref.getPrev;
return [_c('div',[_c('div',{staticClass:"g-row"},_vm._l((splicedList),function(link,t){return _c('div',{staticClass:"g-cell g-cols g-cols--6 g-cols--6-md g-cols--4-xl"},[_c('product-preview',{attrs:{"link":link,"t":t,"findValueAndHighlightTerm":_vm.findValueAndHighlightTerm,"highlightTerm":_vm.highlightTerm,"localsearch":_vm.localsearch,"trigger_overlay":_vm.trigger_overlay}})],1)}),0),_c('div',{staticClass:"g-row g-row--justify_end"},[_c('div',{staticClass:"g-cell g-cols"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(hasPrev),expression:"hasPrev"}],staticClass:"circle-btn circle-btn--light controls controls--variant_bordered controls--appearance_visible",on:{"click":function($event){$event.preventDefault();return getPrev($event)}}},[_c('span',{staticClass:"circle-btn__icon mdi mdi-chevron-left"})])]),_c('div',{staticClass:"g-cell g-cols"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(hasNext),expression:"hasNext"}],staticClass:"circle-btn circle-btn--light controls controls--variant_bordered controls--appearance_visible",on:{"click":function($event){$event.preventDefault();return getNext($event)}}},[_c('span',{staticClass:"circle-btn__icon mdi mdi-chevron-right"})])])])])]}}],null,false,1704071808)})],1)])]):_c('div',{staticClass:"g-cell g-cols g-cols--12 g-cols--9-md multisearch-scroll-content"},[_c('div',{staticClass:"multisearch-preview"},[_c('paginated-view',{key:"all_results_view",attrs:{"list":_vm.results,"found":_vm.found,"limit":_vm.$options.LIMIT_BY_CATEGORY},on:{"load:start":function($event){return _vm.$emit("load:start")},"load:end":function($event){return _vm.$emit("load:end")}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var splicedList = ref.splicedList;
var hasNext = ref.hasNext;
var hasPrev = ref.hasPrev;
var getNext = ref.getNext;
var getPrev = ref.getPrev;
return [_c('div',[_c('div',{staticClass:"g-row"},_vm._l((splicedList),function(link,t){return _c('div',{staticClass:"g-cell g-cols g-cols--6 g-cols--6-md g-cols--4-xl"},[_c('product-preview',{attrs:{"link":link,"t":t,"findValueAndHighlightTerm":_vm.findValueAndHighlightTerm,"highlightTerm":_vm.highlightTerm,"localsearch":_vm.localsearch,"trigger_overlay":_vm.trigger_overlay}})],1)}),0),_c('div',{staticClass:"g-row g-row--justify_end"},[_c('div',{staticClass:"g-cell g-cols"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(hasPrev),expression:"hasPrev"}],staticClass:"circle-btn circle-btn--light controls controls--variant_bordered controls--appearance_visible",on:{"click":function($event){$event.preventDefault();return getPrev($event)}}},[_c('span',{staticClass:"circle-btn__icon mdi mdi-chevron-left"})])]),_c('div',{staticClass:"g-cell g-cols"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(hasNext),expression:"hasNext"}],staticClass:"circle-btn circle-btn--light controls controls--variant_bordered controls--appearance_visible",on:{"click":function($event){$event.preventDefault();return getNext($event)}}},[_c('span',{staticClass:"circle-btn__icon mdi mdi-chevron-right"})])])])])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
  include /mixins
  div(
    v-bem='{"appearance": "bordered"}'
  )
    component(
      :is="component",
      :disabled='isDisabled'
      :value.prop='value',
      ref='input'
      v-bem.element=''
      v-on="listeners"
      v-bind='[$props, $attrs]'
    )
    component(
      :is='labelComponent',
      :id='id',
      :value='value',
      :input-label='inputLabel',
      :is-required='isRequired'
    )
    component(
      v-if="appendIcon"
      :is="appendComponent"
      :append-icon="appendIcon"
      :icon-append-func="iconAppendFunc"
    )
    +b.change-type.mdi.BUTTON(
      v-if='type == "password" && !appendIcon '
      @click.prevent='changeType()',
      tabindex='-1'
      :class='show ? "mdi-eye-off" : "mdi-eye"'
    )
    +b.close-btn.BUTTON(
      v-if='$attrs.innerClearBtn && value'
      @click.prevent='clear',

    ) &times;
    component(
      :is="errorComponent",
      :classes='errorClasses'
      :error='error'
    )
</template>
<script>
export default {
  name: 'UnifiedControlDescriptor',
  block: 'd-control-input',
  props: {
    component: {

    },
    labelComponent: {},
    errorComponent: {
    },
    id: {},
    inputLabel: {},
    isRequired: {},
    isDisabled: {
      type: Boolean,
      default: false,
    },
    error: {},
    value: {},
    type: {},
    errorClasses: {},
    appendComponent: {},
    appendIcon: {},
    iconAppendFunc: {},
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: $event => { this.$emit('input', $event ? $event.id ? $event : ($event.type ? $event.target[this.getKey()] : $event) : $event) },
      }
    },
  },
  methods: {
    clear() {
      // this.value = ''
      this.$emit('input', '')
    },
    getKey() {
      return 'checkbox' === this.type ? 'checked' : 'value'
    },
    changeType() {
      this.show = !this.show
      if (this.show) {
        this.$refs.input.type = 'text'
      } else {
        this.$refs.input.type = this.type
      }
    },
  },

}
</script>

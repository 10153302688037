<template lang="pug">
div
  //- image-captcha
  //- captcha.control-descriptor--clear(
    //- v-model='captcha'
    //- ref='captcha'
    //- :class='{ "is-hidden-captcha": hideCaptcha }'
    //- @verify='saveCaptcha($event)'
    //- :get-captcha='getter'
    //- :key='`${customKey}-captcha`'
    //- :id='customCaptchaId'
  //- )
  ui-loader(v-if='isLoad')
  slot(
    :is_confirmed='is_confirmed',
    :sendCode='force => validate(force)'
  )
</template>
<script>
import {
  make2FactorRequest,
} from '@api/auth.service'

import {
  AnswerMessage,
} from '@utils/submit'

import { storage } from '@utils/session-storage'

const CAPTCHA_FIELD = 'captcha'
const IMAGE_CAPTCHA_FIELD = 'image_captcha'
const TOKEN_FIELD = 'token'
const NON_FIELD = 'non_field_errors'

const REASON_FOR_REDIRECT = 'user_exists'

export default {
  name: 'TokenConfirmationCheck',
  props: {
    token: {
      default: '+380507342777',
    },
    action: {
      type: Function,
    },
    additionalData: {
      type: Object,
      default: () => {},
    },
    hideCaptcha: {
      type: Boolean,
      default: false,
    },
    customKey: {
      type: String,
      default: 'tokenconfirm',
    },
    customCaptchaId: {
      type: String,
      default: 'recaptcha',
    },
    getToken: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      captcha: '',
      is_confirmed: false,
      code: '',
      isLoad: false,
      authPage: window.authUrl,
      g: '',
      isOpen: false,
      inWork: false,
    }
  },
  watch: {
    token() {
      storage.setItem('token', this.token)
    },
  },
  mounted() {
    // this.openCaptcha()
  },
  methods: {
    saveCaptcha(captcha) {
      this.captcha = captcha
      if (this.isOpen) return
      if (!this.inWork) return
      this.$nextTick(() => {
        this.sendCode()
      })
    },
    getter(val) {
      console.log('GETTER');
      this.saveCaptcha(val)
    },
    validate(token) {
      this.inWork = true
      this.g = token
      this.isLoad = true
      if (this.additionalData && this.additionalData.verified && this.additionalData.verified === this.token) {
        this.isLoad = false
        this.is_confirmed = true
        this.inWork = true
        this.action()
        return
      }
      this.openCaptcha()
    },
    async sendCode() {
      this.isLoad = true
      console.log(this.token, this.customKey, '>>>>>>>>>>>');
      try {
        const { id } = (await make2FactorRequest.execute({}, {
          token: this.token || storage.getItem('token'),
          image_captcha: this.captcha,
          ...this.additionalData,
        }))
        this.isLoad = false
        this.$modal.hide('ImageCaptcha')
        this.openModal(id)
      } catch (e) {
        this.isLoad = false
        e.json().then(body => {
          const errors = {}

          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })
          let title = this._('Token validation error header')
          let btn = false
          const btnText = this._('Вход')
          const [first] = Object.keys(errors).reverse()
          console.log(first, errors, Object.keys(errors), '>>>>>>>>>>>');
          let message = ''
          if (first === TOKEN_FIELD) {
            title = errors[first][0].message
          }
          if (first === CAPTCHA_FIELD || first === IMAGE_CAPTCHA_FIELD) {
            title = this._('Captcha validation error title')
            message = this._('Captcha validation error text')
          } else {
            this.$modal.hide('ImageCaptcha')
          }
          if (first === NON_FIELD) {
            title = this._('Too many requests error title')
            // message = this._('Captcha validation error text')
          }
          try {
            const [{ reason }] = errors[first]
            if (reason === REASON_FOR_REDIRECT) {
              // const data = {
              // phone: this.token,
              // }
              storage.setItem('user', this.token)
              // window.location.href = `${this.authPage}?_state=${btoa(JSON.stringify(data))}`
              // return
              message = errors[first][0].message
              btn = window.authUrl
              this.showLogin()
              return
            }
          } catch (e) {
            console.log(e);
          }
          const meta = {
            message: {
              title,
              text: message,
              btnText,
              btn,
            },
          }
          console.log(this.$modal);
          AnswerMessage(meta, this)
        })
      }
    },
    showLogin(id) {
      this.$modal.show(
        () => import('@app/Auth/LoginModal'),
        {
          token: this.token,
          info: { tag: 'Auth' },
          id,
          action: this.action,
          additionalData: this.additionalData,
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true,
        }
      )
    },
    getCaptcha() {
      return this.captcha
    },
    toPromise(arg) {
      if (!arg) return Promise.reject()

      return Promise.resolve(arg)
    },
    openCaptcha() {
      this.isLoad = false
      this.isOpen = true
      const pr = f => this.toPromise(f)
        .then(value => {
          this.captcha = value
          this.inWork = true
          this.sendCode()
        })
      this.$modal.show(
        () => import('@app/UiElements/Controls/ImageCaptcha'),
        {
          token: this.token,
          promise: pr,
          action: this.action,
          additionalData: this.additionalData,
          parent: this,
        }, {
          name: 'ImageCaptcha',
          height: 'auto',
          clickToClose: false,
          scrollable: true,
          width: 300,
        }, {
          closed: () => {
            this.isOpen = false
          },
        }
      )
    },
    openModal(id) {
      this.isOpen = true
      this.$modal.show(
        () => import('@app/TwoFactor/TokenConfirmation'),
        {
          token: this.token,
          id,
          action: this.action,
          additionalData: this.additionalData,
          parent: this,
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true,
        }, {
          closed: () => {
            this.isOpen = false
          },
        }
      )
    },
  },
}
</script>

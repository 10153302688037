<template lang="pug">
  include /mixins
  div
    new-light-box(
      :images='images',
      :media='images',
      ref="lightbox"
      :show-light-box="false"
      :showThumbs='false'
      :showCaption="caption"
    )
    slot(
      :add='(event) => addListeners(event)',
      :show='(event) => show(event)',
      :set='(event) => setIndex(event)',
    )

</template>
<script>
import NewLightBox from 'vue-image-lightbox'

export default {
  components: {
    NewLightBox,
  },

  props: ['images', 'caption'],
  data() {
    return {
      index: 0,
    }
  },
  methods: {
    addListeners(slick) {
      const slides = Array.from(
        slick.$list[0].getElementsByClassName('slick-slide')
      )
      slides.forEach(el => {
        const idx = el.children[0].children[0].dataset.img
        const $this = this
        el.addEventListener('click', () => $this.show(idx))
      })
    },
    setIndex(idx) {
      this.index = idx
    },
    show(idx = 0) {
      const parent = this.$refs.lightbox.$el.closest('.product-sticky')
      const parent2 = this.$refs.lightbox.$el.closest('.product-detail')
      if (parent && parent2) {
        parent.style.zIndex = 556
        parent2.style.zIndex = 'initial'
      }
      this.$refs.lightbox.showImage(idx ?? this.index)
    },
  },
}
</script>

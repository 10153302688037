import {
  createResource,
  postResource,
} from '@resource/resource'

import {
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const PROTOCOL_MODEL = 'protocol'

const QUESTION_CREATE_URL = prefixAPI('/question/create/', PROTOCOL_MODEL)

export const createProtocolQuestion = createResource(
  QUESTION_CREATE_URL,
  postResource
)

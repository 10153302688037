<template lang="pug">
  include /mixins
  +b.ds-panel--space_lg
    +e.element--offset_horizontal
      +b.g-row
        template(v-for='val in filteredValues')
          +b.g-cell.g-cols--6
            +b.variants-filter
              +e.element.INPUT(
                type='radio'
                :value='val.props',
                :name='val.type'
                v-model='checkedValue'
                :input-label='val.props.title',
                :id='`${val.type}:${val.id}`'
                @change='setChecked'
              )
              +e.LABEL.label--light.ds-caption--size_sm(
                :for='`${val.type}:${val.id}`'
              ) {{ val.props.title }} {{ currency }}
      +b.g-row--align_center.--appearance_nowrap
        +b.g-cell.g-cols--12
          +b.tt-space_mb--2
            range-slider(
              v-model="checkedProxy",
              :interval='1'
              :height="4",
              :min='getMin'
              :max='getMax'
              tooltip='none',
              @change='setCheckedFromProxy'
            )
              template(#process='{ start, end, style, index }')
                .vue-slider-process.custom-process(
                  :style="[style]"
                )
              template(#dot='')
                +b.custom-dot
      +b.g-row--align_center.--appearance_nowrap
        +b.g-cell.g-cols--4
          input.d-control-input__element.d-control-input__element--shadowed.ds-caption--size_sm(
            type='number',
            step='1'
            :max='getMax'
            :min='getMin'
            v-model.number='checked[0]'
          )
        +b.g-cell.g-cols--narrow
          +b.ds-caption--size_sm.P {{ _('до') }}
        +b.g-cell.g-cols--4
          input.d-control-input__element.d-control-input__element--shadowed.ds-caption--size_sm(
            type='number',
            step='1'
            :min='getMin'
            :max='getMax'
            v-model.number='checked[1]'
          )
        +b.g-cell.g-cols--narrow
          +b.ds-caption--size_sm.P {{ currency }}
        +b.g-cell.g-cols--narrow
          +b.tt-button--appearance_counter.BUTTON(
            @click.prevent='submit'
          )
            +e.title--mini.P {{ _("ok") }}

</template>
<script>
import debounce from 'lodash.debounce'

import {
  isEmpty,
} from '@aspectus/vue-utils'

import Range from 'vue-slider-component'

export default {
  name: 'RangeWithVariantsWidget',
  props: ['value', 'filter', 'available', 'parameters'],
  components: {
    RangeSlider: Range,
  },
  data() {
    return {
      checked: {},
      currency: window.currency,
      updateMax: '',
      checkedValue: {},
      checkedProxy: {},
      debounceChanger: () => {},
    }
  },
  computed: {
    getMax() {
      const availableMax = this.available && this.available.prices && this.available.prices.max_price
      const [, valueMax] = this.normalize(this.value)
      return this.roundMax(Math.max(availableMax, valueMax)) || 0
    },
    getMin() {
      const availableMin = this.available && this.available.prices && this.available.prices.min_price
      const [valueMin] = this.normalize(this.value)
      return this.roundMin(Math.min(availableMin, valueMin)) || 0
    },
    filteredValues() {
      const values = this.filter.filter(a => a.props.min_value >= this.getMin && a.props.max_value <= this.getMax)
      return values
    },
  },
  watch: {
    value: {
      handler(nval, oval) {
        if (JSON.stringify(oval) === JSON.stringify(nval)) return
        this.checked = this.normalize(nval)
      },
    },
    'available.prices': {
      handler(nval, oval) {
        if (JSON.stringify(oval) === JSON.stringify(nval)) return
        this.checked = this.normalize(this.value)
      },
    },
    checked: {
      handler() {
        this.debounceChanger()
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    const delay = 1000
    this.debounceChanger = debounce(() => {
      this.changer()
    }, delay)
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },
  methods: {
    setCheckedFromProxy() {
      this.checked = this.checkedProxy
    },
    changer() {
      const [min, max] = this.checked
      this.checkedProxy = [Number(min) <= this.getMin ? this.getMin : Number(min), Number(max) >= this.getMax ? this.getMax : Number(max)]
    },
    setChecked() {
      this.checked = [this.checkedValue.min_value, this.checkedValue.max_value]
      this.submit()
    },
    submit() {
      const [min, max] = this.checked
      const value = { min: [!min ? '0' : min], max: [ !max || max === '0' ? '0' : parseInt(max) < parseInt(min) ? min : max] }
      this.$emit('input', value)
    },
    notAvailable(id) {
      const { length } = Object.keys(this.available)
      let answer = false
      if (length && !this.available[id]) answer = true
      return answer
    },
    roundMax(num) {
      return Math.ceil(num)
    },
    roundMin(num) {
      return Math.floor(num)
    },
    currentMin(value) {
      return value.min_value && value.min_value.length && value.min_value[0].split('price-min_')[1]
    },
    currentMax(value) {
      return value.max_value && value.max_value.length && value.max_value[0].split('price-max_')[1]
    },
    normalize(value) {
      if (
        isEmpty(value.min_value) && isEmpty(value.max_value)
      ) {
        return [
          this.roundMin(this.available && this.available.prices && this.available.prices?.min_price) || 0,
          this.roundMax(this.available && this.available.prices && this.available.prices?.max_price) || 0,
        ]
      }
      if (!value) return []
      return [this.currentMin(value), this.currentMax(value)]
    },
  },
}
</script>

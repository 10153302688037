<template lang='pug'>
  include /mixins
  div
    events-sidebar(
      space="xl"
      :filters="filters"
      @update:filters="changeFilters"
      :pagination="pagination"
    )
    events-content(
      :loading="loading"
      :items="result && result.data"
      :pagination="pagination"
      @update:pagination="changePagination"
    )
      slot
</template>

<script>

import EventsSidebar from '@app/Events/Sidebar'
import EventsContent from '@app/ListSimple/Content'

export default {
  components: { EventsSidebar, EventsContent },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
  ],

  created() {
    // this.search()
  },

  methods: {
    search() {
      this.receive(this.parameters)
    },
  },
}

</script>

<script>

import { mapActions } from 'vuex'
import CartView from '@app/Cart/View'
import analyticCartAdd from '@analytic/add.js'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'discount-counter-controller',
  props: {
    id: {},
    type: {},
    name: {},
    maxCount: {},
  },
  data() {
    return {
      items: {},
      load: false,
      validation: {},
    }
  },
  methods: {
    ...mapActions('cart', [
      'changeCart',
    ]),
    changeCount(e) {
      this.items[e.variant_id] = {
        ...this.items[e.variant_id],
        [e.id]: e,
      }
      Object.keys(this.items).forEach(id => {
        const v = Object.keys(this.items[id]).some(k => 0 < this.items[id][k].count)
        console.log(v, id, this.items[id], this.validation);
        // this.validation[id] = this.validation[id] || {}
        this.$set(this.validation, id, v)
      })
    },
    async addToCart(id) {
      this.load = true
      const variant = this.items[id]
      const entities = []
      Object.keys(variant).forEach(key => {
        const entity = {
          element: {
            id,
            type: variant[key].variant_type,
          },
          quantity: variant[key].count,
          parameters: {
            quality_type_id: Number(key),
          },
        }
        entities.push(entity)
      })
      try {
        await this.changeCart({ data: { entities }, retrieve: false }).then(({ data }) => {
          const element = data.groups.find(e => e.base.element.id === this.id)
          if (element) {
            const { brand: { props: { title: brand } }, categories, title: name } = element.base.element.props.product.props
            const { price } = data
            analyticCartAdd(
              name, this.id, price, this.amount, brand, categories.map(l => l.props.title)
            )
          }
        })
        this.openCart()
        this.load = false
      } catch (e) {
        console.log(e);
        this.load = false
        const { errors } = await e.json()
        const [error] = errors
        const [first] = error.state.non_field_errors
        const meta = {
          message: {
            header: this._('Максимальна кількость товарів перевищена. Даний товар вже є у кошику'),
            title: first.message,
          },
        }
        AnswerMessage(meta, this)
      }
    },
    openCart() {
      this.$modal.show(
        CartView,
        {
          retrieve: false,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
  render() {
    return this.$scopedSlots.default({
      changeCount: e => this.changeCount(e),
      addToCart: e => this.addToCart(e),
      isValid: this.validation,
      load: this.load,
    })
  },
}
</script>

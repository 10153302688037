<template lang="pug">
include /mixins

+b.mobile-apps-view
  +b.close-popup--style_light.mdi.mdi-close.BUTTON(
    @click.prevent='$emit("close")'
  )
  +e.head
    +e.percent.P {{ _('3') }} #[span {{ _('%') }}]
    +e.text.P {{ _('Забирай кешбек') }}
  +e.title.P {{ _('Замовляй через застосунок TOTIS Pharma — і отримуй бонуси.') }}
  +e.actions
    +b.mobile-app-btn.A(
      v-if='$options.app_store_app_link'
      :href='$options.app_store_app_link'
      target='_blank'
    )
      +e.img.IMG(src='/static/img/AppStore.svg')
    +b.mobile-app-btn.A(
      v-if='$options.google_play_app_link'
      :href='$options.google_play_app_link'
      target='_blank'
    )
      +e.img.IMG(
        src='/static/img/GooglePlay.svg'
      )

</template>

<script>
import {
  setCookie,
} from '@utils/cookies'

import { TOTIS_2_MOBILE_APPS_VIEW } from '@/app'

const { google_play_app_link, app_store_app_link } = window

export default {
  name: 'MobileAppsView',
  google_play_app_link,
  app_store_app_link,
  mounted() {
    setCookie(TOTIS_2_MOBILE_APPS_VIEW, true)
  },
}
</script>

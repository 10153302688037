<template lang="pug">
  include /mixins
  div
    ui-click-outside(
      :do='close'
    )
      div.relative
        slot(
          name='trigger'
          :open='open'
        )
          +b.share-by-sms(
            @click.prevent='open'
          )
            +e.btn
              +e.icon.icon-new-share
              +e.title.P {{ title || defaultTitle }}
        +b-context('share-by-sms')
          transition(name="fade")
            +e.dropdown(v-if='show')
              +e.row(
                align="center"
                appearance='nowrap'
              )
                +e.part.CELL
                  +b.ds-caption--size_sm.--regular.P.--color_accent {{ _("Отправить:") }}
                +e.items
                  +e.item(v-for='{ network, icon, title }, index in networks' :key='index')
                    share-network.share-wrapper(
                      :url="shareUrl"
                      :network='network'
                      :title="shareTitle"
                      :description="shareDescription"

                    )
                      +b.social-link-icon.A(
                        :title="title"
                      )
                        +e.icon.IMG(:src="`/static/img/social/${icon}.svg`")
                  +e.item
                    +b.social-link-icon--mini.A(
                      title="copy"
                      @click.prevent='copy'
                    )
                      +e.icon.IMG(:src="`/static/img/social/link.svg`")
</template>

<script>
export default {
  name: 'ShareBySms',
  props: {
    shareUrl: String,
    shareTitle: String,
    shareDescription: String,
    title: {
      type: String,
    },
  },
  data() {
    return {
      show: false,
      networks: [
        { icon: 'viber', network: 'viber', title: this._('Viber') },
        { icon: 'telegram', network: 'telegram', title: this._('Telegram') },
        { icon: 'facebook', network: 'facebook', title: this._('Facebook') },
        // { icon: 'letter', network: 'email', title: this._('Email') },
      ],
      defaultTitle: this._('Поделиться Акцией с коллегой'),
      e: null,
    }
  },
  methods: {
    async open() {
      const ZERO = 0
      if (
        (navigator.maxTouchPoints > ZERO ||
        navigator.msMaxTouchPoints > ZERO) &&
        navigator.canShare()
      ) {
        await navigator.share({
          title: this.shareTitle,
          text: this.shareDescription,
          url: this.shareUrl,
        })
        return
      }
      this.show = !this.show
    },
    close() {
      this.show = false
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.shareUrl)
        this.$notify({
          type: 'notify',
          title: `${this._('Посилання скопійовано')}`,
        })
      } catch (err) {
        this.$notify({
          type: 'notify',
          title: `${this._('Посилання не вдалося скопіювати')}`,
        })
        throw new Error('Oops, unable to copy')
      }
    },
  },
}
</script>

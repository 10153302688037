import Subscription from '@app/Forms/Subscription'
import EventRequest from '@app/Forms/EventRequest'
import FormGenerator from '@app/Forms/FormGenerator'
import ModalWrapper from '@app/Forms/ModalWrapper'

import LoyaltyProgramForm from '@app/Forms/LoyaltyProgramForm'
import NewCallbackForm from '@app/Forms/NewCallbackForm'
import HelpToGetStarted from '@app/Forms/HelpToGetStarted'
import ProtocolQuestion from '@app/Forms/ProtocolQuestion'

import AddressBookAdd from '@app/Staff/AddressBook/Add'

export default function install(Vue) {
  Vue.component(AddressBookAdd.name, AddressBookAdd)
  Vue.component(Subscription.name, Subscription)
  Vue.component(EventRequest.name, EventRequest)
  Vue.component(FormGenerator.name, FormGenerator)
  Vue.component(ModalWrapper.name, ModalWrapper)
  Vue.component(LoyaltyProgramForm.name, LoyaltyProgramForm)
  Vue.component(NewCallbackForm.name, NewCallbackForm)
  Vue.component(HelpToGetStarted.name, HelpToGetStarted)
  Vue.component(ProtocolQuestion.name, ProtocolQuestion)
}

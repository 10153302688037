import {
  attributesResource,
  availableAttributesResource,
  pricesResource,
} from '@api/catalog.service'
import FilterController from '@app/FiltersSimple/Controller'
import {
  FILTERS_SHORTEN,
  FILTERS_EXTENDED,
  FILTERS_ALL,
  omitExtended,
} from '@app/FiltersSimple/const'

export default {
  props: [
    'value',
    'allProps',
    'availableProps',
    'pricesProps',
    'pagination',
    'needSorting',
    'needPricing',
    'needTitle',
    'needSearch',
    'sortlist',
    'label',
    'needBlocking',
    'initbaseUrl',
    'kwargs',
  ],

  attributesResource,
  pricesResource,
  availableAttributesResource,
  components: {
    FilterController,
  },

  data() {
    const allFilters = FILTERS_EXTENDED
      .some(key => (this.value.filters &&
        key in this.value.filters && this.value.filters[key]))
    return {
      allFilters,
      displayedFilters: this.getDisplayedFilters(allFilters),
    }
  },
  methods: {
    handleFilter(parameters) {
      this.$emit('input', this.clearExtendedFilters(parameters))
    },

    toggleAllFilters() {
      this.setFiltersAvailability(!this.allFilters)
    },

    getDisplayedFilters(all) {
      return all ? FILTERS_ALL : FILTERS_SHORTEN
    },

    clearExtendedFilters(parameters) {
      if (this.allFilters) {
        return parameters
      }

      const { filters = {}, ...rest } = parameters
      return { filters: omitExtended(filters), ...rest }
    },

    setFiltersAvailability(all) {
      this.allFilters = all
      this.displayedFilters = this.getDisplayedFilters(this.allFilters)
    },
  },
}

<template lang="pug">
  include /mixins
  div(v-if='!authUser')
    +b.g-row--align_center.--justify_between.tt-space_mb--4.tt-space_mt--4
      +b.g-cell.g-cols--6.--6-xl
        +b.tt-link--light.--variant_start.BUTTON(
          @click.prevent='change(false)'
          :class='isOldUser ? "is-passive": "is-selected"'
        )
          +e.title.P {{ _('Я новый покупатель') }}
      +b.g-cell.g-cols--6.--6-xl
        +b.tt-link--light.--variant_end.BUTTON(
          @click.prevent='change(true)'
          :class='isOldUser ? "is-selected": "is-passive"'
        )
          +e.title.P {{ _('Я постоянный клиент') }}
    +b.line-h--light
</template>

<script>
export default {
  name: 'UserTypeTrigger',
  data() {
    return {
      authUser: window.authent,
      isOldUser: false,
    }
  },
  methods: {
    change(value) {
      this.isOldUser = value
      this.$emit('user:type:update', this.isOldUser)
    },
  },
}
</script>

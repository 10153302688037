var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resource-loader-controller',{attrs:{"resource":_vm.$options.getOrderSpecialOfferList,"immediate":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var receive = ref.receive;
var result = ref.result;
var loading = ref.loading;
return [_c('div',[(result && result.length)?_c('div',[_c('div',{staticClass:"ds-panel"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_vertical"},[_c('div',{staticClass:"line-h line-h--light"}),_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_vertical ds-panel__element--indent_inside"},[_c('p',{staticClass:"ds-caption ds-caption--size_sm"},[_vm._v(_vm._s(_vm._('Если вы участвуете в закупке под спецпредложения, то поставьте галочку и наш менеджер свяжется с вами для уточнения')))])])])]),_c('v-selection-controller',{ref:"controller",attrs:{"options":result,"multiple":true,"key-getter":_vm.$options.keyGetter},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var options = ref.options;
var model = ref.value;
var change = ref.change;
return [_c('validation-provider',{attrs:{"name":"special_offer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',[_c('div',{staticClass:"um-space_mt um-space_mt--3"},[_c('tag',{key:"key_without_special_offer",ref:"special_offer_without_special_offer",staticClass:"d-control-static-input d-control-static-input--appearance_inline",attrs:{"rules":{ required: { allowFalse: false } },"tag":"input","input-label":_vm._("Не участвую"),"checked":model.find(function (e) { return e && e.id == _vm.defaultValue.id; }),"id":"special_offer_without_special_offer","type":"checkbox","name":"special_offer"},on:{"change":function($event){change(_vm.defaultValue); _vm.clearOther(model, options); _vm.$emit("input", _vm.type)}},model:{value:(model),callback:function ($$v) {model=$$v},expression:"model"}}),_c('label',{staticClass:"input-group__input",attrs:{"for":"special_offer_without_special_offer"}},[_vm._v(_vm._s(_vm.defaultValue.props.title))])],1),_vm._l((options),function(ref){
var value = ref.value;
return _c('div',{staticClass:"um-space_mt um-space_mt--3"},[_c('tag',{key:("key_" + (value.id)),ref:("special_offer_" + (value.id)),refInFor:true,staticClass:"d-control-static-input d-control-static-input--appearance_inline",attrs:{"rules":{ required: { allowFalse: false } },"tag":"input","checked":!!model.find(function (e) { return e && e.id == value.id; }),"input-label":value.props.title,"id":("special_offer_" + (value.id)),"type":"checkbox","name":"special_offer"},on:{"change":function($event){change(value);  _vm.clearDefault(model, options); _vm.$emit("input", _vm.type)}},model:{value:(model),callback:function ($$v) {model=$$v},expression:"model"}}),_c('label',{staticClass:"input-group__input",attrs:{"for":("special_offer_" + (value.id))}},[_vm._v(_vm._s(value.props.title))])],1)})],2)]}}],null,true)})]}}],null,true),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
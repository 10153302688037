import {
  createResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'sales/'

const SALES_GROUPS_URL = prefixAPI('product-groups/{id}/', MODEL)

export const getSalesGroups = createResource(SALES_GROUPS_URL)

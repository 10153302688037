<template lang="pug">
  include /mixins
  menu-level-renderer(
    :item-id='itemId'
    :state='date'
  )
    template(v-slot="{ list }")
      div
        tree-menu-trigger(
          v-for='item in list'
          :key='item.id'
        )
          template(#default='{ trigger: triggerChildChild, close: closeChildChild, showItem: showChildChildItem }')
            div
              +b.mob-menu-item
                +e.e.g-row--justify_between.--align_center(
                )
                  .g-cell.g-cols.g-cols--narrow
                    +e.link.tt-link.--variant_start.ds-caption--size_sm.--medium.--color_accent.A(
                      :href='item.props.url'
                      :target='item.props.redirect_policy'
                    )
                      +e.text.P {{ item.props.title }}
                  .g-cell.g-cols.g-cols--narrow(
                    v-if='item.props.has_child'
                    @click='triggerChildChild'
                  )
                    +e.trigger.mdi.mdi-chevron-right()
              transition(name='slide')
                +b.mobile-sidebar--full(v-if='showChildChildItem')
                  +b.parent-mob-link.tt-link.--variant_start.ds-caption--size_md.--medium.A(
                    @click.prevent='closeChildChild'
                  )
                    +e.left
                      +e.icon.mdi.mdi-chevron-left
                      +e.text.P {{ item.props.title }}
                    +e.close.mdi.mdi-close
                  +b.line-h--light
                  menu-level-renderer(
                    :item-id="item.id",
                    v-if="showChildChildItem"
                    :state='date'
                  )
                    template(v-slot="{ list: childList }")
                      div
                        +b.mob-menu-item.tt-link.--variant_start.ds-caption--size_sm.--medium.--color_accent.A(
                          v-for='el in childList'
                          :href='el.props.url'
                          :target='el.props.redirect_policy'
                        )
                          +e.text.P {{ el.props.title }}

</template>
<script>
export default {
  props: ['itemId', 'title'],
  name: 'TreeMenuWrapper',
  data() {
    return {
      date: new Date(),
    }
  },
}

</script>

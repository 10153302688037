<script>
export default {
  name: 'CounterController',
  props: {
    maxCount: {
      type: [String, Number],
    },
    initial: {
      type: Number,
      default: 1,
    },
    id: {
      type: String,
    },
    variant_id: {},
    variant_type: {},
  },
  data() {
    return {
      amount: this.initial,
    }
  },
  created() {
    this.emitter()
  },
  methods: {
    emitter() {
      this.$emit('change', {
        count: this.amount,
        id: this.id,
        variant_id: this.variant_id,
        variant_type: this.variant_type,
        max_count: this.maxCount,
      })
    },
    changeAmount(val) {
      this.amount = val.count
      this.emitter()
    },
  },
  render() {
    return this.$scopedSlots.default({
      amount: this.amount,
      change: val => this.changeAmount(val),
      max_count: this.maxCount,
    })
  },
}
</script>

<template lang="pug">
  include /mixins
  payment-requisites
    template(#content='{ result }')
      +b.ds-caption--size_md.--size_lg-xl.--size_xl-fhd.--medium.P {{ _('Реквізити для оплати') }}
      +b.g-row.--appearance_spaced
        +b.g-cell.g-cols--12.--6-sm(
          v-for='item in result'
        )
          +b.requisite(
          )
            +e.data
              +e.placeholder.P {{ item.props.title }}
              +e.value.P {{ item.props.value }}
            +e.copy.mdi.mdi-content-copy(
              :title='_("Нажміть, щоб скопіювати")'
              v-clipboard:copy="item.props.value"
              v-clipboard:success="() => $notify({'title': _('Дані успішно скопійовані')})"
            )
</template>

<script>
export default {
  name: 'PaymentRequisitesModal',
}
</script>

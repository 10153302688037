<template lang='pug'>
  include /mixins
  div
    controller.relative.on-overlay.knowledge-search(
      :localsearch='search'
      :preview='$options.preview'
      :searchResource='$options.asnwersSearchResource'
    )
      template(#inputview='{ keydownEvent, keyupEvent, model, goToSearch }')
        +b.g-cell
          +b.control-stack
            +b.g-row--align_center.--space_none
              +b.g-cell.g-cols--auto
                +b.relative.search-form--fully
                  +b.search-trigger.--fixed-width.BUTTON(
                    @click.prevent='activeSearch'
                  )
                    +e.loop.icon-search
                  +b.D-CONTROL-INPUT.d-control-input--appearance_stacked.control-stack__element.control-stack__element--position_first(
                    name='search',
                    v-bem:d-control-input="{'appearance': 'bordered', 'appearance': 'large'}"
                    type='search',
                    :placeholder='_("Введите здесь свой поисковый запрос ... ")',
                    v-model='search',
                    @keydown='keydownEvent'
                    autocomplete='aqngfjqanfklan'
                    ref='search-input'
                    @keyup="keyupEvent",
                  )
              +b.g-cell.g-cols--narrow
                +b.tt-button--for-input.control-stack__element.control-stack__element--position_last.BUTTON(
                )
                  +e.title--mini.SPAN {{ _('Search') }}
      template(#link='{ meta }')
        +b.tt-space_pa--2.A.tt-link--styling_default.--variant_end.ds-caption--size_xsm.--medium(
          :href="`${url}filters/search=${search}/`",
        ) {{ _('Найдено') }} {{ meta.total }}
</template>
<script>
import { mapState } from 'vuex'

import {
  asnwersSearchResource,
} from '@api/vendor.service'

import ArticlePreview from '@app/Search/Previews/Article'

import Controller from './Controller'

const preview = ArticlePreview

export default {
  name: 'KnowledgeBaseSearch',
  props: ['className', 'fieldId', 'url'],
  components: {
    Controller,
    ArticlePreview,
  },
  asnwersSearchResource,
  preview,
  watch: {
    global_search(nval) {
      this.search = nval
    },
  },

  data() {
    return {
      search: '',
    }
  },
  computed: {
    ...mapState('search', [
      'global_search',
    ]),
  },

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if ('search/SET_SEARCH' == mutation.type) {
        this.search = mutation.payload
      }
    })
  },
}

</script>

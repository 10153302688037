import { numberFormats } from "@/i18n"

const DIGITS_FOR_CURRENCY = {
  EUR: 'decimal_eur',
  UAH: 'decimal_uah',
  PLN: 'decimal_pln',
  CZK: 'decimal_czk',
}

export default function roundPrices() {
  const prices = [...document.getElementsByClassName('js-price-view')]
  prices.forEach(p => {
    let price = 0
    const dataPrice = p.dataset.price
    try {
      price = Number(dataPrice.replace(',', '.'))
    } catch (e) {
      price = dataPrice
    }
    const newPrice = new Intl.NumberFormat('ru-RU', numberFormats[window.language][DIGITS_FOR_CURRENCY[window.currency_code] || DIGITS_FOR_CURRENCY.EUR]).format(price)
    const [before, after] = newPrice.split(',') || newPrice.split('.')
    p.innerHTML = `${before}<span class='price-divider'>${after ? ',' : ''}</span>${after || ''} ${window.currency}`
    p.classList.add('is-active')
  })
}

document.addEventListener('dom-bind', () => roundPrices())

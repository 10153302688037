var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validator",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(_vm.isLoad)?_c('ui-loader'):_vm._e(),_c('div',{staticClass:"relative"},[(_vm.non_field_errors)?_c('label',{staticClass:"error-label"},[_vm._v(_vm._s(_vm.non_field_errors[0]))]):_vm._e()]),_c('row',{attrs:{"justify":["end", "between-xl"],"align":"end"}},[_c('div',{staticClass:"g-cell g-cols g-cols--12"},[_c('div',{staticClass:"g-row"},_vm._l((_vm.form_fields),function(ref){
var rules = ref.rules;
var name = ref.name;
var label = ref.label;
var mask = ref.mask;
var showForModels = ref.showForModels;
var tag = ref.tag;
return _c('div',{staticClass:"g-cell g-cols g-cols--12 tt-space_mt tt-space_mt--6"},[(showForModels.includes(_vm.modelName))?_c('tag',{ref:name,refInFor:true,attrs:{"tag":tag || "d-control-input","rules":rules,"input-label":label,"name":name},model:{value:(_vm.form[name]),callback:function ($$v) {_vm.$set(_vm.form, name, $$v)},expression:"form[name]"}}):_vm._e()],1)}),0)]),_c('div',{staticClass:"g-cell g-cols g-cols--12 tt-space_mt tt-space_mt--6"},[_c('button',{staticClass:"tt-button tt-button--redesign-h",attrs:{"type":"submit","disabled":_vm.isLoad},on:{"click":function($event){$event.preventDefault();return _vm.prepareData(valid)}}},[_c('p',{staticClass:"tt-button__title"},[_vm._v(_vm._s(_vm._('Отправить')))])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
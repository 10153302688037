<template lang="pug">
  include /mixins

  +b.sorting
    .is-disable-xl
      native-sorting(
        :values='values',
        :init-value='checked',
        @change='checked = $event; submit()'
      )
    +e.row-xl
      .is-visible-xl(v-if='needTitle')
        +e.row
          slot(
            name='title'
          )
      +e.row(
        v-if='needSearch'
      )
        slot(
          name='search'
        )
      .is-visible-xl
        +e.row
          +e.title.P.ds-caption--size_sm.--light {{ _('Сортировать по:') }}
          +e.values

            +e.value(
              v-for='val in values'
            )
              +b.variants-filter--no-pa(
              )
                +e.element.INPUT(
                  type='radio'
                  :value='val.key',
                  name='order'
                  v-model='checked'
                  :id='`${val.key}`'
                  @change='submit()'
                )
                +e.LABEL.label.ds-caption--size_sm(
                  :class='{"is-active": val.key == checked}'
                  :for='`${val.key}`'
                ) {{ val.title }}
    +e.icon.mdi.mdi-chevron-down
</template>

<script>
export default {
  name: 'Sorting',
  props: [
    'disabled', 'value', 'filters', 'available', 'needTitle', 'needSearch', 'values',
  ],
  data() {
    return {
      vaslues: [
        {
          title: this._('имени (А-Я)'),
          key: 'name',
        },
        {
          title: this._('имени (Я-А)'),
          key: '-name',
        },
        {
          title: this._('популярности'),
          key: '-rating',
        },
        {
          title: this._('новинки'),
          key: '-date',
        },
      ],
      checked: {},
      show: false,

    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },
  methods: {
    submit(val) {
      this.$emit('input', [this.checked])
    },
    notAvailable(id) {
      const { length } = Object.keys(this.available)
      let answer = false
      if (length) {
        if (this.available[id]) {
          answer = false
        } else {
          answer = true
        }
      } else {
        answer = false
      }
      return answer
    },
    normalize(value) {
      if (!value) return []
      const checked = this.values.find(el => {
        if (el.key == value[0]) {
          return el
        }
      })
      return checked.key
    },
  },
}
</script>

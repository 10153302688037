<template lang="pug">
  include /mixins
  +b.user-wheel-modal-wrapper
    +b.g-row.--align_center.--justify_center.--space_sm
      +b.close-popup.--style_light.mdi.mdi-close.A(
        @click='$emit("close")'
      )
      +b.g-cell.g-cols--12.--6-md
        +b.ds-panel.--space_none.--space_2xl-md
          +e.element.--offset_left
            +b.g-row.--align_center
              +b.g-cell.g-cols--12.tt-space_mb--5
                +b.wheel-modal-title.ds-caption--appearance_left.--size_lg.--bold.--color_white {{ info.props.title_for_modal }}
              +b.g-cell.g-cols--auto.--narrow-sm.--auto-md.tt-space_mb--9
                +b.wheel-modal-subtitle.ds-caption--appearance_left.--size_sm.--color_white.--line-h_xs  {{ info.props.sub_title_for_modal }}
              +b.g-cell.g-cols--narrow.tt-space_mb--9
                +b.smile-win.--modal-variant(
                  src="/static/img/Partying.png"
                )
              +b.g-cell.g-cols--12
                +b.g-row.--justify_center-till-xs
                  +b.g-cell.g-col
                    +b.A.btn-spin.--modal-variant(
                      @click='submitForm()'
                    ) {{ info.props.button_text_for_modal }}
      +b.g-cell.g-cols--6
        +b.whell-mini
          +e.image.IMG(
            :src="info.props.image_for_modal || '/static/img/wheel-mini.png'"
          )
</template>

<script>
import {
  setCookie,
} from '@utils/cookies'

import { TOTIS_2_AVAILABLE_WHEELS_CHECK_NAME } from '@/app'

export default {
  name: 'UserWheelModal',
  props: ['info'],
  data() {
    return {
      title: this._('Хватай удачу за колесо!'),
      subtitle: this._('Скорее забирайте ваш подарок от Тotis в День Рождения!'),
    }
  },
  mounted() {
    setCookie(TOTIS_2_AVAILABLE_WHEELS_CHECK_NAME, true)
  },
  beforeDestroy() {
    const [body] = document.getElementsByTagName('body')
    body.classList.remove('is-blocked')
  },
  methods: {
    submitForm() {
      window.location.href = this.info.props.url
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  menu-level-renderer(
    :item-id='itemId'
    ref='renderer'
  )
    template(v-slot="{ list }")
      div
        ui-accordion(
          :init='false'
          v-for='{ props: item } in list'
          :key='item.id'
        )
          template(#default='{ activeState, toggleActive, collapse }')
            ui-click-outside(
              :do='collapse'
            )
              div
                +b.g-cell.g-cols--12
                  +b.tt-space_pt--1(
                    v-bem:menu-drop-down.item='{"baseline": ""}'
                  )
                    +b.tt-link--appearance_hovered.--variant_start.ds-caption--size_xsm.A.full-h.tt-space_ml--2(
                      :href='item.url'
                      :target='item.redirect_policy'
                    )
                      +e.text.P {{ item.title }}
                    template(v-if="item.has_child")
                      +b.mdi.I(
                        v-bem:menu-drop-down.icon=''
                        :class='activeState ? "mdi-minus" : "mdi-plus"'
                        @click.prevent='toggleActive()'
                      )
                menu-level-renderer(
                  :item-id="item.id",
                  :state='activeState'
                  v-if="activeState"
                )
                  template(v-slot="{ list: childList }")
                    div
                      +b.g-cell.g-cols--12(
                        v-for='child in childList'
                      )
                        +b.tt-space_pl--4
                          +b.tt-link--appearance_hovered.--variant_start.ds-caption--size_xsm.--light.tt-space_pt--1.A.full-h(
                            :href="child.props.url"
                            :target="child.props.redirect_policy"
                          )
                            +e.text.P {{ child.props.title }}
</template>

<script>
export default {
  name: 'MainMenuWrapper',
  props: ['itemId'],
}
</script>

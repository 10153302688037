<template lang='pug'>
  div(ref='topOfContent')
    renderer(
      :result='items',
      v-if='items'
    )
    slot(v-else)
    ui-limit-pagination(
      :pagination='pagination'
      @input='handlePaginate',
      :url='url'
    )
</template>

<script>
import Renderer from '@app/List/Renderer'

export default {
  props: ['loading', 'items', 'pagination', 'url'],
  components: {
    Renderer,
  },
  computed: {
    back() {
      if (this.items) {} // eslint-disable-line no-empty
      return `${window.location.pathname}${window.location.search}`
    },
  },
  methods: {
    handlePaginate({ offset }) {
      if (this.loading) {
        return
      }
      const init = 0
      const { limit = init } = this.pagination
      this.$emit('update:pagination', { limit, offset })
      this.$refs.topOfContent.scrollIntoView({ block: 'start', behavior: 'smooth' })
    },
  },
}

</script>

import UserReview from '@app/Review/UserReview'
import UserList from '@app/Review/UserList'
import ReviewAdd from '@app/Review/Add'
import ReviewList from '@app/Review/List'

export default function install(Vue) {
  Vue.component(UserList.name, UserList)
  Vue.component(UserReview.name, UserReview)
  Vue.component(ReviewAdd.name, ReviewAdd)
  Vue.component(ReviewList.name, ReviewList)
}

<template lang="pug">
include /mixins
+b.input-group
  div(
    class='g-recaptcha'
    :data-sitekey="key"
    :id='id'
    data-callback="onSubmit"
    data-size="invisible"
    :data-expired-callback='reload'
    tabindex='-1'
  )
  +e.error.SPAN(
    v-show="error"
  ) {{ error }}
</template>
<script>

import { VueRecaptcha } from 'vue-recaptcha'

export default {
  name: 'Captcha',
  components: {
    VueRecaptcha,
  },
  props: {
    error: {
      default: '',
    },
    reset: {
      default: '',
    },
    value: {},
    reloader: {},
    id: {
      default: 'recaptcha',
    },
    getCaptcha: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      captcha: this.value,
      key: window.captcha,
    }
  },
  watch: {
    reset() {
      this.$refs.recaptcha.reset()
    },
    reloader() {
      this.$refs.recaptcha.reset()
    },
  },
  async mounted() {
    this.creater()
  },
  methods: {
    captchaCreater() {
      if (Array.from(document.getElementsByClassName('grecaptcha-badge')).length) return
      window.grecaptcha.render(document.getElementById(this.id), {
        sitekey: this.key,
        callback: this.onSubmitFn,
      })
    },
    creater() {
      const ff = document.getElementById(this.id)
      if (!window.grecaptcha) {
        console.log('Google Recaptcha script is missing')
      }
      let inst = null
      setTimeout(() => {
        inst = setInterval(() => {
          if (window.grecaptcha?.render && ff) {
            this.captchaCreater()
            clearInterval(inst)
          }
        }, 1000)
      }, 1000)
    },
    onSubmitFn(v) {
      this.captcha = v
      this.getCaptcha(v)
    },
    reload() {
      window.grecaptcha.execute()
    },
    setValue(event) {
      this.captcha = event
      this.$emit('input', this.captcha)
    },
    onExpiredReset() {
      this.captcha = ''
    },
  },
}
</script>

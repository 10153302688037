const prettifier = str => str
  .replaceAll("Decimal('", '')
  .replaceAll("')", '')
  .replaceAll('None', 'null')
  .replaceAll('False', 'false')
  .replaceAll('True', 'true')
  .replaceAll('\'', '"')

const getConfig = () => JSON.parse(prettifier(window.config_temporary_data))

const getCurrentConfig = key => {
  const config = getConfig()
  return config[key] || null
}

const isConfigAllowed = key => {
  const config = getCurrentConfig(key)
  if (!config) {
    return false
  }
  if (config.availability && config.restriction && 'True' === window.isSuperUser) {
    return true
  }
  if (config.availability && !config.restriction) {
    return true
  }
  return false
}

const HALLOWEEN_KEY = 'allow_halloween'
const HALLOWEEN_CLASSNAME = 'is-halloween'

const NEW_YEAR_KEY = 'allow_new_year'
const NEW_YEAR_CLASSNAME = 'is-new-year'

const createConfig = (key, classname, ids) => {
  const allowed = isConfigAllowed(key)
  if (!allowed) return
  ids.forEach(id => {
    const element = document.getElementById(id)
    if (!element) return
    element.classList.add(classname)
  })
}
const createHalloweenConfig = () => {
  createConfig(HALLOWEEN_KEY, HALLOWEEN_CLASSNAME, ['header'])
}
const createNewYearConfig = () => {
  createConfig(NEW_YEAR_KEY, NEW_YEAR_CLASSNAME, ['header', 'footer', 'desktop-hover', 'sales-decor'])
}

const createDecor = () => {
  createHalloweenConfig()
  createNewYearConfig()
}

export default createDecor

<template lang='pug'>
  resource-loader-controller(
    :resource="attributesResource"
    v-slot="allProps"
    @gotIt='saveData'
    :immediate='false'
    key='attributes_resource_loader'
  )
    router-parameters-controller(
      @history-change="manualHistoryChangeHandler"
      :initial="initial"
      :base-url='baseUrl'
      :to="parametersTo"
      key='router_parameters_controller'
      :changer='ddd'
      ref='routerController'
      :kwargs='kwargs'
      :from="parametersFrom"
      v-slot="{ parameters, changeParameters }"
    )
      div
        paginated-resource-controller(
          :resource="productsResource"
          :parameters="parameters"
          :changeParameters="changeParameters"
          :label='label',
          v-slot="paginatedProps"
          :kwargs='kwargs'
          loader-key='loader_controller'
          pagination-key='pagination_controller'
        )
          controller(
            ref="controller"
            :attributesResource='attributesResource'
            :availableAttributesResource='availableAttributesResource'
            v-bind="paginatedProps",
            :needPrices='needPrices'
            :needSorting='needSorting'
            :allProps='allProps'
            :base-url='baseUrl'
            :needTitle='needTitle'
            :needSearch='needSearch'
            :label='label',
            :sortlist='sortlist||default_sort'
            @category-hack='categoryHack'
            :isStoreCatalog='isStoreCatalog'
          )
            slot
</template>

<script>
import { mapMutations } from 'vuex'

import {
  friendlyUrlParser,
} from '@app/Filters/transformers'

import Controller from '@app/List/Controller'

const PRICE_KEYS = ['price-min_', 'price-max_']

const SPECIAL_ORDER_KEYS = ['stock', '-stock']

export default {
  components: { Controller },
  props: {
    initial: {

    },
    initUrl: {},
    initbaseUrl: {},
    productsResource: '',
    attributesResource: '',
    availableAttributesResource: '',
    needPrices: {
    },
    needSorting: {},
    needTitle: {},
    needSearch: {},
    initlabel: {},
    sortlist: {},
    kwargs: {},
    isStoreCatalog: {},
  },
  data() {
    return {
      ddd: '',
      list: [],
      label: this.initlabel,
      baseUrl: this.initbaseUrl,
      default_sort: [
        {
          title: this._('возрастанию цены'),
          key: 'price',
        },
        {
          title: this._('убыванию цены'),
          key: '-price',
        },
        {
          title: this._('акционные'),
          key: '-sale',
        },
        {
          title: this._('топ продаж'),
          key: '-rating',
        },
        {
          title: this._('ожидают поставки'),
          key: 'stock',
        },
        {
          title: this._('новинки'),
          key: '-date',
        },
      ],
      specialSorting: [
        {
          title: this._('Ожидается поставка -'),
          key: '-stock',
        },

      ],
      prices: [],
    }
  },

  methods: {
    ...mapMutations('search', [
      'SET_SEARCH',
    ]),
    categoryHack($event) {
      if ($event) {
        return
      }
      this.label = ''
      this.baseUrl = this.initUrl
    },
    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        label: this.label,
      }
    },
    saveData(val) {
      this.list = val
      this.$refs.routerController.parametersGetter()
    },
    finder(checked, values) {
      const result = values.reduce((acc, obj) => {
        const res = obj.attribute.values.filter(k => checked.find(c => c == k.props.label))
        if (res.length) {
          acc[obj.attribute.label] = res.map(e => e.props.label)
        }
        return acc
      }, {})
      const sorting = (this.sortlist || this.default_sort).concat(this.specialSorting)
      console.log(sorting);
      const sortResult = checked.find(e => sorting.find(s => s.key === e))
      if (sortResult) result.order = [sortResult]
      if (this.needPrices) {
        const [max, min] = checked.filter(e => PRICE_KEYS.find(k => e.includes(k)))
        if (min) result.price_min = [min]
        if (max) result.price_max = [max]
      }
      return result
    },
    parametersFrom({ ...rest }) {
      const data = friendlyUrlParser(this.initbaseUrl)
      const r = this.finder(data, this.list)
      if (data.search) {
        this.SET_SEARCH(data.search[0])
      }
      return {
        ...rest,
        filters: r,
        ...this.kwargs,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>

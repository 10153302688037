import {
  createResource,
  postResource,
  deleteResource,
  postFormDataPrettifierResource,
} from '@resource/resource'

import {
  simpleTransformer,
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const FEEDBACK_MODEL = 'feedback'
const SUBSCRIBTION_MODEL = 'subscription'
const VACANCY_MODEL = 'totis-work'

const CREATE_SITE_SUBSCRIPTION = prefixAPI('/site/create/', SUBSCRIBTION_MODEL)

const CREATE_BRAND_SUBSCRIPTION = prefixAPI('/brand/create/', SUBSCRIBTION_MODEL)
// const DELETE_SUBSCRIPTION = prefixAPI('/delete/brands/{id}/', SUBSCRIBTION_MODEL)
const DELETE_BRAND_SUBSCRIPTION = prefixAPI('/brand/destroy/{id}/', SUBSCRIBTION_MODEL)
const CHECK_BRAND_SUBSCRIPTION = prefixAPI('/brand/status/{id}/', SUBSCRIBTION_MODEL)
const BRAND_LIST_SUBSCRIPTION = prefixAPI('/brand/list/', SUBSCRIBTION_MODEL)
const BRAND_LIST_IDS_SUBSCRIPTION = prefixAPI('/brand/list-ids/', SUBSCRIBTION_MODEL)
const FEEDBACK_SUBJECT_LIST = prefixAPI('/subject/list/', FEEDBACK_MODEL)
const VACANCY_REQUEST_CREATE = prefixAPI('/job-request/create/', VACANCY_MODEL)
const SITE_TYPO_CREATE = prefixAPI('site-typo/create/')

const CREATE_FEEDBACK = prefixAPI('/create/', FEEDBACK_MODEL)

export const getSubjectsList = createResource(FEEDBACK_SUBJECT_LIST)
export const createFeedback = createResource(CREATE_FEEDBACK, postResource)
export const createSiteSubscription = createResource(
  CREATE_SITE_SUBSCRIPTION,
  postResource
)
export const createBrandSubscription = createResource(
  CREATE_BRAND_SUBSCRIPTION,
  postResource
)
export const createSiteTypo = createResource(
  SITE_TYPO_CREATE,
  postResource
)
export const checkBrandSubscription = createResource(
  CHECK_BRAND_SUBSCRIPTION
)
export const brandSubscriptionList = createResource(
  BRAND_LIST_SUBSCRIPTION
)
export const brandSubscriptionIdsList = createResource(
  BRAND_LIST_IDS_SUBSCRIPTION
)
export const createVacancyRequest = createResource(
  VACANCY_REQUEST_CREATE, postFormDataPrettifierResource
)

export const DeleteSubscription = createResource(
  DELETE_BRAND_SUBSCRIPTION, deleteResource, simpleTransformer
)

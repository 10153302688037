import {
  route,
} from '@app/Routing/helpers'

const FavoritesList = () => import('@app/Staff/Favorites/List')

const MODEL = 'favorites'

const LIST = 'favorites/'

export const FAVORITES_PREFIX = `${MODEL}:`
const p = name => FAVORITES_PREFIX + name

export const FAVORITES_LIST_NAME = p('list')

export function createRoutes() {
  return [
    route(LIST, FavoritesList, FAVORITES_LIST_NAME, {
    }),
  ]
}

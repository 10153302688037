import { compileProductsGA3, compileProductsGA4 } from '@analytic/utils'

export function analyticInitCheckout(array) {
  if (!window.is_analytic_enabled) return

  window.dataLayer = window.dataLayer || []

  const products = compileProductsGA3(array)

  window.dataLayer.push({
    event: 'eec.checkout',
    ecommerce: {
      currencyCode: window.currency_code,
      checkout: {
        products,
      },
    },
  })

  const items = compileProductsGA4(array)

  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      currency: window.currency_code,
      items,
    },
  })
}

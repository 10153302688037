import { evolve, map, pipe } from 'ramda'
import { isEmpty } from '@aspectus/vue-utils'
import {
  keyedTransformer as k,
  sameTransformer,
  composeTransformers,
} from '@utils/transformers'
import { parametersMiddleware } from '@aspectus/resource'

const checkEmpty = value => (isEmpty(value) ? undefined : value)
const ar = value => (Array.isArray(value) ? value : [value])
// const selectTo = curry((filter, id) =>
//  filter.props.items.find(({ id }) => id === id))
const selectFrom = ({ id }) => id
const multiselectFrom = pipe(ar, map(selectFrom))
const dateFrom = date => new Date(date).toISOString()
export const dateRangeFrom = evolve({ starts: dateFrom, ends: dateFrom })
const combinedInputFrom = map(composeTransformers(
  k(selectFrom, 'element', 'id'),
  k(sameTransformer, 'search')
))

// const creators = {
//   select: (filters, key) => (filters[key]
//     ? k(map(pipe(selectTo(filters[key]), ar)), key)
//     : null
//   ),
// }

// export const createToTransformers =
// filters => composeTransformers.apply(null, [
//   creators.select('sex'),
// ].filter(x => x !== null))

const simplifiers = {
  select: key => k(pipe(selectFrom, checkEmpty), key),
  multiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  intRange: key => k(pipe(sameTransformer, checkEmpty), key),
  autocompleteMultiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  combinedInput: key => k(pipe(combinedInputFrom, checkEmpty), key),
}

export const filtersToAPITransformer = composeTransformers(
  simplifiers.multiselect('language'),
  simplifiers.select('media_type'),
  simplifiers.select('website_type'),
  simplifiers.select('gender'),
  simplifiers.select('ordering'),

  simplifiers.intRange('age'),
  simplifiers.intRange('daily_likes'),
  simplifiers.intRange('total_likes'),
  simplifiers.intRange('daily_shares'),
  simplifiers.intRange('total_shares'),

  simplifiers.autocompleteMultiselect('countries'),
  simplifiers.autocompleteMultiselect('button'),
  simplifiers.autocompleteMultiselect('technologies'),

  simplifiers.combinedInput('author'),
  simplifiers.combinedInput('text')
)

export const filterAPIMiddleware = parametersMiddleware(parameters => ({
  ...parameters,
  filters: JSON.stringify(filtersToAPITransformer(parameters.filters)),
}))

export function friendlyUrlParser(splittedPart) {
  let path = window.location.pathname
  const parsedUrl = []
  if (path.includes(splittedPart)) {
    [path] = path.split('/page')
    const [, hashPart] = path.split(splittedPart)
    if (!hashPart) return parsedUrl
    const hashes = hashPart.split('/')
    hashes.map(hash => {
      let val = hash.replace(/\/\//img, '')
      if (val.includes(',')) {
        val = val.split(',')
        val.forEach(v => {
          parsedUrl.push(decodeURIComponent(v))
        })
      }
      parsedUrl.push(decodeURIComponent(val))
    })
  }
  return parsedUrl
}

<template lang="pug">
  include /mixins
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex'

const isAuth = window.authent

export default {
  name: 'BrandList',
  computed: {
    ...mapState('brand', [
      'count',
    ]),
  },
  created() {
    if (isAuth) this.brandSubscriptionIdsList()
  },
  methods: {
    ...mapActions('brand', [
      'brandSubscriptionIdsList',
    ]),
  },

}
</script>

<template lang="pug">
  include /mixins
  resource-loader-controller(
    :resource='$options.getOrderSpecialOfferList'
    :immediate='true'
  )
    template(
      #default='{ receive, result, loading }'
    )
      div()
        div(v-if='result && result.length')
          +b.ds-panel
            +e.element--offset_vertical
              +b.line-h--light
              +e.element--offset_vertical.--indent_inside
                +b.ds-caption--size_sm.P {{ _('Если вы участвуете в закупке под спецпредложения, то поставьте галочку и наш менеджер свяжется с вами для уточнения') }}
          v-selection-controller(
            :options='result'
            :multiple='true',
            :key-getter="$options.keyGetter",
            v-model='type'
            ref='controller'
            v-slot="{ options, value: model, change }"
          )
            validation-provider(
              v-slot="{ errors, classes }"
              name='special_offer'
            )
              div
                +b.um-space_mt--3
                  +b.d-control-static-input--appearance_inline.TAG(
                    :rules='{ required: { allowFalse: false } }',
                    :tag='"input"'
                    :input-label='_("Не участвую")'
                    :key='`key_without_special_offer`'
                    v-model='model'
                    :checked.props='model.find(e => e && e.id == defaultValue.id)'
                    :id='`special_offer_without_special_offer`'
                    :ref='`special_offer_without_special_offer`'
                    @change='change(defaultValue); clearOther(model, options); $emit("input", type)'
                    type='checkbox'
                    name='special_offer'
                  )
                  label.input-group__input(
                    :for='`special_offer_without_special_offer`'
                  ) {{ defaultValue.props.title }}
                +b.um-space_mt--3(v-for='{ value } in options')
                  +b.d-control-static-input--appearance_inline.TAG(
                    :rules='{ required: { allowFalse: false } }',
                    :tag='"input"'
                    :checked.props='!!model.find(e => e && e.id == value.id)'
                    :input-label='value.props.title'
                    :key='`key_${value.id}`'
                    v-model='model'
                    :id='`special_offer_${value.id}`'
                    :ref='`special_offer_${value.id}`'
                    @change='change(value);  clearDefault(model, options); $emit("input", type)'
                    type='checkbox'
                    name='special_offer'
                  )
                  label.input-group__input(
                    :for='`special_offer_${value.id}`'
                  ) {{ value.props.title }}
</template>

<script>

import {
  getOrderSpecialOfferList,
} from '@api/order.service'

export default {
  name: 'OrderSpecialOffersList',
  getOrderSpecialOfferList,
  keyGetter: x => `${x.type}:${x.id}`,
  data() {
    return {
      defaultValue: {
        type: 'order.specialofferorderoption',
        id: 'default',
        props: { title: this._('Не участвую') },
      },
      type: [],
    }
  },
  mounted() {
    this.type.push(this.defaultValue)
  },
  methods: {
    clearOther(model, options) {
      if (this.type.find(e => e && e.id === this.defaultValue.id)) {
        options.forEach(x => {
          this.$refs[`special_offer_${x.value.id}`][0].checked = false
        })
        this.type = [this.defaultValue]
      }
    },
    clearDefault() {
      const i = this.type.findIndex(e => e.id === this.defaultValue.id)
      if (i >= 0) {
        this.type.splice(i, 1)
        this.$refs.special_offer_without_special_offer.checked = false
      }
    },
  },
}
</script>

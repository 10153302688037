<template lang="pug">
  include /mixins
  div
    +b.g-map.GMAP-MAP(
      :center="info.position",
      :zoom="13",
      style="width: 100% height: 100%",
      ref='map',
    )
      gmap-marker(
        :position="info.position",
        :clickable="true",
        @click="toggleInfoWindow(m,i)",
        :icon="icon"
      )
      gmap-info-window(
        :options="infoOptions",
        :position="info.position",
        :opened="infoWinOpen",
        @closeclick="infoWinOpen = false"
      )
        +b.tt-link.A(
          :href="'https://www.google.com/maps/dir//'+info.position.lat+','+info.position.lng",
          target='_blank',
          rel="noopener noreferrer"
        )
          +e.icon.mdi.mdi-routes
          +e.text.P {{ _("Open Google route ") }}

    +b.close-popup.A(
      @click.prevent='$emit("close")'
      v-if='!info.inline'
    ) &times;
</template>

<script>
export default {
  name: 'ModalWithMap',
  props: {
    info: {
    },
  },
  components: {
    'gmap-map': () => import('vue2-google-maps/dist/components/map.vue'),
    'gmap-marker': () => import('vue2-google-maps/dist/components/marker'),
    'gmap-info-window': () => import('vue2-google-maps/dist/components/infoWindow.vue'),
  },
  data() {
    return {
      icon: '/static/img/totis_marker.svg',
      infoWinOpen: true,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    }
  },
  methods: {

  },
}
</script>

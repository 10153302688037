import Checker from '@app/Brand/Checker'
import BrandUnsubscription from '@app/Brand/Unsubscription'
import BrandList from '@app/Brand/List'
import BrandSubscribePreview from '@app/Brand/Preview'

export default function install(Vue) {
  Vue.component('BrandChecker', Checker)
  Vue.component('BrandUnsubscription', BrandUnsubscription)
  Vue.component('BrandList', BrandList)
  Vue.component('BrandSubscribePreview', BrandSubscribePreview)
}

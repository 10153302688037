<script>
import {
  GetPhoneConfirmationStateResource,
} from '@api/order.service'

export default {
  name: 'PhoneConfirmationCheck',
  props: {
    phone: {
      default: '+380507342777',
    },
    action: {
      type: Function,
    },
  },
  data() {
    return {
      is_confirmed: false,
      code: '',
    }
  },
  methods: {
    async sendCode(force) {
      const { data: { item: { confirmed, code } } } = (await GetPhoneConfirmationStateResource.execute({}, {
        phone: this.phone,
        force,
      }))
      this.is_confirmed = confirmed
      // rm after PROD deploy
      this.code = code
      if (this.is_confirmed) {
        this.action()
        return
      }
      this.openModal()
    },
    openModal() {
      this.$modal.show(
        () => import('@app/Order/PhoneConfirmation'),
        {
          phone: this.phone,
          code: this.code,
          action: () => this.action(),
        }, {
          height: 'auto',
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
  render() {
    return this.$scopedSlots.default({
      is_confirmed: this.is_confirmed,
      sendCode: force => this.sendCode(force),
    })
  },
}
</script>

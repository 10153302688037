<script>
import Vue from 'vue'

function RenderComponent(template) {
  const _renderer = Vue.compile(template)
  return {
    render: _renderer.render,
    staticRenderFns: _renderer.staticRenderFns,
  }
}

export default {
  name: 'Renderer',
  props: ['result', 'dataRes'],
  data() {
    return {
      component: '',
      False: false,
      True: true,
      None: undefined,
    }
  },
  mounted() {

  },
  watch: {

    result: {
      handler(nval) {
        if (nval) {
          if ('object' === typeof nval) {
            this.component = nval.val
            return
          }
          const value = nval
            .replaceAll('[[', '{{')
            .replaceAll(']]', '}}')
            .replaceAll(/<style([\S\s]*?)>([\S\s]*?)<\/style>/g, '')
          // console.log(value, '-=-');
          this.component = RenderComponent(value)
          this.$nextTick(() => {
            document.dispatchEvent(new Event('dom-bind'))
          })
        }
      },
      immediate: true,
    },
  },
  render(h) {
    if (this.result) {
      return h(
        'component', {
          is: this.component,
          props: { dataResult: this.dataRes },
        }
      )
    }
    return h('div', this.$slots.render)
  },
}

</script>

import {
  PHARMA_TEST_URL,
  HAIR_TEST_URL,
  HAIR_PROD_URL,
  PHARMA_PROD_URL,
} from '@/consts'

export const firebaseConfigPharmaTest = {
  authDomain: PHARMA_TEST_URL,
  apiKey: 'AIzaSyCEMxg1Zi4gFu6jFHABQ1Q7HAacWgo165M',
  projectId: 'totis-pharma-dev',
  storageBucket: 'totis-pharma-dev.appspot.com',
  messagingSenderId: '65469756188',
  appId: '1:65469756188:web:ed4e4f2c9fb8e23a2f00d9',
  measurementId: 'G-M1T7ZNXJRK',
}
export const firebaseConfigPharmaProd = {
  authDomain: PHARMA_PROD_URL,
  apiKey: 'AIzaSyDKRFMUphqFvmd6_aOhWhC37RBhQQijF0M',
  projectId: 'totis-pharma',
  storageBucket: 'totis-pharma.appspot.com',
  messagingSenderId: '913770183930',
  appId: '1:913770183930:web:a6ce971c66eb2d32159d43',
  measurementId: 'G-2TYSSV4W6V',
}
export const firebaseConfigHairTest = {
  authDomain: HAIR_TEST_URL,
  apiKey: 'AIzaSyDRHnhiuuhVWxSgOJRHSmaSnz-OTJf7cI4',
  projectId: 'totis-hair-dev',
  storageBucket: 'totis-hair-dev.appspot.com',
  messagingSenderId: '985182335275',
  appId: '1:985182335275:web:45532e7e3deacf8ce1556e',
  measurementId: 'G-2MRCJE0RJT',
}
export const firebaseConfigHairProd = {
  authDomain: HAIR_PROD_URL,
  apiKey: 'AIzaSyDP536cC1VFnRR-6v70xAD4jylP_DO7LB0',
  projectId: 'totis-hair',
  storageBucket: 'totis-hair.appspot.com',
  messagingSenderId: '159064878847',
  appId: '1:159064878847:web:02c6516b3af17f83d666ca',
  measurementId: 'G-GVC14PER8H',
}

const SETTINGS_MAP = {
  [PHARMA_TEST_URL]: firebaseConfigPharmaTest,
  [HAIR_TEST_URL]: firebaseConfigHairTest,
  [HAIR_PROD_URL]: firebaseConfigHairProd,
  [PHARMA_PROD_URL]: firebaseConfigPharmaProd,
}

export const getConfig = () => {
  const { hostname } = window.location
  return SETTINGS_MAP[hostname] || firebaseConfigPharmaTest
}

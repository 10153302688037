import OtpComponent from '@app/Otp/Component'
import OtpErrorMessage from '@app/Otp/ErrorMessage'
import OtpGroup from '@app/Otp/Group'
import OtpGroupInput from '@app/Otp/GroupInput'
import OtpInput from '@app/Otp/Base'

export default function install(Vue) {
  Vue.component(OtpComponent.name, OtpComponent)
  Vue.component(OtpErrorMessage.name, OtpErrorMessage)
  Vue.component(OtpGroup.name, OtpGroup)
  Vue.component(OtpGroupInput.name, OtpGroupInput)
  Vue.component(OtpInput.name, OtpInput)
}
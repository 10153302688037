<template lang="pug">
  include /mixins
  +b.dustributor-catalog
    paginated-resource-controller(
      :resource="$options.distributorCaralogResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      ref='controller'
      v-slot="{ pagination, changePagination, changeFilters, filters, receive, result, loading }"
    )
      div.relative
        ui-loader(v-if='loading')
        +e.part
          distributor-sidebar(
            :changeFilters='changeFilters',
            :filters='filters',
            :receive='receive',
            @update='parameters = $event'
          )
        +e.part
          +b.ds-caption--variant_base.P {{ _('Найдено') }} {{ pagination.total }} {{ _('товаров') }}

        +e.part
          distributor-table(
            :table-fields='tableFields'
            :list='result && result.items'
            :truncate='truncate'
          )
          ui-limit-pagination(
            :pagination='pagination'
            @hook:created='receive'
            :need-add-btns='true'
            @showAll='handlePaginate($event, changePagination, pagination)'
            @input='handlePaginate($event, changePagination, pagination)'
          )
</template>
<script>

import {
  distributorCaralogResource,
} from '@api/catalog.service'

import {
  pathGetter,
  Field,
} from '@utils/helpers'

import DistributorTable from '@app/Distributor/Table'
import DistributorSidebar from '@app/Distributor/Sidebar'

const truncate = require('html-truncate')

export default {
  name: 'DistributorCatalog',
  comparison_url: window.comparison_url,
  distributorCaralogResource,
  components: {
    DistributorTable,
    DistributorSidebar,
  },
  data() {
    return {
      parameters: {},
      list: {},
      tableFields: [
        new Field('vendor_code', this._('Артикул'), pathGetter('props', 'vendor_code')),
        new Field('image', this._('Фото'), pathGetter('props', 'img', 'catalog_preview_webp')),
        new Field('title', this._('Название'), pathGetter('props', 'product', 'caption')),
        new Field('weight', this._('Объем'), pathGetter('props', 'weight', 'props', 'name')),
        new Field('presentation', this._('Форма выпуска'), pathGetter('props', 'product', 'props', 'presentation', 'props', 'title')),
        new Field('sticker', this._('Текст стикера'), pathGetter('props', 'product', 'props', 'sticker_text')),
        new Field('short_description', this._('Краткое описание'), pathGetter('props', 'product', 'props', 'short_description')),
        new Field('description', this._('Подробное описание прайс'), pathGetter('props', 'product', 'props', 'description')),
        new Field('receipe', this._('Состав'), pathGetter('props', 'product', 'props', 'composition')),
        new Field('max_sale', this._('Макс. скидка(%)'), pathGetter('props', 'price')),
        new Field('price_wholesale', this._('Цена оптовая €*'), pathGetter('props', 'price')),
        new Field('price', this._('Цена €*'), pathGetter('props', 'price')),
        new Field('quantity', this._('Остаток'), pathGetter('props', 'count')),
        new Field('action', this._('Действие'), "pathGetter('props')"),
        new Field('order', this._('Заказ'), "pathGetter('props')"),
      ],
    }
  },

  methods: {
    truncate,
    handlePaginate({ offset, limit }, fn, pagination) {
      if (this.loading) {
        return
      }
      fn({ limit, offset })
    },
    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
    changeParameters() {

    },
  },
}
</script>

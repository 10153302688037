<template lang="pug">
  include /mixins

  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.title.ds-caption--size_xs.--size_sm-xl.--light.P.tt-space_mt--8-xl
    slot(name='auth-btn')
    +b.g-row
      +b.g-cell.g-cols--12(:class='`g-cols--${cols}`')
        phone-input(
          :rules="'required|phone'",
          :input-label='labels.phone'
          v-model='form.username',
          ref='phone'
          name='username'
        )
      +b.g-cell.g-cols--12.tt-space_mt--6(:class='`g-cols--${cols}`')
        .relative
          slot(name='restore-password')
          d-control-input(
            :rules="{ 'required': is_recovery }",
            :input-label='labels.password'
            v-model='form.password'
            autocomplete='new-password-5'
            id='password',
            type='password',
            name='password',
          )
            //- +b.tt-link--variant_end.--styling_default.--accent.A(
            //-   @click.prevent='resendPassword'
            //-   v-if='!valid && !isRegister'
            //- )
            //-   +e.text.ds-caption--size_xs.P.--color_accent {{ _('Забыли пароль?') }}
    +b.g-row
      +b.g-cell.g-cols--12.tt-space_mt--5.tt-space_mt--10-xl(:class='`g-cols--${cols}`')
        +b.tt-button.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='is_recovery = true; prepareData(valid)'
        )
          +e.title.P {{ _('Войти') }}
        +b.non-field-error-label.P(v-if="non_field_errors") {{ non_field_errors[0] }}
</template>
<script>
import loginMixin from '@app/Forms/mixins/login'

export default {
  name: 'Auth',
  mixins: [loginMixin],
  data() {
    return {
      url: window.password_set_url,
      is_recovery: true,
    }
  },
  methods: {
    async validate(action) {
      this.$refs.validator.reset()
      this.is_recovery = false
      this.$nextTick(async () => {
        const isValid = (await this.$refs.validator.validate())
        if (!isValid) return
        action()
      })
      // this.$emit('validate', { valid, step })
    },
    goToPasswordSetPage(id) {
      const data = {
        phone: this.form.username,
        id,
      }
      window.location.href = `${this.url}?_state=${btoa(JSON.stringify(data))}`
    },
  },
}
</script>

<script>

import {
  SEACRH_V3_PAGINATED_URL,
} from '@api/vendor.service'

import {
  createResource,
} from '@resource/resource'

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 12,
    },
    next: {

    },
    resource: {},
    found: {},
    search: {},
    category: {},
  },
  data() {
    return {
      offset: 0,
      localList: this.list,
      localNext: this.next,
    }
  },
  computed: {
    splicedList: {
      get() {
        return this.localList
      },
      set(e) {
        this.localList = e
      },
    },
    hasNext() {
      const offset = this.offset + this.limit
      return !!this.getIds(offset).length
    },
    hasPrev() {
      const offset = this.offset - this.limit
      if (0 > offset) return false
      return !!this.getIds(offset).length
    },
  },
  watch: {
    found: {
      handler() {
        if (this.list.length) {
          this.splicedList = this.list
          return
        }
        this.fetchData(0, this.limit)
        console.log(this.found);
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    getNext() {
      this.offset += this.limit
      this.fetchData()
    },
    getIds(offset = this.offset, limit = this.limit) {
      const list = JSON.parse(JSON.stringify(this.found.items))
      const ids = list.splice(offset, limit)
      return ids
    },
    getPrev() {
      this.offset -= this.limit
      this.fetchData()
    },
    async fetchData() {
      this.$emit('load:start')
      const { data: { items } } = (await createResource(SEACRH_V3_PAGINATED_URL).execute({
        limit: this.limit,
        offset: this.offset,
        found_ids: this.getIds().join(','),
      }))
      this.splicedList = items
      this.$emit('load:end')
    },
  },
  render() {
    return this.$scopedSlots.default({
      splicedList: this.splicedList,
      hasNext: this.hasNext,
      hasPrev: this.hasPrev,
      getPrev: () => this.getPrev(),
      getNext: () => this.getNext(),
    })
  },
}
</script>

var render = function (_h,_vm) {var _c=_vm._c;return _c('ui-table',{attrs:{"table-fields":_vm.props.tableFields,"items":_vm.props.list},scopedSlots:_vm._u([{key:"table-body-cell-image",fn:function(ref){
var item = ref.item;
return [_c('picture',{staticClass:"tt-image"},[_c('source',{attrs:{"type":"image/webp","srcset":item.props.img.catalog_preview_webp}}),_c('img',{staticClass:"tt-image__body",attrs:{"src":item.props.img.catalog_preview}})])]}},{key:"table-body-cell-sticker",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(value)?_c('div',[_c('div',{staticClass:"tooltip-content",attrs:{"id":("sticker" + (item.id))},domProps:{"innerHTML":_vm._s(value)}}),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({html: ("sticker" + (item.id)), class: 'custom-tooltip'}),expression:"{html: `sticker${item.id}`, class: 'custom-tooltip'}",modifiers:{"top":true}}],domProps:{"innerHTML":_vm._s(_vm.props.truncate(value, 50))}})]):_vm._e()]}},{key:"table-body-cell-title",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('a',{staticClass:"tt-link tt-link--styling_default ds-caption ds-caption--size_xs",attrs:{"href":item.props.url,"target":"_blank"}},[_vm._v(_vm._s(value))])]}},{key:"table-body-cell-short_description",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('p',{domProps:{"innerHTML":_vm._s(value)}})]}},{key:"table-body-cell-description",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(value)?_c('div',[_c('div',{staticClass:"tooltip-content",attrs:{"id":("description" + (item.id))},domProps:{"innerHTML":_vm._s(value)}}),_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({html: ("description" + (item.id)), class: 'custom-tooltip'}),expression:"{html: `description${item.id}`, class: 'custom-tooltip'}",modifiers:{"top":true}}],domProps:{"innerHTML":_vm._s(_vm.props.truncate(value, 50))}})]):_vm._e()]}},{key:"table-body-cell-action",fn:function(ref){
var item = ref.item;
return [_c('row',{attrs:{"appearance":"nowrap","align":["center"]}},[_c('cell',[_c('add-to-comparison',{attrs:{"id":item.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var add = ref.add;
var is_added = ref.is_added;
return [(is_added)?_c('a',{staticClass:"circle-btn circle-btn--accent circle-btn--clear",attrs:{"href":_vm.$options.comparison_url,"title":_vm.parent._('Перейти в сравнение')}},[_c('img',{staticClass:"circle-btn__icon",attrs:{"src":"/static/img/libra_full.svg"}})]):_c('button',{staticClass:"circle-btn circle-btn--accent circle-btn--clear",attrs:{"title":_vm.parent._('Добавить в сравнение')},on:{"click":function($event){$event.preventDefault();return add()}}},[_c('span',{staticClass:"circle-btn__icon icon-libra"})])]}}],null,true)})],1),_c('cell',[_c('likes-view',{attrs:{"is_auth":true,"id":item.id}})],1)],1)]}},{key:"table-body-cell-order",fn:function(ref){
var item = ref.item;
return [_c('counter-controller',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var amount = ref.amount;
var change = ref.change;
var clear = ref.clear;
return [_c('row',{attrs:{"appearance":"nowrap","align":["center"]}},[_c('cell',[_c('counter',{staticClass:"tt-space_mb-xl tt-space_mb-xl--2",attrs:{"amount":amount},on:{"change":function($event){return change($event)}}})],1),_c('cell',[_c('add-to-cart',{attrs:{"id":item.id,"type":item.type,"amount":amount,"name":item.caption},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var add = ref.add;
var load = ref.load;
return [_c('button',{staticClass:"tt-link tt-link--accent icon-cart",attrs:{"disabled":load,"title":_vm.parent._("Добавить в корзину")},on:{"click":function($event){$event.preventDefault();return add($event)}}})]}}],null,true)})],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
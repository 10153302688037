const classTriggerForSearch = (el, val) => {
  if (val) {
    el.classList.add('on-overlay')
  } else {
    el.classList.remove('on-overlay')
  }
}
export function createStore(parameters) {
  return {
    namespaced: true,
    state: {
      global_search: '',
      show_overlay: false,
    },
    actions: {
      trigger_overlay({ commit }, payload) {
        const mobile_el = document.getElementById('header')
        // let desktop_el = document.getElementById('desktop-search')
        // let desktop = 1200
        // if (window.innerWidth < desktop) {
        // } else {
        //   classTriggerForSearch(desktop_el, payload)
        // }
        classTriggerForSearch(mobile_el, payload)
        commit('SET_OVERLAY', payload)
      },
    },
    mutations: {
      SET_SEARCH(state, payload) {
        state.global_search = payload
      },
      SET_OVERLAY(state, payload) {
        state.show_overlay = payload
      },
    },
  }
}

<template lang="pug">
  include /mixins
  +b.img-load-view
    +e.img-wrapper
      +b.close-popup(@click='removeItem(idx)')
        +e.icon.mdi.mdi-close
      +e.img.IMG(
        :src='item.img'
        v-if='!item.err'
      )
      +b.mask-img.LABEL(:class="{'mask-img--with-bg': item.err}")
        +e.item.IMG(
          v-if='item.err'
          src='/static/img/error.svg'
        )
    label.error-label(v-if='item.err') {{ item.err }}
    +b.progress-bar(v-if='!item.err')
      +e.basic.SPAN
      +e.status.SPAN(:style="{'width' : item.progressBarWidth + '%'}")
        .shadow

</template>
<script>
export default {
  name: 'AddImg',
  props: ['item', 'idx'],
  methods: {
    removeItem(idx) {
      this.$emit('removeItem', idx)
    },

  },
}
</script>

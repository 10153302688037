import Gallery from './Gallery'
// import GalleryProductView from './GalleryProductView'
import YoutubeLoader from './YoutubeLoader'

function install(Vue) {
  Vue.component(Gallery.name, Gallery)
  // Vue.component(GalleryProductView.name, GalleryProductView)
  Vue.component(YoutubeLoader.name, YoutubeLoader)
}

export default { install }

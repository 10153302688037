<template lang="pug">
  include /mixins
  controller(
    :localsearch='search'
  )
    template(#inputview='{ keydownEvent, keyupEvent, model, reset }')
      div(
        v-bem=''
        :class='{"is-active": show}'
      )
        +b.icon-search(
          @click='reset'
          v-bem.icon=''
          v-bem:d-theme-base-relief.text='{"4" : true}'
        )
        +e.field(
          v-if='show',
          v-bem.field=''
          :class='{"is-active": show}'
        )
          d-control-input(
            type='search',
            label='search'
            v-bem:d-control-input='{"appearance" : "clear"}'
            v-model='search',
            autocomplete='nddddo'
            :placeholder='_("Найди свой любимый продукт")'
            @keyup="keyupEvent",
            @keydown="keydownEvent",
          )

</template>
<script>
import Controller from '@app/Search/Controller'

export default {
  name: 'UiSearch',
  block: 'search-wrapper',
  components: {
    Controller,
  },
  data() {
    return {
      search: '',
      show: true,

    }
  },
  methods: {
    letsSearch() {

    },
    trigger() {
      // this.show = !this.show
    },

  },
}
</script>

export const categoriesPrettifier = categories => {
  const categoriesWithIndexes = categories.reduce((acc, key, idx) => {
    const prefix = 'item_category'
    if (idx) {
      acc[`${prefix}${idx + 1}`] = key
      return acc
    }
    acc[`${prefix}`] = key
    return acc
  }, {})
  return categoriesWithIndexes
}

export function compileProductsGA4(
  arr
) {
  if (!window.is_analytic_enabled) return false

  const result = arr.reduce((acc, obj, idx) => {
    acc = acc || []
    if (!obj.base.element.props.product) return
    const { brand: { props: { title: item_brand } }, categories, title: item_name } = obj.base.element.props.product.props
    const { price, id: item_id } = obj
    const { quantity } = obj.base
    const categoriesTitles = categories.map(l => l.props.title)
    const product = {
      item_name,
      item_id,
      price,
      item_brand,
      quantity,
      ...categoriesPrettifier(categoriesTitles),
    }
    acc.push(product)
    return acc
  }, [])
  return result
}

export function compileProductsGA3(
  arr
) {
  if (!window.is_analytic_enabled) return false

  const result = arr.reduce((acc, obj) => {
    acc = acc || []
    if (!obj.base.element.props.product) return
    const { brand: { props: { title: brand } }, categories, title: name } = obj.base.element.props.product.props
    const { id } = obj
    const { quantity } = obj.base
    const categoriesTitles = categories.map(l => l.props.title)
    const [category] = categoriesTitles
    const product = {
      name,
      id,
      brand,
      quantity,
      category,
    }
    acc.push(product)
    return acc
  }, [])
  return result
}

import Sorting from '@app/FiltersSimple/Sorting'
import CheckboxWidget from '@app/FiltersSimple/Widgets/CheckboxWidget'
import RangeWithVariantsWidget from '@app/FiltersSimple/Widgets/RangeWithVariantsWidget'
const CategoryWidget = () => import('@app/FiltersSimple/Widgets/CategoryWidget')
const SearchWidget = () => import('@app/Filters/Widgets/SearchWidget')

const WIDGETS_MAP = {
  prices: RangeWithVariantsWidget,
  multiselect: CheckboxWidget,
  category: CategoryWidget,
  search: SearchWidget,
}

export default {
  components: {
    Sorting,
  },
  widgetsMap: WIDGETS_MAP,
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'sortlist',
    'label',
    'initbaseUrl',
  ],
  data() {
    return {
      search: '',
      requestsList: [],
    }
  },
  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,

      }
    },

    baseFilters() {
      return this.getAvailableFilters(this.filters)
    },
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if ('search/SET_SEARCH' == mutation.type) {
        this.search = mutation.payload
        this.onlyValue('search', this.search)
      }
    })
  },
  methods: {
    async searchValues(props, all) {
      props.has_search = true
      const { search } = props
      for (let x = 0; x < all.values.length; x++) {
        const field = all.values[x].props.title.toLowerCase()
        const res = field.includes(search.toLowerCase())
        this.$set(all.values[x], 'is_valid', res)
      }
    },
    getMultiValue(key) {
      return {
        min_value: this.value[`${key}_min`],
        max_value: this.value[`${key}_max`],
      }
    },
    getAvailableFilters(filters = []) {
      return filters
      // .filter(filter => filter.type in this.$options.widgetsMap)
    },
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },
    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    debounceUpdating(fn, key, value, timeout) {
      const [inputValue] = value
      this.requestsList.push(inputValue)
      let timer
      const context = this
      clearTimeout(timer)
      timer = setTimeout(() => {
        if (this.requestsList[this.requestsList.length - 1] === inputValue) {
          fn.call(context, key, value)
          this.requestsList = []
        }
      }, timeout)
    },
    debounceSearch(key, value) {
      this.debounceUpdating(this.updateValue, key, value, 300)
    },
    // updateMultipleValues(key, values) {
    //   let res = {}
    //   Object.keys(values).forEach(k => {
    //     res = { ...res, [`${key}_${k}`]: values[k] }
    //   })
    //   this.$emit('input', Object.assign(this.value, res))
    // },
    updateMultipleValues(key, values) {
      let res = {}
      Object.keys(values).forEach(k => {
        if (values[k][0]) {
          res = { ...res, [`${key}_${k}`]: [`${key}-${k}_${values[k]}`] }
        }
      })
      this.$emit('input', Object.assign(this.value, res))
    },
    updateSimpleMultipleValues(key, values) {
      let res = {}
      Object.keys(values).forEach(k => {
        if (values[k][0]) {
          res = { ...res, [`${key}_${k}`]: [`${values[k]}`] }
        }
      })
      this.$emit('input', Object.assign(this.value, res))
    },
  },
}

<template lang="pug">
include /mixins
+b.product-action.BUTTON(
  @click='openModal'
)
  +e.icon
    +e.img.IMG(
      src='/static/img/360-degree-rotate-icon.svg'
      :alt='_("share icon")'
      loading="lazy"
    )
  +e.text.P {{ _('Просмотр 360') }}
</template>

<script>
import ImageSpinView from '@app/Image360/View'

export default {
  name: 'ImageSpinPreview',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  methods: {
    openModal() {
      this.$modal.show(ImageSpinView, {
        id: this.id,
      }, {
        height: 'auto',
        classes: ['cart-modal'],
        adaptive: true,
        scrollable: true,
        clickToClose: false,
      })
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.inliner-wrapper
    +b.tt-space_mt--3.tt-space_mb--3
      +b.calc-inliner-row--visible-sm(
        v-if='list.length'
      )
        +b.calc-inliner-cell--7(cols='7 8-xl')
          +b.ds-caption--size_sm.--bold.P {{ _('Наименование продуктов') }}
        +b.calc-inliner-cell--4(cols='3')
          +b.ds-caption--size_sm.--bold.P {{ _('Расход на процедуру') }}
        +b.calc-inliner-cell--1(cols='2 1-xl')
    +b.VALIDATION-OBSERVER(
      :ref="`validator${i}`",
      v-slot="{ valid }",
      tag="form",
      v-for='(el, i) in list'
      :key='`form${i}`'
    )
      ui-loader(v-if='isLoad')
      +b.tt-space_mt--3.tt-space_mb--3
        +b.calc-inliner-row.relative
          +b.calc-inliner-cell(
            v-for='{ name, label, type, classname, inputtype, cols, props, events, mask, appearance, afterList } in mainFields'
            :class='`calc-inliner-cell--${cols}`'
            :key='`${type}${name}${i}${el.id}_main`'
          )
            tag(
              :tag='type || "d-control-input"'
              :input-label='label'
              :type='inputtype || "text"'
              v-model='el[name]',
              :id='`${inputtype}${name}${i}${el.id}_main`'
              :ref='`${inputtype}${name}${i}${el.id}_main`'
              @input='recalcAdditional(el)'
              :name='name'
              v-bind='props'
              v-on='events'
              :class='classname'
              :disabled='!!(type && el.id)'
            )
              template(
                #afterList=''
                v-if='afterList'
              )
                div
                  div(
                    v-if="props.options.length > 50 || props.pagination.next "
                    v-observe-visibility="visible => afterList(visible)",
                  )
              template(#option="{ option }")
                product-variant-option(v-if='option.props && !el.id' :option='option')
                +b.ds-caption(v-if='option.props && el.id') {{ option.props.title }}
              template(#singleLabel="{ option }")
                product-variant-option(v-if='option.props && !el.id' :option='option')
                +b.ds-caption(v-if='option.props && el.id') {{ option.props.title }}
                span.multiselect__placeholder(v-if='!option.props') {{ _('Выберите продукт') }}
          //- +b.calc-inliner-cell--disabled
            //- +b.ds-caption--size_xs.--bold.P {{ _('Укажите расход') }}
          +b.calc-inliner-cell--1(
          )
            +b.inliner-btn--grey.BUTTON(
              v-if='list.length > 1 && !el.id'
              :title='_("Удалить")'
              @click.prevent='remove(i)'
            )
              +e.title.P.mdi.mdi-close
          //- +b.inliner-delete(
          //- )
        +b.calc-child-wrapper
          div(cols='12' v-for='(child, childid) in el.additional_items' :key='`child_${i}_${childid}_form`')
            +b.tt-space_mt--3.tt-space_mb--3
              +b.calc-inliner-row--end
                +b.calc-inliner-cell(
                  v-for='{ name, label, type, classname, inputtype, cols, props, events, mask, appearance, afterList } in mainFields'
                  :class='`calc-inliner-cell--${cols}`'
                  :key='`${type}${name}${i}${childid}_childcell`'
                )
                  tag(
                    :tag='type || "d-control-input"'
                    :input-label='label'
                    :type='inputtype || "text"'
                    v-model='child[name]',
                    :id='`${inputtype}${name}${i}_child${childid}`'
                    :ref='`${inputtype}${name}${i}_child${childid}`'
                    @input='$emit("update", list)'
                    :name='`child_${name}`'
                    v-bind='props'
                    :disabled='!!(!type || type && el.id)'
                    v-on='events'
                    :class='classname'
                  )
                    template(
                      #afterList=''
                      v-if='afterList'
                    )
                      div
                        div(
                          v-if="props.options.length > 50 || props.pagination.next "
                          v-observe-visibility="visible => afterList(visible)",
                        )
                    template(#option="{ option }")
                      product-variant-option(v-if='option.props && !el.id' :option='option')
                      +b.ds-caption(v-if='option.props && el.id') {{ option.props.title }}
                    template(#singleLabel="{ option }")
                      product-variant-option(v-if='option.props && !el.id' :option='option')
                      +b.ds-caption(v-if='option.props && el.id') {{ option.props.title }}
                      span.multiselect__placeholder(v-if='!option.props') {{ _('Выберите продукт') }}
                +b.calc-inliner-cell--disabled
                  +b.ds-caption--size_xs.--bold.P
                +b.calc-inliner-cell--1
          //-     +b.g-cell.g-cols--narrow.tt-space_mt--1
          //-       +b.tt-button--for-input.--appearance_fixed(
          //-         @click.prevent='prepareData(valid, i)'
          //-       )
          //-         +b.ds-caption--size_2xs.--color_white.P {{_('Сохранить')}}
      +b.line-h--light
    +b.g-row--justify_start(
      v-if='limit > list.length'
    )
      +b.g-cell.g-cols--narrow(
      )
        +b.ds-caption.--size_sm.tt-link--variant_start.--styling_default.BUTTON(
          @click.prevent='addFields'
        )  {{ btnText || _('Добавить товар') }}

</template>
<script>
import { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { confirmWithPromise } from '@utils/helpers'
import {
  getPaginatedPropsForArray,
} from '@utils/valuesGetter'

import {
  AnswerMessage,
} from '@utils/submit'

import { asyncForEach } from '@utils/async'

import ProductVariantOption from '@app/Calculator/Option'

const SPLITED_LENGTH = 1

export default {
  name: 'CalculatorInliner',
  props: {
    url: {},
    user: {},
    type_name: {
    },
    btnText: {
      type: String,
    },
    hint: {},
    base: {},
    // form_fields: {
    //   type: Array,
    //   default: () => [],
    // },
    initial: {
      default: () => ([]),
    },
    prepare: {},
    withProcedure: {},
    limit: {
      default: 10,
    },
  },
  components: {
    ProductVariantOption,
  },
  data() {
    return {
      list: [],
      data: {},
      isLoad: false,
      mainFields: [
        {
          name: 'variant',
          label: '',
          type: 'd-control-multiselect',
          cols: '7',
          events: {
            open: () => getPaginatedPropsForArray(this.url, '', 'variant', null, this.mainFields),
            'search-change': $event => getPaginatedPropsForArray(this.url, $event, 'variant', null, this.mainFields),
          },
          afterList: $event => getPaginatedPropsForArray(this.url, '', 'variant', $event, this.mainFields),
          props: {
            options: [],
            searchable: true,
            label: 'caption',
            'internal-search': false,
            search: '',
            taggable: false,
            pagination: {},
            customLabel: obj => obj.props?.title,
            // placeholder: this._('Выберите продукт'),
          },
        },
        {
          name: 'amount',
          label: '',
          inputtype: 'number',
          props: {
            placeholder: this._('Введите расход в г/мл'),
            rules: 'notNull',
          },
          cols: '4',
        }],
    }
  },

  watch: {
    initial: {
      handler() {
        this.list = JSON.parse(JSON.stringify([...this.initial]))
        this.list.forEach(el => this.recalcAdditional(el))
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    recalcAdditional(el) {
      el.additional_items.forEach(e => {
        e.amount = Number(Number(e.amount_coefficient) * Number(el.amount)).toFixed(2)
      })
      this.$emit('update', this.list)
    },
    catchFormErrors(promise, i) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e
        }

        if (!this.updateValidator) {
          return e
        }

        return e.json().then(body => {
          const errors = {}

          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })

          this.updateValidator(errors, i)

          return e
        })
      })
    },
    submit(valid, data, id) {
      if (!valid) {
        return Promise.reject()
      }

      this.isLoad = true
      return this.catchFormErrors(this.send(data), id)
        .finally(() => {
          this.isLoad = false
        })
    },
    async validate() {
      return new Promise(async resolve => {
        const res = []
        await asyncForEach(this.list, async (el, i) => {
          if (!this.$refs[`validator${i}`]) return
          const r = (await this.$refs[`validator${i}`][0].validate())
          res.push(r)
        })
        resolve(!res.some(e => !e))
      })
    },
    async remove(i) {
      const question = this._('Вы действительно хотите удалить?')
      try {
        await confirmWithPromise(question, this)
        if (this.list[i].id) {

          // this.deleteResource.execute({ id: this.list[i].id })
        }
        this.list.splice(i, SPLITED_LENGTH)
      } catch (e) {
        console.log(e)
      }
    },
    addFields() {
      this.list.push(JSON.parse(JSON.stringify(this.base)))
    },
    async getList() {
      const { data: { items } } = (await this.getResource.execute())
      this.list = items.map(el => el.props)
      if (!this.list.length) {
        this.addFields()
      }
    },
    async prepareData(valid, i) {
      this.$refs[`validator${i}`][0].validate()
      if (!valid) return
      const data = this.prepare(this.list[i])
      console.log(data);
      // this.submit(valid, { ...data, profile: this.user }, i)
    },
    send(data, i) {
      const resource = data.id ? this.patchResource : this.postResource
      return resource.execute({ id: data.id }, data)
        .then(res => {
          const message = {
            message: {
              title: this._('Profile info create/update success header'),
              text: this._('Profile info create/update success text'),
            },
          }
          if (!data.id) {
            this.getList()
          }
          AnswerMessage(message, this)
          this.$refs[`validator${i}`][0].reset()
        })
    },
    updateValidator(errors, i) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs[`validator${i}`][0].setErrors(parsed)
    },
  },
}
</script>

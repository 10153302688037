export const DEFAULT_DATE_FORMAT = 'dd.MM.yyy'

export const COUNTRY_CODE_COOKIE_NAME = 'TOTIS2.selected_country_code'

export const CURRENCY_CODE_COOKIE_NAME = 'TOTIS2.currency_code'

export const POLAND_CODE = '616'
export const UKRAINE_CODE = '804'
export const CZECH_CODE = '203'
export const MOLDOVA_CODE = '498'
export const SLOVAKIA_CODE = '703'
export const UZBEKISTAN_CODE = '860'

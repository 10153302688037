import {
  getFavoritesList,
} from '@api/like.service'

export function createStore(parameters) {
  return {
    namespaced: true,
    state: {
      count: 0,
      ids: [],
    },
    actions: {
      addId({ commit }, payload) {
        commit('ADD_TO_FAVORITES', payload)
        commit('SET_COUNT')
      },
      async getFavoritesList({ commit }, payload) {
        const { data: { ids } } = (await getFavoritesList.execute())
        commit('SET_IDS_LIST', ids)
        commit('SET_COUNT')
      },
      removeId({ commit }, payload) {
        commit('REMOVE_ID', payload)
      },
    },
    mutations: {
      ADD_TO_FAVORITES(state, payload) {
        state.ids.push(payload)
      },
      SET_COUNT(state) {
        state.count = state.ids.length
      },
      SET_IDS_LIST(state, payload) {
        state.ids = payload
      },
      REMOVE_ID(state, payload) {
        state.ids.splice(state.ids.findIndex(e => e === payload), 1)
      },
    },
  }
}

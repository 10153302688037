<template lang="pug">
  include /mixins
  div
    vue-easy-lightbox(
      :visible="visible"
      :imgs="source"
      :index="index"
      @hide="handleHide"
      ref='lightbox'
    )

    slot(
      :show='src => showImg(src)'
    )

</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'LightBoxV2',
  props: {
    source: {
      type: Array,
    },
  },
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      visible: false,
      index: 0,
      img: [],
    }
  },
  methods: {
    setIndex(i) {
      console.log(i, '>>>>>>>>>>>');
      this.index = i
    },
    showImg(index = this.index) {
      this.index = index
      this.visible = true
      this.$nextTick(() => {
        const parent = this.$refs.lightbox.$el.closest('.product-sticky')
        const parent2 = this.$refs.lightbox.$el.closest('.product-detail')
        if (parent && parent2) {
          parent.style.zIndex = 556
          parent2.style.zIndex = 'initial'
        }
      })
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>

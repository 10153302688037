import { compileProductsGA4 } from '@analytic/utils'

export default function analyticCartView(products) {
  if (!window.is_analytic_enabled) return

  window.dataLayer = window.dataLayer || []

  const objectsList = compileProductsGA4(products)

  window.dataLayer.push({
    ecommerce: {
      currency: window.currency_code,
      items: objectsList,
    },
    event: 'view_cart',
  })
}

import {
  createResource,
  deleteResource,
  postResource,
  patchResource,
} from '@resource/resource'
import {
  simpleTransformer,
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const MODEL = 'authentication'
const MODEL_FIREBASE = 'firebase/authentication'

const FIREBASE_AUTH_LINK = prefixAPI('/auth/', MODEL_FIREBASE)
const FIREBASE_LIST_LINK = prefixAPI('/list/', MODEL_FIREBASE)
const FIREBASE_CREATE_CONNECT_LINK = prefixAPI('/create/', MODEL_FIREBASE)
const FIREBASE_DELETE_CONNECT_LINK = prefixAPI('/delete/{id}/', MODEL_FIREBASE)

const VERIFY_LINK = prefixAPI('/verify/', MODEL)
const AUTH_LINK = prefixAPI('/login/', MODEL)
const LOGOUT_LINK = prefixAPI('/logout/', MODEL)
const REGISTER_LINK = prefixAPI('/register/', MODEL)
const SMS_RESEND_LINK = prefixAPI('/password/send-sms/', MODEL)
const RECOVERY_LINK = prefixAPI('/password/reset/', MODEL)
const CHANGE_PASSWORD_LINK = prefixAPI('/password/change/', MODEL)
const RECOVERY_CONFIRM_LINK = prefixAPI('/password/reset/confirm/', MODEL)
const USER_INFO_LINK = prefixAPI('/user/retrieve/', 'staff')
const USER_INFO_UPDATE_LINK = prefixAPI('/user/update/', 'staff')
const USER_ADDRESS_DELETE_LINK = prefixAPI('address/destroy/{id}/')
const MARKET_LAUNCH_PASSWORD_SET = prefixAPI('/set-password/', 'market-launch')
const PHONE_CHANGE_LINK = prefixAPI('/phone/change/', MODEL)
const CHECK_PASSWORD_LINK = prefixAPI('/password/check/', MODEL)

const TWO_FACTOR_REQUEST = prefixAPI('/request-confirmation/', '2factor')
const TWO_FACTOR_REQUEST_CONFIRM = prefixAPI('/confirm/', '2factor')

export const userInfo = createResource(USER_INFO_LINK)

export const userInfoUpdate = createResource(
  USER_INFO_UPDATE_LINK, patchResource
)
export const userAddressDelete = createResource(
  USER_ADDRESS_DELETE_LINK, deleteResource, simpleTransformer
)
export const loginResource = createResource(AUTH_LINK, postResource)
export const verifyResource = createResource(VERIFY_LINK, postResource)
export const registerResource = createResource(REGISTER_LINK, postResource)
export const logoutResource = createResource(
  LOGOUT_LINK, postResource, simpleTransformer
)
export const passwordRecoveryResource = createResource(
  RECOVERY_LINK, postResource
)
export const smsResend = createResource(
  SMS_RESEND_LINK, postResource
)
export const passwordRecoveryConfirmResource = createResource(
  RECOVERY_CONFIRM_LINK, postResource
)
export const passwordChangeResource = createResource(
  CHANGE_PASSWORD_LINK, postResource
)
export const marketLaunchPasswordSet = createResource(
  MARKET_LAUNCH_PASSWORD_SET, postResource
)
export const make2FactorRequest = createResource(
  TWO_FACTOR_REQUEST, postResource
)
export const confirm2FactorRequest = createResource(
  TWO_FACTOR_REQUEST_CONFIRM, postResource
)
export const changePhoneRequest = createResource(
  PHONE_CHANGE_LINK, postResource
)
export const checkPasswordRequest = createResource(
  CHECK_PASSWORD_LINK, postResource
)
export const firebaseAuthRequest = createResource(
  FIREBASE_AUTH_LINK, postResource
)
export const firebaseListRequest = createResource(FIREBASE_LIST_LINK)
export const firebaseCreateConnectRequest = createResource(FIREBASE_CREATE_CONNECT_LINK, postResource)
export const firebaseDeleteConnectRequest = createResource(FIREBASE_DELETE_CONNECT_LINK, deleteResource, simpleTransformer)

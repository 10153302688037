<template lang="pug">
  include /mixins
  label(
    v-bem.label='{"bound": true}'
    :class="{'d-control-input__label--active': hasValue }"
    :for='id',
  ) {{ inputLabel }}
    slot
    span(
      v-if='isRequired && inputLabel'
      v-bem.is_required=''
    ) *
</template>

<script>

import {
  isEmpty as isEmptyLocal,
} from '@aspectus/vue-utils'

import isEmpty from 'ramda/src/isEmpty'

export default {
  name: 'BoundLabel',
  block: 'd-control-input',
  isEmpty,
  props: {
    labelText: {},
    id: {
      type: [String, Number],
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
  computed: {
    hasValue() {
      return !isEmptyLocal(this.value) || !isEmpty(this.value)
    },
  },
}
</script>

export const Tabs = {
  name: 'rs-tabs',
  props: {
    breakPoint: {
      type: [String, Number],
      default: false,
    },
    defaultIndex: {
      type: Number,
      default: 0,
    },
    closedDefault: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
    },
  },
  data() {
    return {
      selectedIndex: this.defaultIndex,
    }
  },
  mounted() {
    if (this.closedDefault &&
      this.breakPoint &&
      window.innerWidth <= this.breakPoint
    ) {
      this.selectedIndex = null
    }
  },
  methods: {
    switchTab(e, index, isDisabled) {
      if (!isDisabled) {
        if (this.breakPoint &&
            window.innerWidth <= this.breakPoint &&
            this.selectedIndex === index
        ) {
          this.selectedIndex = null
          return
        }
        this.selectedIndex = index
        if (this.onSelect) this.onSelect(e, index)
      }
    },
  },
  render() {
    const tabs = this.$slots.default.filter(
      component => component.componentOptions
    )
    const tabList = []
    tabs.forEach((child, index) => {
      const { title, titleSlot, disabled } = child.componentOptions.propsData
      const content = titleSlot ? this.$slots[titleSlot] : title
      const isDisabled = true === disabled || '' === disabled
      if (this.breakPoint && window.innerWidth <= this.breakPoint) {
        tabList.push(
          <div
            class='rs-tab'
            role='accordeon'
            aria-selected={this.selectedIndex === index ? 'true' : 'false'}
            aria-disabled={isDisabled ? 'true' : 'false'}
          >
            <div
              class='rs-accordion'
              onClick={e => this.switchTab(e, index, isDisabled)}
            >
              {content}
            </div>
            <div class="rs-accordion-content">
              {tabs[index]}
            </div>
          </div>
        )
        return
      }
      tabList.push(
        <li
          class='rs-tab'
          role='tab'
          onClick={e => this.switchTab(e, index, isDisabled)}
          aria-selected={this.selectedIndex === index ? 'true' : 'false'}
          aria-disabled={isDisabled ? 'true' : 'false'}
        >
          {content}
        </li>
      )
    })
    if (this.breakPoint && window.innerWidth <= this.breakPoint) {
      return (
        <div class="rs-tabs" role='tabs'>
          <div class="rs-tablist" role='accordeon-list'>
            {this.$slots.left}
            {tabList}
            {this.$slots.right}
          </div>
        </div>
      )
    }

    return (
      <div class='rs-tabs' role='tabs'>
        <ul class='rs-tablist' role='tablist'>
          {this.$slots.left}
          {tabList}
          {this.$slots.right}
        </ul>
        {tabs[this.selectedIndex]}
      </div>
    )
  },
}

export const Tab = {
  name: 'rs-tab',
  props: ['title', 'titleSlot', 'disabled'],
  render() {
    return (
      <div class='rs-tabpanel' role='tabpanel'>
        {this.$slots.default}
      </div>
    )
  },
}

export function install(Vue) {
  Vue.component(Tabs.name, Tabs)
  Vue.component(Tab.name, Tab)
}

<template lang="pug">
  include /mixins
  +b.order-address-card
    +e.text.P {{ _('Получатель:') }} &nbsp;
      +b.ds-caption.--regular.SPAN {{ pathGetter('props', 'last_name')(item) }} {{ pathGetter('props', 'first_name')(item) }} 
        span(v-if='isUkraine') {{ pathGetter('props', 'middle_name')(item) }}
    +e.text.P {{ _('Город:') }} &nbsp;
      +b.ds-caption.--regular.SPAN(
        v-if="pathGetter('props', 'city', 'props', $options.lang == 'ru' ? 'description_ru' : 'description' )(item)"
      ) {{ pathGetter('props', 'city', 'props', $options.lang == 'ru' ? 'description_ru' : 'description' )(item) }}
      +b.ds-caption.--regular.SPAN(
        v-else
      ) {{ pathGetter('props', 'city', 'props', 'description' )(item) }}
      +b.ds-caption.--regular.--color_red.SPAN(
        v-if="item.props.city.props.is_not_available"
      ) &nbsp;({{ _('Город недоступен') }})
    +e.text.P {{ _('Способ доставки:') }} &nbsp;
      +b.ds-caption.--regular.SPAN {{ pathGetter('props', 'shipping_service', 'props', 'title')(item) }}
    template(
      v-if='item.props.internal_warehouse'
    )
      +e.text.P {{ _('Отделение:') }} &nbsp;
        +b.ds-caption.--regular.SPAN {{ pathGetter('props', 'internal_warehouse', 'props', 'address')(item) }}
    template(
      v-else-if='item.props.warehouse'
    )
      +e.text.P {{ _('Отделение:') }} &nbsp;
        +b.ds-caption.--regular.SPAN(
          v-if="pathGetter('props', 'warehouse', 'props', $options.lang == 'ru' ? 'description_ru' : 'description')(item)"
        ) {{ pathGetter('props', 'warehouse', 'props', $options.lang == 'ru' ? 'description_ru' : 'description')(item) }}
        +b.ds-caption.--regular.SPAN(
          v-else
        ) {{ pathGetter('props', 'warehouse', 'props', 'description')(item) }}
        +b.ds-caption.--regular.--color_red.SPAN(
            v-if="item.props.warehouse.props.is_not_available"
          ) &nbsp;({{ _('отделение временно не работает') }})
    template(v-else)
      +e.text.P {{ _('Адрес:') }} &nbsp;
          +b.ds-caption.--regular.SPAN.
            #[span(v-if='item.props.street_name') {{ pathGetter('props', 'street_name')(item) }}]   #[span(v-if='item.props.street') {{ pathGetter('props', 'street', 'props', 'streets_type')(item) }} {{ pathGetter('props', 'street', 'props', 'description')(item) }}], {{ _('буд.') }} {{ pathGetter('props', 'house')(item) }}#[span(v-if="item.props.building") , {{ _('корпус') }} {{ pathGetter('props', 'building')(item) }}]#[span(v-if="item.props.apartment") , {{ _('кв.') }} {{ pathGetter('props', 'apartment')(item) }} ] #[span(v-if="item.props.zip_code") , {{ _('Postal code: ') }} {{ pathGetter('props', 'zip_code')(item) }} ]
    +e.text.P {{ _('Телефон:') }} &nbsp;
      +b.ds-caption.--regular.SPAN {{ pathGetter('props', 'phone')(item) }}
    row(
      
    )
      cell(
        cols='12 6-xl'
      )
        +b.tt-link--grey.--variant_start.ds-caption--size_xsm.light.A.tt-space_mt--3(
          @click.prevent='openEdit'
          v-if='current.id == item.id'
        ) #[span.mdi.mdi-pencil.tt-space_pr.tt-space_pr--1 ] {{ _('Редактировать') }}
        //- +b.tt-link--grey.--variant_start.ds-caption--size_xsm.--light.tt-space_mt--3(
        //-   v-else
        //- ) &nbsp;
</template>
<script>

import {
  pathGetter,
} from '@utils/helpers'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
  UKRAINE_CODE,
} from '@app/consts'

import {
  ADDRESS_BOOK_EDIT_NAME,
} from '@app/Staff/AddressBook/routes'

import ModalWrapper from '@app/Forms/ModalWrapper'

const lang = window.language

export default {
  name: 'AddressBookCard',
  props: {
    item: Object,
    current: {},
  },
  ADDRESS_BOOK_EDIT_NAME,
  UKRAINE_CODE,
  lang,
  data() {
    return {
    }
  },
  computed: {
    isUkraine() {
      return getCookie(COUNTRY_CODE_COOKIE_NAME) === UKRAINE_CODE
    },
  },
  methods: {
    pathGetter,
    toPromise(arg) {
      if (!arg) return Promise.reject()

      return Promise.resolve(arg)
    },

    openEdit() {
      const pr = f => this.toPromise(f)
        .then(() => {
          this.$emit('update:list')
        })
      this.$modal.show(
        ModalWrapper,
        {
          classes: ['cart-modal'],
          info: {
            tag: 'AddressBookAdd',
            fromOrderPage: true,
            promise: pr,
            id: this.item.id,
          },
        }, {
          classes: ['cart-modal'],
          height: 'auto',
          minWidth: 300,
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
</script>

<template lang="pug">
a(:href="pageHref" target="_blank")
  slot
</template>

<script>

// const INFO_PAGE_PATH = 'pages/'

const DEFAULT_LANG = 'ru'

export default {
  inheritAttrs: false,
  name: 'Ciipa',
  data() {
    const lang = window.language
    return {
      lang,
    }
  },
  computed: {
    pageHref() {
      return `/${this.lang === DEFAULT_LANG ? '' : this.lang + '/' }${this.$attrs.href}`
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  .relative
    ui-loader(v-if='isLoad')
    +b.tt-space_mt--6.VALIDATION-OBSERVER(
      ref="validator",
      v-slot="{ valid }",
      tag="form",
    )
      ui-loader(v-if='isLoad')
      +b.g-row--align_center.--justify_end.--justify_end-xl.--appearance_spaced.--space_xl-xl.tt-space_mt--6

        cell(
          cols='12 8-md'
        )
          phone-input(
            :rules="'required|phone'",
            :input-label='labels.phone'
            v-model='form.phone'
            id='phone',
            name='phone',
            :disabled='!!$options.userphone'
          )
        cell(
          cols='12 4-md'
        )
          token-confirmation-check(
            :token='form.phone'
            :additional-data='{ is_new_user: !!!authUser }'
            :action='(code) => prepareData(valid, code)'
            :hide-captcha='true'
          )
            template(#default='{ is_confirmed, sendCode }')
              +b.tt-button--for-input.BUTTON(
                v-if='valid'
                :disabled='isLoad'
                @click.prevent='sendCode(true)'
              )
                +e.title--mini.P {{ _('далее') }}
              +b.tt-button--light.--bordered.--for-input.ds-caption--size_4xs.BUTTON(
                v-else
                :disabled='isLoad'
                @click.prevent='prepareData(valid)'
              )
                +e.title--mini.P {{ _('Замовити в один клік') }}
          //- phone-confirmation-check(
          //-   :phone='form.phone'
          //-   v-if='valid'
          //-   :action='() => prepareData(valid)'
          //- )
          //-   template(#default='{ is_confirmed, sendCode }')
          //-     +b.tt-button--light.--bordered.--for-input.ds-caption--size_4xs.BUTTON(
          //-       :disabled='isLoad'
          //-       @click.prevent='sendCode(true)'
          //-     )
          //-       +e.title--mini.P {{ _('Замовити в один клік') }}
</template>

<script>
import { mapActions } from 'vuex'

import {
  OneClickOrderCreate,
} from '@api/order.service'

import {
  AnswerMessage,
} from '@utils/submit'

import { decodeHTMLEntities } from '@utils/helpers'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

const userphone = window.request_phone
const username = decodeHTMLEntities(window.request_name)

export default {
  name: 'OneClickOrder',
  mixins: [FormMixin],
  userphone,
  data() {
    return {
      authUser: window.authent,
      form: {
        phone: userphone || '',
        name: username || '',
        id_token: '',
      },
      isLoad: false,
      labels: {
        name: this._('Имя'),
        phone: this._('Телефон'),
      },
    }
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
    ]),
    async prepareData(valid, code) {
      await this.$refs.validator.validate()
      if (!valid) return
      if (code) {
        this.form.id_token = code
      }
      this.submit(valid, this.form)
    },
    send(data) {
      return OneClickOrderCreate.execute({}, data)
        .then(res => {
          AnswerMessage(res.data, this)
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },

}
</script>

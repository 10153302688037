<template lang="pug">
  include /mixins
  +b.ds-panel--space_sm
    +e.element--offset_vertical
      +b.g-row--appearance_column
        //- +b.g-cell.g-cols--12
        //-   +b.checkout-title
        //-     +e.title.P {{ _('Контактные данные') }}
        +b.g-cell.g-cols--12(
          v-for='tab in tabs'
        )
          keep-alive
            tag(
              :key='tab.key'
              :tag='$options.widgetsMap[tab.tag]'
              v-if='tab.condition()'
              :fields='form'
              :no-redirect='true'
              @update='simulate($event)',
              @validate='$emit("valid", $event)'
            )

</template>

<script>

import {
  mapState,
  mapActions,
} from 'vuex'

// import Login from '@app/Forms/Login'
import NewUser from './Parts/NewUser'

const WIDGETS_MAP = {
  // login: Login,
  new_user: NewUser,
}
const USER_FIELDSET = ['first_name', 'last_name', 'middle_name', 'email']

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
  UKRAINE_CODE,
} from '@app/consts'

export default {
  name: 'OrderPersonal',
  props: {
    isOldUser: {
      default: false,
    },
  },
  widgetsMap: WIDGETS_MAP,
  data() {
    return {
      isAuth: false,
      tabs: [
        {
          key: 'new',
          title: this._('Новый клиент'),
          tag: 'new_user',
          condition: () => this.isLegal,
        },
        // {
        //   key: 'old',
        //   title: this._('Постоянный клиент'),
        //   tag: 'login',
        //   condition: () => !this.isLegal,
        // },
      ],
      form: {
        first_name: '',
        last_name: '',
        middle_name: '',
        phone: '',
        email: '',
      },

    }
  },
  created() {
    this.getUser(false)
  },
  computed: {
    ...mapState('staff', [
      'user',
    ]),
    isLegal() {
      const result = this.isOldUser && !this.isAuth
      return !result
    },
  },
  methods: {
    ...mapActions('staff', [
      'getUserInfo',
    ]),
    simulate(event) {
      const data = Object.assign(this.form, event)
      this.$emit('update', data)
      this.$emit('user:save', data)
    },
    async getUser(value) {
      this.$emit('user:type:update', value)
      try {
        if (!window.authent) {
          this.isAuth = false
          return
        }
        await this.getUserInfo()
        this.isAuth = true
        const { info } = this.user.props
        this.form.phone = this.user.props.username
        if (info) {
          USER_FIELDSET.forEach(key => {
            this.form[key] = info[key]
          })
          const additional = {
            isUkraine: getCookie(COUNTRY_CODE_COOKIE_NAME) === UKRAINE_CODE,
            has_middle_name: !!this.form.middle_name,
          }
          this.$emit('user:info', { ...info, ...additional })
          this.$emit('update', this.form)
        }
        this.$emit('user:type:update', true)
        this.$emit('update', this.form)
      } catch (e) {
        this.isAuth = false
      }
    },
  },
}
</script>

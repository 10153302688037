<script>
export default {
  name: 'TreeMenuTrigger',
  data() {
    return {
      show: false,
    }
  },
  methods: {
    close() {
      this.show = false
    },
    trigger() {
      this.show = !this.show
    },
  },
  render() {
    return this.$scopedSlots.default({
      trigger: this.trigger,
      close: this.close,
      showItem: this.show,
    })
  },
}
</script>

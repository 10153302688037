<template lang="pug">
include /mixins
div
  .is-visible-xl
    ui-accordion(
      :init='false',
      :is-calculate-height='false'
    )
      template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
        ui-click-outside(
          :do='collapse'
        )
          +b.switcher-with-dropdown
            +e.current(
              @click.prevent='countriesList.length > 1 ? toggleActive() : () => ({})'
              v-if='selectedCountry && selectedCountry.props'
            )
              +e.img-wrap
                +e.img.IMG(
                  :src='selectedCountry.props.icon'
                  :alt='selectedCountry.props.title'
                )
              +e.link.ds-caption--size_sm.--appearance_uppercase.SPAN {{ selectedCountry.props.title }}
              +e.icon.mdi.I(
                v-if='countriesList.length > 1'
                :class='icon'
              )
            transition(name='slidedown')
              +e.options(
                v-show="activeState"
                :class='classname'
              )
                +e.option(
                  v-for='country in countriesList'
                  @click='selectCountry(country, toggleActive)'
                  v-if='country !== selectedCountry'
                )
                  //- template(v-if='!checkHostAndIso(country)')
                  +e.img-wrap
                    +e.img.IMG(
                      :src='country.props.icon'
                    )
                  +e.link.ds-caption--size_sm.--appearance_uppercase.SPAN(
                  ) {{ country.props.title }}
  .is-disable-xl
    +b.native-changer-select-wrapper
      +b.native-changer-select--with-img.SELECT(
        :class='classname'
        @change='select'
        v-model='selectedCountry'
        :style='{ "--icon": `url("${selectedCountry && selectedCountry.props && selectedCountry.props.icon}")` }'
      )
        +e.option--with-img.OPTION(
          v-for='country in countriesList'
          :key='country.props.title'
          :value='country'
          :style='{ "--icon": `url("${country.props.icon}")` }'
        )
          //- +e.flag.SPAN {{ $options.FLAG_MAP[country.props.iso_code] }}
          +e.link.ds-caption--size_sm.--appearance_uppercase.SPAN(
          )  {{ country.props.title }}
      ui-loader(v-if='load')
</template>

<script>

import {
  countriesListResource,
  countryChangeResource,
  LangChangeResource,
} from '@api/vendor.service'

import {
  mapActions,
  mapState,
} from 'vuex'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

import {
  removeLanguage,
  prefixLanguage,
} from '@utils/urls'

const host = 'totispharma.com'
const isoExclude = '203'

const FLAG_MAP = {
  703: '🇸🇰',
  804: '🇺🇦',
  616: '🇵🇱',
  498: '🇲🇩',
  203: '🇨🇿',
  860: '🇺🇿',
}

export default {
  name: 'CountrySwitcher',
  props: ['classname'],
  countriesListResource,
  countryChangeResource,
  FLAG_MAP,
  data() {
    return {
      iso_code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      // selectedCountry: {},
      list: [],
      load: false,
    }
  },
  computed: {
    ...mapState('country', [
      // 'selectedCountry',
      'countriesList',
    ]),
    selectedCountry: {
      get() {
        return this.$store.state.country.selectedCountry
      },
      set(value) {
        this.saveCountry(value)
      },
    },
  },
  methods: {
    ...mapActions('country', [
      'saveCountry',
      'getCountries',
    ]),
    checkHostAndIso(country) {
      return country.props.iso_code === isoExclude && window.location.host === host && window.isSuperUser !== 'True'
    },
    async selectCountry(country, action) {
      if (!this.selectedCountry) return
      // this.selectedCountry = country
      this.saveCountry(country)
      const { data: { lang } } = (await countryChangeResource.execute({ code: this.selectedCountry.props.iso_code }))
      action()
      const url = removeLanguage(window.location.pathname)
      const newUrl = prefixLanguage(url, { language: lang })
      await LangChangeResource.execute({ code: lang })
      window.location.href = newUrl
    },
    select() {
      this.load = true
      this.selectCountry(this.selectedCountry, () => {})
    },
  },
}
</script>

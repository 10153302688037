import {
  createResource,
  postResource,
  deleteResource,
  patchResource,
} from '@resource/resource'

import {
  simpleTransformer,
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const MODEL = 'recipe'

const RECIPE_CHANGE_URL = prefixAPI('/change/', MODEL)
const RECIPE_UPDATE_URL = prefixAPI('/update/', MODEL)
const RECIPE_RETRIEVE_URL = prefixAPI('/retrieve/', MODEL)
const RECIPE_CLEAR_URL = prefixAPI('/clear/', MODEL)
const RECIPE_DESTROY_URL = prefixAPI('/lines/destroy/{id}/', MODEL)
const RECIPE_DISCOUNTS_URL = prefixAPI('/discounts/', MODEL)
const RECIPE_CREATE_CART_URL = prefixAPI('/to-cart/', MODEL)
const RECIPE_LINE_UPDATE_URL = prefixAPI('/lines/update/{id}/', MODEL)

export const changeRecipeEntity = createResource(
  RECIPE_CHANGE_URL, postResource
)
export const clearRecipeEntity = createResource(RECIPE_CLEAR_URL, postResource)
export const createCart = createResource(RECIPE_CREATE_CART_URL, postResource)
export const updateRecipeEntity = createResource(
  RECIPE_LINE_UPDATE_URL,
  patchResource
)
export const updateRecipe = createResource(
  RECIPE_UPDATE_URL,
  patchResource
)
export const destroyRecipeLine = createResource(
  RECIPE_DESTROY_URL,
  deleteResource,
  simpleTransformer
)

export const retriveRecipe = createResource(RECIPE_RETRIEVE_URL)
export const retriveDiscounts = createResource(RECIPE_DISCOUNTS_URL)

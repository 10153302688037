const escapeRegExp = string => string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // экранирование специальных символов

const regexer = value => new RegExp(`(?![^&;]+;)(?!<[^<>]*)(${escapeRegExp(value)})(?![^<>]*>)(?![^&;]+;)`, 'gi')

export function highlightTerm(value, term) {
  return value
    .replace(regexer(term),
      '<b>$1</b>')
}

export function findValueAndHighlightTerm(template, value, term) {
  return template
    .replace(regexer(value),
      highlightTerm(value, term))
}

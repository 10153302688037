<template lang="pug">
  include /mixins
  div(
    v-if='order.shipping_service'

  )
    +b.tt-space_mt--6.D-CONTROL-CALENDAR(
      v-model="form.delivery_date"
      @input="handleInput"
      :config="config"
      @on-day-create="onDayCreate"
      :events='eventsToBeEmitted'
      :input-label='_("Желаемая дата доставки")'
    )
    +b.ds-caption--size_xs.--color_red.P(
      v-if='isBonusesAvailable && order.shipping_service.props && form.is_urgent'
    ) {{ order.shipping_service.props.urgent_delivery_description }}
    +b.delivery-time(
      v-if='timesForSelect.length > $options.DATES_LENGTH'
    )
      template(
        v-for='item in timesForSelect'
      )
        +e.item.A(
          href=''
          :class='{ "is-active": selected == item }'
          @click.prevent='select(item)'
        ) {{ _('время с') }} {{ item.after }} {{ _('время до') }} {{ item.before }}
</template>

<script>

import { add, isAfter, lightFormat, differenceInMinutes } from 'date-fns'

const DAYS_OFFSET = 2

const DATE_WITH_OFFSET = add(new Date(), {
  days: DAYS_OFFSET,
})

const ALT_FORMAT = 'D d.m'

const DATES_LENGTH = 1

const DAY_OF_WEEK = {
  0: 'Sunday',
  1: 'monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export default {
  name: 'DateTime',
  props: ['order', 'isBonusesAvailable'],
  DATES_LENGTH,
  data() {
    return {
      form: {
        delivery_date: DATE_WITH_OFFSET,
        is_urgent: false,
        customer_delivery_time_from: '',
        customer_delivery_time_to: '',
      },
      config: {
        // dateFormat: 'd.m.Y',
        altInput: true,
        altFormat: ALT_FORMAT,
        minDate: DATE_WITH_OFFSET,
        disableMobile: true,
        disable: [],
      },
      selected: {},
      info: {},
      eventsToBeEmitted: ['onDayCreate'],
    }
  },
  watch: {
    'order.shipping_service.props': {
      handler(nval, oval) {
        if (nval === oval || !this.order.shipping_service?.props) return
        this.setMinDate()
      },
      deep: true,
      immediate: true,
    },
    selected: {
      handler() {
        if (!this.order.shipping_service?.props) return
        const d = this.selected?.after ? `${this.selected.after}-${this.selected.before}` : ''
        this.config.altFormat = `${ALT_FORMAT} ${d}`
        this.form.customer_delivery_time_from = this.selected?.after
        this.form.customer_delivery_time_to = this.selected?.before
        this.handleInput()
      },
      deep: true,
      immediate: true,
    },
    'form.delivery_date': {
      handler() {
        if (!this.order.shipping_service?.props) return
        this.setUrgent()
      },
      immediate: true,
    },
    timesForSelect: {
      handler() {
        const [time] = this.timesForSelect
        this.selected = time
      },
      deep: true,
    },
    refactorDates: {
      handler() {
        // const dates = this.refactorDates
        // this.selected = time
      },
      deep: true,
    },
  },
  computed: {
    timesForSelect() {
      if (!this.order.shipping_service?.props) return []
      const times = this.order.shipping_service.props.delivery_time.filter(e => {
        const [h, m] = e.before.split(':')
        const d = new Date(new Date(this.form.delivery_date).setHours(h)).setMinutes(m)
        const d1 = lightFormat(d, 'yyyy-MM-dd HH:mm')
        const dWith = new Date()
        let d2 = add(dWith, {
          hours: this.order.shipping_service.props.hours_to_delivery_date,
        })
        d2 = lightFormat(d2, 'yyyy-MM-dd HH:mm')
        return isAfter(new Date(d1), new Date(d2))
      })
      return times
    },
    refactorDates() {
      const disable = []
      if (!this.order.shipping_service) return {}
      const { props } = this.order.shipping_service
      const info = {}
      if (props.shipping_days_configurator) {
        const dates = props.shipping_days_configurator.props.day_confs.reduce((acc, obj) => {
          const dayType = obj.props.day_type.value;
          const dayProps = obj.props.day.props;

          const targetArray = 'business_day' === dayType ? acc.business : acc.holiday;

          if (dayProps.date_at) {
            targetArray.dates.push(dayProps.date_at)
            info[dayProps.date_at] = dayProps.title
          }
          if (dayProps.days_of_week) {
            targetArray.days.push(dayProps.days_of_week)
          }
          return acc
        }, { business: { days: [], dates: [] }, holiday: { days: [], dates: [] } })
        this.info = info
        return dates
      }
      return disable
    },
  },
  mounted() {
    this.handleInput()
  },
  methods: {
    onDayCreate(dObj, dStr, fp, dayElem) {
      const t = this.checkBlockedInfo(dayElem.dateObj)
      let title = ''
      const r = t.dateHoliday || t.dateBusiness
      const dh = t.dayHoliday
      if (dh) {
        // dayElem.classList.add('is-holiday-day')
        title = this._('Выходной день')
      }
      if (r) {
        dayElem.classList.add('is-holiday-date')
        title = this.info[r]
      }
      dayElem.title = title
    },
    handleInput() {
      this.$emit('update', this.form)
    },
    select(item) {
      this.selected = item
    },
    getValidMinDate(date) {
      let newDate = add(new Date(date), {
        days: 1,
      })
      const isInvalid = this.getBlocked(newDate)
      if (isInvalid) {
        newDate = this.getValidMinDate(newDate)
      }
      return newDate
    },
    setMinDate() {
      this.config.disable = [day => this.getBlocked(day)]
      const date = new Date()

      // const isInvalidInit = this.getBlocked(date)
      // if (isInvalidInit) {
      //   date = this.getValidMinDate(date)
      // }

      // const hours = this.order.shipping_service.props.hours_to_delivery_date

      // for (let x = hours; 0 < x; x--) {
      //   const d = add(date, {
      //     hours: 1,
      //   })
      //   const isInvalid = this.getBlocked(d)
      //   if (isInvalid) {
      //     date = this.getValidMinDate(d)
      //   }
      // }
      // console.log(date, 'DATE <<<<>>>>');

      let newDate = add(date, {
        hours: this.order.shipping_service.props.hours_to_delivery_date,
      })
      const isInvalid = this.getBlocked(newDate)
      if (isInvalid) {
        newDate = this.getValidMinDate(newDate)
      }
      this.form.delivery_date = newDate
      this.config.minDate = newDate
    },
    checkBlocked(day) {
      const d1 = lightFormat(day, 'dd.MM.yyyy')
      const dayHoliday = this.refactorDates.holiday.days.includes(DAY_OF_WEEK[day.getDay()])
      const dateHoliday = this.refactorDates.holiday.dates.includes(d1)
      const dayBusiness = this.refactorDates.business.days.includes(DAY_OF_WEEK[day.getDay()])
      const dateBusiness = this.refactorDates.business.dates.includes(d1)
      return { dayHoliday, dateHoliday, dayBusiness, dateBusiness }
    },
    checkBlockedInfo(day) {
      const d1 = lightFormat(day, 'dd.MM.yyyy')
      const dayHoliday = this.refactorDates.holiday.days.find(e => e === DAY_OF_WEEK[day.getDay()])
      const dateHoliday = this.refactorDates.holiday.dates.find(e => e === d1)
      const dayBusiness = this.refactorDates.business.days.find(e => e === DAY_OF_WEEK[day.getDay()])
      const dateBusiness = this.refactorDates.business.dates.find(e => e === d1)
      return { dayHoliday, dateHoliday, dayBusiness, dateBusiness }
    },
    getBlocked(day) {
      const { dayHoliday, dateHoliday, dayBusiness, dateBusiness } = this.checkBlocked(day)

      if ((dayHoliday || dateHoliday) && (dayBusiness || dateBusiness)) {
        return false
      }
      return dayHoliday || dateHoliday
    },
    setUrgent() {
      let r = new Date()
      for (let x = 1; x < this.order.shipping_service.props.days_for_urgent_delivery; x++) {
        r = add(r, {
          days: 1,
        })
        const isInvalid = this.getBlocked(r)
        if (isInvalid) {
          r = this.getValidMinDate(r)
        }
      }
      const d1 = lightFormat(r, 'yyyy-MM-dd HH:mm')
      const d2 = lightFormat(new Date(this.form.delivery_date), 'yyyy-MM-dd HH:mm')
      this.form.is_urgent = isAfter(new Date(d1), new Date(d2))
      this.handleInput()
    },
  },
}
</script>

import {
  GoogleAuthProvider,
  signInWithRedirect,
} from 'firebase/auth';

import { firebaseLogout } from '@/js/firebase/logout'

export function applySignIn(auth) {
  console.log(auth);
  if (auth.currentUser) {
    firebaseLogout()
  }
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' })
  provider.addScope('https://www.googleapis.com/auth/plus.login');
  signInWithRedirect(auth, provider)
}

export function toggleGoogleSignIn(signInButton, auth) {
  signInButton.addEventListener('click', () => applySignIn(auth), false)
}

<template lang='pug'>
  include /mixins
  ui-click-outside(:do='hide')
    div
      slot(
        name='inputview',
        :keyup-event='dynamicSearch'
        :keydown-event='getFocus',
        :go-to-search='goToSearch'
        :reset='hide'
      )
      ui-loader.mini-loading(v-if='load')
      +b.seacrh-result-wrapper
        transition-group(name="slidedown")

          +b.search-results(
            v-if='results.length',
            key='results'
          )

            +b.list-search
              tag(
                :results='results',
                :findValueAndHighlightTerm='findValueAndHighlightTerm',
                :highlightTerm='highlightTerm'
                :tag='preview'
                :localsearch='localsearch'
              )
            //- +b.g-row
            //-   +b.g-cell.g-cols--12
            //-     .relative
            //-       +b.line-h--light
            //-       slot(
            //-         name='link'
            //-         :meta='meta'
            //-       )
            //-         +b.tt-space_pa--2.ds-caption--size_xsm.--medium.BUTTON(
            //-         ) {{ _('Найдено') }} {{ meta.total }}
          +b.search-results(
            v-if='meta.total == 0',
            key='withoutresults'
          )
            +b.ds-panel--space_xs.--space_md-xl
              +e.element--offset_full
                +b.ds-caption--size_6.--regular.P(
                  v-if='meta.total == 0',
                ) {{ _("По вашему поисковому запросу ничего не найдено.") }}

</template>
<script>
// Highlight the query part of the search term
import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy';
import UiClickOutside from '@app/UiElements/UiClickOutside'
import { mapMutations, mapActions, mapState } from 'vuex'
import {
  debounce,
} from '@utils/helpers'

import {
  findValueAndHighlightTerm,
  highlightTerm,
} from '@utils/highlight'

import { createResource, getResource } from '@resource/resource'

export default {
  name: 'SearchController',
  props: ['className', 'fieldId', 'url', 'localsearch', 'searchResource', 'preview'],
  components: {
    UiClickOutside,
  },
  data() {
    return {
      // localsearch: '',
      show: false,
      arrowCounter: -1,
      empty: false,
      load: false,
      results: [],
      length: 1,
      meta: {

      },
      currentRequest: '',
    }
  },

  computed: {
    ...mapState('cart', [
      'cart_info',
    ]),
  },
  watch: {
    localsearch(nval) {
      if (!nval) {
        this.hide()
      }
    },
  },
  methods: {
    findValueAndHighlightTerm,
    highlightTerm,
    ...mapMutations('search', [
      'SET_SEARCH',
    ]),
    ...mapActions('search', [
      'trigger_overlay',
    ]),
    hide() {
      this.reset()
      this.activeClass = false
      this.trigger_overlay(false)
    },
    reset() {
      this.results = []
      this.clearClas = false
      this.empty = false
      this.activeClass = false
      this.arrowCounter = -1
      this.meta = {}
    },
    activeSearch() {
      this.show = !this.show
    },
    clearSearch() {
      this.search = ''
    },
    dynamicSearch(event) {
      // if ('Enter' == event.code) {
      //   this.goToSearch()
      //   return
      // }
      const up = 38
      const down = 40
      const width = 1200
      if (window.innerWidth < width) {
        this.localsearch = event.target.value
      }
      const limit = 2
      if (up !== event.keyCode && down !== event.keyCode) {
        if (this.localsearch.length < limit) return
        this.load = true

        this.debounceExecuter()
      } else {
        this.results = []
        this.empty = false
      }
    },
    debounceExecuter(parameters) {
      const time = 1000
      const action = debounce(this.executer, time)
      action(parameters)
    },
    executer() {
      if (this.currentRequest) this.currentRequest.cancel()
      const cancelController = new AbortController();
      const base = getResource.config('signal', cancelController.signal)
      const resource = createResource(this.searchResource, base)

      this.currentRequest = cancellablePromiseProxy(
        new Promise((resolve, reject) => {
          resource.execute({
            search: this.localsearch,
          })
            .then(resolve, reject);
        }),
        { cancelController }
      ).then(({ data: { items, pagination } }) => {
        this.trigger_overlay(true)
        this.results = items
        this.meta = pagination
        this.load = false
        this.$body.dataset.blocked = 0 < items.length
      })
    },
    goToSearch() {
      // this.trigger_overlay(false)
      // // if (window.is_main_page) {
      // //   this.SET_SEARCH(this.localsearch)
      // // } else {
      // // }
      // window.location.pathname = `${window.baseUrl}filters/search=${this.localsearch}/`
      // this.reset()
    },
    onEnter() {
      this.arrowCounter = -1
      this.goToSearch()
    },
    getFocus(event) {
      // if ('Enter' == event.code) {
      //   this.reset()
      //   return
      // }
      const i = 0
      const one = 1
      const zero = 0
      if (this.results.length > i) {
        if ('ArrowDown' === event.code) {
          this.$refs['search-link'][i].focus()
        }
        document.addEventListener('keydown', e => {
          if ('ArrowDown' === e.code && this.arrowCounter < this.results.length - one) {
            e.preventDefault()
            this.arrowCounter += one
            this.$refs['search-link'][this.arrowCounter].focus()
          }
          if ('ArrowUp' === e.code && this.arrowCounter > zero) {
            e.preventDefault()
            this.arrowCounter -= one
            this.$refs['search-link'][this.arrowCounter].focus()
          }
        })
      }
    },
  },
}

</script>

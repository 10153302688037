import {
  AnswerMessage,
} from '@utils/submit'

import { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  methods: {
    parseErrors(errors, name) {
      errors.json().then(body => {
        const errorsList = {}
        body.errors.forEach(error => {
          if ('request' === error.domain && error.state) {
            Object.assign(errorsList, error.state)
          }
        })
        const parsed = defaultValidatorErrorsParser(errorsList)
        let meta
        if ('non_field_errors' in parsed) {
          meta = {
            message: {
              title: parsed.non_field_errors[0],
            },
          }
        } else {
          const field = parsed[name]
          meta = {
            message: {
              title: field[0],
            },
          }
        }
        AnswerMessage(meta, this)
      })
    },
  },
}

<template lang="pug">
include /mixins
+b.linear-progress-bar
  +e.bar
    +e.progress(:style="{ width: computedWidth }")
  +e.text.ds-caption--size_sm.--medium.tt-space_ml--4 {{ progress }}%
</template>
<script>
export default {
  name: 'LinearProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0
    },
  },
  computed: {
    // Вычисляем ширину прогресс-бара
    computedWidth() {
      return `${this.progress}%`
    },
  },
};
</script>

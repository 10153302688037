<script>
import { mapActions } from 'vuex'
// import RecipeView from '@app/Recipe/View'
export default {
  name: 'AddToRecipe',
  props: {
    id: {
      type: [String, Number],
    },
    type: {
      type: String,
    },
    amount: {
      type: [String, Number],
    },
    price: {},
    name: {},
  },
  methods: {
    ...mapActions('recipe', [
      'changeRecipeEntity',
    ]),
    async addToRecipe(id, type, amount) {
      const data = {
        product_variant: this.id,
        quantity: this.amount,
      }
      const entities = []
      entities.push(data)
      await this.changeRecipeEntity({ entities })
      // this.openRecipe()
      this.$notify({
        // type: 'success', //'error', 'warning'
        title: `${this.name} ${this._('добавлено в рецепт.')}`,
        text: this._('Перейти в рецепт'),
      })
    },
    openRecipe() {
      this.$modal.show(
        RecipeView,
        {}, {
          height: 'auto',
          classes: ['Recipe-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      add: () => {
        this.addToRecipe()
      },
    })
  },
}
</script>

import {
  retriveRecipe,
  clearRecipeEntity,
  // retrieveRecipeInfo,
  destroyRecipeLine,
  updateRecipeEntity,
  updateRecipe,
  changeRecipeEntity,
} from '@api/recipe.service'

const SET_RECIPE = 'SET_RECIPE'
const SET_RECIPE_INFO = 'SET_RECIPE_INFO'

export function createStore(parameters) {
  return {
    namespaced: true,
    state: {
      recipe: {
        groups: [],
      },
      recipe_info: {
        quantity: 0,
        total_price: 0,
      },
    },
    actions: {
      async getRecipe({ commit }, payload) {
        const { data: { props } } = (await retriveRecipe.execute())
        commit(SET_RECIPE, { payload: props })
      },
      async updateRecipe({ commit }, { id, ...rest }) {
        return updateRecipeEntity.execute({ id }, rest)
          .then(async ({ data: { props } }) => {
            commit(SET_RECIPE, { payload: props })
          })
      },
      async changeRecipeEntity({ commit }, payload) {
        return changeRecipeEntity.execute({}, payload)
          .then(async ({ data: { props } }) => {
            commit(SET_RECIPE, { payload: props })
          })
      },
      async changeRecipe({ commit }, payload) {
        return updateRecipe.execute({}, payload)
          .then(async ({ data: { props } }) => {
            // commit(SET_RECIPE, { payload: props })
          })
      },
      async destroyLine({ commit, dispatch }, { id }) {
        return destroyRecipeLine.execute({ id })
          .then(async () => dispatch('getRecipe'))
      },
      async clearRecipe({ commit }, payload) {
        const { data } = (await clearRecipeEntity.execute())
        commit(SET_RECIPE, { payload: data })
      },
      async getRecipeInfo({ commit }, payload) {
        const { data } = (await retrieveRecipeInfo.execute())
        commit(SET_RECIPE_INFO, data)
      },
    },
    mutations: {
      [SET_RECIPE](state, payload) {
        state.recipe = payload.payload
        // state.recipe_info.total_price = state.recipe.total_price
        // state.recipe_info.quantity = state.recipe.quantity
      },
      [SET_RECIPE_INFO](state, payload) {
        state.recipe_info = payload
        // windowstate.recipe_total = state.recipe_info.total_price
      },
    },
  }
}

<template lang="pug">
include /mixins
+b.g-row--align_center.--justify_center
  +b.g-cell--12.--6-md.--3-xl
    modal-trigger(
      url='Result/Create',
      name='ResultCreate',
      :id='id'
      :classes='["cart-modal"]'
    )
      template(#default='{ open }')
        +b.tt-button(
          @click.prevent='open',
        )
          +e.title.P {{ _('Добавить результат') }}
</template>

<script>

import {
  ResultCreate,
  ResultList,
} from '@api/store.service'

export default {
  name: 'ProductResultList',
  ResultCreate,
  props: ['id'],
  data() {
    return {
      result: {
        name: '',
        description: '',
        img: '',
        price: '',
        category: '',
        quantity: '',
      },
      keys: [
        {
          name: 'before',
          title: this._('Загрузить ДО'),
          additional: { is_before: true },
        },
        {
          name: 'after',
          title: this._('Загрузить ПОСЛЕ'),
          additional: { is_after: true },
        },
      ],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    createResult() {
      // ResultCreate
    },
    setKeys(list, key) {
      this.form[key] = [...this.form[key], ...list.map(el => el.id)]
    },
    getList() {
      ResultList.execute({ id: this.id })
        .then(res => {
          this.list = res.data
        })
    },
  },
}
</script>

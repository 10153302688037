import CartPreview from '@app/Cart/Preview'
import GiftVariants from '@app/Cart/GiftVariants'
import StockChecker from '@app/Cart/StockChecker'
import Promocode from '@app/Cart/Promocode'

export default function install(Vue) {
  Vue.component(CartPreview.name, CartPreview)
  Vue.component(GiftVariants.name, GiftVariants)
  Vue.component(StockChecker.name, StockChecker)
  Vue.component(Promocode.name, Promocode)
}

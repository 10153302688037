<template lang="pug">
  include /mixins
  div
    +b.seo-text.t-content(
      :class="{ 'shadowed': shadowed && !showSeo }"
      :style="{ maxHeight: `${contentHeight}px` }",
      ref="text"
    )
      slot
    +b.A.tt-link--variant_end.--styling_default.tt-space_mt--3.--5-xl(
      type="button",
      v-if="showBtn"
      @click="showSeo = !showSeo; setHeight();"
      :class="{ 'visible': !showSeo }"
    ) {{ showSeo ?  _('Свернуть') : _(' Узнать больше ') }}
</template>

<script>
export default {
  name: 'SeoText',
  props: {
    visibleHeight: {
      default: 0,
    },
  },
  data() {
    return {
      shadowed: false,
      showBtn: false,
      showSeo: false,
      contentHeight: this.visibleHeight,
      scrollHeight: 0,
    }
  },
  mounted() {
    this.innerHeight()
  },
  methods: {
    innerHeight() {
      const delay = 500
      setTimeout(() => {
        if (this.$refs.text.scrollHeight > this.visibleHeight) {
          this.scrollHeight = this.$refs.text.scrollHeight
          this.$refs.text.style.maxHeight = `${this.visibleHeight}px`
          this.showBtn = true
          this.shadowed = true
        }
      }, delay)
    },
    setHeight() {
      if (this.showSeo) {
        this.contentHeight = this.scrollHeight
        return
      }
      this.contentHeight = this.visibleHeight
    },
  },
}
</script>

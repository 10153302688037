<template lang="pug">
include /mixins
+b.result-img-loader-wrapper(
  @dragover.prevent="handleDragOver"
  @drop.prevent="handleDrop"
  @dragenter.prevent
  @dragleave="handleDragLeave"
)
  input(type='file' accept="image/*" :id='name' @input='handleDrop')
  +b.result-img-loader(
    :class='{ "is-active": localImage }'
  )
    +e.img.IMG(
      :src='localImage',
      v-if='localImage'
    )
    +e.content(
      v-else
    )
      +e.icon.SVG(width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(
          d='M48.92 11.36C44.9734 8.13334 40.0934 6.00001 34.7334 5.46667V10.8533C38.6267 11.3333 42.1734 12.88 45.1334 15.1733L48.92 11.36ZM53.2134 29.3333H58.6C58.0667 23.9733 55.9334 19.0933 52.7067 15.1467L48.8934 18.9333C51.1867 21.8933 52.7334 25.44 53.2134 29.3333ZM48.8934 45.0667L52.7067 48.88C55.9334 44.9333 58.0667 40.0267 58.6 34.6933H53.2134C52.7334 38.56 51.1867 42.1067 48.8934 45.0667ZM34.7334 53.1467V58.5333C40.0934 58 44.9734 55.8667 48.92 52.64L45.1067 48.8267C42.1734 51.12 38.6267 52.6667 34.7334 53.1467ZM41.64 28.24L34.7334 35.12V18.6667H29.4V35.12L22.4934 28.2133L18.7334 32L32.0667 45.3333L45.4 32L41.64 28.24ZM29.4 53.1467V58.5333C15.9334 57.2 5.40002 45.84 5.40002 32C5.40002 18.16 15.9334 6.80001 29.4 5.46667V10.8533C18.8667 12.16 10.7334 21.12 10.7334 32C10.7334 42.88 18.8667 51.84 29.4 53.1467Z'
          fill='#1183F6'
        )
      +e.title.P {{ _('Перетащите сюда файл или') }}
        label(:for='name') {{ _('выберите его') }}
      +e.text.SPAN {{ _('.jpg. .png') }}
  +e.button-load
    +b.tt-button--mini.--bordered_main.LABEL(
      :for='name'
      @click='toggleShow'
      style='min-height: 47px'
    )
      +e.title.SPAN {{ localImage ? titles.update : titles.create }}
</template>

<script>

import Clipper from '@app/Staff/Components/Clipper'

const DEFAULT_SIZE = 200
const DEFAULT_RATIO = 1

export default {
  name: 'ResultLoader',
  props: {
    name: {},
    titles: {},
    img: {},
    type: {
      default: () => 'avatar',
    },
    width: {
      default: () => DEFAULT_SIZE,
    },
    height: {
      default: () => DEFAULT_SIZE,
    },
    method: {
      default: () => 'POST',
    },
    ratio: {
      default: () => DEFAULT_RATIO,
    },
    rate: {
      default: () => DEFAULT_RATIO,
    },
    postResource: {},
    deleteResource: {},
    additional: {},
    filekey: {
      default: 'img',
    },
  },
  data() {
    return {
      file: null,
      localImage: null,
      isDragOver: false,
    }
  },
  methods: {
    handleDragOver(event) {
      event.preventDefault();
      this.isDragOver = true;
      // Добавим следующую строку, чтобы предотвратить открытие изображения в новой вкладке
      event.dataTransfer.dropEffect = 'copy';
      console.log(event.dataTransfer);
    },
    handleDragLeave() {
      this.isDragOver = false;
    },
    handleDrop(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.toggleShow(files[0])
    },
    toPromise(file) {
      if (!file) return Promise.reject()

      return Promise.resolve({
        file, img: window.URL.createObjectURL(file),
      })
    },
    load(element) {
      if (this.postResource) {
        this.postResource.execute({}, {
          ...this.additional, [this.filekey]: element,
        }).then(({ data: { item: { id } } }) => {
          this.$emit('update', id)
        })
        return
      }
    },
    toggleShow(imgfile) {
      console.log(imgfile);
      const preview = window.URL.createObjectURL(imgfile)
      const pr = f => this.toPromise(f)
        .then(({ file, img }) => {
          this.file = file
          this.localImage = img
          this.load(file)
          this.$emit('completed', { key: 'img', value: file })
        })
        .catch(err => {
          this.$emit('error', err)
        })

      this.$modal.show(
        Clipper,
        {
          promise: pr,
          type: this.type,
          w: this.width,
          h: this.height,
          method: this.method,
          ratio: this.ratio,
          rate: this.rate,
          preview,
        },
        {
          height: 'auto',
          classes: 'loadPopup',
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
</script>

import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'store/favorite'

const ADD_TO_FAVORITE_URL = prefixAPI('/add/', MODEL)
const REMOVE_FROM_FAVORITE_URL = prefixAPI('/remove/', MODEL)
const FAVORITE_COUNT_URL = prefixAPI('/count/', MODEL)
const FAVORITE_LIST_URL = prefixAPI('/is/', MODEL)
const FAVORITE_FULL_LIST_URL = prefixAPI('/list/{?limit,offset,category}', MODEL)
const FAVORITE_CATEGORIES_URL = prefixAPI('/filter/', MODEL)

export const addToFavorite = createResource(ADD_TO_FAVORITE_URL, postResource)
export const removeFromFavorite = createResource(
  REMOVE_FROM_FAVORITE_URL, postResource
)
export const getFavoritesCount = createResource(
  FAVORITE_COUNT_URL
)
export const getFavoritesList = createResource(
  FAVORITE_LIST_URL
)
export const getFavoritesFullList = createResource(
  FAVORITE_FULL_LIST_URL
)
export const getCategoriesList = createResource(
  FAVORITE_CATEGORIES_URL
)

<template lang='pug'>
  include /mixins
  div(v-if='showFilters')
    transition(name='fade')
      +b.fake-sidebar-load(
        v-if='loading'
      )
        ui-loader
    ui-accordion(
      :init='false',
      :full-height='true'
    )
      template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
        +b.tt-accordion--bordered(
          :class='{"tt-accordion--is-open": activeState}',
        )
          ui-click-outside(
            :do='collapse'
          )
            div
              +e.question(
                :class='{"is-small": needSorting}'
                @click='$modal.show("filters-modal");',
                v-if='!isDesktop'
              )
                +e.icon.mdi.I(:class='activeState ? "mdi-minus" : "mdi-plus"')
                +b.filter-title
                  +b.ds-caption--size_xl.--medium.--height_2xs.P.tt-space_mr--2(
                  )
                    span.icon-filter_n
                  +b.ds-caption--size_sm.--medium.P {{ _('Фильтры') }}
                //- +e.text
                //-   +b.ds-caption--size_2xs.--size_xs-xl.--medium.--appearance_center.P
                //-     +b.SPAN.ds-caption--size_xl.--medium.--height_2xs.tt-space_mr--2.is-icon.icon-filter_n
                //-     span {{ _('Фильтры') }}
                portal-target(name='sorting')
              template(
                v-else
              )
                +b.filter-title-wrap
                  +b.g-row--justify_between.--align_center
                    +b.g-cell.g-cols
                      +b.filter-title
                        +b.ds-caption--size_xl.--medium.--height_2xs.P.tt-space_mr--2(
                        )
                          span.icon-filter_n
                        +b.ds-caption--size_sm.--medium.P {{ _('Фільтри') }}
                    +b.g-cell.g-cols
                      +b.tt-link.--variant_start.ds-caption--size_sm.--appearance_underline.A(
                        href=''
                        @click.prevent='handleInputFilter({})'
                      ) {{ _('Очистить фильтр') }}

              +e.description(
                :style='{maxHeight: myHeight}',
                :class='{"isFullMaxHeight": isDesktop}'
              )
                +e.content--no-pa
                  filter-ui(
                    :disabled="loading"
                    :value="prepared"
                    :available="availableMap"
                    :filters="filters"
                    :need-sorting='needSorting'
                    :need-search='needSearch'
                    :need-title='needTitle'
                    :pagination='pagination'
                    :prices='prices'
                    :sortlist='sortlist'
                    @input="handleInputFilter",
                    @category-hack='$emit("category-hack")'
                    :isDesktop='isDesktop',
                    :label='label',
                    :initbaseUrl='initbaseUrl'
                  )

</template>

<script>
import ControllerMixin from '@app/FiltersSimple/mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}

</script>

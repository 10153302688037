import { addHours } from 'date-fns'
/**
 * get cookie
 * @param {*} name
 */
 export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      `(?:^|; )${name.replace(
        /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
        '\\$1'
      )}=([^;]*)`
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

const bakeCookie = (name, value, hours) => {
  let date = new Date()
  date = addHours(date, hours)
  const cookie = [name, '=', JSON.stringify(value), ';expires', '=', date.toUTCString(), '; path=/;'].join('')
  document.cookie = cookie
}
const ONE_MONTH_HOURS_COUNT = 30 * 1 * 24
/**
 * set cookie without expired date
 * @param {*} name
 * @param {*} val
 */
export function setCookie(name, val, hours = ONE_MONTH_HOURS_COUNT) {
  // if (this.getCookie(name) ) return
  bakeCookie(name, val, hours)
}

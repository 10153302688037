<template>
  <div>
    <filter-controller
      :receive="allProps.receive"
      :receiveAvailable="availableProps.receive"
      :receivePrices="pricesProps.receive"
      :all="allProps.result"
      :prices="pricesProps.result"
      :available="availableProps.result"
      :displayed="displayedFilters"
      :pagination='pagination'
      :loading="allProps.loading"
      :need-sorting='needSorting'
      :need-pricing='needPricing'
      :need-search='needSearch'
      :need-title='needTitle'
      :value="value"
      @input="handleFilter"
      @category-hack='$emit("category-hack")'
      :sortlist='sortlist'
      :label='label'
      :kwargs='kwargs'
      :initbaseUrl='initbaseUrl'
      :needBlocking='needBlocking'
    />
  </div>
</template>

<script>

import ViewMixin from '@app/FiltersSimple/mixins/ViewMixin'

export default {
  name: 'FiltersSimpleView',
  mixins: [ViewMixin],
}

</script>

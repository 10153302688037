import {
  OAuthProvider,
  signInWithRedirect,
} from 'firebase/auth';

import { firebaseLogout } from '@/js/firebase/logout'

export function applySignIn(auth) {
  if (auth.currentUser) {
    firebaseLogout()
  }
  const provider = new OAuthProvider('apple.com');

  provider.addScope('email');
  provider.addScope('name');

  signInWithRedirect(auth, provider)
}

export function toggleAppleSignIn(signInButton, auth) {
  signInButton.addEventListener('click', () => applySignIn(auth), false)
}

<script>
import { confirmWithPromise } from '@utils/helpers'
import { logoutResource } from '@api/auth.service'
import { firebaseLogout } from '@/js/firebase/logout'

export default {
  name: 'Logout',

  methods: {
    async logout() {
      const url = window.indexPage
      const question = this._('Вы уже уходите?')
      await confirmWithPromise(question, this)
      await logoutResource.execute().then(() => {
        firebaseLogout()
        window.location = url
      })
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      open: () => {
        this.logout()
      },
    })
  },
}
</script>

<template lang="pug">
include /mixins
ModalWrapper(
  :info='{}'
  :hide-close='true'
  @close='$emit("close")'
)
  +b.ck-agreement
    +e.title.H5 {{ organization.props && organization.props.cookie_agreement_title }}
    +e.content.t-content(
      v-html="organization.props && organization.props.cookie_agreement_content"
    )
    +e.actions
      +b.g-row--appearance_spaced
        +b.g-cell.g-cols--12.--6-sm
          +b.tt-button.A(
            @click.prevent='saveCookie'
          )
            +e.title.SPAN {{ _('Zaakceptuj wszystko') }}
        +b.g-cell.g-cols--12.--6-sm
          +b.tt-button--bordered_grey.A(
            @click.prevent='saveCookie'
          )
            +e.title.SPAN {{ _('Nie akceptuję') }}

</template>
<script>
import {
  setCookie,
} from '@utils/cookies'

import {
  OrganizationResource,
} from '@api/vendor.service'

import { TOTIS_2_COOKIE_AGGREMENT } from '@/app'

export default {
  name: 'CookiesAgreement',
  data() {
    return {
      organization: {},
    }
  },
  created() {
    this.getOrganization()
  },
  mounted() {
  },
  methods: {
    saveCookie() {
      setCookie(TOTIS_2_COOKIE_AGGREMENT, true)
      this.$emit('close')
    },
    async getOrganization() {
      const { data } = (await OrganizationResource.execute())
      this.organization = data
    },
  },
}
</script>

<template lang='pug'>
  include /mixins
  +b.tt-space_mt--5(
    :class='{ "has-additional-filters-space": needSearch}'
  )
    row.g-row--appearance_column-reverse-till-xl.g-row--appearance_reverse-xl(
      :justify='"between-xl"'
    ).relative
      .cell-content(
        :class='{"tt-space_mt tt-space_mt--10-xl": needSorting}'
      )
        div(
          :class='{"tt-space_pt tt-space_pt--10-xl": needSorting || !needSorting && needSearch}'
        )
          ui-loader(v-if='loading')
          catalog-content(
            :loading="loading"
            :items="result && result.data"
            :pagination="pagination"
            @update:pagination="changePagination"
            :url='baseUrl'
          )
            slot
      aside.cell-sidebar
        catalog-sidebar(
          space="xl"
          :filters="filters"
          @update:filters="changeFilters"
          :pagination="pagination",
          :attributesResource='attributesResource',
          :availableAttributesResource='availableAttributesResource',
          :needPrices='needPrices'
          :needSorting='needSorting'
          :needTitle='needTitle'
          :sortlist='sortlist'
          :needSearch='needSearch'
          :allProps='allProps'
          :pricesProps='pricesProps'
          @category-hack='$emit("category-hack", $event)'
          :label='label'
          :base-url='baseUrl'
          :isStoreCatalog='isStoreCatalog'
        )
          slot(name="sidebar")
</template>

<script>

import CatalogSidebar from './Sidebar'
import CatalogContent from './Content'

export default {
  components: { CatalogSidebar, CatalogContent },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'availableAttributesResource',
    'needPrices',
    'needSorting',
    'needTitle',
    'needSearch',
    'label',
    'sortlist',
    'allProps',
    'pricesProps',
    'selector',
    'baseUrl',
    'isStoreCatalog',
  ],

  created() {
    // this.search()
  },

  methods: {
    search() {
      this.receive(this.parameters)
    },
  },
}

</script>

import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'store/preorder'

const ADD_TO_PREORDER_URL = prefixAPI('/add/', MODEL)

export const addToPreorder = createResource(
  ADD_TO_PREORDER_URL, postResource
)

import { createSiteTypo } from '@api/feedback.service'
import SiteTypoView from '@app/UiElements/SiteTypoView'
function sendTextToApi(typo) {
  window.app.$modal.show(SiteTypoView, {
    typo,
  }, {
    height: 'auto',
    width: 300,
    minWidth: 300,
    adaptive: true,
    scrollable: true,
    classes: ['cookie-modal'],
  })
}

export function typoHandler() {
  document.addEventListener('keydown', event => {
    if (event.ctrlKey && 'Enter' === event.key) {
      const selectedText = window.getSelection().toString();
      if (selectedText) {
        sendTextToApi(selectedText);
      }
    }
  })
}

<template lang='pug'>
  simple-router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initial"
    :to="parametersTo"
    :base-url='baseUrl'
    :from="parametersFrom"
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="$options.eventsResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      v-slot="paginatedProps"
    )
      controller(
        ref="controller"
        :loading='false'
        v-bind="paginatedProps"
      )
        slot
        template(#slider='')
          slot(
            name='slider'
          )
</template>

<script>
import { eventsListResource } from '@api/events.service'

import {
  friendlyUrlParser,
} from '@app/FiltersSimple/transformers'

import Controller from '@app/Events/Controller'

export default {
  eventsResource: eventsListResource,
  components: { Controller },
  props: {
    initial: {

    },
    baseUrl: {},
  },
  name: 'EventsView',
  data() {
    return {

    }
  },

  methods: {

    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
      }
    },
    parametersFrom({ ...rest }) {
      const data = friendlyUrlParser(this.baseUrl)
      return {
        ...rest,
        filters: data,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>

<script>
export default {
  name: 'UiAccordion',
  props: {
    checkDesktop: {
      default: () => true,
    },
    active: {
      default: () => false,
    },
    collapsable: {
      default: () => false,
    },
    id: { },
    fullHeight: {
      default: () => false,
    },
    init: {
      default: () => true,
    },
    isCalculateHeight: {
      default: () => true,
    },
    options: {

    },

  },
  data() {
    return {
      activeState: this.active,
      windWidth: 1200,
      one: 1,
      zero: 0,
      contentHeight: 0,
      isDesktop: false,
    }
  },
  computed: {
    style() {
      return {
        icon: this.activeState ? 'mdi-chevron-up' : 'mdi-chevron-down',
        active: this.activeState ? this.one : this.zero,
        myHeight: this.activeState ?
          this.stylesGenerator(this.contentHeight) : this.zero,
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.$nextTick(() => {
          setTimeout(() => {
            this.calcHeight()
            this.initialCheck()
          }, 200)
        })
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.checkUrl()
    this.resizeHandler()
    if (this.isCalculateHeight) {
      this.calcHeight()
    }
    this.initialCheck()
    this.checkWidth()
  },
  methods: {
    stylesGenerator(val) {
      if (this.fullHeight && !this.isDesktop) {
        return 'initial'
      }
      return `${val}px`
    },
    initialCheck() {
      this.activeState = this.init
    },
    calcHeight() {
      if (!this.$el) return 0
      const v = this.$el.getElementsByClassName('tt-accordion__description')
      if (v) {
        const el = v[0]
        if (el) {
          this.contentHeight = el.scrollHeight
        }
      }
      return 0
    },
    checkWidth() {
      if (window.innerWidth > this.windWidth) {
        this.isDesktop = true
      }
    },
    resizeHandler() {
      if (window.innerWidth < this.windWidth) {
        this.activeState = false
      } else {
        this.activeState = true
        this.isDesktop = true
      }
    },
    toggleActive(optional) {
      if ('resizable' === optional) {
        if (window.innerWidth > this.windWidth) {
          return
        }
      }
      this.activeState = !this.activeState
      if (this.isCalculateHeight) {
        this.calcHeight()
      }
      // this.calcHeight()
      this.checkWidth()
    },
    checkUrl() {
      let { hash } = location
      if (hash) {
        hash = hash.split('#')
        hash = hash[this.one]
        if (hash == this.id) {
          this.activeState = true
        }
      }
    },
    async scrolly(time) {
      setTimeout(async () => {
        const offset = 90
        const top = document.getElementById(this.id).documentOffsetTop() - offset
        window.scrollTo({ top, behavior: 'smooth' })
      }, time)
    },
  },

  render(h) {
    return this.$scopedSlots.default({
      style: this.style,
      activeState: this.activeState,
      toggleActive: this.toggleActive,
      isDesktop: this.isDesktop,
      collapse: () => {
        if (this.checkDesktop) {
          if (this.isDesktop) {
            return
          }
        }
        this.activeState = false
      },
      recalc: () => this.calcHeight(),
    })
  },
}
</script>

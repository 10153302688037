<template lang="pug">
  include /mixins
  +b.review-form.tt-space_mt--4-xl.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.close-popup.mdi.mdi-close.A(
      href='',
      v-if='!inline'
      @click.prevent='$emit("close")'
    )
    template(
      v-if='!info.reply'
    )
      //- +b.ds-caption--size_sm.--size_md-xl.--medium.H2 {{ _('Оцените') }} {{ title }}

      +b.g-row--align_center.--appearance_spaced.--space_md
        +b.g-cell.g-cols
          +b.ds-caption--variant_default.P {{ _('Ваша оценка:') }}
        +b.g-cell.g-cols(
          @mouseleave="showCurrentRating(false)"
        )
          pretty-stars(
            v-model='form.rate',
            @current-rating="showCurrentRating",
            :rating='5'
          )
        +b.g-cell.g-cols
          +b.ds-caption--variant_default.--size_xs.P {{ currentRating }}
    +b.ds-caption--size_lg.--bold.H2.main-title(
      v-else
    ) {{ _('Ответ на отзыв') }}

    +b.g-row--align_center.--justify_between.--justify_center-xl.tt-space_mt--6
      cell(
        cols='12 6-xl'
      )
        +b.tt-space_mb--6.D-CONTROL-INPUT(
          :rules="'required|max:30'",
          :input-label='labels.name'
          v-model='form.name'
          id='name',
          name='name',

        )
      cell(
        cols='12 6-xl'
      )
        +b.tt-space_mb--6.D-CONTROL-INPUT(
          :rules="'required'",
          :input-label='labels.email'
          v-model='form.email'
          id='email',
          name='email',

        )
      cell(
        cols='12'
      )
        d-control-textarea.is-textarea(
          :input-label='labels.comment'
          :rules="'required|min:5'",
          rows='4'
          v-model='form.comment',
          name='comment',
          ref='comment'
          error-classes='error-label--top'
        )
      cell(
        cols='12'
        v-if='!info.reply'
      )
        gallery(
          :post-resource='$options.ReviewPhotoCreate',
          :patch-resource='$options.personalProfileDiplomaUpdate',
          :delete-resource='$options.ReviewPhotoRemove',
          :id='`review_photo_${info.field_id}`',
          :reset='reset'
          @update='setKeys($event, "photos")'
        )
      cell(
        cols='12'
        v-if='!info.reply'
      )
        gallery(
          :post-resource='$options.ReviewVideoCreate',
          :patch-resource='$options.personalProfileDiplomaUpdate',
          :delete-resource='$options.ReviewVideoRemove',
          id='review_video',
          :reset='reset'
          filekey='url'
          @update='setKeys($event, "videos")'
        )
          template(
            #loader='{ addVideo }'
          )
            +b.g-cell.g-cols--12
              // https://www.youtube.com/watch?v=aynRTt51pps&t=3709s&ab_channel=%D0%92%D0%B5%D0%B1-%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82%D1%8B
              youtube-loader(
                @update='addVideo'
              )

          //- :additional='{personal_profile: profile_id, is_active: true}'
          //- :get-resource='$options.personalProfileDiplomasRetrieve',
      cell(
        cols='12'
        align='end'
      )
        +b.g-row--justify_end
          +b.g-cell.g-cols
            +b.tt-button.--fixed.BUTTON.tt-space_mt--6(
              type='submit'
              :disabled='isLoad',
              @click.prevent='prepareData(valid)'
            )
              +e.title.P {{ _('Отправить отзыв ') }}
</template>

<script>

import {
  ReviewCreate,
  ReviewPhotoCreate,
  ReviewPhotoRemove,
  ReviewVideoCreate,
  ReviewVideoRemove,
} from '@api/review.service'

import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'ReviewAdd',
  mixins: [FormMixin],
  props: {
    info: {},
    product_type: {
      type: String,
    },
    product_id: {
      type: String,
    },
    reply: {},
    id: {},
    reply_name: {},
    promise: {},
    inline: {},
    title: {},
  },
  ReviewPhotoCreate,
  ReviewVideoCreate,
  ReviewPhotoRemove,
  ReviewVideoRemove,
  data() {
    return {
      rates: {
        1: this._('Плохо'),
        2: this._('Более менее'),
        3: this._('Нормально'),
        4: this._('Хорошо'),
        5: this._('Отлично'),
      },
      labels: {
        name: this._('Имя'),
        email: this._('Ваш email'),
        advantages: this._('Переваги'),
        disadvantages: this._('Недоліки'),
        comment: this._('Ваше враження'),
      },
      form: {
        comment: this.info.reply_name ? `${this.info.reply_name}, ` : '',
        name: this.info.user_name || '',
        phone: this.info.user_phone || '',
        email: window.request_email || '',
        rate: 5,
        element: {
          type: this.info.product_type,
          id: this.info.product_id,
        },
        parent: this.info.id,
        reply: this.info.reply,
        photos: [],
        videos: [],
      },
      non_field_errors: '',
      isLoad: false,
      currentRating: '',
      reset: new Date(),
    }
  },
  mounted() {
    this.currentRating = this.rates[this.form.rate]
  },
  methods: {
    setKeys(list, key) {
      this.form[key] = list.map(el => el.id)
    },
    showCurrentRating(rating) {
      if (rating) {
        this.currentRating = this.rates[rating]
        return
      }
      this.currentRating = this.rates[this.form.rate]
    },
    async prepareData(valid) {
      this.$refs.validator.validate()
      if (!valid) return
      const data = JSON.parse(JSON.stringify(this.form))
      if (this.info.reply) {
        delete data.rate
      }
      this.submit(valid, data)
    },
    send(data) {
      return ReviewCreate.execute({}, data)
        .then(({ data: res }) => {
          this.$emit('close')
          AnswerMessage(res, this)
          this.form.name = null
          this.form.phone = null
          this.form.comment = null
          this.form.email = null
          this.reset = new Date()
          if (this.promise) this.promise(true)
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },

}
</script>

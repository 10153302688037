import {
  createResource,
  postResource,
  deleteResource,
} from '@resource/resource'
import {
  simpleTransformer,
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const MODEL = 'comparison/'

const COMPARISON_CREATE_URL = prefixAPI('create/', MODEL)
const COMPARISON_LIST_URL = prefixAPI('list/', MODEL)
const CLEAR_COMPARISON_URL = prefixAPI('clear/', MODEL)
const COMPARISON_IDS_LIST_URL = prefixAPI('ids-list/', MODEL)
const REMOVE_FROM_COMPARISON_URL = prefixAPI('destroy/{id}/', MODEL)

export const addToComparison = createResource(
  COMPARISON_CREATE_URL,
  postResource
)
export const clearComparison = createResource(
  CLEAR_COMPARISON_URL,
  postResource,
  simpleTransformer
)
export const getComparisonList = createResource(
  COMPARISON_LIST_URL
)
export const getComparisonIdsList = createResource(
  COMPARISON_IDS_LIST_URL
)
export const removeFromComparison = createResource(
  REMOVE_FROM_COMPARISON_URL,
  deleteResource,
  simpleTransformer
)

<template lang='pug'>
  include /mixins
  div
    transition(name='fade')
      +b.fake-sidebar-load(
        v-if='loading'
      )
        ui-loader
    ui-accordion(
      :init='false',
      :full-height='true'
    )
      template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
        +b.tt-accordion(
          :class='{"tt-accordion--is-open": activeState}',
        )
          ui-click-outside(
            :do='collapse'
          )
            div
              +e.question--clear-all.is-disable-xl(
                :class='{"is-small": needSorting}'
                @click='$modal.show("filters-modal");',
              )
                +e.icon.mdi.I(:class='activeState ? "mdi-minus" : "mdi-plus"')
                +b.filter-title
                  +b.ds-caption--size_xl.--medium.--height_2xs.P.tt-space_mr--2(
                  )
                    span.icon-filter_n
                  +b.ds-caption--size_sm.--medium.P {{ _('Фильтры') }}
                //- +e.text
                //-   +b.ds-caption--size_2xs.--size_xs-xl.--medium.--appearance_center.P
                //-     +b.SPAN.ds-caption--size_xl.--medium.--height_2xs.tt-space_mr--2.is-icon.icon-filter_n
                //-     span {{ _('Фильтры') }}
                portal-target(name='sorting')
              //- template(
              //-   v-else
              //- )
              //-   +b.filter-title-wrap
              //-     +b.g-row--justify_between.--align_center
              //-       +b.g-cell.g-cols
              //-         +b.filter-title
              //-           +b.ds-caption--size_xl.--medium.--height_2xs.P.tt-space_mr--2(
              //-           )
              //-             span.icon-filter_n
              //-           +b.ds-caption--size_sm.--medium.P {{ _('Фільтри 112222111') }}
              //-       +b.g-cell.g-cols
              //-         +b.tt-link.--variant_start.ds-caption--size_sm.--appearance_underline.A(
              //-           href=''
              //-           @click.prevent='handleInputFilter({})'
              //-         ) {{ _('Очистить фильтр') }}

              +e.description(
                :style='{maxHeight: myHeight}',
                :class='{"isFullMaxHeight": true}'
              )
                +e.content--no-pa
                  inline-filter-ui(
                    :disabled="loading"
                    :value="prepared"
                    :available="availableMap"
                    :filters="filters"
                    :need-sorting='needSorting'
                    :need-search='needSearch'
                    :need-title='needTitle'
                    :pagination='pagination'
                    :prices='prices'
                    :sortlist='sortlist'
                    @input="handleInputFilter",
                    @category-hack='$emit("category-hack")'
                    :isDesktop='isDesktop',
                    :label='label',
                    :initbaseUrl='initbaseUrl'
                  )

</template>

<script>
import ControllerMixin from '@app/FiltersSimple/mixins/ControllerMixin'
import InlineFilterUi from '@app/FiltersInlinesSimple/Ui'

export default {
  mixins: [ControllerMixin],
  components: { InlineFilterUi },
  methods: {
    prepare(value) {
      this.prepared = value
    },
  },
}

</script>

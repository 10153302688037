<template lang="pug" functional>
  include /mixins
  add-to-favorites(
    :id='props.id'
    v-if='props.is_auth'
  )
    template(#default='{ add, is_added }')
      +b.circle-btn--accent.--clear.A(
        v-if='is_added'
        :title="parent._('В избранном')"
      )
        +e.icon.IMG(
          src='/static/img/full-heart.svg'
        )
      +b.circle-btn--accent.--clear.BUTTON(
        @click.prevent='add'
        :title="parent._('Добавить в избранное')"
        v-else
      )
        +e.icon.icon-like.SPAN
  modal-trigger(
    v-else
    url='UiElements/NotPermission',
    name='NotPermission',

  )
    template(#default='{ open }')
      +b.circle-btn--accent.--clear.BUTTON(
        :title="parent._('Добавить в избранное')"
        @click.prevent='open'
      )
        +e.icon.icon-like.SPAN

</template>
<script>
export default {

  name: 'LikesView',
}
</script>

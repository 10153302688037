<template lang="pug">
  include /mixins

  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    row(
      justify='end g-row--justify_between-xl'
      align='end'
    )
      +b.g-cell.g-cols--12
        +b.g-row
          +b.g-cell.g-cols--12.tt-space_mt--6(
            v-for='{ rules, name, label, mask, tag } in form_fields'
          )
            tag(
              :tag='tag || "d-control-input"'
              :rules="rules",
              :input-label='label'
              v-model='form[name]',
              :ref='name'
              :name='name'
            )
      +b.g-cell.g-cols--12.tt-space_mt--6
        +b.tt-button--redesign-h.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('Отправить') }}

</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { addToPreorder } from '@api/preorder.service'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'PreOrderForm',
  props: {
    productVariant: Number,
    userName: String,
    userPhone: String,
    info: Object,
  },
  mixins: [FormMixin],
  data() {
    return {
      form_fields: [
        {
          name: 'count',
          label: this._('Желаемое количество к заказу'),
          rules: 'required',
        },
        {
          name: 'name',
          label: this._('Ваше имя'),
          rules: 'required',
        },
        {
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          tag: 'phone-input',
        },
      ],
      form: {
        product_variant: this.productVariant,
        count: '',
        name: this.userName,
        phone: this.userPhone,
      },
      non_field_errors: '',
      isLoad: false,
      create_preorder: true,
    }
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return addToPreorder.execute({}, data)
        .then(() => {
          this.$emit('success')
          const meta = {
            message: {
              title: this._('Preorder success title'),
              text: this._('Preorder success text'),
            },
          }
          AnswerMessage(meta, this)
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

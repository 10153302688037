<template lang="pug">
include /mixins
+b.protocol-question-form
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.g-row
      +b.g-cell.g-cols--12.tt-space_mt--6(
        v-for='{ rules, name, label, tag, classname, errorClasses, mask, type, ...rest } in form_fields'
      )
        tag(
          :tag='tag || "d-control-input"'
          :rules="rules",
          :input-label='label'
          v-model='form[name]',
          :ref='name'
          @input='$emit("update", form)'
          :name='name'
          :type='type || "text"'
          :class='classname',
          :error-classes='errorClasses'
          v-bind='rest'
        )
      +b.g-cell.g-cols--12.tt-space_mt--6
        +b.tt-button.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('Отправить') }}

</template>
<script>
import {
  createProtocolQuestion,
} from '@api/protocol.service'

import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'ProtocolQuestion',
  mixins: [FormMixin],
  props: {
    protocol: {
      required: true,
    },
  },
  data() {
    return {
      form_fields: [
        // {
        //   name: 'name',
        //   label: this._('Ваше имя'),
        //   rules: 'required',
        // },
        {
          name: 'email',
          label: this._('Электронная почта'),
          rules: 'required|email',
          type: 'email',
        },
        // {
        //   name: 'phone',
        //   label: this._('Номер телефона'),
        //   rules: 'required|phone',
        //   tag: 'phone-input',
        // },
        // {
        //   name: 'city',
        //   label: this._('Город'),
        //   rules: 'required',
        // },
        {
          name: 'message',
          label: this._('Ваш вопрос'),
          rules: 'required',
          tag: 'd-control-textarea',
          class: 'is-textarea',
          errorClasses: 'error-label--top',
          rows: 6,
        },
      ],
      form: {
        email: window.request_email,
        message: '',
        protocol: this.protocol,
      },
      non_field_errors: '',
      isLoad: false,
    }
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return createProtocolQuestion.execute({}, data)
        .then(res => {
          // window.dataLayer = window.dataLayer || []
          // window.dataLayer.push({ event: 'event_registration_topic' })
          const message = {
            message: {
              title: this._('Protocol question create success header'),
              text: this._('Protocol question create success text'),
            },
          }
          AnswerMessage(message, this)
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
          this.form.message = ''
          this.$emit('close')
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

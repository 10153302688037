<template lang='pug'>
  include /mixins
  +b.ds-panel--space_xs.--space_md-mac.relative
    +e.element--offset_full
      controller.relative(
        :localsearch='search'
        :searchResource='$options.searchV3Resource'
        :preview='$options.preview'
        :id='$attrs.id'
      )
        template(#inputview='{ keyupEvent, goToSearch, reset, trigger_overlay, activeSearch }')
          //- .relative.search-form
          //-   +b.A.search-trigger.--fixed-width(
          //-     href='javascript.void(0)',
          //-     @click.prevent='activeSearch'
          //-   )
          //-     +e.loop.icon-search
          //-   transition(name="fade")
          //-     d-control-input(
          //-       v-bem:d-control-input="{'appearance': 'bordered', 'appearance': 'large'}"
          //-       type='search',
          //-       :placeholder='_("Я ищу... ")',
          //-       v-model='search',
          //-       autocomplete='125выпывпфывп'
          //-       ref='search-input'
          //-       @keyup="keyupEvent",
          //-       @search="test(reset, trigger_overlay)",
          //-     )

          .control-stack
            +b.g-row.--space_none.--align_center
              +b.g-cell.g-cols--8.--9-mac.--8-fhd

                .relative.search-form--variant_header
                  +b.search-trigger.--left.--fixed-width.BUTTON(
                    :aria-label=`_('Поиск')`
                  )
                    +e.loop.icon-search
                  transition(name="fade")
                    +b.d-control-input--appearance_bordered.--appearance_stacked.D-CONTROL-INPUT.control-stack__element.control-stack__element--position_first(
                      type='search',
                      :placeholder='placeholder',
                      v-model='search',
                      autocomplete='125выпывпфывп'
                      ref='search-input'
                      @keyup="keyupEvent",
                      @search="test(reset, trigger_overlay)",
                    )

              +b.g-cell.g-cols--4.--3-mac.--4-fhd
                +b.tt-button--for-input.BUTTON.control-stack__element.control-stack__element--position_last(
                  type='submit'
                  @click.prevent='keyupEvent'
                )
                  +e.title--mini.P {{ _('Найти') }}

</template>
<script>
import { mapState } from 'vuex'
import { SEACRH_V3_URL } from '@api/vendor.service'
import ProductPreview from '@app/Search-v3/Ui/Product'
import Controller from './Controller'

const preview = ProductPreview

export default {
  name: 'SearchView',
  props: ['className', 'fieldId', 'url'],

  components: {
    Controller,
    ProductPreview,
  },
  preview,
  searchV3Resource: SEACRH_V3_URL,

  data() {
    return {
      search: '',
      placeholder: this._('Я ищу...'),
    }
  },
  watch: {
    global_search(nval) {
      this.search = nval
    },
  },

  computed: {
    ...mapState('search', [
      'global_search',
    ]),
  },

  mounted() {
    this.$store.subscribe(mutation => {
      if ('search/SET_SEARCH' == mutation.type) {
        this.search = mutation.payload
      }
    })
  },
  methods: {
    test(fn, rm_overlay) {
      if (!this.search) {
        fn()
        rm_overlay()
      }
    },
  },
}

</script>

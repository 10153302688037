<template lang="pug">
  include /mixins
  +b.appended-component
    +e.icon.I(
      @click.prevent="iconAppendFunc ? iconAppendFunc: ''",
      :class="appendIcon"
      v-if="appendIcon !== 'none'"
    )
</template>
<script>
export default {
  name: 'AppendComponent',
  props: {
    appendIcon: {},
    iconAppendFunc: {},
  },
}
</script>

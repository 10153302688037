var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loyalty-program__form-bg"},[_c('div',{staticClass:"loyalty-program__form-wrapper"},[_c('p',{staticClass:"ds-caption ds-caption--size_lg ds-caption--size_md-lg ds-caption--size_lg-xl ds-caption--color_grey ds-caption--medium ds-caption--appearance_center",staticStyle:{"line-height":"normal"}},[_vm._v(_vm._s(_vm._('Участвуйте в программе лояльности')))]),_c('p',{staticClass:"ds-caption ds-caption--size_md ds-caption--size_sm-lg ds-caption--color_grey ds-caption--regular ds-caption--appearance_center tt-space_pt tt-space_pt--1"},[_vm._v(_vm._s(_vm._('И пользуйтесь всеми преимуществами')))]),_c('validation-observer',{ref:"validator",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(_vm.isLoad)?_c('ui-loader'):_vm._e(),_c('row',[_vm._l((_vm.form_fields),function(ref){
var rules = ref.rules;
var name = ref.name;
var label = ref.label;
var mask = ref.mask;
var cols = ref.cols;
var type = ref.type;
var tag = ref.tag;
return [_c('cell',{staticClass:"tt-space_mt tt-space_mt--8 tt-space_mt--5-lg tt-space_mt--8-xl tt-space_mt--10-mac relative",attrs:{"cols":cols || '12'}},[_c('tag',{ref:name,refInFor:true,staticClass:"static-control",attrs:{"tag":tag || "d-control-input","rules":rules,"input-label":label,"name":name,"type":type || "text"},model:{value:(_vm.form[name]),callback:function ($$v) {_vm.$set(_vm.form, name, $$v)},expression:"form[name]"}})],1)]}),_c('cell',{staticClass:"tt-space_mt tt-space_mt--5 tt-space_mt--10-xl",attrs:{"cols":"12"}},[_c('button',{staticClass:"tt-button",attrs:{"type":"submit","disabled":_vm.isLoad},on:{"click":function($event){$event.preventDefault();return _vm.prepareData(valid)}}},[_c('p',{staticClass:"tt-button__title"},[_vm._v(_vm._s(_vm._('подать заявку')))])])])],2)]}}])}),_vm._t("after_text",[_c('div',{staticClass:"tt-space_mt tt-space_mt--7 tt-space_mt--5-lg tt-space_mt--7-xl"},[_c('p',{staticClass:"ds-caption ds-caption--size_sm ds-caption--color_accent ds-caption--appearance_center"},[_vm._v(_vm._s(_vm._('Програма TOTIS CLUB - наша подяка Вам за те, що обираєте Totis')))])])])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
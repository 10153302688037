<template lang="pug" functional>
  include /mixins
  +b.ds-panel--space_xl
    +b.close-popup.mdi.mdi-close.A(
      @click='listeners.close'
    )
    +e.element--offset_full
      +b.ds-caption.--size_lg.--medium.P {{ parent._('Оформить предзаказ') }}
      +b.g-row.--justify_center.tt-space_mt--5
        +b.g-cell.g-cols--4
          +b.tt-picture--size_auto.product__img.PICTURE
            +e.body.IMG(
              :src='props.info.img'
            )
        +b.g-cell.g-cols--8
          +b.ds-caption--size_sm.--size_2md-xl.--bold.--color_grey.tt-space_mb--4-xl.--appearance_capitalize.P {{ props.info.maintitle }}
          +b.ds-caption--size_sm.--size_xsm-xl.--light.--color_grey.tt-space_mb--4(v-html='props.info.description')
          +b.ds-caption--size_sm.--size_xsm-xl.--light.--color_grey.tt-space_mb--4.P {{ props.info.weight }}
          +b.tt-space_mt--5-xl
        +b.g-cell.g-cols--12
            pre-order-cart(
              @success='listeners.close'
              :product-variant='props.info.id'
              :user-name='props.info.user_name'
              :user-phone='props.info.user_phone'
              :info='props.info'
            )
            //- pre-order-form(
            //-   @success='listeners.close'
            //-   :product-variant='props.info.id'
            //-   :user-name='props.info.user_name'
            //-   :user-phone='props.info.user_phone'
            //-   :info='props.info'
            //- )

</template>

<script>
export default {
  name: 'PreOrderModal',
  props: ['info'],
}
</script>

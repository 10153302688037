<template lang="pug">
div
  slot(
    :is-active='isActive'
    :toggle="toggleActivity"
  )
</template>

<script>
export default {
  name: 'ContentToggler',
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    toggleActivity() {
      this.isActive = !this.isActive
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.ds-panel--space_2xl
    +b.close-popup.mdi.mdi-close.BUTTON(
      @click.prevent='$emit("close")'
    )
    +e.element--offset_full
      +b.ds-caption--size_lg.--medium.--appearance_center-xl.H2.main-title.tt-space_mb--5 {{ _('Выбор подарка') }}
    paginated-resource-controller(
      :resource="$options.retrieveGiftsList"
      :parameters="parameters"
      :changeParameters="changeParameters"
      ref='controller'
      v-slot="{ pagination, changePagination, changeFilters, filters, receive, result, loading }"
    )
      div
        +b.cart-wrapper
          template(
            v-if='!!(result && result.items && result.items.length)'
            v-for="(item, index) in result && result.items"
          )
            +b.gift-wrap(
              @click.prevent='selectCurrent(item.id)',

            )
              product-base-view(
                :product='{ "element": item, "quantity": count }',
                :price='_("Подарок")',
                :disabled='true',
                :is-gift='true',
              )
                template(#sale='')
                  counter(
                    :amount='count',
                    :disable='true'
                  )
                template(#mob-sale='')
                  +b.g-cell.g-cols--12
                    counter(
                      :amount='count',
                      :disable='true'
                    )
                template(#pricetips='')
                  p
                template(#info='')
                  p
                template(#sum='{ price }')
                  p
                template(#counter='')
                  p
                template(#price='{ price }')
                  +b.ds-caption--size_sm.--semibold.--appearance_right.--color_accent.P {{ _('Подарок') }}
                template(#desktop-price='{ price }')
                  +b.ds-caption--size_sm.--semibold.--appearance_right.--color_accent.P {{ _('Подарок') }}
                template(#price-tips='')
        ui-limit-pagination(
          :pagination='pagination'
          :need-add-btns='false'
          class='ds-panel--space_none-xl tt-space_mt tt-space_mt--5'
          @showAll='handlePaginate($event, changePagination, pagination)'
          @input='handlePaginate($event, changePagination, pagination)'
          @hook:created="changeFilters(parameters)"
        )
</template>
<script>

import {
  retrieveGiftsList,
} from '@api/cart.service.js'

import ProductBaseView from '@app/Cart/Variants/Base'

export default {
  name: 'GiftVariants',
  components: {
    ProductBaseView,
  },
  retrieveGiftsList,
  props: ['id', 'promise', 'currentId', 'count', 'group_id'],
  data() {
    return {
      list: [],
      parameters: {
        id: this.id,
      },
    }
  },
  methods: {

    handlePaginate({ offset, limit }, fn, pagination) {
      if (this.loading) {
        return
      }
      fn({ limit, offset })
    },
    async selectCurrent(product_variant) {
      const data = {
        product_variant,
        quantity: this.count,
        parameters: {
          is_obligatory_gift: false,
        },
        group_id: this.group_id,
      }
      this.promise(data)
      this.$emit('close')
    },
    changeParameters() {
      // this.parameters = value
    },
  },
}
</script>

import * as rules from 'vee-validate/dist/rules'
import trans from '@aspectus/vee-i18n'

import VueTrans, { compileMessages } from '@aspectus/vue-trans'

const dateTimeFormats = {}

export const numberFormats = {
  [window.language]: {
    decimal_eur: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
    decimal_uah: {
      style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0,
    },
    decimal_pln: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
    decimal: {
      style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0,
    },
    decimal_czk: {
      style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2,
    },
  },
}

// source https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
const pluralizationRules = {
  [window.language]: (choice, choicesLength) => {
    if (0 === choice) {
      return 0;
    }

    const teen = 10 < choice && 20 > choice;
    const endsWithOne = 1 === choice % 10;

    if (4 > choicesLength) {
      return (!teen && endsWithOne) ? 1 : 2;
    }
    if (!teen && endsWithOne) {
      return 1;
    }
    if (!teen && 2 <= choice % 10 && 4 >= choice % 10) {
      return 2;
    }

    return (4 > choicesLength) ? 2 : 3;
  },
}

const { locale, messages } = compileMessages()

const messagesWithPlural = {
  ru: {
    ...messages.ru,
    товар: 'товаров | товар | товара | товаров',
    товара: 'товаров | товара | товаров | товаров',
  },
  uk: {
    ...messages.uk,
    товар: 'товарiв | товар | товари | товарiв',
    товара: 'товарiв | товари | товарiв | товарiв',
  },
  pl: {
    ...messages.pl,
    товар: 'produktów | produkt | produkty | produktów',
    товара: 'produktów | produkty | produktów | produktów',
  },
  cs: {
    ...messages.cs,
    товар: 'produktů | produkt | produkty | produktů',
    товара: 'produktů | produkty | produktů | produktů',
  },
  sk: {
    ...messages.sk,
    товар: 'produktov | produkt | produkty | produktov',
    товара: 'produktov | produkty | produktov | produktov',
  },
  en: {
    ...messages.en,
    товар: 'products | product | products | products',
    товара: 'products | products | products | products',
  },
  ro: {
    ...messages.ro,
    товар: 'găsite | produs | găsite | găsite',
    товара: 'găsite | găsite | găsite | găsite',
  },
  'ru-md': {
    ...messages['ru-md'],
    товар: 'товаров | товар | товара | товаров',
    товара: 'товаров | товара | товаров | товаров',
  },
  'ru-uz': {
    ...messages['ru-uz'],
    товар: 'товаров | товар | товара | товаров',
    товара: 'товаров | товара | товаров | товаров',
  },
  'cs-sk': {
    ...messages['cs-sk'],
    товар: 'produktů | produkt | produkty | produktů',
    товара: 'produktů | produkty | produktů | produktů',
  },
}

export function createI18n({ Vue, I18n }) {
  Vue.use(VueTrans)

  const i18n = new I18n({
    messages: messagesWithPlural,
    locale,
    silentTranslationWarn: true,
    numberFormats,
    dateTimeFormats,
    pluralizationRules,
  })

  trans(i18n, rules)

  return i18n
}

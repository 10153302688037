<template lang="pug">
include /mixins
+b.product-set-card--mini.relative
  +e.icons
    add-to-favorites(
      :id='variant.base.element.id'
      :not-reload='true'
      @hook:remove="$emit('hook:remove', $event)"
    )
      template(#default='{ add, is_added, remove }')
        +b.circle-btn--accent.--clear.--transparent.BUTTON(
          v-if='is_added'
          @click.prevent='remove'
          :title="_('В избранном')"
        )
          +e.icon.IMG(
            src="/static/img/full-heart.svg"
            :alt='_("full heart")'
          )
        +b.circle-btn--accent.--clear.--transparent.BUTTON(
          @click.prevent='add'
          :title="_('Добавить в избранное')"
          v-else
        )
          +e.icon.icon-like.SPAN
  +e.img-wrap
    +b.tt-picture--size_auto.--size_auto-h.product-set-card__img.PICTURE.relative
      source(
        type="image/webp"
        :srcset="variant.base.element.props.img && variant.base.element.props.img.catalog_preview_webp"
      )
      +e.body.IMG(
        v-if='variant.base.element.props.img && variant.base.element.props.img.catalog_preview'
        :src='variant.base.element.props.img && variant.base.element.props.img.catalog_preview',
        :alt='variant.base.element.props.title',
      )
      +e.body.IMG(
        v-else
        src='https://via.placeholder.com/60x60',
      )
  +e.content
    +e.title.ds-caption--size_sm.--medium.A.tt-link--variant_start(
      :href='variant.base.element.props.url'
    ) {{ variant.base.element.props.product.props.title }}
    +e.vendor.ds-caption--size_3xs.--medium.seller-product-name(v-if='variant.base.element.props.seller') {{ variant.base.element.props.seller.props.title }}
    +e.vendor.ds-caption--size_3xs.--medium.--color_accent(v-else) {{ variant.base.element.props.product.props.brand.props.title }}

    +e.weight.ds-caption--size_3xs.--medium.P(
      v-if='variant.base.element.props.weight'
    ) {{ variant.base.element.props.weight.props.name }}

    +e.art.ds-caption--size_3xs.--light.P {{ _('Артикул:') }} &nbsp;
      +b.ds-caption--medium.SPAN {{ variant.base.element.props.vendor_code || "-" }}
    +e.price-wrap
      +e.count.P {{ variant.base.quantity }} {{ _('шт') }}
      +e.prices
        +e.old-price.ds-caption--size_sm.--semibold.--appearance_line-througt.--color_semigrey.P(
          v-if='Number(variant.base.price) !== Number(variant.base.parameters.calculated_price)'
          v-html='getSplittedHtml(variant.base.price)'
        )
        +e.current-price.ds-caption--size_sm.--semibold.P(
          v-html='getSplittedHtml(variant.base.parameters.calculated_price)'
        )
</template>

<script>
export default {
  name: 'SalesCartVariantCard',
  props: ['variant', 'max'],
  data() {
    return {
      currency: window.currency,
    }
  },
}
</script>

<template lang='pug'>
include /mixins
+b.calculator-view
  +e.form
    +e.inliner
      .relative(v-if="non_field_errors")
        +b.error-label.LABEL {{ non_field_errors }}
      +e.select
        d-control-multiselect(
          v-if='withProcedure'
          :options='procedures'
          v-model='selected'
          label='title'
          :custom-label='procedureLabel'
          :input-label='_("Выберите процедуру")'
          @input='clearSelfCost'
        )
      calculator-inliner(
        :initial='getSelected'
        :url='$options.CONFIGURATOR_VARIANTS_LIST_URL'
        :base='baseItem'
        ref='inliner'
        @update='setSelected'
        :limit='10'
        :with-procedure='withProcedure'
      )
    +e.btn.is-visible-md
      +b.tt-button--auto.--bordered_modern.--mini.A(
        :href='url'
      )
        +e.icon.mdi.mdi-arrow-left
        +e.title.SPAN {{ _('Назад') }}
  +e.actions
    +b.calc-preview
      +e.img.IMG(
        :src='withProcedure ? "/static/img/calc/procedure.svg" : "/static/img/calc/product.svg"'
      )
      +e.btn
        +b.tt-button--auto.--centered.--product.LOADER-BTN(
          @click.prevent='calculate'
          :action='calculate'
          :title="_('Рассчитать себестоимость')"
          :load='isLoad'
          :disabled='!current.length'
        )
      +b.selfcost.A()
        +e.title.P {{ _('Себестоимость процедуры') }}
        +e.value.P(v-html='getSplittedHtml(selfcost)')
    +e.btn.is-disable-md
      +b.tt-button--auto.--bordered_modern.--mini.A(
        :href='url'
      )
        +e.icon.mdi.mdi-arrow-left
        +e.title.SPAN {{ _('Назад') }}
</template>

<script>

import {
  getConfiguratorList,
  CONFIGURATOR_VARIANTS_LIST_URL,
  calculateProcedure,
} from '@api/calculator.service'

export default {
  name: 'CalculatorProcedure',
  CONFIGURATOR_VARIANTS_LIST_URL,
  props: ['url', 'withProcedure'],
  data() {
    return {
      selfcost: 0,
      procedures: [],
      pagination: {},
      selected: '',
      currency: window.currency,
      isLoad: false,
      baseItem: {
        amount: null,
        cost: 0,
        is_pattern: true,
        variant: {
        },
        additional_items: [],
      },
      current: {},
      non_field_errors: '',
      validator: new Date(),
    }
  },
  computed: {
    getSelected() {
      if (this.selected && this.selected.props.main_items) {
        const result = this.selected.props.main_items.reduce((acc, obj) => {
          const additional_items = obj.props.additional_items.map(a => ({ ...a.props, internal_id: a.id }))
          acc.push({ ...obj.props, internal_id: obj.id, additional_items })
          return acc
        }, [])
        console.log(result);
        return result
      }
      return []
    },
  },
  created() {
    if (this.withProcedure) {
      this.getList()
    } else {
      this.selected = { props: { main_items: [{ props: this.baseItem }] } }
    }
  },
  methods: {
    clearSelfCost() {
      this.selfcost = 0
    },
    async calculate() {
      const isValid = (await this.$refs.inliner.validate())
      if (!isValid) return
      this.non_field_errors = ''
      const data = this.current.reduce((acc, obj) => {
        if (obj.variant) {
          acc.push({
            variant: obj.variant.id,
            amount: obj.amount,
          })
        }
        obj.additional_items.forEach(element => {
          if (element.variant) {
            acc.push({
              variant: element.variant.id,
              amount: element.amount,
            })
          }
        })
        return acc
      }, [])

      if (data.some(e => !e.variant)) {
        this.non_field_errors = this._('Выберите корректные варианты товаров для просчета')
        return
      }
      try {
        this.isLoad = true
        const { data: { total_cost } } = (await calculateProcedure.execute('', { items: data }))
        this.selfcost = total_cost
        this.isLoad = false
      } catch (e) {
        this.isLoad = false
      }
    },
    setSelected(e) {
      this.current = e
      // e.forEach((element, i) => {
      //   if (this.selected.props?.main_items[i]) {
      //     this.selected.props.main_items[i].props.variant = e[i].variant
      //     this.selected.props.main_items[i].props.additional_items = e[i].additional_items.map(item => ({ props: item }))
      //     return
      //   }
      //   if (this.selected.props) {
      //     this.selected.props.main_items.push({
      //       props: {
      //         variant: element.variant,
      //         additional_items: element.additional_items.map(item => ({ props: item })),
      //       },
      //     })
      //   } else {
      //     this.selected = {}
      //     this.selected.props = {
      //       main_items: [{
      //         props: {
      //           variant: element.variant,
      //           additional_items: element.additional_items.map(item => ({ props: item })),
      //         },
      //       }],
      //     }
      //   }
      // })
    },
    async getList() {
      const { data: { items, pagination } } = (await getConfiguratorList.execute())
      this.procedures = items
      this.pagination = pagination
    },
    procedureLabel({ props }) {
      return props.title
    },
  },
}
</script>

export function friendlyUrlParser() {
  let path = window.location.pathname
  const zero = 0
  const parsedUrl = {}
  if (path.indexOf('filters') > zero) {
    path = path.split('/page')[0]
    const hashes = path.split('filters/')[1].split(';')
    hashes.map(hash => {
      const decodedHash = decodeURIComponent(hash)
      let [key, val] = decodedHash.split('=')

      parsedUrl[key] = []
      if (val.indexOf(',') > zero) {
        val = val.split(',')
        val.forEach(v => {
          if (v.indexOf('/') > zero) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]))
          } else {
            parsedUrl[key].push(decodeURIComponent(v))
          }
        })
      } else if (val.indexOf('/') > zero) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]))
      } else {
        parsedUrl[key].push(decodeURIComponent(val))
      }
    })
  }
  return parsedUrl
}

import { pipe } from 'ramda'

import {
  receiveResource,
  baseResource as superResource,
  jsonRequestMiddleware,
} from '@aspectus/resource'

import makeTemplateGetter from '@aspectus/resource-template-url-getter'
import {
  fetcher,
  defaultDataTransformer,
  transformDataFetcher,
} from '@resource/transformer'

import {
  xCSRFMiddleware,
  multipartRequestMiddleware,
  raiseHttpErrorTransformer,
} from '@aspectus/resource-commons'

import {
  HEADERS_MIDDLEWERE,
} from '@resource/middleware'

export const baseResource = superResource
  .middleware(xCSRFMiddleware)
  .middleware(HEADERS_MIDDLEWERE)
  .transform(raiseHttpErrorTransformer)

export const getResource = receiveResource
  .middleware(jsonRequestMiddleware)
  .middleware(HEADERS_MIDDLEWERE)

export const postResource = getResource
  .config('method', 'POST')

export const optionsResource = getResource
  .config('method', 'OPTIONS')

export const postFormDataResource = receiveResource
  .middleware(HEADERS_MIDDLEWERE)
  .config('method', 'POST')

export const postFormDataPrettifierResource = postFormDataResource
  .middleware(multipartRequestMiddleware)
  .middleware(HEADERS_MIDDLEWERE)
  .config('method', 'POST')

export const patchFormDataResource = postFormDataResource
  .config('method', 'PATCH')

export const patchResource = postResource
  .config('method', 'PATCH')

export const putResource = postResource
  .config('method', 'PUT')

export const deleteResource = getResource
  .config('method', 'DELETE')

// decodeURIComponent
const decodeResultingUrl = context =>
  // let fn = decodeURIComponent()
  context
  // console.log(context, template, '@@@@@@@@@@############')

export function createResource(
  template,
  resource = getResource,
  transformer = defaultDataTransformer
) {
  return resource
    .url(decodeResultingUrl(makeTemplateGetter(template)))
    .fetcher(transformDataFetcher(fetcher,
      pipe(raiseHttpErrorTransformer, transformer)))
}

export function createDynamicResource(
  template,
  resource = getResource,
  transformer = defaultDataTransformer
) {
  return resource
    .url(template)
    .fetcher(transformDataFetcher(fetcher,
      pipe(raiseHttpErrorTransformer, transformer)))
}

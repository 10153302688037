<template lang="pug">
  include /mixins
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    .relative
      +b.error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    row(
      appearance='spaced'
      space='xl'
    )
      cell(
        v-for='field in fields'
        :cols='field.cols'
        :key='field.name'
      )
        tag(
          :tag='field.tag'
          v-model='form[field.name]'
          :input-label='field.title',
          :rules='field.rules'
          v-bind='field.props'
          v-on='field.events'
          :name='field.name'
          :type='field.type'
        )
      +b.g-cell.g-cols--12
        slot(
          name='submit'
          :disabled='isLoad',
          :submit='() => prepareData(valid)'
        )
          +b.tt-button--primary.--for-input.BUTTON(
            type='submit',
            :disabled='isLoad',
            @click.prevent='prepareData(valid)'
          )
            +e.title.P {{ btnText || _('Сохранить') }}
</template>
<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  AnswerMessage,
} from '@utils/submit'

const formatter = data => data
const afterSend = () => false

export default {
  name: 'FormGenerator',
  props: {
    form: {
      default: () => {},
    },
    fields: {
      default: () => [],
    },
    resource: {
      default: () => new Promise(),
    },
    dataFormatter: {
      default: () => formatter,
    },
    afterSend: {
      default: () => afterSend,
    },
    customValidatatorParser: {
      default: () => afterSend,
    },
    getParams: {
      default: () => {},
    },
    timeout: {
      default: 0,
    },
    btnText: {
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      isLoad: false,
      non_field_errors: '',
    }
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      this.$emit('isValid', valid)
      if (!valid) return
      const data = this.dataFormatter(this.form)
      this.submit(valid, data)
    },
    send(data) {
      return this.resource.execute({ ...this.getParams }, data)
        .then(res => {
          const { timeout } = this
          AnswerMessage({ ...res.data, timeout }, this)
          this.$emit('close')
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
          this.afterSend()
        })
    },
    updateValidator(errors) {
      const e = this.customValidatatorParser(errors, this)
      const parsed = defaultValidatorErrorsParser(e)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

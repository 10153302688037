<template lang="pug">
  include /mixins
  +b.control-stack.tt-space_mt--6.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    +b.g-row.--justify_between
      +b.g-cell.g-cols--12.--6-xl(
        :class="!has_reviews ? 'g-cols--6-mac g-cols--order_second' : 'g-cols--8-mac'"
      )
        +b.ds-caption.--size_xs.--size_sm-xl.--semibold.P  {{ _('Вы можете оставить отзыв или задать вопрос') }}
        +b.ds-caption.--size_xs.--size_sm-xl.tt-space_mt--3.--6-xl.P  {{ _('Используйте данную форму для того, чтобы оставить отзыв о товаре или задать вопрос. ') }}
        +b.ds-caption.--size_xs.--size_sm-xl.P.tt-space_mt--3.--6-xl  {{ _(' Все комментарии, не касающиеся продукта, будут удалены!') }}
        +b.ds-caption.--size_xs.--size_sm-xl.--semibold.P.tt-space_mt--3.--6-xl  {{ _(' Комментарий будет выведен только после предварительной проверки нашими сотрудниками.') }}
        +b.ds-caption.--size_xs.--size_sm-xl.P.tt-space_mt--3.--6-xl  {{ _('*Оставьте свой email и мы отправим оповещение сразу после того, как специалист даст ответ на ваш вопрос.') }}
      +b.g-cell.g-cols--12.--6-xl.--4-mac
        +b.g-row--align_center.--justify_between.--justify_center-xl.tt-space_mt--6
          cell(
            cols='12'
          )
            +b.tt-space_mb--6.D-CONTROL-INPUT(
              :rules="'required'",
              :input-label='labels.name'
              v-model='form.name'
              id='name',
              name='name',

              )
          cell(
              cols='12'
            )
            +b.tt-space_mb--6.PHONE-INPUT(
              :rules="'required|phone'",
              :input-label='labels.phone'
              v-model='form.phone',
              ref='phone'
              name='phone'
            )
          cell(
            cols='12'
          )
            d-control-textarea.is-textarea(
              :input-label='labels.comment'
              :rules="'required|min:5'",
              rows='4'
              v-model='form.comment',
              name='comment',
              ref='comment'
              error-classes='error-label--top'
            )
          cell(
            cols='12 9-xl'
            align='center'
          )
            +b.tt-button.BUTTON.tt-space_mt--6(
              type='submit'
              :disabled='isLoad',
              @click.prevent='prepareData(valid)'
            )
              +e.title.P {{ _('Отправить отзыв ') }}
</template>

<script>

import {
  ReviewCreate,
} from '@api/vendor.service'

import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'ReviewAdd',
  mixins: [FormMixin],
  props: {
    info: {},
    product_type: {
      type: String,
    },
    product_id: {
      type: String,
    },
    has_reviews: {
      type: Boolean,
    },
    reply: {},
    id: {},
    reply_name: {},
    promise: {},
    inline: {},
  },
  data() {
    return {
      labels: {
        name: this._('Ваше ім‘я '),
        phone: this._('Ваш номер телефону'),
        comment: this._('Ваше враження'),
      },
      form: {
        comment: this.info.reply_name ? `${this.info.reply_name}, ` : '',
        name: '',
        phone: '',
        element: {
          type: this.info.product_type,
          id: this.info.product_id,
        },
        parent: this.info.id,
        reply: this.info.reply,
      },
      non_field_errors: '',
      isLoad: false,
    }
  },
  methods: {

    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return

      this.submit(valid, this.form)
    },
    send(data) {
      return ReviewCreate.execute({}, data)
        .then(({ data: res }) => {
          this.$emit('close')
          AnswerMessage(res, this)
          this.form.name = null
          this.form.phone = null
          this.form.comment = null
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },

}
</script>

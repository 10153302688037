function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"is-visible-xl"},[_c('ui-accordion',{attrs:{"init":false,"is-calculate-height":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var activeState = ref.activeState;
var toggleActive = ref.toggleActive;
var collapse = ref.collapse;
var ref_style = ref.style;
var icon = ref_style.icon;
var myHeight = ref_style.myHeight;
var isDesktop = ref.isDesktop;
var rest = objectWithoutProperties( ref, ["activeState", "toggleActive", "collapse", "style", "isDesktop"] );
var other = rest;
return [_c('ui-click-outside',{attrs:{"do":collapse}},[_c('div',{staticClass:"switcher-with-dropdown"},[(_vm.selectedCurrency && _vm.list.length > 1)?_c('div',{staticClass:"switcher-with-dropdown__current",on:{"click":function($event){$event.preventDefault();return toggleActive()}}},[_c('span',{staticClass:"switcher-with-dropdown__link ds-caption ds-caption--size_sm ds-caption--appearance_uppercase"},[_vm._v(_vm._s(_vm.selectedCurrency.code))]),(_vm.list.length > 1)?_c('i',{staticClass:"switcher-with-dropdown__icon mdi",class:icon}):_vm._e()]):_vm._e(),_c('transition',{attrs:{"name":"slidedown"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(activeState),expression:"activeState"}],staticClass:"switcher-with-dropdown__options",class:_vm.classname},_vm._l((_vm.list),function(currency){return (currency.code !== _vm.selectedCurrency.code)?_c('div',{staticClass:"switcher-with-dropdown__option",on:{"click":function($event){return _vm.selectCurrency(currency, toggleActive)}}},[_c('span',{staticClass:"switcher-with-dropdown__link ds-caption ds-caption--size_sm ds-caption--appearance_uppercase"},[_vm._v(_vm._s(currency.code))])]):_vm._e()}),0)])],1)])]}}])})],1),_c('div',{staticClass:"is-disable-xl"},[_c('div',{staticClass:"native-changer-select-wrapper"},[(_vm.list.length > 1)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedCurrency),expression:"selectedCurrency"}],staticClass:"native-changer-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedCurrency=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.select]}},_vm._l((_vm.list),function(currency){return _c('option',{key:currency.code,staticClass:"native-changer-select__option",domProps:{"value":currency}},[_vm._v(_vm._s(currency.code))])}),0):_vm._e(),(_vm.load)?_c('ui-loader'):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
  include /mixins

  +b.static-label.LABEL(
    :for='id',
    v-bem.input=''
  ) {{ inputLabel }}

</template>

<script>
export default {
  name: 'BoundLabel',
  block: 'input-group',
  props: {
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.ds-panel--space_xl
    +b.close-popup.mdi.mdi-close.A(
      @click='$emit("close")'
    )
    +e.element--offset_full
      +b.wheel-modal-wrapper--gift
        +b.g-row.--align_center.--justify_center.--space_sm
          +b.g-cell.g-cols--narrow
            +b.smile-win--small.background(
              src="/static/img/gift.svg"
            )
          +b.g-cell.g-cols--12
            +b.wheel-modal-title-wrap
              +b.g-row--align_center.--justify_center.--appearance_nowrap
                +b.g-cols.g-cell
                  +b.wheel-modal-title.H3 {{ _('Вам подарок') }}
                +b.g-cols.g-cell
                  +b.smile-with-heart--large
                    +e.image(
                      src="/static/img/Smiling.png"
                    )
          +b.g-cell.g-cols
            +b.wheel-modal-description--bold.P {{ info.event }}
            +b.wheel-modal-description--thin.P {{ info.gift }}

          +b.g-cell.g-cols--12(
            v-if='info.url'
          )
            +b.g-row.--align_center.--justify_center.--space_2xl.--appearance_spaced
              +b.g-cell.g-cols--8
                +b.gift-modal-btn
                  +b.tt-space_mt--1
                    +b.A.tt-button--for-gift-modal.--centered(
                      :href='info.url'
                      target='_blank'
                    )
                      +e.title.SPAN {{ info.button_text }}
</template>
<script>
import {
  makeGiftAsRead,
} from '@api/wheel.service'
export default {
  name: 'WheelWinModal',
  props: ['info'],
  data() {
    return {
    }
  },
  async mounted() {
    await this.makeAsRead()
  },
  methods: {
    async makeAsRead() {
      await makeGiftAsRead.execute({ id: this.info.id })
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.trip-agreement-tabs
    +b.tt-page-tabs.--trips.tt-space_mb--6.tt-space_mt--4
      template(v-for="tab in tabs")
        +e.tab.tt-offset_mb--10(
          :key="tab.name"
          :class="{'is-active' : activeTab == tab.name}"
          @click="setActiveTab(tab.name)"
        )
          +b.tt-link.ds-caption.--size_sm.--size_md-lg.--semibold.full-h.SPAN {{ tab.title }}
    +e.content
      slot(:name='activeTab')
</template>
<script>
export default {
  name: 'AgreementTabs',
  data() {
    return {
      activeTab: 'description',
      tabs: [
        {
          title: this._("Описание"),
          name: 'description'
        },
        {
          title: this._("Условия"),
          name: 'terms'
        },
      ],

    }
  },
  methods: {
    setActiveTab(name) {
      this.activeTab = name
    },
  },
}
</script>

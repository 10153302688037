var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-result-item"},[_c('a',{staticClass:"search-result-item__img-link",attrs:{"href":_vm.link.props.url},on:{"click":function($event){$event.preventDefault();return _vm.goTo(_vm.link)}}},[_c('picture',{staticClass:"search-result-item__mini-img"},[(_vm.link.props.img)?[_c('source',{attrs:{"type":"image/webp","srcset":_vm.link.props.img.catalog_preview_webp}}),_c('img',{staticClass:"search-result-item__element",attrs:{"src":_vm.link.props.img.catalog_preview}})]:_c('img',{staticClass:"search-result-item__element",attrs:{"src":"https://via.placeholder.com/75x75"}})],2)]),_c('div',{staticClass:"search-result-item__info"},[_c('div',{staticClass:"search-result-item__line"}),_c('div',{staticClass:"search-result-item__line"},[_c('a',{staticClass:"search-result-item__title ds-caption ds-caption--size_xs ds-caption--medium",attrs:{"href":_vm.link.props.url},on:{"click":function($event){$event.preventDefault();return _vm.goTo(_vm.link)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.findValueAndHighlightTerm(_vm.link.props.product.props.title, _vm.link.props.product.props.title, _vm.localsearch))}})])]),_c('div',{staticClass:"search-result-item__line tt-space_mt tt-space_mt--1"},[_c('a',{staticClass:"search-result-item__content ds-caption ds-caption--size_2xs ds-caption--color-relief ds-caption--medium",attrs:{"href":_vm.link.props.url},on:{"click":function($event){$event.preventDefault();return _vm.goTo(_vm.link)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightTerm(_vm.link.props.product.props.short_description, _vm.localsearch ))}})])]),_c('p',{staticClass:"ds-caption ds-caption--size_2xs ds-caption--color-relief ds-caption--medium tt-space_mt tt-space_mt--1"},[_vm._v(_vm._s(_vm.link.props.weight.props.name))]),_c('BtnsController',{attrs:{"t":_vm.link.props.price_definition.user.unavailable},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var has_no_contract_btn = ref.has_no_contract_btn;
var has_cosmetologist_btn = ref.has_cosmetologist_btn;
var has_cart_btn = ref.has_cart_btn;
var has_out_of_stock_btn = ref.has_out_of_stock_btn;
var show_cosmetologist_desc = ref.show_cosmetologist_desc;
var show_price = ref.show_price;
var availability = ref.availability;
return [_c('div',{staticClass:"search-result-item__line"},[(show_price)?_c('p',{staticClass:"search-result-item__price ds-caption ds-caption--size_sm ds-caption--size_sm-xl ds-caption--color-relief ds-caption--bold",domProps:{"innerHTML":_vm._s(_vm.getSplittedHtml(_vm.getPrice(_vm.link.props.price_definition)))}}):_c('div',{staticClass:"search-result-item__price search-result-item__price--fake"}),(has_cosmetologist_btn)?[_c('modal-trigger',{attrs:{"url":"UiElements/CosmetologPermissions","name":"CosmetologPermissions","classes":["cart-modal"],"subtitle_auth":"true"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('button',{staticClass:"tt-button tt-button--mini tt-button--appearance_free tt-space_mb tt-space_mb--2",on:{"click":function($event){$event.stopPropagation();_vm.trigger_overlay(); open()}}},[_c('p',{staticClass:"tt-button__title"},[_vm._v(_vm._s(_vm._('Повысить уровень')))])])]}}],null,true)})]:(has_no_contract_btn)?_c('modal-trigger',{attrs:{"url":"UiElements/AccessPriceRequest","name":"AccessPriceRequest","classes":["defaultModal"],"subtitle_auth":"true","brand":_vm.link.props.product.props.brand.id,"user_name":_vm.username,"user_phone":_vm.phone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('button',{staticClass:"tt-button tt-button--mini tt-button--appearance_free tt-space_mb tt-space_mb--2",on:{"click":function($event){_vm.trigger_overlay(); open()}}},[_c('p',{staticClass:"tt-button__title"},[_vm._v(_vm._s(_vm._('Заключить контракт')))])])]}}],null,true)}):_vm._e(),(has_out_of_stock_btn)?_c('modal-trigger',{attrs:{"user_name":_vm.username,"user_phone":_vm.phone,"url":"Preorder/Modal","name":"PreOrderModal","id":_vm.link.id,"can_order":_vm.link.props.allow_preorder_payment,"img":_vm.link.props.image && _vm.link.props.image.catalog_preview || "https://via.placeholder.com/150x150","maintitle":_vm.link.props.product.props.title,"description":_vm.link.props.product.props.short_description,"weight":_vm.link.props.weight.props.name,"stock":_vm.link.props.stock,"price":_vm.link.props.price,"type":_vm.link.type,"classes":["videoModal"]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('button',{staticClass:"tt-button tt-button--mini tt-button--appearance_free tt-button--bordered_primary product-preorder tt-space_mb tt-space_mb--2",on:{"click":function($event){$event.preventDefault();_vm.trigger_overlay(); open()}}},[_c('p',{staticClass:"tt-button__title"},[_vm._v(_vm._s(_vm._('Предзаказ')))])])]}}],null,true)}):_vm._e(),(has_cart_btn)?_c('add-to-cart',{attrs:{"id":_vm.link.id,"type":_vm.link.type,"amount":"1","name":_vm.link.caption},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var add = ref.add;
var load = ref.load;
return [_c('button',{staticClass:"tt-button tt-button--mini tt-button--appearance_free tt-space_mb tt-space_mb--2",attrs:{"disabled":load,"title":_vm._("Купить")},on:{"click":function($event){$event.preventDefault();_vm.trigger_overlay(); add()}}},[_c('p',{staticClass:"tt-button__title"},[_vm._v(_vm._s(_vm._('Купить')))])])]}}],null,true)}):_vm._e()],2)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
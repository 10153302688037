<template lang="pug">
  include /mixins
  div(v-if='order.shipping_service && order.shipping_service.props.conf_fields.filter(e => e.props.recipient_info).length')
    +b.ds-caption--size_xsm.--size_sm-xl.--color_grey.--medium.P.tt-space_mt--6 {{ _('Данные получателя') }}
    +b.g-row(key='field_recipient')
      +b.g-cell.g-cols--12.tt-space_mt--6(
        v-for='field in order.shipping_service.props.conf_fields.filter(e => e.props.recipient_info)'
      )
        tag(
          :tag='$options.TAG_MAP[field.props.type_input]',
          v-bind="field", :ref='get_name(field.props.field, field.props.order_delivery_info)',
          :name='get_name(field.props.field, field.props.order_delivery_info)',
          :rules="{ 'required': field.props.is_required, ...getValidation(field.props.field.props.validation) }",
          :input-label='field.props.field.props.title', v-model='form[get_name(field.props.field, field.props.order_delivery_info)]',
          @input='check'
        )
</template>

<script>

const FIELDSSET = {
  first_name: 'delivery_first_name',
  last_name: 'delivery_last_name',
  phone: 'delivery_phone',
  middle_name: 'delivery_middle_name',
}

const TAG_MAP = {
  input: 'd-control-input',
  select: 'd-control-input',
  phone: 'phone-input',
}

export default {
  name: 'Recipient',
  props: ['info', 'user', 'recipient', 'order'],
  TAG_MAP,
  data() {
    return {
      form: {
        delivery_first_name: '',
        delivery_last_name: '',
        delivery_phone: '',
        delivery_middle_name: '',
      },
      isAuth: window.authent,
    }
  },
  watch: {
    info: {
      handler() {
        if (window.authent) {
          Object.keys(FIELDSSET).forEach(key => {
            this.form[FIELDSSET[key]] = this.info[key]
          })
          this.check()
        }
      },
    },
    recipient: {
      handler() {
        Object.keys(this.form).forEach(key => {
          if (this.recipient[key]) {
            this.form[key] = this.recipient[key]
          }
        })
        this.check()
      },
    },
    user: {
      handler() {
        this.$nextTick(() => {
          Object.keys(FIELDSSET).forEach(key => {
            this.form[FIELDSSET[key]] = this.user[key]
          })
          this.check()
        })
      },
      immediate: true,
      deep: true,
    },
    'order.first_name': {
      handler() {
        this.setDataHandler()
      },
      immediate: true,
    },
    'order.last_name': {
      handler() {
        this.setDataHandler()
      },
      immediate: true,
    },
    'order.phone': {
      handler() {
        this.setDataHandler()
      },
      immediate: true,
    },
    'order.middle_name': {
      handler() {
        this.setDataHandler()
      },
      immediate: true,
    },
  },
  methods: {
    get_name(field, orderField) {
      if (orderField) return orderField.props.label
      return field.props.label
    },
    check() {
      this.$emit('update', this.form)
    },
    getValidation(arr) {
      return arr.reduce((acc, item) => {
        if (item.props.rule) {
          acc = { ...acc, ...item.props.rule }
        }
        return acc
      }, {})
    },
    setDataHandler() {
      Object.keys(FIELDSSET).forEach(key => {
        if (this.$refs[FIELDSSET[key]]) {
          const [field] = this.$refs[FIELDSSET[key]]
          if (field.$children[0].$attrs['provider-context']) {
            const toched = field.$children[0].$attrs['provider-context'].touched
            if (toched) return
            this.form[FIELDSSET[key]] = this.order[key]
          }
        }
      })
      this.check()
    },
  },
}
</script>

<template lang='pug'>
  resource-loader-controller(
    :resource="attributesResource"
    v-slot="allProps"
  )
    resource-loader-controller(
      :resource="availableAttributesResource"
      v-slot="availableProps"
    )
      resource-loader-controller(
        :resource="$options.pricesResource"
        v-slot="pricesProps"
      )
        div
          search-filter(
            :value="filters"
            @input="changeFilters"
            :pricesProps="pricesProps"
            :allProps="allProps",
            :availableProps="availableProps",
            :pagination='pagination'
            :need-pricing='needPrices',
            :need-sorting='needSorting',
            :needTitle='needTitle',
            :needSearch='needSearch',
            :sortlist='sortlist',
            :label='label',
            :kwargs='kwargs',
            :needBlocking='needBlocking'
            :initbaseUrl='initbaseUrl'
            @category-hack='$emit("category-hack")'
          )
</template>

<script>

import {
  pricesResource,
} from '@api/catalog.service'

import SearchFilter from '@app/FiltersInlinesSimple'

export default {
  components: { SearchFilter },
  props: [
    'space',
    'filters',
    'pagination',
    'attributesResource',
    'availableAttributesResource',
    'needPrices',
    'needSorting',
    'needTitle',
    'needSearch',
    'label',
    'sortlist',
    'needBlocking',
    'initbaseUrl',
    'kwargs',
  ],
  pricesResource,
  methods: {
    changeFilters(value) {
      const params = Object.assign(value, { label: this.label })
      this.$emit('update:filters', params)
    },
  },
}

</script>

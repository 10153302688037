/* eslint-disable */ 

if (process.env.NODE_ENV !== 'production') {
  const url = new URL(document.currentScript.src);
  __webpack_public_path__ = (url.origin + __webpack_public_path__);
}

import 'vuejs-clipper/dist/vuejs-clipper.css'

if ('serviceWorker' in navigator) {
  // console.log("Will the service worker register?")
  navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
    .then(function (reg) {
      // console.log("Yes, it did.")
    }).catch(function (err) {
      // console.log("No it didn't. This happened:", err)
    })
}

<template lang="pug">
  include /mixins
  div
    slot(
      :is-subscribed='isSubscribed'
    )
</template>

<script>
import {
  checkBrandSubscription,
} from '@api/feedback.service'

export default {
  name: 'BrandChecker',
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      isSubscribed: false,
    }
  },
  created() {
    this.checkStatus()
  },
  methods: {
    async checkStatus() {
      const { data: { props: { is_subscribed } } } = (await checkBrandSubscription.execute({ id: this.id }))
      this.isSubscribed = is_subscribed
    },
  },
}
</script>

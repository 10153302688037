<template lang="pug">
  include /mixins
  +b.post-preview
    +e.img-wrap
      +e.img.IMG(
        :src='item.props.img && item.props.img.preview || "/static/img/rebranding/non.png"'
      )
    +e.content
      +e.info
        +e.title.P {{ item.props.title }}
        +e.description(v-html='item.props.text')
      +e.btn
        +b.tt-button.A(
          :target='item.props.redirect_policy'
          :href='item.props.url'
        )
          +e.title.SPAN {{ item.props.button_text }}
</template>

<script>
export default {
  name: 'PreviewPost',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

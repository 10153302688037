const SEO_CONTENT_CLASS_NAME = 'js-seo-content'
const SEO_TRIGGER_CLASS_NAME = 'js-seo-btn'
const SEO_SHADOWED_CLASS_NAME = 'shadowed'

const INITIAL_HEIGHT = 'initial'

const hideContent = content => {
  content.classList.add(SEO_SHADOWED_CLASS_NAME)
  content.style.maxHeight = `${content.dataset.height}px`
}

export default function seoTextTrigger() {
  const contents = Array.from(document.getElementsByClassName(SEO_CONTENT_CLASS_NAME))
  const triggers = Array.from(document.getElementsByClassName(SEO_TRIGGER_CLASS_NAME))
  contents.forEach(content => {
    if (content.scrollHeight > Number(content.dataset.height)) {
      content.nextElementSibling.classList.remove('is-hidden')
      hideContent(content)
    }
  })
  let show = false
  triggers.forEach(trigger => {
    trigger.addEventListener('click', () => {
      trigger.innerText = show ? trigger.dataset.hide : trigger.dataset.show
      show = !show
      if (show) {
        trigger.previousElementSibling.classList.remove(SEO_SHADOWED_CLASS_NAME)
        trigger.previousElementSibling.style.maxHeight = INITIAL_HEIGHT
        return
      }
      hideContent(trigger.previousElementSibling)
    })
  })
}

<template lang="pug">
  include /mixins
  +b.ds-panel
    +e.element--offset_full
      +b.ds-caption--size_lg.--medium.H2.main-title {{ _('Изменить пароль') }}
      +b.tt-space_mt--6.FORM-GENERATOR(
        :fields='fields',
        :resource='$options.passwordChangeResource',
        :form='form'
        :custom-validatator-parser='errorsParser'
        :btn-text='_("Сохранить")'
        :after-send='afterSend'
      )

</template>
<script>
import { passwordChangeResource } from '@api/auth.service'
import loginMixin from '@app/Forms/mixins/login'
import { mapState } from 'vuex'

const EXCEPT_KEY = 'username'

export default {
  name: 'PasswordChange',
  passwordChangeResource,
  mixins: [loginMixin],
  data() {
    return {
      form: {
        old_password: '',
        new_password1: '',
        new_password2: '',
        username: '',
      },
      fields: [
        {
          name: 'old_password',
          tag: 'd-control-input',
          cols: '12',
          title: this._('Ваш пароль'),
          type: 'password',
          rules: 'required',
        },
        {
          name: 'new_password1',
          tag: 'd-control-input',
          cols: '12',
          title: this._('Новый пароль'),
          type: 'password',
          rules: 'required',
        },
        {
          name: 'new_password2',
          tag: 'd-control-input',
          cols: '12',
          title: this._('Повторите новый пароль'),
          type: 'password',
          rules: 'required|confirmed:new_password1',
        },
      ],
    }
  },
  computed: {
    ...mapState('staff', [
      'user',
    ]),
  },
  watch: {
    user: {
      handler() {
        this.form.username = this.user?.props?.username
      },
      immediate: true,
    },
  },
  methods: {
    afterSend() {
      Object.keys(this.form).forEach(key => {
        if (key !== EXCEPT_KEY) {
          this.form[key] = ''
        }
      })
      this.$emit('close')
    },
    errorsParser(errors) {
      const e = {}
      Object.keys(errors).forEach(key => {
        console.log(typeof errors[key], errors[key]);
        if ('Array' === errors[key].constructor.name) {
          const [first] = errors[key]
          e[key] = first
          return
        }
        e[key] = errors[key]
      })
      return e
    },
  },
}
</script>

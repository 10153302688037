<template lang="pug">
  include /mixins
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')

    +b.g-row(
    )
      +b.g-cell.g-cols--12
        slot(
          name='auth-btn'
        )
    +b.g-row
      +b.g-cell.g-cols--12.tt-space_mt--8
        phone-input(
          :rules="'required|phone'",
          :input-label='labels.phone'
          v-model='form.phone',
          ref='phone'
          name='phone'
          :disabled='false'
        )
      +b.g-cell.g-cols.tt-space_mt--6(
      )
        +b.ds-caption.--size_sm.--medium.--color_black.P {{ _('Нажимая кнопку “Далее” вы соглашаетесь с') }} &nbsp;
          +b.tt-link--inline.--styling_default.--black.A.ds-caption--size_sm.--medium(:href='offer', target='_blank') {{ _('политикой конфиденциальности') }}
          span &nbsp; {{ _('и') }} &nbsp;
          +b.tt-link--inline.--styling_default.--black.A.ds-caption--size_sm.--medium(:href='rules', target='_blank') {{ _('правилами пользования TOTIS Pharma') }}
    .relative
      +b.non-field-error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    +b.g-row
      +b.g-cell.g-cols--12.tt-space_mt--5.tt-space_pt--4-xl
        token-confirmation-check(
          :token='form.phone'
          :additional-data='{ is_new_user: true }'
          :action='goToPasswordSetPage'
        )
          template(#default='{ is_confirmed, sendCode }')
            +b.tt-button--bordered_modern.BUTTON(
              type='submit'
              :disabled='isLoad',
              @click.prevent='validate(sendCode)'
            )
              +e.title.P {{ _('Зарегистироваться')}}
    slot(name='register-btn')
</template>
<script>

import FormMixin from '@app/Forms/FormMixin'

export default {
  name: 'Verify',
  mixins: [FormMixin],
  props: {
    noRedirect: {
      default: false,
    },
  },
  data() {
    return {
      labels: {
        phone: this._('Телефон'),
      },
      form: {
        phone: '',
        is_new_user: true,
      },
      accept_rules: false,
      isLoad: false,
      non_field_errors: '',
      offer: window.offer,
      rules: window.rules,
      recovery_link: window.recovery_page,
      register_link: window.registration_page,
    }
  },

  methods: {
    async validate(action) {
      const isValid = (await this.$refs.validator.validate())
      if (!isValid) return
      action()
    },
    goToPasswordSetPage(id) {
      const data = {
        ...this.form,
        id,
      }
      window.location.href = `${window.registration_url}?_state=${btoa(JSON.stringify(data))}`
    },
  },
}
</script>

import {
  route,
} from '@app/Routing/helpers'

import {
  createRoutes as createBalanceRoutes,
} from '@app/Staff/Balance/routes'
import {
  createRoutes as createBonusesRoutes,
} from '@app/Staff/Bonuses/routes'

import {
  createRoutes as createAddressBookRoutes,
} from '@app/Staff/AddressBook/routes'

import {
  createRoutes as createFavoritesRoutes,
} from '@app/Staff/Favorites/routes'

import {
  REDIRECT_PATH,
} from '@app/Staff/router'

const UserInfo = () => import('@app/Staff/User/Info')
const GiftsList = () => import('@app/Staff/Gifts/List')
const OrderList = () => import('@app/Staff/Order/List')
const PublicList = () => import('@app/Staff/Public/View')
const LevelRequest = () => import('@app/Staff/Level/Request')
const LevelList = () => import('@app/Staff/Level/List')

const NotificationsList = () => import('@app/Staff/Notifications/List')

const PersonalPage = () => import('@app/Staff/Personal')

const AuthDataChange = () => import('@app/Staff/User/AuthDataChange')
const Manager = () => import('@app/Staff/User/Manager')

const USER_DATA = 'info/'
const GIFTS_LIST = 'gifts/'
const ORDER_LIST = 'orders/'
const PUBLIC_EDIT = 'public/'
const DOCUMENTS = 'documents/'
const LEVEL_LIST = 'levels/'
const AUTH_DATA_CHANGE = 'auth-data-change/'
const MANAGER = 'manager/'

const NOTIFICATIONS = 'notifications/'

const PERSONAL_USER = 'user/'
const PERSONAL_CLINIC = 'clinic/'
const PERSONAL_COSMETOLOGIST = 'cosmetologist/'
const PERSONAL_SALON = 'salon/'

export const USER_INFO_NAME = 'user:info'
export const GIFTS_LIST_NAME = 'user:gifts'
export const ORDERS_LIST_NAME = 'user:orders'
export const PUBLIC_LIST_NAME = 'user:public'
export const DOCUMENTS_NAME = 'user:documents'
export const LEVEL_LIST_NAME = 'user:levels'
export const USER_BASE_NAME = 'user:base'
export const AUTH_DATA_CHANGE_NAME = 'user:auth-data:change'
export const MANAGER_NAME = 'user:manager'

export const PERSONAL_USER_NAME = 'user:personal:user'
export const PERSONAL_CLINIC_NAME = 'user:personal:cosmetologist'
export const PERSONAL_COSMETOLOGIS_NAME = 'user:personal:clinic'
export const PERSONAL_SALON_NAME = 'user:personal:salon'
export const PERSONAL_NOTIFICATION_NAME = 'user:personal:notification'

export function createRoutes(options) {
  return [
    route(USER_DATA, UserInfo, USER_INFO_NAME, options),
    route(ORDER_LIST, OrderList, ORDERS_LIST_NAME, {
      meta: {
      },
    }),
    ...createBalanceRoutes(),
    ...createBonusesRoutes(),
    ...createAddressBookRoutes(),
    ...createFavoritesRoutes(),
    route(PUBLIC_EDIT, PublicList, PUBLIC_LIST_NAME, {
      meta: {
      },
    }),
    route(DOCUMENTS, LevelRequest, DOCUMENTS_NAME, {
    }),
    route(NOTIFICATIONS, NotificationsList, PERSONAL_NOTIFICATION_NAME, {
    }),
    route(AUTH_DATA_CHANGE, AuthDataChange, AUTH_DATA_CHANGE_NAME, {
    }),
    route(MANAGER, Manager, MANAGER_NAME, {
      meta: { hideVisitCard: true },
    }),
    route(LEVEL_LIST, LevelList, LEVEL_LIST_NAME, {
    }),
    route(GIFTS_LIST, GiftsList, GIFTS_LIST_NAME, {}),
    route(PERSONAL_CLINIC, PersonalPage, PERSONAL_CLINIC_NAME, { meta: { name: 'clinic' } }),
    route(PERSONAL_USER, PersonalPage, PERSONAL_USER_NAME, { meta: { name: 'end-user' } }),
    route(PERSONAL_COSMETOLOGIST, PersonalPage, PERSONAL_COSMETOLOGIS_NAME, { meta: { name: 'cosmetologist' } }),
    route(PERSONAL_SALON, PersonalPage, PERSONAL_SALON_NAME, { meta: { name: 'salon' } }),
    { path: '', redirect: REDIRECT_PATH },
  ]
}

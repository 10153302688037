var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('controller',{staticClass:"relative on-overlay knowledge-search",attrs:{"localsearch":_vm.search,"preview":_vm.$options.preview,"searchResource":_vm.$options.asnwersSearchResource},scopedSlots:_vm._u([{key:"inputview",fn:function(ref){
var keydownEvent = ref.keydownEvent;
var keyupEvent = ref.keyupEvent;
var model = ref.model;
var goToSearch = ref.goToSearch;
return [_c('div',{staticClass:"g-cell"},[_c('div',{staticClass:"control-stack"},[_c('div',{staticClass:"g-row g-row--align_center g-row--space_none"},[_c('div',{staticClass:"g-cell g-cols g-cols--auto"},[_c('div',{staticClass:"relative search-form search-form--fully"},[_c('button',{staticClass:"search-trigger search-trigger--fixed-width",on:{"click":function($event){$event.preventDefault();return _vm.activeSearch($event)}}},[_c('div',{staticClass:"search-trigger__loop icon-search"})]),_c('d-control-input',{directives:[{name:"bem",rawName:"v-bem:d-control-input",value:({'appearance': 'bordered', 'appearance': 'large'}),expression:"{'appearance': 'bordered', 'appearance': 'large'}",arg:"d-control-input"}],ref:"search-input",staticClass:"d-control-input d-control-input--appearance_stacked control-stack__element control-stack__element control-stack__element--position_first",attrs:{"name":"search","type":"search","placeholder":_vm._("Введите здесь свой поисковый запрос ... "),"autocomplete":"aqngfjqanfklan"},on:{"keydown":keydownEvent,"keyup":keyupEvent},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('div',{staticClass:"g-cell g-cols g-cols--narrow"},[_c('button',{staticClass:"tt-button tt-button--for-input control-stack__element control-stack__element control-stack__element--position_last"},[_c('span',{staticClass:"tt-button__title tt-button__title--mini"},[_vm._v(_vm._s(_vm._('Search')))])])])])])])]}},{key:"link",fn:function(ref){
var meta = ref.meta;
return [_c('a',{staticClass:"tt-space_pa tt-space_pa--2 tt-link tt-link--styling_default tt-link--variant_end ds-caption ds-caption--size_xsm ds-caption--medium",attrs:{"href":(_vm.url + "filters/search=" + _vm.search + "/")}},[_vm._v(_vm._s(_vm._('Найдено'))+" "+_vm._s(meta.total))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang='pug'>
  resource-loader-controller(
    :resource="$options.attributesResource"
    v-slot="allProps"
  )
    events-search-filter(
      :value="filters"
      @input="changeFilters"
      :allProps="allProps"
      :pagination='pagination'
    )
</template>

<script>

import {
  attributesResource,

} from '@api/events.service'

import EventsSearchFilter from '@app/Events/Filters'

export default {
  components: { EventsSearchFilter },
  props: [
    'space', 'filters', 'pagination',
  ],
  attributesResource,

  methods: {
    changeFilters(value) {
      this.$emit('update:filters', value)
    },
  },
}

</script>

<template lang="pug">
  include /mixins
  +b.ds-aspect-ratio.FIGURE.mb-2.clickable.hidden(
    :class='classname'
  )
    +e.body.IMG(
      :src='source'
      @click.prevent='showImg'
    )
    vue-easy-lightbox(
      :visible="visible"
      :imgs="img"
      @hide="handleHide"
    )
</template>
<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: 'Lightbox',
  props: {
    source: {
      type: String,
    },
    classname: {
      default: 'ds-aspect-ratio--ratio_16x9',
    },
  },
  components: {
    VueEasyLightbox,
  },
  data() {
    return {
      visible: false,
      img: [this.source],
    }
  },
  methods: {
    showImg() {
      if (this.source) {
        this.visible = true
      }
    },
    handleHide() {
      this.visible = false
    },
  },
}
</script>

import {
  loginResource,
  registerResource,
  smsResend,
  verifyResource,
} from '@api/auth.service'
import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { storage } from '@utils/session-storage'

// export const detectAutofill = element => new Promise(resolve => {
//   console.log(element);
//   setTimeout(() => {
//     resolve(window.getComputedStyle(element).getPropertyValue('appearance') === 'menulist-button')
//   }, 600)
// })

export default {
  name: 'Auth',
  mixins: [FormMixin],
  props: {
    noRedirect: {
      default: false,
    },
    isRegister: {
      default: false,
    },
    cols: {
      default: '12-fhd',
    },
  },
  data() {
    return {
      labels: {
        phone: this._('Номер телефона'),
        password: this._('Пароль'),
        email: this._('Email'),
      },
      form: {
        phone: '',
        email: '',
        username: '',
        password: '',
      },
      isLoad: false,
      verifyUrl: window.verifyUrl,
      non_field_errors: '',
      offer: window.offer,
      rules: window.rules,
      isPasswordSended: false,
      accept_rules: false,
      autofilled: '',
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.testAutoFill()
    // })
    // console.log(storage.getItem('user'), '>>>>>>>>>>>');
    this.form.username = storage.getItem('user')
    // setTimeout(() => {
    //   this.form.username = storage.getItem('user')
    // }, 500)
  },
  methods: {
    // async testAutoFill() {
    //   this.autofilled = await detectAutofill(this.$refs.phone.$el)
    //   console.log(this.autofilled);
    // },
    afterPassordSend() {
    },
    async resendPassword() {
      this.non_field_errors = ''
      if (!this.form.username) {
        this.non_field_errors = [this._('Введите номер телефона, куда будет отправленно смс с новым паролем')]
        return
      }
      const data = {
        phone: this.form.username,
      }

      const action = this.isRegister ? verifyResource : smsResend
      storage.setItem('user', this.form.username)
      try {
        const { data: response } = (await action.execute({}, data))
        const meta = {
          message: {
            title: this._('Password resend SMS success header'),
            text: this._('Password resend SMS success text'),
          },
          ...response,
        }
        AnswerMessage(meta, this)
        this.afterPassordSend()
      } catch (e) {
        e.json().then(body => {
          const errors = {}

          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })
          this.non_field_errors = [errors.phone?.message || errors.phone[0].message]
        })
      }
    },
    async prepareData() {
      this.non_field_errors = ''
      const isValid = (await this.$refs.validator.validate())
      if (!isValid) return
      this.submit(isValid, this.form)
    },
    send(data) {
      const action = this.isRegister ? registerResource : loginResource
      return action.execute({}, data)
        .then(({ data: res }) => {
          if (!this.noRedirect) {
            AnswerMessage(res, this)
          } else {
            window.location.reload()
          }
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}

<template lang="pug">
  include /mixins
  +b.g-row.--align_center
    +b.g-cell.g-cols.phone-input--code
      +b.d-control-input--appearance_bordered.D-CONTROL-MULTISELECT(
        :options='list || []',
        v-model='code',
        :label-component='$options.staticInput'
        name='code',
        :allow-empty="false"
        :input-label="_('Ваш номер телефону')",
        :disabled='disabled'
        @input='setCode(); save()'
        :custom-label='obj => obj.props.code'
      )
        template(#option="{ option }")

          +b.switcher-with-dropdown.tt-space_ml--2.--4-xl
            +e.current--dark
              +e.img-wrap
                +e.img.IMG(
                  :src='option.props.icon'
                )
              +e.link--dark.ds-caption--size_xs.--appearance_uppercase.SPAN {{ option.props.code }}
        template(#singleLabel="{ option }")
          +b.switcher-with-dropdown
            +e.current--dark
              +e.img-wrap
                +e.img.IMG(
                  :src='option.props.icon'
                )
              +e.link--dark.ds-caption--size_xs.--appearance_uppercase.SPAN {{ option.props.code }}

    +b.g-cell.g-cols.phone-input--phone
      +b.d-control-input--appearance_bordered.D-CONTROL-INPUT(
        :rules="{required: true, phone: { codeLength: code && code.props && code.props.code.length - 1, numberAfterCode: code && code.props && code.props.number_after_code } }",
        @input='checkCode(); save()'
        v-model='phone',
        name='phone'
        v-bind='props'
        :disabled='disabled'
        type='tel'
      )
</template>

<script>

import StaticLabel from '@app/UiElements/Controls/StaticLabel'


import {
  phoneCodeListResource,
} from '@api/vendor.service'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

export default {
  name: 'PhoneInput',
  phoneCodeListResource,
  staticInput: StaticLabel,
  props: {
    props: {
      type: Object,
      default: () => {},
    },
    value: {},
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      phone: '',
      code: '',
      default_code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      list: [],
      regexp: [],
    }
  },
  created() {
    this.getPhoneCodes()
  },
  watch: {
    value: {
      async handler() {
        if (!this.list.length) await this.getPhoneCodes()
        this.phone = this.value;
        if (!this.phone) this.setCode()
        this.checkCode()
      },
      immediate: true,
    },
  },
  methods: {
    async getPhoneCodes() {
      const { data } = (await phoneCodeListResource.execute())
      this.list = data
      if (!this.code) {
        this.code = this.list
          .find(c => c.props.countries
            .find(e => e.props.iso_code === this.default_code))
      }
      this.list.forEach(l => {
        const t = l.props.code.replace('+', '[\+]')
        const regexp = new RegExp(`^${t}`, 'img')
        l.props.regexp = regexp
      })
    },
    save() {
      this.$emit('input', this.phone)
    },
    setCode() {
      this.phone = `${this.code.props.code}`
    },
    checkCode() {
      const code = this.list.find(r => r.props.regexp.exec(this.phone))
      if (code) {
        this.code = code
      }
    },
  },

}
</script>

import { createResource, getResource } from '@resource/resource'
import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy';

const FIRST_PAGE_OFFSET = 0

export const findParent = (fields, field) => {
  const parentIndex = fields.findIndex(el => el.name === field)
  const parent = fields[parentIndex]
  return parent
}
export const saveSearchInfo = (parent, event, visible) => {
  if (!visible) parent.props.search = event
  parent.props.loading = true
  let offset = (parent.props.pagination.offset +
    parent.props.pagination.limit) || 0
  if (!visible) offset = FIRST_PAGE_OFFSET
  return offset
}

export const saveResults = (parent, visible, data) => {
  parent.props.loading = false
  if (data.pagination) {
    const items = parent.filter ? data.items.map(parent.filter) : data.items
    parent.props.pagination = data.pagination
    if (visible) {
      parent.props.options.push(...items)
      return
    }
    parent.props.options = items
    return
  }
  parent.props.options = data
}

let currentRequest = null

const makeRequest = async (resourceUrl, search, offset, params) => {
  if (currentRequest) currentRequest.cancel()
  const cancelController = new AbortController()
  const base = getResource.config('signal', cancelController.signal)
  const resource = createResource(resourceUrl, base)

  return new Promise((res, rej) => {
    currentRequest = cancellablePromiseProxy(
      new Promise((resolve, reject) => {
        resource.execute({
          search,
          offset,
          limit: 50,
          ...params,
        })
          .then(resolve, reject);
      }),
      { cancelController }
    ).then(({ data }) => {
      res(data)
    }, err => rej(err))
  })
}

export const getPaginatedProps = async (resourceUrl, event, field, visible, getParams = '{?offset,limit,search,id*}') => {
  if (false === visible) return
  const parent = field

  const offset = saveSearchInfo(parent, event, visible)

  const resource = `${resourceUrl}${getParams}`
  console.log(parent.props?.search);
  const data = await makeRequest(resource, parent.props?.search, offset, parent.params)

  saveResults(parent, visible, data)
}

export const getPaginatedPropsForArray = async (resource, event, field, visible, fields) => {
  // console.log(resource, event, field, visible, id, fields);
  if (false === visible) return

  const parent = findParent(fields, field)

  const offset = saveSearchInfo(parent, event, visible)

  const data = await makeRequest(resource, parent.props?.search, offset, parent.params)

  saveResults(parent, visible, data)
}

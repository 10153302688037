<template lang="pug">
  include /mixins
  +b.ds-panel--space_none
    ui-loader(v-if='load')
    +e.product_variant--indent_inside.--offset_top.product_in_cart.relative
      +b.card-product-inner--variant_recipe
        +b.g-row--align_center
          +b.g-cell.g-cols--3.--2-xl.relative
            +b.tt-picture--size_auto.--size_auto-h.product__img--mini.PICTURE.relative
              source(
                type="image/webp"
                :srcset="product.product_variant.props.img.catalog_preview_webp"
              )
              +e.body.IMG(
                v-if='product.product_variant.props.img.catalog_preview'
                :src='product.product_variant.props.img.catalog_preview',
                :alt='product.product_variant.props.title',
              )

              +e.body.IMG(
                v-else
                src='https://via.placeholder.com/60x60',
              )

          +b.g-cell.g-cols--9.--10-xl
            +b.g-row--appearance_column.--justify_between.full-h
              +b.g-cell.g-cols--12
                +b.g-row.--justify_between.full-h
                  +b.g-cell.g-cols--6.--6-xl
                    +b.ds-caption--size_sm.--size_sm-xl.--bold.A.tt-link--variant_start(
                      :href='product.product_variant.props.url'
                    ) {{ product.product_variant.caption }}
                  +b.g-cell.g-cols--6.--3-xl
                    +b.ds-caption--size_2xs.--light.--appearance_right.P {{ _('Артикул:') }} #[+b.ds-caption--medium.SPAN {{ product.product_variant.props.vendor_code || "-" }} ]
                  +b.g-cell.g-cols--6.--3-xl.is-visible-xl
                    +b.ds-caption--size_xs.--semibold.--appearance_right.P {{ product.discount_price }} {{ currency }}
              +b.g-cell.g-cols--12.--6-xl
                +b.ds-caption--size_2xs.--light.P(v-html='product.product_variant.props.product.props.short_description')
              +b.g-cell.g-cols--12.--6-xl
              +b.g-cell.g-cols--12.--6-xl
                +b.ds-caption--size_xs.--regular.P(
                  v-if='product.product_variant.props.weight'
                ) {{ product.product_variant.props.weight.props.name }}
              +b.g-cell.g-cols--12.tt-space_mt--3.is-disable-xl
                +b.g-row
                  +b.g-cell.g-cols--6
                    +b.ds-caption--size_xs.--regular.P(
                      v-if='product.product_variant.props.weight'
                    ) {{ product.product_variant.props.weight.props.name }}
                  +b.g-cell.g-cols--6
                    +b.ds-caption--size_xs.--semibold.--appearance_right.P {{ product.discount_price }} {{ currency }}

        row(
          justify='between'
        )
          +b.g-cell.g-cols.--2-xl
          +b.g-cell.g-cols--6.--4-xl
            counter(
              :amount='product.quantity',
              :disable='isSale || disabled'
              @change='$emit("update", {quantity:  $event.count, id: product.id})'
            )
          +b.g-cell.g-cols.--2-xl
          +b.g-cell.g-cols--6.--4-xl
        row(
          justify='between'
        )
          cell(cols='6')
            +b.g-row.V-SELECTION-CONTROLLER(
              :options='percents'
              :multiple='false',
              :key-getter="$options.keyGetter",
              v-model='product.discount_percent'
              v-slot="{ options, value: model, change }"
            )
              +b.d-control-input.--appearance_flexible.--appearance_auto.g-cell.g-cols--narrow(
                v-for='{ value, selected } in options'
                :key='value.id'
              )
                +e.element.INPUT(
                  type='radio'
                  :name='`discount:${product.id}`'
                  :ref='`discount_${value.id}`'
                  :checked.prop='selected'
                  :value='value'
                  @change='change(value); prepare(value)'
                  :id='`discount:${value.id}:${product.id}`'
                )
                +e.LABEL.label--static(
                  :for='`discount:${value.id}:${product.id}`'
                )
                  +b.ds-caption.P {{ value.caption }}
          cell(cols='6')
            row(
              justify='between'
            )
              cell(
                cols='narrow'
              )
                +b.ds-caption--size_sm.--light.--appearance_right.P  {{ _('Сумма:') }}
              cell(
                cols='narrow'
              )
                +b.ds-caption--size_sm.--semibold.--appearance_right.P   {{ product.total_price }} {{ currency }}
        template(v-if='!disabled')
          +b.remove-from-cart.SPAN.mdi.mdi-close(
            @click='$emit("removeFromRecipe", {id: product.id})'
            v-if='!isSale'
          )
</template>
<script>
export default {
  name: 'ProductView',
  props: {
    product: {
      default: () => { },
    },
    price: {
      default: '0.00',
    },
    isSale: {
      default: () => false,
    },
    load: {},
    disabled: {
      default: () => false,
    },
    percents: {

    },
  },
  keyGetter: x => x.props && x.props.value,
  data() {
    return {
      currency: window.currency,
      discount_val: 0,
    }
  },
  methods: {
    prepare({ props: { value } }) {
      const data = {
        discount_percent: value,
        id: this.product.id,

      }
      this.$emit('update', data)
    },
  },
}
</script>

const Sorting = () => import('@app/Filters/Sorting')
const SelectWidget = () => import('@app/Filters/Widgets/SelectWidget')
const MultiselectWidget = () => import('@app/Filters/Widgets/MultiselectWidget')
const CheckboxWidget = () => import('@app/Filters/Widgets/CheckboxWidget')
const SliderWidget = () => import('@app/Filters/Widgets/SliderWidget')
const DatePickerWidget = () => import('@app/Filters/Widgets/DatePickerWidget')
const DateRangeWidget = () => import('@app/Filters/Widgets/DateRangeWidget')
const CombinedInputWidget = () => import('@app/Filters/Widgets/CombinedInputWidget')
const AutocompleteMultiselectWidget = () => import('@app/Filters/Widgets/AutocompleteMultiselectWidget')
const RangeWithVariantsWidget = () => import('@app/Filters/Widgets/RangeWithVariantsWidget')
const SimpleMultiselectWidget = () => import('@app/Filters/Widgets/SimpleMultiselectWidget')
const InputWidget = () => import('@app/Filters/Widgets/InputWidget')
const SearchWidget = () => import('@app/Filters/Widgets/SearchWidget')
const SimpleCategoryWidget = () => import('@app/Filters/Widgets/SimpleCategoryWidget')
const CategoryWidget = () => import('@app/Filters/Widgets/CategoryWidget')

const WIDGETS_MAP = {
  range: SliderWidget,
  prices: RangeWithVariantsWidget,
  'date-range': DateRangeWidget,
  'date-picker': DatePickerWidget,
  input: InputWidget,
  search: SearchWidget,
  select: SelectWidget,
  multiselect: CheckboxWidget,
  'combined-input': CombinedInputWidget,
  'autocomplete-multiselect': AutocompleteMultiselectWidget,
  'simple-select': MultiselectWidget,
  'simple-multiselect': SimpleMultiselectWidget,
  category: CategoryWidget,
  type: MultiselectWidget,
  simpleCategory: SimpleCategoryWidget,
}
const WIDGETS_TYPE_MAP = {
  flat: CheckboxWidget,
  tree: SimpleCategoryWidget,
}

export default {
  components: {
    Sorting,
  },
  widgetsMap: WIDGETS_MAP,
  widgetsTypeMap: WIDGETS_TYPE_MAP,
  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'sortlist',
    'label',
    'baseUrl',
    'isStoreCatalog',
  ],
  data() {
    return {
      search: '',
      requestsList: [],
    }
  },
  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,

      }
    },

    baseFilters() {
      return this.getAvailableFilters(this.filters)
    },
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if ('search/SET_SEARCH' == mutation.type) {
        this.search = mutation.payload
        this.onlyValue('search', this.search)
      }
    })
  },
  methods: {
    async searchValues(props, all) {
      props.has_search = true
      const { search } = props
      for (let x = 0; x < all.values.length; x++) {
        const field = all.values[x].props.title.toLowerCase()
        const res = field.includes(search.toLowerCase())
        this.$set(all.values[x], 'is_valid', res)
      }
    },
    getMultiValue(key) {
      return {
        min_value: this.value[`${key}_min`],
        max_value: this.value[`${key}_max`],
      }
    },
    getAvailableFilters(filters = []) {
      return filters
    },
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },
    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
    debounceUpdating(fn, key, value, timeout) {
      const [inputValue] = value
      this.requestsList.push(inputValue)
      let timer
      const context = this
      clearTimeout(timer)
      timer = setTimeout(() => {
        if (this.requestsList[this.requestsList.length - 1] === inputValue) {
          fn.call(context, key, value)
          this.requestsList = []
        }
      }, timeout)
    },
    debounceSearch(key, value) {
      this.debounceUpdating(this.updateValue, key, value, 300)
    },
    updateMultipleValues(key, values) {
      let res = {}
      Object.keys(values).forEach(k => {
        if (values[k][0]) {
          res = { ...res, [`${key}_${k}`]: [`${key}-${k}_${values[k]}`] }
        }
      })
      this.$emit('input', { ...this.value, ...res })
    },
  },
}

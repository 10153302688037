<template lang="pug">
  include /mixins
  form.relative.search-form(
    :action='url',
    method='POST'
    @submit.prevent='goToSearch'
  )
    +b.BUTTON.search-trigger.--fixed-width(
      type='submit'
    )
      +e.loop.icon-search
    input(
      type='hidden',
      name='csrfmiddlewaretoken'
      :value='getCookie("csrftoken")'
    )
    transition(name="fade")
      d-control-input(
        v-bem:d-control-input="{'appearance': 'bordered', 'appearance': 'large'}"
        type='search',
        :placeholder='_("Поиск публикации ")',
        autocomplete='no'
        v-model.trim='localSearch'
        ref='search-input'
      )
</template>

<script>

import { getCookie } from '@utils/cookies'

export default {
  name: 'NativeSearch',
  props: ['search', 'url', 'order'],
  data() {
    return {
      localSearch: this.search,
    }
  },
  methods: {
    getCookie,
    goToSearch() {
      const order = window.location.search.includes('order=') ? `&order=${this.order}` : ''
      window.location.href = `${this.url}?search=${this.localSearch}${order}`
    },

  },
}
</script>

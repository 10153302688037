<script>

import { checkCartStock } from '@api/cart.service'

import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'StockChecker',
  data() {
    return {
      items: [],
    }
  },
  computed: {
    ...mapState('cart', [
      'stock',
    ]),
    ...mapGetters('cart', [
      'cartIsValid',
    ]),
    isValid() {
      return this.stock.every(e => e.is_valid)
    },
  },
  watch: {
    isValid: {
      handler(nval) {
        this.$emit('cartValidation', nval)
      },
      immediate: true,
    },
  },
  async created() {
    this.getStockData()
  },
  methods: {
    ...mapActions('cart', [
      'getStockData',
    ]),
    async getList() {
      try {
        const { data } = (await checkCartStock.execute())
      } catch (e) {
        console.log(e);
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      isValid: this.cartIsValid,
    })
  },
}
</script>

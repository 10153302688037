<template lang="pug">
include /mixins
div
  .is-visible-xl
    ui-accordion(
      :init='false',
      :is-calculate-height='false'
    )
      template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
        ui-click-outside(
          :do='collapse'
        )
          +b.switcher-with-dropdown
            +e.current(
              @click.prevent='toggleActive()'
              v-if='selectedCurrency && list.length > 1'
            )
              +e.link.ds-caption--size_sm.--appearance_uppercase.SPAN {{ selectedCurrency.code }}
              +e.icon.mdi.I(
                v-if='list.length > 1'
                :class='icon'
              )
            transition(name='slidedown')
              +e.options(
                v-show="activeState"
                :class='classname'
              )
                +e.option(
                  v-for='currency in list'
                  @click='selectCurrency(currency, toggleActive)'
                  v-if='currency.code !== selectedCurrency.code'
                )
                  +e.link.ds-caption--size_sm.--appearance_uppercase.SPAN(
                  ) {{ currency.code }}
  .is-disable-xl
    +b.native-changer-select-wrapper
      +b.native-changer-select.SELECT(
        @change='select'
        v-model='selectedCurrency'
        v-if='list.length > 1'
      )
        +e.option.OPTION(
          v-for='currency in list'
          :key='currency.code'
          :value='currency'
        ) {{ currency.code }}
      ui-loader(v-if='load')
</template>

<script>

import {
  currencyListResource,
} from '@api/vendor.service'

import {
  getCookie,
} from '@utils/cookies'

import {
  CURRENCY_CODE_COOKIE_NAME,
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

export default {
  name: 'CurrencySwitcher',
  props: ['classname'],
  currencyListResource,
  data() {
    return {
      code: getCookie(CURRENCY_CODE_COOKIE_NAME),
      selectedCurrency: {},
      list: [],
      load: false,
    }
  },
  created() {
    this.getCountries()
  },
  methods: {
    async getCountries() {
      const { data: { available, default: selected } } = (await currencyListResource.execute({
        country_available: getCookie(COUNTRY_CODE_COOKIE_NAME),
      }))
      this.list = available
      this.selectedCurrency = this.list.find(e => e.code === this.code) || selected
    },
    async selectCurrency(currency, action) {
      this.selectedCurrency = currency
      window.location.href = currency.url
      action()
    },
    select() {
      this.load = true
      this.selectCurrency(this.selectedCurrency, () => {})
    },
  },
}
</script>

<script>

import {
  getMenuChildren,
} from '@api/menu.service'

export default {
  name: 'MenuLevelRenderer',
  props: ['itemId', 'state'],
  data() {
    return {
      list: [],
    }
  },
  watch: {
    state: {
      handler(nval) {
        if (nval) {
          if (this.list.length) return
          this.getItems()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // this.getItems()
  },
  methods: {
    async getItems() {
      await getMenuChildren.execute({ id: this.itemId }).then(({ data: { items } }) => {
        if (items.length) {
          this.list = items
        }
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      list: this.list,
    })
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('menu-level-renderer',{attrs:{"item-id":_vm.itemId,"state":_vm.date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var list = ref.list;
return [_c('div',_vm._l((list),function(item){return _c('tree-menu-trigger',{key:item.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var triggerChildChild = ref.trigger;
var closeChildChild = ref.close;
var showChildChildItem = ref.showItem;
return [_c('div',[_c('div',{staticClass:"mob-menu-item"},[_c('div',{staticClass:"mob-menu-item__e g-row g-row--justify_between g-row--align_center"},[_c('div',{staticClass:"g-cell g-cols g-cols--narrow"},[_c('a',{staticClass:"mob-menu-item__link tt-link tt-link--variant_start ds-caption ds-caption--size_sm ds-caption--medium ds-caption--color_accent",attrs:{"href":item.props.url,"target":item.props.redirect_policy}},[_c('p',{staticClass:"mob-menu-item__text"},[_vm._v(_vm._s(item.props.title))])])]),(item.props.has_child)?_c('div',{staticClass:"g-cell g-cols g-cols--narrow",on:{"click":triggerChildChild}},[_c('div',{staticClass:"mob-menu-item__trigger mdi mdi-chevron-right"})]):_vm._e()])]),_c('transition',{attrs:{"name":"slide"}},[(showChildChildItem)?_c('div',{staticClass:"mobile-sidebar mobile-sidebar--full"},[_c('a',{staticClass:"parent-mob-link tt-link tt-link--variant_start ds-caption ds-caption--size_md ds-caption--medium",on:{"click":function($event){$event.preventDefault();return closeChildChild($event)}}},[_c('span',{staticClass:"parent-mob-link__left"},[_c('span',{staticClass:"parent-mob-link__icon mdi mdi-chevron-left"}),_c('p',{staticClass:"parent-mob-link__text"},[_vm._v(_vm._s(item.props.title))])]),_c('span',{staticClass:"parent-mob-link__close mdi mdi-close"})]),_c('div',{staticClass:"line-h line-h--light"}),(showChildChildItem)?_c('menu-level-renderer',{attrs:{"item-id":item.id,"state":_vm.date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var childList = ref.list;
return [_c('div',_vm._l((childList),function(el){return _c('a',{staticClass:"mob-menu-item tt-link tt-link--variant_start ds-caption ds-caption--size_sm ds-caption--medium ds-caption--color_accent",attrs:{"href":el.props.url,"target":el.props.redirect_policy}},[_c('p',{staticClass:"mob-menu-item__text"},[_vm._v(_vm._s(el.props.title))])])}),0)]}}],null,true)}):_vm._e()],1):_vm._e()])],1)]}}],null,true)})}),1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
import UiMessageModal from '@app/UiElements/UiMessageModal'

export const AnswerMessage = (meta, $this) => {
  if (meta.message && (meta.message.title || meta.message.text)) {
    $this.$modal.show(
      UiMessageModal,
      {
        meta,
      }, {
        height: 'auto',
        minWidth: 300,
        maxWidth: 370,
        adaptive: true,
      }
    )
  } else if (meta.redirect && meta.redirect.location) {
    window.location.href = meta.redirect.location
  }
}

<template lang="pug">
  include /mixins
  +b.review-form.tt-space_mt--6(
    v-if='list.length'
  )
    +b.media-row()
      +e.title.H5 {{ _('Фотографии и видео покупателей') }}
      +e.content
        +b.slider-box--free.relative
          +b.category-swipe-wrapper.sales-slider--free-xl.--spaced.fake-slider.g-row.--align_center.--space_none.--appearance_nowrap.media-resource(
            data-snap-slider="main-slider"
          )
            +e.cell.g-cell.g-cols--12.--3-md(
              v-for='(el, i) in list',
            )
              +b.ds-aspect-ratio--appearance_filled.--ratio_16x9.FIGURE(
              )
                +e.body
                  +b.media-preview(
                    @click='show(i)'
                    v-if='el.props.img'
                  )
                    +e.img.IMG(
                      :src='el.props.img'
                    )
                  +b.media-preview(
                    v-else
                  )
                    ui-youtube-view(
                      :url='el.props.url'
                    )
                      template(#default='{ play, preview }')
                        +e.mask(
                          @click='show(i)'
                        )
                          +e.img.IMG(
                            :src='preview'
                          )
                          +e.play.icon-play
</template>

<script>

import {
  ReviewMediaList,
} from '@api/review.service'

export default {
  name: 'ReviewsMedia',
  props: ['id', 'type', 'product_type', 'product_id', 'title', 'info'],
  data() {
    return {
      list: [],
      timestamp: new Date(),
    }
  },
  created() {
    this.getList()
  },
  methods: {
    photoMap() {
      return this.list.map(el => el.props.img)
    },
    show(index) {
      this.$modal.show(
        () => import('@app/Review-v2/MediaDetail'),
        {
          info: this.info,
          list: this.list,
          index,
          product_type: this.product_type,
          product_id: this.product_id,
          title: this.title,
        },
        {
          height: 'auto',
          classes: 'extralarge-modal',
          adaptive: true,
          scrollable: true,
        }
      )
    },
    async getList() {
      const media = ['photos', 'videos']
      media.forEach(async key => {
        const { data: { items } } = (await ReviewMediaList.execute({ key, type: this.type, id: this.id }))
        this.list.push(...items)
      })
    },
  },
}
</script>

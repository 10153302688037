import {
  route,
} from '@app/Routing/helpers'

const BonusesList = () => import('@app/Staff/Bonuses/List')
const BonusesRules = () => import('@app/Staff/Bonuses/Rules')

const MODEL = 'bonuses'

const BASE = `${MODEL}`
const u = url => BASE + url

const LIST = u('/')
const RULES = u('/rules/')

export const BONUSES_PREFIX = `${MODEL}:`
const p = name => BONUSES_PREFIX + name

export const BONUSES_BASE_NAME = p('base')
export const BONUSES_LIST_NAME = p('list')
export const BONUSES_RULES_NAME = p('rules')

export function createRoutes() {
  return [
    route(LIST, BonusesList, BONUSES_LIST_NAME, {
      meta: { hideVisitCard: true },
    }),
    route(RULES, BonusesRules, BONUSES_RULES_NAME, {
      meta: { hideVisitCard: true },
    }),
  ]
}

export default {
  bind(element, binding) {
    const tags = binding.value.split('::')
    let innerText = element.innerHTML
    tags.forEach(tag => {
      const replacer = tag
      const expression = new RegExp(replacer, "gi")
      innerText = innerText.replaceAll(expression, (match) => `<mark>${match}</mark>`)
    })
    element.innerHTML = innerText
  },
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inliner-wrapper"},[_c('div',{staticClass:"tt-space_mt tt-space_mt--3 tt-space_mb tt-space_mb--3"},[(_vm.list.length)?_c('div',{staticClass:"calc-inliner-row calc-inliner-row--visible-sm"},[_c('div',{staticClass:"calc-inliner-cell calc-inliner-cell--7",attrs:{"cols":"7 8-xl"}},[_c('p',{staticClass:"ds-caption ds-caption--size_sm ds-caption--bold"},[_vm._v(_vm._s(_vm._('Наименование продуктов')))])]),_c('div',{staticClass:"calc-inliner-cell calc-inliner-cell--4",attrs:{"cols":"3"}},[_c('p',{staticClass:"ds-caption ds-caption--size_sm ds-caption--bold"},[_vm._v(_vm._s(_vm._('Расход на процедуру')))])]),_c('div',{staticClass:"calc-inliner-cell calc-inliner-cell--1",attrs:{"cols":"2 1-xl"}})]):_vm._e()]),_vm._l((_vm.list),function(el,i){return _c('validation-observer',{key:("form" + i),ref:("validator" + i),refInFor:true,attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(_vm.isLoad)?_c('ui-loader'):_vm._e(),_c('div',{staticClass:"tt-space_mt tt-space_mt--3 tt-space_mb tt-space_mb--3"},[_c('div',{staticClass:"calc-inliner-row relative"},[_vm._l((_vm.mainFields),function(ref){
var name = ref.name;
var label = ref.label;
var type = ref.type;
var classname = ref.classname;
var inputtype = ref.inputtype;
var cols = ref.cols;
var props = ref.props;
var events = ref.events;
var mask = ref.mask;
var appearance = ref.appearance;
var afterList = ref.afterList;
return _c('div',{key:("" + type + name + i + (el.id) + "_main"),staticClass:"calc-inliner-cell",class:("calc-inliner-cell--" + cols)},[_c('tag',_vm._g(_vm._b({ref:("" + inputtype + name + i + (el.id) + "_main"),refInFor:true,class:classname,attrs:{"tag":type || "d-control-input","input-label":label,"type":inputtype || "text","id":("" + inputtype + name + i + (el.id) + "_main"),"name":name,"disabled":!!(type && el.id)},on:{"input":function($event){return _vm.recalcAdditional(el)}},scopedSlots:_vm._u([(afterList)?{key:"afterList",fn:function(){return [_c('div',[(props.options.length > 50 || props.pagination.next )?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (visible) { return afterList(visible); }),expression:"visible => afterList(visible)"}]}):_vm._e()])]},proxy:true}:null,{key:"option",fn:function(ref){
var option = ref.option;
return [(option.props && !el.id)?_c('product-variant-option',{attrs:{"option":option}}):_vm._e(),(option.props && el.id)?_c('div',{staticClass:"ds-caption"},[_vm._v(_vm._s(option.props.title))]):_vm._e()]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.props && !el.id)?_c('product-variant-option',{attrs:{"option":option}}):_vm._e(),(option.props && el.id)?_c('div',{staticClass:"ds-caption"},[_vm._v(_vm._s(option.props.title))]):_vm._e(),(!option.props)?_c('span',{staticClass:"multiselect__placeholder"},[_vm._v(_vm._s(_vm._('Выберите продукт')))]):_vm._e()]}}],null,true),model:{value:(el[name]),callback:function ($$v) {_vm.$set(el, name, $$v)},expression:"el[name]"}},'tag',props,false),events))],1)}),_c('div',{staticClass:"calc-inliner-cell calc-inliner-cell--1"},[(_vm.list.length > 1 && !el.id)?_c('button',{staticClass:"inliner-btn inliner-btn--grey",attrs:{"title":_vm._("Удалить")},on:{"click":function($event){$event.preventDefault();return _vm.remove(i)}}},[_c('p',{staticClass:"inliner-btn__title mdi mdi-close"})]):_vm._e()])],2),_c('div',{staticClass:"calc-child-wrapper"},_vm._l((el.additional_items),function(child,childid){return _c('div',{key:("child_" + i + "_" + childid + "_form"),attrs:{"cols":"12"}},[_c('div',{staticClass:"tt-space_mt tt-space_mt--3 tt-space_mb tt-space_mb--3"},[_c('div',{staticClass:"calc-inliner-row calc-inliner-row--end"},[_vm._l((_vm.mainFields),function(ref){
var name = ref.name;
var label = ref.label;
var type = ref.type;
var classname = ref.classname;
var inputtype = ref.inputtype;
var cols = ref.cols;
var props = ref.props;
var events = ref.events;
var mask = ref.mask;
var appearance = ref.appearance;
var afterList = ref.afterList;
return _c('div',{key:("" + type + name + i + childid + "_childcell"),staticClass:"calc-inliner-cell",class:("calc-inliner-cell--" + cols)},[_c('tag',_vm._g(_vm._b({ref:("" + inputtype + name + i + "_child" + childid),refInFor:true,class:classname,attrs:{"tag":type || "d-control-input","input-label":label,"type":inputtype || "text","id":("" + inputtype + name + i + "_child" + childid),"name":("child_" + name),"disabled":!!(!type || type && el.id)},on:{"input":function($event){return _vm.$emit("update", _vm.list)}},scopedSlots:_vm._u([(afterList)?{key:"afterList",fn:function(){return [_c('div',[(props.options.length > 50 || props.pagination.next )?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(function (visible) { return afterList(visible); }),expression:"visible => afterList(visible)"}]}):_vm._e()])]},proxy:true}:null,{key:"option",fn:function(ref){
var option = ref.option;
return [(option.props && !el.id)?_c('product-variant-option',{attrs:{"option":option}}):_vm._e(),(option.props && el.id)?_c('div',{staticClass:"ds-caption"},[_vm._v(_vm._s(option.props.title))]):_vm._e()]}},{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [(option.props && !el.id)?_c('product-variant-option',{attrs:{"option":option}}):_vm._e(),(option.props && el.id)?_c('div',{staticClass:"ds-caption"},[_vm._v(_vm._s(option.props.title))]):_vm._e(),(!option.props)?_c('span',{staticClass:"multiselect__placeholder"},[_vm._v(_vm._s(_vm._('Выберите продукт')))]):_vm._e()]}}],null,true),model:{value:(child[name]),callback:function ($$v) {_vm.$set(child, name, $$v)},expression:"child[name]"}},'tag',props,false),events))],1)}),_c('div',{staticClass:"calc-inliner-cell calc-inliner-cell--disabled"},[_c('p',{staticClass:"ds-caption ds-caption--size_xs ds-caption--bold"})]),_c('div',{staticClass:"calc-inliner-cell calc-inliner-cell--1"})],2)])])}),0)]),_c('div',{staticClass:"line-h line-h--light"})]}}],null,true)})}),(_vm.limit > _vm.list.length)?_c('div',{staticClass:"g-row g-row--justify_start"},[_c('div',{staticClass:"g-cell g-cols g-cols--narrow"},[_c('button',{staticClass:"ds-caption ds-caption--size_sm tt-link tt-link--variant_start tt-link--styling_default",on:{"click":function($event){$event.preventDefault();return _vm.addFields($event)}}},[_vm._v(" "+_vm._s(_vm.btnText || _vm._('Добавить товар')))])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
export default function accordion() {
  const accordions = [...document.getElementsByClassName('js-accordion')]
  accordions.forEach(e => {
    const triggers = [...e.getElementsByClassName('js-accordion-trigger')]
    const content = [...e.getElementsByClassName('js-accordion-content')]
    let show = false
    triggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        const triggersIcons = [...trigger.getElementsByClassName('js-accordion-trigger-icon')]
        content.forEach(c => {
          if (c.firstChild.__vue__ && c.firstChild.__vue__.$refs && c.firstChild.__vue__.$refs.renderer) {
            c.firstChild.__vue__.$refs.renderer.getItems()
          }
          c.classList.toggle('is-active')
        })
        show = !show
        triggersIcons.forEach(i => i.classList.remove(show ? i.dataset.plus : i.dataset.minus))
        triggersIcons.forEach(i => i.classList.add(show ? i.dataset.minus : i.dataset.plus))
      })
    })
  })
}

import Swipe from './swipe'

const innerContentScroller = (direction, distance, box) => {
  if ('left' === direction) {
    box.scrollLeft += distance
  } else if ('right' === direction) {
    box.scrollLeft -= distance
  }
}

const ZERO = 0

export default function sliderControl(className = 'slider-box') {
  if (
    navigator.maxTouchPoints > ZERO ||
    navigator.msMaxTouchPoints > ZERO
  ) return
  const boxes = [...document.getElementsByClassName(className)];
  boxes.forEach(parent => {
    // SWIPE INITIALIZATION
    const [box] = parent.getElementsByClassName('fake-slider')
    const swipe = new Swipe(box, {
      corners: true,
      minDistance: 200,
    });
    const liveEvent = swipe.addEventListener('live', (direction, distance) => {
      // runAnimation(direction);
      innerContentScroller(direction, distance, box)
    });
    const [controls] = parent.getElementsByClassName('snap-controls')

    if (controls) {
      const arrows = [...controls.children]
      const [prev, next] = arrows
      arrows.forEach(arr => {
        const direction = arr.dataset.snapSliderGoto
        const distance = box.firstChild.clientWidth
        arr.addEventListener('click', () => {
          arrows.forEach(arrow => arrow.classList.remove('is-disabled'))
          innerContentScroller(direction, distance, box)
          setTimeout(() => {
            if (!box.scrollLeft) {
              prev.classList.add('is-disabled')
            }
            if (box.scrollLeft + box.clientWidth >= (box.scrollWidth - 60)) {
              next.classList.add('is-disabled')
            }
          }, 500)
        })
        setTimeout(() => {
          if (box.scrollLeft + box.clientWidth >= (box.scrollWidth - 65)) {
            if (next) next.classList.add('is-disabled')
            return
          }
          if (next) next.classList.remove('is-disabled')
        })
      })
    }
  })
}

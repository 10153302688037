<script>
import {
  DeleteSubscription,
} from '@api/feedback.service'

import { confirmWithPromise } from '@utils/helpers'

import {
  mapActions,
} from 'vuex'

export default {
  name: 'BrandUnsubscription',
  props: ['brand'],
  methods: {
    ...mapActions('brand', [
      'brandSubscriptionIdsList',
    ]),
    async destroySubscription() {
      const question = this._('Вы действительно хотите отказаться от подписки на бренд?')
      try {
        await confirmWithPromise(question, this)

        await DeleteSubscription.execute({ id: this.brand }, {})
        this.brandSubscriptionIdsList()
      } catch (e) {
        throw new Error(e)
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      destroy: () => this.destroySubscription(),
    })
  },
}
</script>

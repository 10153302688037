import {
  getRedirectResult,
} from 'firebase/auth';

import { firebaseAuthRequest, firebaseCreateConnectRequest } from '@api/auth.service'
import { toggleLoader } from '@/js/firebase/helpers'
import {
  AnswerMessage,
} from '@utils/submit'

export function handleAppleRedirectResult(auth) {
  // Result from Redirect auth flow.
  console.log(auth);
  getRedirectResult(auth)
    .then(async result => {
      toggleLoader(!!result)
      if (!result) {
        setTimeout(() => {
          toggleLoader(false)
        }, 5000)
        return
      }
      try {
        const { user } = result;
        if (window.authent) {
          await firebaseCreateConnectRequest.execute({}, { token: user.accessToken })
          window.location.reload()
        } else {
          await firebaseAuthRequest.execute({}, { token: user.accessToken })
          window.location.href = window.indexPage
        }
      } catch (e) {
        e.json().then(body => {
          const errors = {}
          body.errors.forEach(e => {
            if ('request' === e.domain && e.state) {
              Object.assign(errors, e.state)
            }
          })
          const meta = {
            message: {
              title: errors.non_field_errors[0].message,
            },
          }
          AnswerMessage(meta, window.app)
          toggleLoader(false)
          setTimeout(() => {
            toggleLoader(false)
          }, 2000)
        })
      }
    })
    .catch(error => {
      // Handle Errors here.
      const errorCode = error.code
      if ('auth/user-cancelled' === errorCode) {
        const meta = {
          message: {
            title: window.app._('Ви перервали процес авторизації. Дочекайтеся завершення і перенаправлення на сайт.'),
          },
        }
        AnswerMessage(meta, window.app)
        toggleLoader(false)
        setTimeout(() => {
          toggleLoader(false)
        }, 2000)
      }
    });
}

<template lang="pug">
include /mixins
+b.ds-panel--space_2xl-xl
  +b.close-popup.mdi.mdi-close.BUTTON(
    @click.prevent='$emit("close")'
  )
  +e.element--offset_full
    +b.spin-360
      +e.view
        vue-product-360(
          :images='imgs'
        )
          p Loading
      +e.previews
        row(appearance='spaced')
          cell(
            v-for='(item, idx) in images'
            :key="idx"
            cols
          )
            +e.preview(
              :class='{ "is-active": index == idx }'
              @click='setCurrent(idx)'
            )
              +e.img.IMG(
                :src='item.props.images[0].img'
              )
</template>

<script>
import {
  variantSpinResource,
} from '@api/catalog.service'

import VueProduct360 from '@deviznet/vue-product-360'

export default {
  name: 'ImageSpin',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    VueProduct360,
  },
  data() {
    return {
      images: [],
      index: 0,
      current: {},
    }
  },
  computed: {
    imgs() {
      return this.current.props && this.current.props.images.map(e => e.img)
    },
  },
  mounted() {
    this.getImages()
  },
  methods: {
    getImages() {
      variantSpinResource.execute({ id: this.id })
        .then(response => {
          this.images = response.data.items
          const [first] = this.images
          this.current = first
        })
        .catch(error => {
          console.log(error)
        })
    },
    setCurrent(index) {
      this.index = index
      this.current = this.images[index]
    },
  },
}
</script>

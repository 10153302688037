<template lang="pug">
  include /mixins
  +b.img-load-view--free
    +e.img-wrapper
      +b.close-popup(@click='removeItem(idx)', v-if="canDelete")
        +e.icon.mdi.mdi-close
      +e.img--small.IMG(
        :src='icon && icon.props.icon'
        v-if='!item.err'
      )
      +b.mask-img.LABEL(
        :class="{'mask-img--with-bg': item.err}"
        @click.prevent='openPdf'
      )
        +e.item.IMG(v-if='item.err', src='/static/img/error.svg')
    //- label.error-label(v-if='item.err') {{ item.err }}
    +b.progress-bar(v-if='!item.err')
      +e.basic.SPAN
      +e.status.SPAN(:style="{'width' : item.progressBarWidth + '%'}")
        .shadow
</template>
<script>

const EXTENTIONS_MAP = {
  'application/pdf': 'PDF',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC',
  'text/plain': 'TXT',
  'application/msword': 'DOC',
  'application/rtf': 'RTL',
  'application/vnd.oasis.opendocument.text': 'ODT',
}

export default {
  name: 'PdfViewer',
  props: {
    extentions: {
      type: Array,
      default: () => [],
    },
    item: Object,
    idx: Number,
    filekey: { default: 'img' },
    canDelete: { type: Boolean, default: true },
  },
  data() {
    return {
    }
  },
  computed: {
    icon() {
      return (this.extentions || []).find(e => e.caption === EXTENTIONS_MAP[this.item.type]) || this.extentions[0]
    },
  },
  methods: {
    getIcon() {
      return (this.extentions || []).find(e => e.caption === EXTENTIONS_MAP[this.item.type]) || this.extentions[0]
    },
    removeItem(idx) {
      this.$emit('removeItem', idx)
    },
    openPdf() {
      window.open(this.item[this.filekey], { target: '_blank' });
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  div
    slot()
      +b.circle-btn.A(
        :href='$options.url'
      )
        +e.icon.icon-like.SPAN
        +e.counter.SPAN {{ count }}
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex'

export default {
  name: 'FavoritePreview',
  // url: `${window.baseUrl}filters/favorite=true/`,
  url: `${window.profileUrl}favorites/`,
  computed: {
    ...mapState('like', [
      'count',
    ]),
  },
  created() {
    this.getFavoritesList()
  },
  methods: {
    ...mapActions('like', [
      'getFavoritesList',
    ]),
  },

}
</script>

<template lang="pug">
  a(
    @click='$emit("click")'
  ) {{ text }}
</template>
<script>
export default {
  name: 'UiLink',
  props: ['text'],
}
</script>

<template lang="pug">
  include /mixins
  div
    d-control-multiselect(
      :options='list'
      :input-label='filter.attribute.title'
      :custom-label='getLabel'
      v-model='checked'
      trackBy='id'
      @input='submit()'
      :multiple='true'
    )
      //- template(v-for='val in filter.attribute.values')
      //-   +b.tt-space_mt--3()
      //-     +b.d-control-input.--appearance_flexible(
      //-       v-if='!val.hasOwnProperty("is_valid") || val.is_valid'
      //-     )
      //-       +e.element.INPUT(
      //-         type='checkbox'
      //-         :value='val.props.label',
      //-         :name='filter.attribute.label'
      //-         v-model='checked'
      //-         :input-label='val.props.title',
      //-         :id='`${filter.attribute.label}:${val.id}`'
      //-         :disabled='!isAvailable(val.id, val.props.label)'
      //-         @change='submit()'
      //-       )
      //-       +e.LABEL.label--for-checkbox(
      //-         :for='`${filter.attribute.label}:${val.id}`'
      //-       ) {{ val.props.title }}
      //-       // for available filters debug
      //-       //- span(style='color: red') {{ isAvailable(val.id, val.props.label) }} {{ hasActive(val.props.label) }}
</template>
<script>
import pathOr from 'ramda/src/pathOr'

const LABEL_MAP = {
  country: [['props', 'title']],
  city: [window.language.includes('ru') ? ['props', 'description_ru'] : ['props', 'description']],
  position: [['props', 'title']],
  tag: [['props', 'title']],
}

export default {
  name: 'CheckboxRenderer',
  props: ['value', 'filter', 'available'],
  data() {
    return {
      checked: [],
    }
  },
  computed: {
    list() {
      return this.filter.attribute.values.reduce((acc, obj) => {
        acc = acc || []
        obj.$isDisabled = !this.isAvailable(obj.id, obj.props.label)
        console.log(obj.$isDisabled);
        acc.push(obj)
        return acc
      }, [])
    },
  },
  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },
  methods: {
    getLabel(object) {
      const l = LABEL_MAP[this.filter.attribute.label]
      if (l) {
        const gh = l.reduce((acc, obj) => {
          acc.push(pathOr('', obj, object))
          return acc
        }, [])
        return gh.join(' ')
      }
      return object
    },
    submit() {
      console.log(this.checked);
      this.$emit('input', this.checked.map(e => e.props.label))
    },
    isAvailable(id, label) {
      const customFilter = this.available[this.filter.attribute.label]
      if (customFilter) {
        const a = customFilter.find(el => el == id)
        if (!a && this.hasActive(label)) return true
        return a
      }
      return true
      // const a = this.available.find(el => el == id)
      // console.log(a, '---');
      // if (!a && this.hasActive(label)) return true
      // return a
    },
    hasActive(val) {
      return this.checked.find(e => e.props.label === val)
    },
    normalize(value) {
      if (!value) return []
      const checked = this.filter.attribute.values.filter(el => value.find(v => v == el.props.label))
      return checked
    },
  },
}
</script>

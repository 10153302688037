<template lang="pug">
  error-component(
    :message='displayMessage'
    :has-error='hasError'
    v-if='displayMessage'
  )
    p {{ message }}
</template>

<script>
export default {
  name: 'otp-error-message',

  props: {
    message: String,
    hasError: Boolean,
  },

  computed: {
    displayMessage() {
      return this.message ? this.message : '';
    },
  },
}
</script>

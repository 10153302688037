<template lang="pug">
  include /mixins
  +b.share-by-sms--free
    ui-click-outside(
      :do='close'
    )
      div
        +e.btn.A(
          @click.prevent='open'
        )
          +e.icon.icon-share
          +e.title.P {{ _('Поделиться') }}
        transition(name="slidedown")
          +e.dropdown(v-if='show')
            row(
              align="center"
              justify="between"
              appearance='nowrap'
            )
              cell(v-for='{ network, icon, title }, index in networks' :key='index')
                share-network.share-wrapper(
                  :url="shareUrl"
                  :network='network'
                  :title="shareTitle"
                  :description="shareDescription"

                )
                  +b.social-link.A(
                    :class="'icon-' + icon"
                    :title="title"
                  )
</template>

<script>

const ZERO = 0

export default {
  name: 'ShareBtn',
  props: {
    shareUrl: String,
    shareTitle: String,
    shareDescription: String,
  },
  data() {
    return {
      show: false,
      networks: [
        { icon: 'viber', network: 'viber', title: this._('Viber') },
        { icon: 'telegram', network: 'telegram', title: this._('Telegram') },
        { icon: 'facebook', network: 'facebook', title: this._('Facebook') },
        { icon: 'letter', network: 'email', title: this._('Email') },
      ],
    }
  },
  methods: {
    async open() {
      if (
        (navigator.maxTouchPoints > ZERO ||
        navigator.msMaxTouchPoints > ZERO) &&
        navigator.share
      ) {
        navigator.share({
          url: this.shareUrl,
          title: this.shareTitle,
          text: this.shareDescription,
        })
        return
      }
      this.show = !this.show
    },
    close() {
      this.show = false
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dustributor-catalog"},[_c('paginated-resource-controller',{ref:"controller",attrs:{"resource":_vm.$options.distributorCaralogResource,"parameters":_vm.parameters,"changeParameters":_vm.changeParameters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pagination = ref.pagination;
var changePagination = ref.changePagination;
var changeFilters = ref.changeFilters;
var filters = ref.filters;
var receive = ref.receive;
var result = ref.result;
var loading = ref.loading;
return [_c('div',{staticClass:"relative"},[(loading)?_c('ui-loader'):_vm._e(),_c('div',{staticClass:"dustributor-catalog__part"},[_c('distributor-sidebar',{attrs:{"changeFilters":changeFilters,"filters":filters,"receive":receive},on:{"update":function($event){_vm.parameters = $event}}})],1),_c('div',{staticClass:"dustributor-catalog__part"},[_c('p',{staticClass:"ds-caption ds-caption--variant_base"},[_vm._v(_vm._s(_vm._('Найдено'))+" "+_vm._s(pagination.total)+" "+_vm._s(_vm._('товаров')))])]),_c('div',{staticClass:"dustributor-catalog__part"},[_c('distributor-table',{attrs:{"table-fields":_vm.tableFields,"list":result && result.items,"truncate":_vm.truncate}}),_c('ui-limit-pagination',{attrs:{"pagination":pagination,"need-add-btns":true},on:{"hook:created":receive,"showAll":function($event){return _vm.handlePaginate($event, changePagination, pagination)},"input":function($event){return _vm.handlePaginate($event, changePagination, pagination)}}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
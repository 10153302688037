<template>
  <component
    :is="loaderController"
    :resource="resource"
    :immediate='immediate'
    :key='$attrs.loaderKey'
    v-slot="{ receive, loading, result }"
    @gotIt="$emit('gotIt')"
  >
    <component
      :is="paginationController"
      :resultPagination="result && result.pagination"
      :key='$attrs.paginationKey'
      :receiver="receive"
      :parameters="parameters"
      :label='label'
      ref="paginationController"
      @update:parameters="changeParameters"
      v-slot="{ filters, changeFilters, pagination, changePagination }"
    >
      <slot
        :parameters="parameters"
        :filters="filters"
        :changeFilters="changeFilters"
        :pagination="pagination"
        :changePagination="changePagination"
        :receive="receive"
        :loading="loading"
        :result="result"
      />
    </component>
  </component>
</template>

<script>

export default {
  name: 'paginated-resource-controller',

  props: {

    resource: {},
    parameters: {},
    changeParameters: Function,
    loaderController: {
      default: 'resource-loader-controller',
    },
    paginationController: {
      default: 'pagination-controller',
    },
    label: {},
    immediate: Boolean,
  },
}

</script>

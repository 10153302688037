const zero = 0

export const truncate = (text, stop, clamp) => text.slice(zero, stop) + (stop < text.length ? clamp || '...' : '')

const BASE_SIZE = 2
export const dateTime = text => (text.toString().length == BASE_SIZE ? text : `${zero}${text}`)

export const install = Vue => {
  Vue.filter('truncate', truncate)
  Vue.filter('dateTime', dateTime)
}

export default { install }

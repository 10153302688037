import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const ORDER_MODEL = 'orders'
const NOVA_POSHTA_MODEL = 'novaposhta'
const ORGANIZATION_MODEL = 'organization'

const PAYMENT_STATUSES_LIST = prefixAPI('joint-orders/payment-statuses/list/')
const ORDER_SPECIAL_OFFER_OPTION_LIST = prefixAPI('special-offer-order-option/list/')
const ORDER_STATUSES_LIST = prefixAPI('joint-orders/statuses/list/')
const SHIPPING_SERVICES_LINK = prefixAPI('/list/{?settlements,country_available,for_order,for_preorder}', 'shipping-services')
const CHECK_FREE_SHIPPING_LINK = prefixAPI('/price-data/', 'shipping-services')
const PAYMENT_GATEWAYS_LINK = prefixAPI('/list/{?shipping_services,available_for_preorder}', 'payments/gateways')

export const BASE_NOVAPOSHTA_CITIES_LINK = prefixAPI('/settlements/list/', NOVA_POSHTA_MODEL)
const NOVAPOSHTA_CITIES_LINK = prefixAPI('/settlements/list/{?search,offset,country_available__iso_code}', NOVA_POSHTA_MODEL)
const NOVAPOSHTA_WAREHOUSES_LINK = prefixAPI('/warehouses/list/{?search,offset,city,country_available,is_not_available,shipping_service}', NOVA_POSHTA_MODEL)
const NOVAPOSHTA_STREETS_LINK = prefixAPI('/streets/list/{?search,city,offset}', NOVA_POSHTA_MODEL)

const ORDER_CREATE_URL = prefixAPI('/create/', ORDER_MODEL)
const PREORDER_CREATE_URL = prefixAPI('preorders/create/')
const ORDER_ONE_CLICK_URL = prefixAPI('/one-click/', ORDER_MODEL)

const ORDER_BONUSES_URL = prefixAPI('/bonus-validate/', ORDER_MODEL)

const USER_PHONE_GET_CONFIRMATION_CODE_LINK = prefixAPI('/phone-confirmer/send-confirmation/', ORDER_MODEL)
const USER_PHONE_CONFIRMATION_LINK = prefixAPI('/phone-confirmer/confirm/', ORDER_MODEL)

const REUISITES_LINK = prefixAPI('/requisites/list/', ORGANIZATION_MODEL)

export const OrderCreate = createResource(ORDER_CREATE_URL, postResource)
export const PreOrderCreate = createResource(PREORDER_CREATE_URL, postResource)
export const OneClickOrderCreate = createResource(
  ORDER_ONE_CLICK_URL, postResource
)

export const PhoneConfirmationResource = createResource(
  USER_PHONE_CONFIRMATION_LINK, postResource
)
export const GetPhoneConfirmationStateResource = createResource(
  USER_PHONE_GET_CONFIRMATION_CODE_LINK, postResource
)

export const getShipping = createResource(SHIPPING_SERVICES_LINK)
export const getPayments = createResource(PAYMENT_GATEWAYS_LINK)

export const getNovaposhtaCities = createResource(NOVAPOSHTA_CITIES_LINK)
export const getNovaposhtaWarehouses = createResource(
  NOVAPOSHTA_WAREHOUSES_LINK
)
export const getNovaposhtaStreets = createResource(
  NOVAPOSHTA_STREETS_LINK
)
export const getOrderPaymentStatuses = createResource(
  PAYMENT_STATUSES_LIST
)
export const getOrderStatuses = createResource(
  ORDER_STATUSES_LIST
)
export const getOrderSpecialOfferList = createResource(
  ORDER_SPECIAL_OFFER_OPTION_LIST
)
export const getRequisitesList = createResource(
  REUISITES_LINK
)
export const applyBonuses = createResource(
  ORDER_BONUSES_URL, postResource
)
export const checkFreeShipping = createResource(
  CHECK_FREE_SHIPPING_LINK, postResource
)

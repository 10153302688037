<template lang="pug">
  include /mixins
  date-range-picker(
    ref='picker',
    autocomplete='off'
    :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy', applyLabel, ...locale }"
    :autoApply="false"
    :ranges='false'
    :single-date-picker='"range"'
    v-bind='params'
    @update="updateValues"
    @clear="clear"
    v-model="dateRange"
  )
  //- :ranges='availableRanges'
</template>
<script>
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DateRangePicker from 'vue2-daterange-picker/src/components/DateRangePicker.vue'
import { isNil } from 'ramda'
import { format } from 'date-fns'

const LOCALE_DATA = {
  ru: {
    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  },
  uk: {
    daysOfWeek: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'],
  },
  pl: {
    daysOfWeek: ['Niedz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob'],
    monthNames: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],

  },
  cs: {
    daysOfWeek: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
    monthNames: ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čvn', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro'],
  },
  sk: {
    daysOfWeek: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pia', 'So'],
    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

  },
  en: {
    daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  },
  'ru-md': {
    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],

  },
  'ru-uz': {
    daysOfWeek: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],

  },
  'cs-sk': {
    daysOfWeek: ['Ne', 'Po', 'Ut', 'St', 'Št', 'Pia', 'So'],
    monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],

  },
  ro: {
    daysOfWeek: ['Dum', 'Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm'],
    monthNames: ['Ian', 'Feb', 'Mar', 'Apr', 'Mai', 'Iun', 'Iul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  },

}

export default {
  name: 'UiDateRangePicker',
  components: {
    DateRangePicker,
  },
  props: {
    params: {
      type: Object,
      default: () => ({}),
    },
    prepare: {
      type: Function,
    },
    range: {},
    label: {
      type: String,
    },
    slug: {
      type: String,
    },
    reset: {

    },
    value: {
      default: () => ([]),
    },
  },
  data() {
    return {
      dateRange: {},
      locale: LOCALE_DATA[window.language],
      applyLabel: this._('Применить'),
    }
  },
  watch: {
    reset(nval) {
      if (nval) {
        this.dateRange = ''
      }
    },
    value: {
      handler() {
        let v = this.value
        if (!v) v = [undefined, undefined]
        try {
          if (v.some(e => isNil(e))) {
            this.dateRange = {
              startDate: null,
              endDate: null,
            }
            return
          }
          const [startDate, endDate] = v
          const newStartDate = new Date(this.prepare(startDate))
          const newEndDate = new Date(this.prepare(endDate))

          const isReversed = newStartDate > newEndDate

          this.dateRange = {
            endDate: isReversed ? newStartDate : newEndDate,
            startDate: isReversed ? newEndDate : newStartDate,
          }
        } catch (e) {
          console.log(e);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleChange(values) {
      this.$emit('input', values)
    },
    clear() {
      this.dateRange = {}
    },
    formatter(val) {
      return format(val, 'dd.LL.yyyy')
    },
    updateValues() {
      const arr = []
      const keys = Object.keys(this.dateRange).reverse()
      keys.forEach(el => {
        const mom2 = this.formatter(this.dateRange[el])
        if ('Invalid date' !== mom2) {
          arr.push(mom2)
        } else {
          arr.push('')
        }
      })
      this.$emit('input', arr)
    },
  },

}
</script>

<template lang="pug">
  include /mixins
  +b.recipe-view
    +e.elements
      +b.g-row
        +b.g-cell.g-cols--12.--6-md
          product(
            v-for='{ id, props } in recipe.lines'
            :key='id',
            :product='props',
            :percents='percents'
            @update='update($event)',
            @removeFromRecipe='prepareDestroy'
          )
        +b.g-cell.g-cols--12.--6-md
          .relative
            +b.line-h--light
            +b.tt-space_pb--4.tt-space_pt--4.ROW(justify='end')
              cell(cols='12')
                //- +b.line-h--light
                +b.ROW(
                  justify='between'
                )
                  cell(
                    cols='narrow'
                  )
                    +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Сумма рецепта:') }}
                  cell(
                    cols='narrow'
                  )
                    +b.ds-caption--size_sm.--color_grey.--semibold.P {{ recipe.total_price }} {{ currency }}
            +b.line-h--light
            +b.tt-space_pb--4.tt-space_pt--4.ROW(
              justify='end',
              tag='validation-observer',
              ref="validator",
              :slim='true'
              v-slot="{ valid }",
            )
              cell(cols='12')
                phone-input(
                  :rules="'required|phone'",
                  :input-label='_("Номер телефона клиента")'
                  v-model='form.customer_phone'
                  id='customer_phone',
                  name='customer_phone',
                )
              cell(cols='12')
                +b.tt-button.BUTTON.tt-space_mt--4(
                  @click.prevent='prepareData(valid)'
                )
                  +e.title.P {{ _('Отправить рецепт') }}
            +b.line-h--light
            +b.tt-space_pb--4.tt-space_pt--4
              +b.ds-caption--appearance_center.P {{ _('ИЛИ') }}
            +b.tt-space_pb--4.tt-space_pt--4.ROW
              cell(cols='12')
                +b.tt-button.tt-space_mt--4.BUTTON(
                  @click.prevent='createCart()'
                )
                  +e.title.P {{ _('Оформить заказ') }}

</template>
<script>

import { mapActions, mapState } from 'vuex'

import {
  retriveDiscounts,
  createCart,
} from '@api/recipe.service'

import {
  confirmWithPromise,
} from '@utils/helpers'

import Product from '@app/Recipe/Product'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'RecipeView',
  components: {
    Product,
  },
  mixins: [FormMixin],
  data() {
    return {
      percents: [],
      currency: window.currency,
      form: {
        customer_phone: '',
      },
    }
  },
  computed: {
    ...mapState('recipe', [
      'recipe',
    ]),
  },
  async created() {
    await this.getDiscounts()
    await this.getRecipe()
  },
  methods: {
    ...mapActions('recipe', [
      'getRecipe',
      'updateRecipe',
      'destroyLine',
      'changeRecipe',
    ]),
    createCart() {
      createCart.execute()
        .then(({ data: { redirect: { location } } }) => window.location.href = location)
    },
    async getDiscounts() {
      const { data: { items } } = (await retriveDiscounts.execute())
      this.percents = items
    },
    update(event) {
      this.updateRecipe(event)
    },
    async prepareDestroy(event) {
      const question = this._('Удалить товар из рецепта?')
      await confirmWithPromise(question, this)
      this.destroyLine(event)
    },
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return this.changeRecipe(data)
        .then(res => {
          // AnswerMessage(res.meta, this)
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

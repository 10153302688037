<template lang="pug">
  include /mixins
  div

    +b.reviews
      +e.title
      div(v-if='list.length')
        review-detail-v2(
          v-for='review in list'
          :review='review'
          :key='review.id'
          @reply='reply'
        )
        +b.ds-panel--space_3xl.UI-LIMIT-PAGINATION(
          :pagination='meta'
          @input='offset = $event.offset; getList()'
        )
</template>

<script>

import {
  ReviewListResoure,
} from '@api/review.service'

import AddReview from '@app/Review-v2/Add'

export default {
  name: 'ReviewList',
  props: {
    product_type: {
      type: String,
    },
    product_id: {
      type: String,
    },
    resource: {
      default: () => ReviewListResoure,
    },
    title: {
      type: String,
    },
    info: {},
  },
  data() {
    return {
      list: {},
      offset: 0,
      limit: 5,
      meta: {},
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        type: this.product_type,
        id: this.product_id,
        offset: this.offset,
        limit: this.limit,
      }
      const { data: { items, pagination } } = (await this.resource
        .execute(params))
      this.list = items
      this.meta = pagination
      if (!this.list.length) {
        const container = document.getElementById('reviews-container')
        if (!container) return
        container.style.display = 'none'
      }
    },
    toPromise(result) {
      if (!result) return Promise.reject()
      return Promise.resolve(result)
    },
    reply(id, parentId, name) {
      const pr = f => this.toPromise(f)
        .then(() => this.getList())
      this.$modal.show(
        AddReview,
        {
          info: {
            ...this.info,
            product_type: this.product_type,
            product_id: this.product_id,
            reply_name: name,
            reply: parentId,
            id,
            field_id: 'field_002',
            ...this.$attrs,
          },
          title: this.title,
          promise: pr,
        }, {
          height: 'auto',
          // classes: ['extralarge-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
</script>

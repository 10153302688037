<template lang="pug" functional>
  include /mixins
  +b.ds-panel--space_md
    +b.close-popup.mdi.mdi-close.A(
      @click='listeners.close'
    )
    +e.element--offset_full
      +b.g-row--align_center.--appearance_column
        //- +b.g-cell.g-cols--narrow.--4-md

        //-   +e.img.IMG(
        //-     src='/static/img/Logo_multi.svg'
        //-   )
        +b.g-cell.g-cols--12.--6-md
          +b.ds-caption--appearance_center.--size_md.--size_lg-xl.--bold {{ parent._('Уже уходите ?') }}
          +b.ds-caption--appearance_center.--size_sm.tt-space_mt--5.tt-space_mb--5 {{ parent._('Подпишитесь на наши новости, чтобы не упустить новинки и акции.') }}
        +b.g-cell.g-cols--12
          +b.tt-space_mt--5.SUBSCRIPTION
</template>

<script>
export default {
  name: 'LeaveModal',
}
</script>

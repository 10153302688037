<script>
import {
  getProductConsumption,
} from '@api/store.service'

export default {
  name: 'Consumption',
  props: ['id'],
  data() {
    return {
      data: {
        cost: 0,
      },
    }
  },
  async created() {
    await this.getInfo()
  },
  methods: {
    async getInfo() {
      const { data } = (await getProductConsumption.execute({
        id: this.id,
      }))
      this.data = data
    },
  },
  render() {
    return this.$scopedSlots.default({
      info: this.data,
      cost: this.getSplittedHtml(this.data.cost),
    })
  },
}

</script>

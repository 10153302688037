import {
  route,
} from '@app/Routing/helpers'

const AddressBookList = () => import('@app/Staff/AddressBook/List')
const AddressBookAdd = () => import('@app/Staff/AddressBook/Add')

const MODEL = 'address-books'

const BASE = `${MODEL}`
const u = url => BASE + url

const LIST = u('/')
const ADD = u('/add/')
const EDIT = u('/edit/:id/')

export const ADDRESS_BOOK_PREFIX = `${MODEL}:`
const p = name => ADDRESS_BOOK_PREFIX + name

export const ADDRESS_BOOK_LIST_NAME = p('list')
export const ADDRESS_BOOK_ADD_NAME = p('add')
export const ADDRESS_BOOK_EDIT_NAME = p('edit')

export function createRoutes() {
  return [
    route(LIST, AddressBookList, ADDRESS_BOOK_LIST_NAME, {
      meta: { hideVisitCard: true },
    }),
    route(ADD, AddressBookAdd, ADDRESS_BOOK_ADD_NAME, {
      meta: { hideVisitCard: true },
    }),
    route(EDIT, AddressBookAdd, ADDRESS_BOOK_EDIT_NAME, {
      meta: { hideVisitCard: true },
    }),
    // route(BASE, AddressBookBase, ADDRESS_BOOK_BASE_NAME, {
    //   meta: { hideVisitCard: true },
    //   redirect: { name: ADDRESS_BOOK_LIST_NAME },
    //   children: [
    //   ],
    // }),
  ]
}

<template lang="pug">
  include /mixins
  +b.SECTION.ds-section.--space_none(
    v-if='packages.length'
  )
    +e.element.hidden(
      v-for='group, group_id in tables'
    )
      +b.new-loyalty-program
        +e.title-wrapper
          +e.P.main-title {{ group[0].props.table.props.block_title }}
          +e.P.subtitle(v-html="group[0].props.table.props.block_description")
      +b.g-container--variant_for-loyalty-page
        +b.new-loyalty-program(:class='getKeys(group, group_id) % 2 ? "is-even": "is-odd"')
          +e.content-wrapper.--variant_1.--decorated
            +e.content.--variant_4
              div(
              )
                +b.new-loyalty-corporate-program.is-visible-xl
                  ui-loader(
                    v-if='load'
                    :class-name='false'
                  )
                  +b.loyalty-programs
                    template(
                      v-for='(pack, packIdx) in getPackages(group_id)'
                    )
                      +b.new-loyalty-program-card(
                        :style='{ "--bg": pack.props.color, "--bg-item": pack.props.pair_color, "--size":  getPackages(group_id).length }'
                      )
                        +e.item.--title.--pretty(
                          :class='{"new-loyalty-program-card__item--first": !(getKeys(group, group_id) % 2)}'
                        )
                          +b.new-loyalty-program-pattern
                            +e.title.P(
                              :style='{ "--first": pack.props.color, "--second": pack.props.pair_color, "--size":  getPackages(group_id).length }'
                            ) {{ pack.props.title }}
                            modal-trigger(
                              v-if='!(getKeys(group, group_id) % 2)'
                              url='Forms/ModalWrapper'
                              tag='LoyaltyProgramForm'
                            )
                              template(#default='{ open }')
                                +b.new-loyalty-btn
                                  +b.tt-button.BUTTON(
                                    @click.prevent='open'
                                  )
                                    +e.title.P {{ _('подать заявку') }}
                        template(
                          v-for='(item, i) in conditions'
                        )
                          +e.item.--br-light.--parent(
                            :class='[`condition_${group_id}_${packIdx+1}`, { "has-divider": hasSecondary(pack.id, item.id, group_id), "is-last": conditions[i+1] && !hasValuesInCondition(group_id, conditions[i+1].id, conditions[i+1].props.children) } ]'
                            :style='{ "height": !!item.props.children.length ? "auto" : val[group_id] && val[group_id][i]&& val[group_id][i].h }'
                            v-show="hasValuesInCondition(group_id, item.id, item.props.children)"
                          )
                            template(
                              v-if='!!item.props.children.length'
                            )
                              +e.child-wrap(
                                :style='{ "padding-top": `${parentH[group_id] && parentH[group_id][i]&& parentH[group_id][i].h}px` }'
                              )
                                template(
                                  v-for='(child, idx) in item.props.children'
                                )
                                  +e.child--col(
                                    :class='[{"is-not-last": idx+1 < item.props.children.length}, `condition_child_${group_id}_${packIdx+1}`, {"has-divider": hasSecondary(pack.id, child.id, group_id), "no-br": !idx && (!hasSecondary(pack.id, child.id, group_id)) }]'
                                    :style='{ "height": valChild[group_id] && valChild[group_id][getIdx(child.id)]&& valChild[group_id][getIdx(child.id)].h }'
                                  )

                                    +e.text--col(
                                      :class='{"is-content is-first": hasSecondary(pack.id, child.id, group_id)}'
                                      v-if='hasTitle(pack.id, child.id, group_id) || getValue(pack.id, child.id, group_id).text'
                                    )
                                      p(v-html='getValue(pack.id, child.id, group_id, group_id).title')
                                      +e.small.SPAN(
                                        :class='{"is-content is-first": hasSecondary(pack.id, child.id, group_id), "ds-caption--bold": getValue(pack.id, child.id, group_id, group_id).text_style}'
                                        v-if='getValue(pack.id, child.id, group_id).text'
                                        v-html='getValue(pack.id, child.id, group_id) && getValue(pack.id, child.id, group_id).text || "-"'
                                      )
                                    +e.text--col(
                                      :class='{"is-content": hasPrimary(pack.id, child.id, group_id)}'
                                      v-if='getValue(pack.id, child.id, group_id).secondary_title  || getValue(pack.id, child.id, group_id).secondary_text'
                                    )
                                      p(v-html='getValue(pack.id, child.id, group_id).secondary_title')
                                      +e.small.SPAN(
                                        :class='{"is-content": hasPrimary(pack.id, child.id), "ds-caption--bold": getValue(pack.id, child.id, group_id).secondary_text_style}'
                                        v-if="getValue(pack.id, child.id, group_id).secondary_text"
                                        v-html='getValue(pack.id, child.id, group_id).secondary_text'
                                      )
                            template(v-else)
                              +e.text--col(
                                v-if='hasTitle(pack.id, item.id, group_id) || getValue(pack.id, item.id, group_id).text'
                                :class='{"is-content is-first": hasSecondary(pack.id, item.id, group_id)}'
                              )
                                p(v-html='getValue(pack.id, item.id, group_id).title')
                                +e.small(
                                  v-if='getValue(pack.id, item.id, group_id).text'
                                  :class='{"is-content is-first": hasSecondary(pack.id, item.id, group_id), "ds-caption--bold": getValue(pack.id, item.id, group_id).text_style}'
                                  v-html='getValue(pack.id, item.id, group_id) && getValue(pack.id, item.id, group_id).text || "-" '
                                )
                              +e.text--bold.--col(
                                v-if='getValue(pack.id, item.id, group_id).secondary_title || getValue(pack.id, item.id, group_id).secondary_text'
                                :class='{"is-content": hasPrimary(pack.id, item.id, group_id)}'
                              )
                                p(v-html='getValue(pack.id, item.id, group_id).secondary_title ')
                                +e.small(
                                  :class='{"is-content": hasPrimary(pack.id, item.id, group_id), "ds-caption--bold": getValue(pack.id, item.id, group_id).secondary_text_style}'
                                  v-if="getValue(pack.id, item.id, group_id).secondary_text"
                                  v-html='getValue(pack.id, item.id, group_id) && getValue(pack.id, item.id, group_id).secondary_text '
                                )
                      //center section desktop
                      template(v-if='!packIdx')
                        +b.new-loyalty-program-card--large
                          +e.item--simple.--title
                            //- +b.loyalty-program-title.P {{ group[0] && group[0].props && group[0].props.table && group[0].props.table.props && group[0].props.table.props.title }}
                            +b.new-loyalty-program-title(
                              :class='getKeys(group, group_id) % 2 ? "is-even": "is-odd"'
                            )
                              +e.totis.SPAN Totis &nbsp;
                              +e.club club
                          template(
                            v-for='(item, i) in conditions'
                          )
                            //- pre {{ hasValuesInCondition(group_id, item.id, item.props.children) }}
                            +e.item--simple.--br-dark(
                              :style='{ "height": !!item.props.children.length ? "auto" : val[group_id] && val[group_id][i]&& val[group_id][i].h }'
                              :class='[`condition_${group_id}_0`, { "is-clear": !item.props.title }]'
                              v-show='hasValuesInCondition(group_id, item.id, item.props.children)'
                            )
                              +b.P.ds-caption.--size_2md.--size_lg-fhd.--appearance_center.--medium(
                                :class='[`condition_parent_${group_id}_${i}`, { "ds-caption--bold": item.props.style }]'
                                ) {{ item.props.title }}
                              template(v-if='item.props.children.length')
                                +e.child--dashed(
                                  v-for='(child, idx) in item.props.children'
                                  v-show='hasValuesInCondition(group_id, item.id, item.props.children)'
                                  :class='[{"is-not-last": idx+1 < item.props.children.length}, `condition_child_${group_id}_0`, { "is-clear": !child.props.title }, { "ds-caption--bold": child.props.style }]'
                                  :style='{ "height": valChild[group_id] && valChild[group_id][getIdx(child.id)]&& valChild[group_id][getIdx(child.id)].h }'
                                )
                                  +b.ds-caption.--size_2md.--size_lg-fhd.--color_semigrey.--appearance_center(
                                    v-if='child.props.title'
                                    v-html='child.props.title'
                                  )
                                  //- +b.ds-caption.--size_2md.--size_lg-fhd.--color_semigrey.--appearance_center(
                                  //-   v-if='!child.props.title && !idx'
                                  //-   v-html='group[0] && group[0].props && group[0].props.table && group[0].props.table.props && group[0].props.table.props.title'
                                  //- )
                  +b.new-loyalty-table-description
                    +b.ds-caption--size_2md.--appearance_center.t-content.tt-space_mt--10(
                      style='max-width: 465px'
                      v-html="group[0] && group[0].props && group[0].props.table && group[0].props.table.props && group[0].props.table.props.description"
                    )

                +b.is-disable-xl.tt-space_mb--4
                  +b.g-row--align_start
                    template(
                      v-for='pack in packages'
                    )
                      +b.g-cell.g-cols--12.--6-md
                        ui-accordion(
                          :key='pack.id'
                          :init='false',
                          v-if="hasValuesInPackage(group_id, pack.id)"
                        )
                          template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
                            +b.new-loyalty-program-card(
                              :class='{"is-active": activeState}'
                              :style='{ "--bg": pack.props.color, "--bg-item": pack.props.pair_color, "--size":  getPackages(group_id).length }'
                            )
                              +e.item.--title.--pretty
                                +b.new-loyalty-program-pattern
                                  +e.title.P(
                                    :style='{ "--first": pack.props.color, "--second": pack.props.pair_color, "--size":  getPackages(group_id).length }'
                                  ) {{ pack.props.title }}
                                  modal-trigger(
                                    v-if='!(getKeys(group, group_id) % 2)'
                                    url='Forms/ModalWrapper'
                                    tag='LoyaltyProgramForm'
                                  )
                                    template(#default='{ open }')
                                      +b.new-loyalty-btn
                                        +b.tt-button.BUTTON(
                                          @click.prevent='open'
                                        )
                                          +e.title.P {{ _('подать заявку') }}
                              transition(name='slidedown')
                                div(v-show="activeState")
                                  template(
                                    v-for='(item, i) in conditions'
                                  )
                                    +e.item.--variant_4.--br-dark(
                                      v-show="hasValuesInCondition(group_id, item.id, item.props.children)"
                                    )
                                      +e.el--spaced.P.ds-caption.--size_2md(
                                        v-if='item.props.title'
                                        :class="{ 'loyalty-program-card__el--bordered': item.props.children[0] && getValue(pack.id, item.props.children[0].id, group_id).secondary_title || item.props.children[0] && getValue(pack.id, item.props.children[0].id, group_id).secondary_text, 'ds-caption--bold': item.props.style  }"
                                      ) {{ item.props.title }}
                                      template(v-if='item.props.children.length')
                                        template(
                                          v-for='child in item.props.children'
                                        )
                                          +e.el.P.ds-caption.--size_2md-xl(
                                            v-html='child.props.title'
                                            :class='{ "loyalty-program-card__el--bordered": getValue(pack.id, child.id, group_id).secondary_title || getValue(pack.id, child.id, group_id).secondary_text, "ds-caption--bold": child.props.style }'
                                            v-if="child.props.title"
                                          )
                                          .loyalty-cell-wrap
                                            +e.text--col(
                                              v-if='hasTitle(pack.id, child.id, group_id) || getValue(pack.id, child.id, group_id).text'
                                              :class='{"is-content is-first": hasSecondary(pack.id, child.id, group_id)}'
                                            )
                                              p(v-html='getValue(pack.id, child.id, group_id).title')
                                              +e.small--col.SPAN(
                                                v-if='getValue(pack.id, child.id, group_id).text'
                                                :class='{"is-content is-first": hasSecondary(pack.id, child.id, group_id), "ds-caption--bold": getValue(pack.id, child.id, group_id).text_style}'
                                                v-html='getValue(pack.id, child.id, group_id) && getValue(pack.id, child.id, group_id).text || "-"'
                                              )
                                            +e.text--col(
                                              v-if='getValue(pack.id, child.id, group_id).secondary_title  || getValue(pack.id, child.id, group_id).secondary_text'
                                              :class='{"is-content": hasPrimary(pack.id, child.id, group_id)}'
                                            )
                                              p(v-html='getValue(pack.id, child.id, group_id).secondary_title')
                                              +e.small--col.SPAN(
                                                :class='{"is-content": hasPrimary(pack.id, child.id, group_id), "ds-caption--bold": getValue(pack.id, child.id, group_id).secondary_text_style}'
                                                v-if="getValue(pack.id, child.id, group_id).secondary_text"
                                                v-html='getValue(pack.id, child.id, group_id) && getValue(pack.id, child.id, group_id).secondary_text'
                                              )
                                      template(v-else='')
                                        .loyalty-cell-wrap
                                          +e.text(
                                            v-if='hasTitle(pack.id, item.id, group_id) || getValue(pack.id, item.id, group_id).text'
                                            :class='{"is-content is-first": hasSecondary(pack.id, item.id, group_id)}'
                                          )
                                            p(v-html='getValue(pack.id, item.id, group_id).title')
                                            +e.small(
                                              v-if='getValue(pack.id, item.id, group_id).text'
                                              :class='{"is-content is-first": hasSecondary(pack.id, item.id, group_id), "ds-caption--bold": getValue(pack.id, item.id, group_id).text_style}'
                                              v-html='getValue(pack.id, item.id, group_id) && getValue(pack.id, item.id, group_id).text || "-"'
                                            )
                                          +e.text--bold(
                                            v-if='getValue(pack.id, item.id, group_id).secondary_title || getValue(pack.id, item.id, group_id).secondary_text'
                                            :class='{"is-content": hasPrimary(pack.id, item.id, group_id)}'
                                          )
                                            p(v-html='getValue(pack.id, item.id, group_id).secondary_title')
                                            +e.small(
                                              :class='{"is-content": hasPrimary(pack.id, item.id, group_id), "ds-caption--bold": getValue(pack.id, item.id, group_id).secondary_text_style}'
                                              v-if="getValue(pack.id, item.id, group_id).secondary_text"
                                              v-html='getValue(pack.id, item.id, group_id) && getValue(pack.id, item.id, group_id).secondary_text'
                                            )
                              +b.new-loyalty-accordion-trigger(
                                @click.prevent="toggleActive"
                              )
                                +e.link.tt-link.--variant_start.ds-caption--size_md.--medium(
                                ) {{ activeState ? _('Скрыть') : _('Подробнее') }}
                                +e.chevron.mdi(
                                  :class='activeState ? "mdi-chevron-up" : "mdi-chevron-down"'
                                )

</template>
<script>
import { pathOr } from 'ramda'

import {
  valuesListResponse,
  conditionsListResponse,
  packagesListResponse,
} from '@api/loyalty.service'

import {
  groupByOrNull,
  pathGetterArray,
} from '@utils/helpers'

const VALUES_KEY = 'values'
const CONDITIONS_KEY = 'conditions'
const PACKAGES_KEY = 'packages'

export default {
  name: 'LoyaltyList',
  data() {
    return {
      [VALUES_KEY]: [],
      [CONDITIONS_KEY]: [],
      [PACKAGES_KEY]: [],
      val: {},
      valChild: {},
      children: [],
      parentH: {},
      load: false,
      tables: {},
    }
  },
  async created() {
    await this.receiver(valuesListResponse, VALUES_KEY)
    await this.receiver(packagesListResponse, PACKAGES_KEY)
    await this.receiver(conditionsListResponse, CONDITIONS_KEY)
    this.children = this.conditions.reduce((acc, obj) => {
      acc.push(...obj.props.children)
      return acc
    }, [])
    const groups = groupByOrNull(this.values, pathGetterArray(['props', 'table', 'props', 'sort_order'], null), pathGetterArray(['props', 'table', 'id'], null))
    console.log(groups);
    this.tables = groups
    this.$nextTick(() => {
      this.getHeight()
    })
  },
  mounted() {
    this.getHeight()
    this.$nextTick(() => {
      this.getHeight()
    })
  },
  methods: {
    getKeys(group, id) {
      return Object.keys(this.tables).findIndex(e => e === id);
    },
    getPackages(groupId) {
      return this.packages.filter(pack => this.hasValuesInPackage(groupId, pack.id))
    },
    hasValuesInCondition(groupId, condition, children) {
      const p = this.tables[groupId].find(e => e.props.condition.id === condition)
      const c = children.some(x => this.tables[groupId].find(e => e.props.condition.id === x.id))

      return !!p || (children.length ? !!c : false)
    },
    hasValuesInPackage(groupId, pack) {
      const p = this.tables[groupId].find(e => e.props.package.id === pack)
      return !!p
    },
    async receiver(resource, key) {
      const { data } = (await resource.execute())
      this[key] = data
    },
    getValue(pack, condition, group) {
      const g = this.tables[group]
      if (!g) return {}
      const v = g.find(e => e.props.condition.id === condition && e.props.package.id === pack)
      return v?.props || {}
    },
    hasTitle(pack, condition, group) {
      const v = this.getValue(pack, condition, group)
      return v?.title
    },
    hasSecondary(pack, condition, group) {
      const v = this.getValue(pack, condition, group)
      return v?.secondary_title || v?.secondary_text
    },
    hasPrimary(pack, condition, group) {
      const v = this.getValue(pack, condition, group)
      return v?.title || v?.text
    },
    getIdx(id) {
      return this.children.findIndex(x => x.id == id)
    },
    getChildIdx(id) {
      const child = this.children.findIndex(x => x.id == id)
      return child
    },
    getHeight() {
      this.load = true
      const one = 1
      const zero = 0
      console.log(this.tables);
      Object.keys(this.tables).forEach(table => {
        this.val = {}
        this.valChild = {}
        const total = this.conditions.length
        let total_child = this.conditions.reduce((acc, obj) => acc += obj.props.children.length, 0)
        total_child += one
        for (let x = zero; x < total; x++) {
          this.val[table] = this.val[table] || []
          this.val[table].push({
            h: 'auto',
          })
        }
        if (this.val[table].length > zero) {
          this.val = JSON.parse(JSON.stringify(this.val))
          this.val[table].forEach((el, i) => {
            el = 'auto'
          })
        }
        for (let x = zero; x < total_child; x++) {
          this.valChild[table] = this.valChild[table] || []
          this.valChild[table].push({
            h: 'auto',
          })
        }
        if (this.valChild[table].length > zero) {
          this.valChild = JSON.parse(JSON.stringify(this.valChild))
          this.valChild[table].forEach((el, i) => {
            el = 'auto'
          })
        }
        const time = 2000
        setTimeout(() => {
          const arr = {}
          const arr2 = {}
          this.parentH = {}
          
          this.conditions.forEach((el, i) => {
            arr[table] = arr[table] || []
            arr[table].push(Array.from(document.getElementsByClassName(`condition_${table}_${i}`)).map((element, index) => {
              console.log(element.scrollHeight);
              return { h: element.scrollHeight } 
            }))
            this.parentH[table] = this.parentH[table] || []
            this.parentH[table].push(Array.from(document.getElementsByClassName(`condition_parent_${table}_${i}`)).map((element, index) => ({ h: element.scrollHeight }))[0])
          })
          console.log(arr[table]);
          for (let t = 0; t <= this.packages.length; t++) {
            arr2[table] = arr2[table] || []
            arr2[table].push(Array.from(document.getElementsByClassName(`condition_child_${table}_${t}`)).map((element, index) => {
              return { h: element.scrollHeight }
            }))
          }

          // })
          this.val = JSON.parse(JSON.stringify(this.val))
          this.val[table] = arr[table][0]
          for (let i = zero; i < arr[table].length; i++) {
            for (let j = zero; j < arr[table][i].length; j++) {
              if (this.val[table][j].h < arr[table][i][j].h) {
                this.val[table][j].h = arr[table][i][j].h
                this.$set(this.val[table][j], 'h', arr[table][i][j].h)
              }
            }
          }
          this.val = JSON.parse(JSON.stringify(this.val || []))
          this.val[table].forEach((el, i) => {
            // el = JSON.parse(JSON.stringify(el))
            el.h = `${el.h}px`
            this.$set(this.val[table][i], 'h', `${el.h}`)
          })
          if (total != this.val[table].length) {
            const other = total - this.val[table].length
            for (let x = zero; x < other; x++) {
              this.val[table].push({
                h: 'auto',
              })
            }
          }
          // 222222222222

          this.valChild = JSON.parse(JSON.stringify(this.valChild))
          this.valChild[table] = arr2[table][0]
          for (let i = zero; i < arr2[table].length; i++) {
            for (let j = zero; j < arr2[table][i].length; j++) {
              if (this.valChild[table][j].h < arr2[table][i][j].h) {
                this.valChild[table][j].h = arr2[table][i][j].h
                this.$set(this.valChild[table][j], 'h', arr2[table][i][j].h)
              }
            }
          }
          this.valChild = JSON.parse(JSON.stringify(this.valChild || []))
          this.valChild[table].forEach((el, i) => {
            // el = JSON.parse(JSON.stringify(el))
            el.h = `${el.h}px`
            this.$set(this.valChild[table][i], 'h', `${el.h}`)
          })
          if (total_child - one != this.valChild[table].length) {
            const other = total_child - this.valChild[table].length
            for (let x = zero; x < other; x++) {
              this.valChild[table].push({
                h: 'auto',
              })
            }
          }
          // this.isPageLoading = false
          this.load = false
        }, time)
      })
    },
  },
}
</script>

import UiSearch from '@app/UiElements/Header/UiSearch'
import UiSidebar from '@app/UiElements/Header/UiSidebar'
import TreeMenuTrigger from '@app/UiElements/Header/TreeMenuTrigger'
import TreeMenuWrapper from '@app/UiElements/Header/TreeMenuWrapper'
import CountrySwitcher from '@app/UiElements/Header/CountrySwitcher'
import CurrencySwitcher from '@app/UiElements/Header/CurrencySwitcher'

export default function install(Vue) {
  Vue.component(UiSearch.name, UiSearch)
  Vue.component(UiSidebar.name, UiSidebar)
  Vue.component(TreeMenuTrigger.name, TreeMenuTrigger)
  Vue.component(TreeMenuWrapper.name, TreeMenuWrapper)
  Vue.component(CountrySwitcher.name, CountrySwitcher)
  Vue.component(CurrencySwitcher.name, CurrencySwitcher)
}

<template lang="pug">
  include /mixins
  +b.g-row--align_end.VALIDATION-OBSERVER(
    ref="video_validator",
    v-slot="{ valid }",
  )
    +b.g-cell.g-cols--12
      d-control-input(
        :input-label='_("Ссылка на видео с Youtube")'
        ref='search-input'
        v-model='link',
        name='link',
        id='link',
        :rules="'url'",
      )
    +b.g-cell.g-cols--12
      +b.tt-link--styling_default.BUTTON(
        href='',
        :class='{"is-disabled": !valid || !link}'
        :disabled='!valid || !link'
        @click.prevent='send(valid)',
      )
        +e.title--mini.P {{ _('Отправить') }}

</template>

<script>

export default {
  name: 'YoutubeLoader',
  data() {
    return {
      link: '',
    }
  },
  methods: {
    send(valid) {
      this.$refs.video_validator.validate()
      if (!valid) return
      this.$emit('update', this.link)
      this.link = ''
    },
  },
}
</script>

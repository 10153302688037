<template lang="pug">
  include /mixins

  form-generator(
    :fields='fields',
    :resource='resource || $options.passwordRecoveryResource',
    :form='form'
    :custom-validatator-parser='errorsParser'
    :data-formatter='formatter'
    :btn-text='_("Сохранить")'
  )

</template>
<script>

import { passwordRecoveryResource } from '@api/auth.service'

export default {
  name: 'PasswordRestore',
  passwordRecoveryResource,
  props: ['resource'],
  data() {
    return {
      form: {
        phone: '',
        email: '',
        id_token: '',
        new_password1: '',
        new_password2: '',
      },
      fields: [
        {
          name: 'new_password1',
          tag: 'd-control-input',
          cols: '12',
          title: this._('Новый пароль'),
          type: 'password',
          rules: 'required',
        },
        {
          name: 'new_password2',
          tag: 'd-control-input',
          cols: '12',
          title: this._('Повторите новый пароль'),
          type: 'password',
          rules: 'required|confirmed:new_password1',
        },
      ],
    }
  },
  mounted() {
    const state = new URLSearchParams(window.location.search).get('_state')
    console.log(JSON.parse(atob(state)));
    const { id, phone, email } = JSON.parse(atob(state))
    this.form.id_token = id
    this.form.phone = phone || null
    this.form.email = email || null
  },
  methods: {
    formatter(data) {
      const d = JSON.parse(JSON.stringify(data))
      const r = Object.keys(d).reduce((acc, key) => {
        if (d[key]) {
          acc[key] = d[key]
        }
        return acc
      }, {})
      return r
    },
    errorsParser(errors) {
      const e = {}
      Object.keys(errors).forEach(key => {
        console.log(typeof errors[key], errors[key]);
        if ('Array' === errors[key].constructor.name) {
          const [first] = errors[key]
          e[key] = first
          return
        }
        e[key] = errors[key]
      })
      return e
    },
  },
}
</script>

import { withDefaultProps } from '@aspectus/vue-utils'

import BaseView from '@app/List/View'

import {
  productsListUrl,
  attributesResource,
  availableAttributesResource,
  salesDetailUrl,
  salesDetailAttributesResource,
  availableAttributesForBrandResource,
  brandsAttributesResource,
  brandDetailUrl,
  DiscountAvailableFiltersResource,
  discountFiltersResource,
  discountDetailUrl,
  bonusDetailAttributesResource,
  availableAttributesForBonusResource,
  bonusDetailUrl,
} from '@api/catalog.service'

// const CatalogView = {
//   name: 'CatalogViewTest',
//   productsResource: productsListUrl,
//

const composer = (
  listUrl,
  filtersUrl,
  availableFiltersUrl,
  needPrices = false,
  needSorting = false,
  needTitle = false,
  needSearch = false,
  needBlocking = true,
  isStoreCatalog = false,
) => withDefaultProps({
  productsResource: () => listUrl,
  attributesResource: () => filtersUrl,
  availableAttributesResource: () => availableFiltersUrl,
  needPrices,
  needSorting,
  needTitle,
  needSearch,
  needBlocking,
  isStoreCatalog,
})(BaseView)

const CatalogView = composer(
  productsListUrl,
  attributesResource,
  availableAttributesResource,
  true,
  true,
  true,
  false,
  true, true
)
const SalesDetailView = composer(
  salesDetailUrl,
  salesDetailAttributesResource,
  availableAttributesForBrandResource,
  true,
  true,
  true,
  false,
  true
)
const BonusDetailView = composer(
  bonusDetailUrl,
  bonusDetailAttributesResource,
  availableAttributesForBonusResource,
  true,
  true,
  true,
  false,
  true
)

const BrandCatalogView = composer(
  brandDetailUrl,
  brandsAttributesResource,
  availableAttributesForBrandResource,
  true,
  true,
  true,
  false,
  true
)
const DiscountCatalogView = composer(
  discountDetailUrl,
  discountFiltersResource,
  DiscountAvailableFiltersResource,
  true,
  false,
  true,
  false,
  true
)

export default function plugin(Vue, { postfix = '-filters' } = { }) {
  // Vue.component(CatalogHOC.name, CatalogHOC)
  Vue.component(`catalog${postfix}`, CatalogView)
  Vue.component(`sales-detail${postfix}`, SalesDetailView)
  Vue.component(`bonus-detail${postfix}`, BonusDetailView)
  Vue.component(`brand-catalog${postfix}`, BrandCatalogView)
  Vue.component(`discount-catalog${postfix}`, DiscountCatalogView)
}

<template lang="pug">
include /mixins
modal-wrapper(
  :info='{}'
  @close='$emit("close")'
)
  +b.ds-panel--space_xl
    +e.element--offset_vertical
      +b.ds-caption--size_lg.--medium.--appearance_center.P {{ _('Результаты до и после') }}
      +b.ds-caption--size_xs.--medium.--appearance_center.P {{ _('Загрузите, пожалуйста, фото до и после процедуры') }}
    +e.element--offset_vertical
      +b.g-row--align_center.--justify_center.--appearance_spaced
        template(v-for='item in keys')
          +b.g-cell--12.--narrow-md
            +b.ds-caption--size_sm.--medium.--appearance_center.P.tt-space_mb--2 {{ item.titles.initial }}
            result-loader(
              :width='188'
              :height='240'
              :ratio='7/9'
              :rate='2'
              :post-resource='$options.ResultImageCreate',
              :delete-resource='$options.ResultImageDestroy',
              v-bind='item'
              :key='item.name'
              @update='setKeys($event, item.name)'
            )
      .relative(v-if="non_field_errors")
        +b.error-label.LABEL {{ non_field_errors[0] }}
    +e.element--offset_vertical
      +b.g-row--align_center.--justify_center
        +b.g-cell--12.--6-md.--3-xl
          +b.tt-button.A(
            @click.prevent='createResult',
          )
            +e.title.SPAN {{ _('Сохранить') }}
          .is-disable-md
            +b.tt-space_pt--10
</template>

<script>

import {
  ResultCreate,
  ResultImageCreate,
  ResultImageDestroy,
} from '@api/store.service'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'CreateResult',
  props: ['info'],
  ResultCreate,
  ResultImageCreate,
  ResultImageDestroy,
  data() {
    return {
      form: {
        product: this.info.id,
        product_result_images: [],
      },
      reset: new Date(),
      keys: [
        {
          name: 'before',
          titles: {
            create: this._('Загрузить ДО'),
            update: this._('Изменить ДО'),
            initial: this._('Фото ДО'),
          },
          additional: { type: 'before' },
        },
        {
          name: 'after',
          titles: {
            create: this._('Загрузить После'),
            update: this._('Изменить После'),
            initial: this._('Фото После'),
          },
          additional: { type: 'after' },
        },
      ],
      images: new Map(),
      non_field_errors: '',
    }
  },
  methods: {
    async validate() {
      return new Promise((resolve, reject) => {
        this.non_field_errors = ''
        if (this.keys.length > this.images.size) {
          this.non_field_errors = [this._('Загрузите, пожалуйста, фото до и после процедуры')]
          reject()
        }
        resolve()
      })
    },
    async createResult() {
      try {
        await this.validate()
        this.form.product_result_images = [...this.images.values()]
        ResultCreate.execute({}, this.form)
          .then(res => {
            AnswerMessage(res.data, this)
            this.$emit('close')
          })
      } catch (e) {
        console.log(e);
      }
    },
    setKeys(list, key) {
      this.images.set(key, list)
      this.non_field_errors = ''
    },
  },
}
</script>

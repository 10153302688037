<template lang="pug">
include /mixins
+b.product-set-card.relative
  +e.icons
    add-to-favorites(
      :id='variant.id'
      :not-reload='true'
      @hook:remove="$emit('hook:remove', $event)"
    )
      template(#default='{ add, is_added, remove }')
        +b.circle-btn--accent.--clear.--transparent.BUTTON(
          v-if='is_added'
          @click.prevent='remove'
          :title="_('В избранном')"
        )
          +e.icon.IMG(
            src="/static/img/full-heart.svg"
            :alt='_("full heart")'
          )
        +b.circle-btn--accent.--clear.--transparent.BUTTON(
          @click.prevent='add'
          :title="_('Добавить в избранное')"
          v-else
        )
          +e.icon.icon-like.SPAN
  +e.img-wrap
    +b.tt-picture--size_auto.--size_auto-h.product-set-card__img.PICTURE.relative
      source(
        type="image/webp"
        :srcset="variant.props.img && variant.props.img.catalog_preview_webp"
      )
      +e.body.IMG(
        v-if='variant.props.img && variant.props.img.catalog_preview'
        :src='variant.props.img && variant.props.img.catalog_preview',
        :alt='variant.props.title',
      )
      +e.body.IMG(
        v-else
        src='https://via.placeholder.com/60x60',
      )
  +e.content
    +e.title.ds-caption--size_md.--medium.A.tt-link--variant_start(
      :href='variant.props.url'
    ) {{ variant.props.product.props.title }}
    +e.vendor.ds-caption--size_xs.--size_sm-xl.--medium.seller-product-name(v-if='variant.props.seller') {{ variant.props.seller.props.title }}
    +e.vendor.ds-caption--size_xs.--size_sm-xl.--medium.--color_accent(v-else) {{ variant.props.brand.props.title }}
    +e.weight.ds-caption--size_xs.--size_sm-xl.--medium.P(
      v-if='variant.props.weight'
    ) {{ variant.props.weight.props.name }}

    +e.art.ds-caption--size_2xs.--light.P {{ _('Артикул:') }} &nbsp;
      +b.ds-caption--medium.SPAN {{ variant.props.vendor_code || "-" }}
    +e.price-and-count
      +e.price.ds-caption--size_md.--semibold.P(
        v-html='getSplittedHtml(getPrice)'
      )
      counter-controller(
        :max-count='getMax'
        :key='variant.props.id'
        :initial='0'
      )
        template(#default='{ amount, change, clear, max_count }')
          +b.COUNTER(
            :amount='amount'
            @immediately='change($event)'
            @input='$emit("changeCount", $event)'
            :max-count='getMax'
            :min-count='0'
          )

</template>

<script>
export default {
  name: 'SalesVariantCard',
  props: ['variant', 'max'],
  data() {
    return {
      currency: window.currency,
    }
  },
  computed: {
    getMax() {
      return Math.min(this.variant.props.stock, this.max)
    },
    getPrice() {
      const [initialPrice] = this.variant.props.price_definition.user.ranged_prices
      return initialPrice.sale_price || initialPrice.default_price
    },
  },
}
</script>

import isShallowEqual from '@wordpress/is-shallow-equal'
import FilterUi from '@app/FiltersSimple/Ui'

export default {
  props: {

    loading: {},
    value: {},
    receive: {},
    receivePrices: {},
    all: {
      default: () => [],
    },
    prices: {},
    available: {},
    receiveAvailable: {},
    displayed: {},
    pagination: {},
    needSorting: {},
    needPricing: {},
    needTitle: {},
    needSearch: {},
    sortlist: {},
    label: {},
    needBlocking: {},
    initbaseUrl: {},
    kwargs: {
      defaults: () => ({}),
    },
  },
  components: {
    FilterUi,

  },

  data() {
    return {
      prepared: {},
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return
        }
        this.prepare(filters)
        if (this.needBlocking) {
          this.receiveAvailable({ filters, label: this.label, ...this.kwargs })
        }
      },
    },
  },

  created() {
    if (this.needPricing) {
      this.receivePrices()
    }
    this.receive({ label: this.label })
  },

  computed: {
    filters() {
      return this.all
    },
    availableMap() {
      if (!this.available) {
        return {}
      }
      return this.available
    },
    showFilters() {
      const arr = this.all || []
      return arr.some(e => e.attribute.values?.length)
    },
  },

  methods: {
    prepare(value) {
      this.prepared = value
    },
    handleInputFilter(value) {
      this.handleInput(value)
      this.filter()
    },
    handleInput(value) {
      this.prepare(value)
      if (this.needBlocking) {
        this.receiveAvailable({ filters: this.prepared, label: this.label, ...this.kwargs })
      }
    },
    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared, offset: 0 })
    },
  },
}

<template lang="pug">
  include /mixins
  +b-context('p-404')
  +b.tt-button.p-404__button.--width_auto.A(
    :href='linkGenerator()'
  )
    +e.title.P {{ _('ПОВЫСИТЬ УРОВЕНЬ') }}
</template>

<script>
const lang = window.language
const levelRequest = '/profile/documents/'

export default {
  name: 'PermissionsUpdateBtn',
  lang,
  levelRequest,
  methods: {
    linkGenerator() {
      let link = levelRequest
      if ('ru' !== lang) {
        link = `/${lang}${link}`
      }
      return link
    },
  },
}
</script>

<script>
export default {
  name: 'UiTableController',
  props: {
    items: {
      default: () => [],
    },
  },
  data() {
    return {
      arr: [],
    }
  },
  computed: {
    list() {
      if (!this.items) return []
      this.arr = this.items || []

      this.arr.forEach((el, key) => {
        this.$set(this.arr[key], 'show', false)
      })
      return this.arr
    },
  },
  methods: {
    toggle({ index, event }) {
      if (event.target.classList.contains('table-toggle')) {
        this.list.forEach((element, idx) => {
          if (this.list[index] == element) {
            this.$set(
              this.list[index],
              'show',
              !this.list[index].show
            )
          } else {
            this.$set(this.list[idx], 'show', false)
          }
        })
      }
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      list: this.list,
      toggle: $event => this.toggle($event),
    })
  },
}
</script>

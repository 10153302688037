import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n'
import Vuex from 'vuex'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VModal from 'vue-js-modal'
import { install } from '@app/index'
import '@utils'

import ClickOutside from 'vue-click-outside';

import '@/js/animation'

import directives from '@utils/directives'
import filters from '@utils/filters'
import { install as mapInstall } from 'vue2-google-maps'
// import { Carousel3d, Slide } from 'vue-carousel-3d'
// import Tooltip from 'vue-directive-tooltip'
import Notifications from 'vue-notification'
import VueSocialSharing from 'vue-social-sharing'
import VueClipboard from 'vue-clipboard2'
import { farewellDirectivePlugin } from 'vue-farewell'
// import createPreviewDirective from 'vue-photoswipe-directive'
import PortalVue from 'portal-vue'
// import { VueAgile } from 'vue-agile'
import { mask } from 'vue-the-mask'

import VueProduct360 from '@deviznet/vue-product-360'

const updatedMask = {
  bind(el, binding) {
    if (!binding.value) return
    mask(el, binding)
  },
}

export function plugin(Vue) {
  Vue.use(farewellDirectivePlugin)
  Vue.use(filters)
  Vue.use(install)
  Vue.use(Notifications)
  Vue.use(PortalVue)
  // Vue.use(VueQrcode)
  // Vue.use(Tooltip, { autoHide: false })
  Vue.use(VueClipboard)
  Vue.use(mapInstall, {
    load: {
      key: window.gmap,
      libraries: 'places',
    },
    installComponents: false,
  })
  Vue.use(VueI18n)
  Vue.use(VueRouter)
  Vue.use(VueSocialSharing)
  Vue.use(Vuex)
  Vue.use(VModal, { dynamic: true })

  Vue.mixin({
    directives,
  })

  Vue.directive('clickaway', ClickOutside)
  Vue.directive('mask', updatedMask)
  Vue.component('VueProduct360', VueProduct360)

  // Vue.component('Carousel3d', Carousel3d)
  // Vue.component('VueAgile', VueAgile)
  Vue.component('VueQrcode', VueQrcode)
}

export default { plugin }

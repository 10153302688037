<template lang="pug">
  include /mixins
  +b.circle-btn.A(
    :href='link'
    :title="_('Перейти в сравнение')"
  )
    +e.icon.icon-libra.SPAN
    +e.counter.SPAN {{ comparison_ids.length }}

</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ComparisonPreview',
  props: ['link'],
  computed: {
    ...mapState('comparison', [
      'comparison_ids',
    ]),
  },
  created() {
    this.getComparisonList()
  },
  methods: {
    ...mapActions('comparison', [
      'getComparisonList',
    ]),
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.ds-panel--space_lg.--space_sm-xl
    +e.element--offset_horizontal

      template(v-for='val in filter.attribute.values')
        +b.tt-space_mt--3()
          +b.d-control-input.--appearance_flexible(
            v-if='!val.hasOwnProperty("is_valid") || val.is_valid'
          )
            +e.element.INPUT(
              type='checkbox'
              :value='val.props.label',
              :name='filter.attribute.label'
              v-model='checked'
              :input-label='val.props.title',
              :id='`${filter.attribute.label}:${val.id}`'
              :disabled='!isAvailable(val.id, val.props.label)'
              @change='submit()'
            )
            +e.LABEL.label--for-checkbox(
              :for='`${filter.attribute.label}:${val.id}`'
            ) {{ val.props.title }}
            // for available filters debug
            //- span(style='color: red') {{ isAvailable(val.id, val.props.label) }} {{ hasActive(val.props.label) }}
</template>
<script>

export default {
  name: 'CheckboxRenderer',
  props: ['value', 'filter', 'available'],
  data() {
    return {
      checked: [],
    }
  },
  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },
  mounted() {
    this.checked = this.normalize(this.value)
  },
  methods: {
    submit() {
      this.$emit('input', this.checked)
    },
    isAvailable(id, label) {
      if (!this.available.attributes) return true
      const customFilter = this.available[this.filter.attribute.label]
      if (customFilter) {
        const a = customFilter.find(el => el == id)
        if (!a && this.hasActive(label)) return true
        return a
      }
      const a = this.available.attributes.find(el => el == id)
      if (!a && this.hasActive(label)) return true
      return a
    },
    hasActive(val) {
      return this.checked.includes(val)
    },
    normalize(value) {
      if (!value) return []
      const checked = this.filter.attribute.values.filter(el => value.find(v => v == el.props.label)).map(el => el.props.label)
      return checked
    },
  },
}
</script>

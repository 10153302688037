import { renderSlim } from '@aspectus/vue-utils'

export default {
  name: 'infinite-results-collector',

  props: {
    items: {},
    pagination: {},
  },

  data() {
    return {
      cache: [],
    }
  },

  watch: {
    items: 'itemsReceived',
  },

  computed: {
    collected() {
      return this.cache && Array.prototype.concat.apply([], this.cache) || []
    },
  },

  methods: {
    itemsReceived(items) {
      const zero = 0
      if (!(zero < this.pagination.offset)) {
        this.cache = []
      }

      if (items && items.length) {
        this.cache.push(items)
      }
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      items: this.collected,
    }), h, 'tag')
  },
}

<template lang="pug">
  include /mixins
  +b.ds-panel--space_sm
    ui-loader(v-if='load || cartIsLoad')
    +e.element--offset_full
      +b.close-popup.mdi.mdi-close.BUTTON(
        v-if='isFully'
        @click.prevent='$emit("close")'
      )
      +b.ds-caption--size_lg.--medium.--appearance_center-xl.H2.main-title.tt-space_mb--5 {{ _('Кошик') }}
      template(v-if='(cart.hasOwnProperty("quantity") || cart.groups.length) && !load')
        template(v-if='cart.quantity || cart.groups.length')
          +b.tt-link--styling_default.--grey.variant_end.A.tt-space_mb--1(
            href=''
            @click.prevent='clearCurrentCart'
          )
            +e.title.SPAN {{ _('Очистить корзину') }}
          +b.ds-caption--size_2xs.--regular.--color_red(
            v-if='!cartIsValid'
          ) {{ _('В корзине есть товары, которых нет в необходимом Вам количестве') }}
          +b.ds-caption--size_2xs.--regular.--color_red(
            v-if='!cart.total_price'
          ) {{ _('Добавьте платный товар в корзину, чтобы продолжить оформление заказа') }}
          .relative
            +b.line-h--light
          +b.cart-wrapper
            cart-product(
              v-for='(product, idx) in cart.groups'
              :key='idx',
              :product='product',
              @update:cart='updateEntities($event)'
            )
              template(
                #default='{ removeFromCart, updateCart, isProductload }'
              )
                cart-product-ui(
                  :product='product',
                  :load='isProductload'
                  @removeFromCart='removeFromCart',
                  @updateCart='updateCart',
                )
            cart-set-ui(
              v-for='set, idx in cart.sets'
              :key='`et_${idx}`',
              :set='set',
              :id='idx'
              @update:cart='updateEntities($event)'
              @load:change='changeLoad'
            )
          .relative
            +b.line-h--light
            
            +b.tt-space_pb--4.ROW(justify='end')
              
              cell(
                cols='12',
              )
                template(
                  v-if='cart.cart_bonus_sum'
                )
                  +b.tt-space_mt--4.ROW(
                    justify='between',
                    align='center',
                  )
                    cell(
                      cols='narrow'
                    )
                      +b.ds-caption--size_sm.--regular.P {{ _('Очікувана сума кешбеку') }}
                    cell(
                      cols='narrow'
                    )
                      row(align='center')
                        cell
                          +b.cashback-percent--only-icon
                            +e.icon.SVG(:title='_("cashback")' xmlns='http://www.w3.org/2000/svg', width='20', height='20', viewBox!='0 0 20 20', fill='none')
                              circle(cx='10', cy='10', r='9.5', fill='#FF7A00', stroke='white')
                              path(
                                d='M11.612 9.68L14.096 14H10.904L9.296 10.964H8.504V14H5.672V5.6H8.504V8.732H9.368L11.156 5.6H14.108L11.612 9.68Z', fill='white'
                                style='transform: translateX(0px)'
                              )
                        cell
                          +b.ds-caption--size_sm.--regular.P(
                            style='line-height: 1;'
                            v-html='getSplittedHtml(cart.cart_bonus_sum)'
                          )
                +b.tt-space_mt--6.ROW(
                  justify='between'
                  align='center',
                )
                  cell(
                    cols='narrow'
                  )
                    +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Итого:') }}
                  cell(
                    cols='narrow'
                  )
                    +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(getTotalPrice)')
                template(
                  v-if='cart.parameters.promocode_title && cart.promocode_discount_summ'
                )
                  +b.tt-space_mt--4.ROW(
                    justify='between',
                    align='center',
                  )
                    cell(
                      cols='narrow'
                    )
                      +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка по промокоду') }} {{ cart.parameters.promocode_title }}
                    cell(
                      cols='narrow'
                    )
                      +b.ds-caption--size_sm.--color_red.--regular.P(
                        v-html='getSplittedHtml(cart.promocode_discount_summ)'
                      )
                
                template(
                  v-if='cart.parameters.discount_from_sum_limit_action_by_items && hasGlobalDiscountFromSum'
                )
                  +b.tt-space_mt--4.ROW(
                    justify='between',
                    align='center',
                  )
                    cell(
                      cols='narrow'
                    )
                      +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка по акции:') }}
                    cell(
                      cols='narrow'
                    )
                      +b.ds-caption--size_lg.--color_red.--regular.P  {{ hasGlobalDiscountFromSum && hasGlobalDiscountFromSum.base.parameters.discount_amount }} %
                template(
                  v-if='cart.parameters.site_discount'
                )
                  +b.tt-space_mt--4.ROW(
                    justify='between',
                    align='center',
                  )
                    cell(
                      cols='narrow'
                    )
                      +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка при покупке online:') }}
                    cell(
                      cols='narrow'
                    )
                      +b.ds-caption--size_lg.--color_red.--regular.P  {{ cart.parameters.site_discount }} %
                +b.tt-space_mt--4.ROW(
                  align='center',
                  v-if='cart.parameters.promocode_title && cart.promocode_discount_summ || cart.parameters.site_discount || cart.parameters.discount_from_sum_limit_action_by_items && hasGlobalDiscountFromSum'
                  justify='between'
                )
                  cell(
                    cols='narrow'
                  )
                    +b.ds-caption--size_sm.--color_red.--medium.P {{ _('Сумма со скидкой:') }}
                  cell(
                    cols='narrow'
                  )
                    +b.ds-caption--size_lg.--color_red.--semibold.P(
                      v-html='getSplittedHtml(cart.total_price)'
                    )
            +b.line-h--light
          +b.tt-space_mt--5.ROW.g-row--appearance_column-reverse-till-sm.--appearance_spaced(
            align='center'
            justify='center'
            v-if='freeShippingMessage'
          )
            cell(cols)
              row
                cell(cols)
                  +b.ds-caption.P
                    span.icon-shipping-car
                cell(cols)
                  +b.ds-caption--size_sm.P {{ freeShippingMessage }}
          +b.tt-space_mt--5.ROW.g-row--appearance_column-reverse-till-sm.--appearance_spaced(
            justify='center'
            align='center'
            appearance='nowrap'
            v-if='isFully'
          )
            cell(cols='auto 4-md 6-xl')
              +b.tt-link--accent.--appearance_underline.--variant_start.BUTTON.ds-caption--size_sm.is-disable-xl(
                @click.prevent='$emit("close")'
              )
                +e.title.P() {{ _(' Продолжить покупки') }}
              .is-visible-xl
                +b.tt-button--bordered_modern.--fixed-h.BUTTON(
                  @click.prevent='$emit("close")'
                )
                  +e.title.P() {{ _(' Продолжить покупки') }}
            cell(cols='12 8-md 6-xl')
              +b.tt-button--fixed-h.BUTTON.ds-caption--size_2xs.--size_sm-xl(
                @click.prevent='nextStep'
                :disabled='!cartIsValid  || !cart.total_price'
              )
                +e.title.P() {{ _('оформити замовлення') }}
          +b.tt-space_mt--5.ROW(
            justify='between'
            align='start'
            appearance='nowrap'
            v-if='!isFully'
          )
            cell(cols='4 6-xl')
            cell(cols='narrow 6-md 6-xl')
              +b.tt-button--fixed-h.BUTTON.ds-caption--size_2xs.--size_sm-xl(
                @click.prevent='$emit("close"); promise(true)'
                :disabled='!cartIsValid || !cart.total_price'
              )
                +e.title.P() {{ _('оформити замовлення') }}

              //- +b.tt-link--styling_default.A.tt-space_mt--4(
              //-   @click.prevent='triggerOneClickOrder'
              //- )
              //-   +e.title.SPAN {{ _('Замовити в один клік') }}
          transition(name='slidedown')
            +b.tt-space_mt--4(v-if='isOneClickOrderAvailable')
              one-click-order
        template(v-else)
          +b.tt-space_mt--10.ROW(
            justify='center',
            align='center',
            appearance='column'
          )
            cell(cols='12 5-xl')
              +b.ds-caption--size_lg.--medium.--appearance_center.H2 {{ _('Ваш кошик для покупок поки що порожній') }}
            +b.tt-space_mt--10.tt-space_mb--10.CELL(cols='12 5-xl')
              +b.tt-button.BUTTON(
                @click.prevent='$emit("close")'
              )
                +e.title.P() {{ _(' Продолжить покупки') }}
      div(v-else style='height: 200px')
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { addCartCoupon } from '@api/cart.service'
import CartProduct from '@app/Cart/Product'
import CartProductUi from '@app/Cart/ProductUi'
import CartSetUi from '@app/Cart/SetUi'
import OneClickOrder from '@app/Purchase/OneClickOrder'
import analyticCartView from '@analytic/cart'
import { confirmWithPromise } from '@utils/helpers'

export default {
  name: 'CartView',
  props: {
    isFully: {
      type: Boolean,
      default: true,
    },
    promise: {},
    retrieve: {
      default: true,
    },
  },
  components: {
    CartProduct,
    CartProductUi,
    OneClickOrder,
    CartSetUi,
  },
  data() {
    return {
      order_page: window.order_page,
      currency: window.currency,
      index_page: window.baseUrl,
      oneClickOrderEnabled: window.is_one_click_order_enabled,
      show: false,
      load: true,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart', 'coupon', 'hasPackages', 'cartIsLoad',
    ]),
    ...mapState('staff', [
      'level',
    ]),
    ...mapGetters('cart', [
      'cartIsValid',
    ]),
    hasGlobalDiscountFromSum() {
      return this.cart.groups.find(el => el.base.parameters.is_global_condition)
    },
    getTotalPrice() {
      return this.hasGlobalDiscountFromSum || this.cart.parameters && this.cart.parameters.promocode_title && this.cart.promocode_discount_summ ? this.cart.total_price_without_discounts : this.cart.total_price_without_site_discount
    },
    isOneClickOrderAvailable() {
      return window.authent ? this.level?.props?.allow_one_click_order : window.allow_one_click_order_for_anonim
    },
    freeShippingMessage() {
      return window.authent ? this.level?.props?.free_shipping_information : window.free_shipping_information_for_anonim
    },
    offerPackage() {
      const globalCondition = window.authent ? this.level?.props?.offer_package : window.offer_package_for_anonim
      const hasPackageInCart = this.cart.has_package
      const sellerIds = this.cart.seller_ids.includes(null)
      return globalCondition && !hasPackageInCart && sellerIds
    },
  },
  watch: {
    cartIsValid: {
      handler(nval) {
        // if (this.promise && nval) {
        //   this.promise(nval)
        //   this.$emit('close')
        // }
      },
      deep: true,
    },
  },
  async created() {
    if (!this.retrieve) {
      this.load = false
      await this.getCartAdditionalFeatures()
      return
    }
    this.getCart().then(async () => {
      this.load = false
      await this.getCartAdditionalFeatures()
    })
  },
  methods: {
    ...mapActions('cart', [
      'getCart', 'getPackages', 'clearCart',
    ]),
    ...mapActions('staff', [
      'getUserLevelSettings',
    ]),
    toPromise(arg) {
      if (!arg) return Promise.reject()

      return Promise.resolve(arg)
    },
    async getCartAdditionalFeatures() {
      if (this.cart.groups) {
        analyticCartView(this.cart.groups)
      }
      if (window.authent) {
        await this.getUserLevelSettings()
        if (this.offerPackage) this.getPackages()
        return
      }
      if (this.offerPackage) this.getPackages()
    },
    openPackage() {
      const pr = f => this.toPromise(f)
        .then(() => {
          this.initCheckout()
        })
      this.$modal.show(
        () => import('@app/Cart/PackagesView'),
        {
          promise: pr,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
          clickToClose: false,
        }
      )
    },
    nextStep() {
      if (this.offerPackage && this.hasPackages) {
        this.openPackage()
        return
      }
      this.initCheckout()
    },
    initCheckout() {
      window.location.href = this.order_page
    },
    triggerOneClickOrder() {
      this.show = !this.show
    },
    async clearPromo() {
      const question = this._('Вы действительно хотите отменить примененный промокод?')

      await confirmWithPromise(question, this)
      return addCartCoupon.execute({}, { title: null })
        .then(() => {
          this.getCart()
        })
    },
    async clearCurrentCart() {
      const question = this._('Вы действительно хотите очистить корзину?')

      await confirmWithPromise(question, this)
      return this.clearCart()
    },
    changeLoad(event) {
      console.log(event, '.>>>>>>>>>>>');
      this.load = event
    },
  },
}
</script>

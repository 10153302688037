<template lang="pug">
include /mixins
resource-loader-controller(
  :resource='$options.SocialLinksResource'
  :immediate='true'
  :parameters="parameters"
)
  template(#default='result')
    div
      +b.close-popup.A(
        @click.prevent='$emit("close")'
      )  &times;
      +b.site-main-title.ds-caption.--appearance_center.H1.tt-space_mt--7 {{ info.title }}
      +b.ds-caption--size_sm.--size_3md-xl.--appearance_center.P.tt-space_mt--2 {{ info.title_second }}
      +b.ds-caption--size_sm.--size_3md-xl.--appearance_center.P.tt-space_mt--2  {{ info.text }}
      +b.g-row--justify_center.--align_top.tt-space_mt--10
        +b.g-cell.g-cols
          +b.order-checkbox(
            :class='{"order-checkbox--mini": !isSuccess}'
          )
            +e.img.IMG(
              :src='img'
              alt=("success image")
            )
        +b.g-cell.g-cols--12.tt-space_mt--10.tt-space_mb--10
          +b.g-row--appearance_column
            +b.g-cell.g-cols--12
              +b.success-title--dark.P {{ _('Анонсы семинаров, ивентов, акций, новинок') }}
            +b.g-cell.g-cols--12
              +b.success-text--dark.P {{ _('Подписывайтесь на нас:') }}
            +b.g-cell.g-cols--12
              +b.UL.socials-order.g-row--align-center.--justify_center
                template(v-for='social_link in result.result')
                  template(v-if='social_link.props.is_show_on_success_order_page')
                    +e.LI.item.g-cell.g-cols
                    +e.LI.item.g-cell.g-cols
                      <!--noindex-->
                        +b.A.social-link-icon(
                          :href='social_link.props.url'
                          target="_blank"
                        )
                          //- aria-label=exp('social_link.service')
                          +e.icon.IMG(
                            :src='social_link.props.colored_web_icon'
                            alt=("social link")
                          )
                      <!--/noindex-->
</template>

<script>
import { SocialLinksResource } from '@api/vendor.service.js'

const SUCCESS_IMAGE = '/static/img/rafiki.svg'
const UNSUCCESS_IMAGE = '/static/img/Paper_Plane.svg'

export default {
  name: 'EventResponseModal',
  SocialLinksResource,
  props: {
    info: {
      default: () => ({}),
      type: Object,
    },
    isSuccess: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      parameters: {
        filters: {
          is_show_on_success_order_page: true,
        },
      },
      img: this.isSuccess ? SUCCESS_IMAGE : UNSUCCESS_IMAGE,
    }
  },
}
</script>

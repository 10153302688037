import {
  route,
} from '@app/Routing/helpers'

const BalanceList = () => import('@app/Staff/Balance/List')
const BalanceCharge = () => import('@app/Staff/Balance/Charge')

const MODEL = 'balance'

const BASE = `${MODEL}`
const u = url => BASE + url

const LIST = u('/')
const CHARGE = u('/charge/')

export const BALANCE_PREFIX = `${MODEL}:`
const p = name => BALANCE_PREFIX + name

export const BALANCE_BASE_NAME = p('base')
export const BALANCE_LIST_NAME = p('list')
export const BALANCE_CHARGE_NAME = p('liqpay:success')

export function createRoutes() {
  return [
    route(LIST, BalanceList, BALANCE_LIST_NAME, {
      meta: { hideVisitCard: true },
    }),
    route(CHARGE, BalanceCharge, BALANCE_CHARGE_NAME, {
      meta: { hideVisitCard: true },
    }),
  ]
}

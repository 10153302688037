<template lang="pug">
  include /mixins
  +b.reviews
    +e.title
    div(v-if='list.length')
      +b.review-view(
        v-for='review in list'
        :key='review.id'
      )
        ui-accordion(
          :init='false'
          :id='review.id'
        )
          template(#default='{ activeState, toggleActive, collapse,  style }')
            +b.tt-accordion--review(
              :class='{"ns-accordion--is-open": activeState}',
              :id='review.id'
            )

              div
                +e.question--clear(@click='toggleActive()')
                  +b.g-row--align_start-xl
                    +b.g-cell.g-cols--12.--3-xl
                      +b.ds-panel--space_md
                        +e.element--offset_full
                          +b.g-row--appearance_column
                            +b.g-cell.g-cols--12
                              +b.ds-caption--size_xs.--semibold.--appearance_uppercase.P {{ review.props.name }}
                              +b.approved-purchase.tt-space_mt--3.tt-space_mb--3(
                                v-if='review.props.is_completed_order'
                              )
                                +e.icon.icon-cart.SPAN
                                +e.check.mdi.mdi-check.SPAN
                                +e.text.P.ds-caption--color_accent.--size_xs {{ _('Уже купил') }}

                            +b.g-cell.g-cols--12
                              +b.ds-caption--size_xs.P.is-disable-xl {{ review.props.comment }}
                      +b.line-h--light.is-disable-xl
                    +b.g-cell.g-cols--12.--9-xl
                      +e.body
                        +b.ds-panel--space_md
                          +e.element--offset_full

                            +b.ds-caption--size_2xs.--appearance_right.P.is-visible-xl.review-date {{ review.props.created_at }}
                            +b.ds-caption--size_xs.P.is-visible-xl {{ review.props.comment }}
                            +b.g-row
                              +b.g-cell.g-cols--6
                                +b.tt-link--variant_start.BUTTON(
                                  @click.prevent='reply(review.id, review.id, review.props.name)'
                                )
                                  +e.icon.icon-right
                                  +e.text.P.ds-caption--color_accent.--size_xs.--light {{ _('ответить') }}
                              +b.g-cell.g-cols--6
                                +b.tt-link--variant_end.A(
                                  v-if='review.props.children.length'
                                )
                                  +e.text.P.ds-caption--color_accent.--size_xs.--light {{ _('все ответы') }} ({{ review.props.children.length }})
                                    +e.icon.mdi(
                                      :class='style.icon'
                                    )
                +e.description--spaced(:style='{maxHeight: style.myHeight}')
                  +e.question--clear(
                    v-for='child in review.props.children'
                  )
                    +b.g-row--align_start-xl
                      +b.g-cell.g-cols--12.--3-xl
                        +b.ds-panel--space_md
                          +e.element--offset_full
                            +b.g-row--appearance_column
                              +b.g-cell.g-cols--12
                                +b.ds-caption--size_xs.--medium.P {{ child.props.name }}
                                +b.approved-purchase.tt-space_mt--3.tt-space_mb--3
                                  +e.icon.icon-cart.SPAN
                                  +e.check.mdi.mdi-check.SPAN
                                  +e.text.P.ds-caption--color_accent.--size_xs {{ _('Уже купил') }}
                              +b.g-cell.g-cols--12
                                +b.ds-caption--size_xs.P.is-disable-xl  {{ child.props.comment }}
                      +b.g-cell.g-cols--12.--9-xl
                        .line-h.is-disable-xl
                        +e.body
                          +b.ds-panel--space_md
                            +e.element--offset_full
                              +b.ds-caption--size_2xs.--appearance_right.P.is-visible-xl.review-date {{ child.props.created_at }}
                              +b.ds-caption--size_xs.P.is-visible-xl {{ child.props.comment }}
                              +b.g-row
                                +b.g-cell.g-cols--6
                                  +b.tt-link--variant_start.BUTTON(
                                    @click.prevent='reply(review.id, child.id, child.props.name)'
                                  )
                                    +e.icon.icon-right
                                    +e.text.P.ds-caption--color_accent.--size_xs.--light {{ _('ответить') }}

      ui-limit-pagination(
        v-if="meta.length"
        :pagination='meta'
        @input='offset = $event.offset; getList()'
      )
    //- +b.ds-caption--size_sm.--light.P(v-else)  {{ _('Нет ни одного отзыва') }}
    +b.g-row.--justify_center
      +b.g-cell.g-cols--12.--6-sm
        +b.tt-button.is-disable-xl.BUTTON(
          @click.prevent='reply()'
        )
          +e.text.P.ds-caption--color_white {{ _('Написать отзыв') }}
</template>

<script>

import {
  ReviewListResoure,
} from '@api/vendor.service'

import AddReview from '@app/Review/Add'

export default {
  name: 'ReviewList',
  props: {
    product_type: {
      type: String,
    },
    product_id: {
      type: String,
    },
  },
  data() {
    return {
      list: {},
      offset: 0,
      limit: 3,
      meta: {},
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        type: this.product_type,
        id: this.product_id,
        offset: this.offset,
      }
      const { data: { items, pagination } } = (await ReviewListResoure
        .execute(params))
      this.list = items
      this.meta = pagination
    },
    reply(id, parent_id, name) {
      this.$modal.show(
        AddReview,
        {
          info: {
            product_type: this.product_type,
            product_id: this.product_id,
            reply_name: name,
            reply: parent_id,
            id,
          },
        }, {
          height: 'auto',
          classes: ['extralarge-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
</script>

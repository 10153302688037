import UserReview from '@app/Review-v2/UserReview'
import UserList from '@app/Review-v2/UserList'
import ReviewAdd from '@app/Review-v2/Add'
import ReviewSlimAdd from '@app/Review-v2/SlimAnswer'
import ReviewsMedia from '@app/Review-v2/Media'
import ReviewList from '@app/Review-v2/List'
import Detail from '@app/Review-v2/Detail'

export default function install(Vue, prefix = 'V2') {
  Vue.component(`${Detail.name}${prefix}`, Detail)
  Vue.component(`${UserList.name}${prefix}`, UserList)
  Vue.component(`${UserReview.name}${prefix}`, UserReview)
  Vue.component(`${ReviewAdd.name}${prefix}`, ReviewAdd)
  Vue.component(`${ReviewList.name}${prefix}`, ReviewList)
  Vue.component(`${ReviewsMedia.name}${prefix}`, ReviewsMedia)
  Vue.component(`${ReviewSlimAdd.name}${prefix}`, ReviewSlimAdd)
}

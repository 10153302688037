<template lang='pug'>
  include /mixins
  +b.d-control-input
    +e.element--fixed.--clear.SELECT(
      name='sorting',
      v-model='checked',
      id='sorting'
      @change="$emit('change', checked)",
    )
      template(
        v-for='val in values',
      )
        option(
          :key='val.key'
          :value='val.key'
        ) {{ val.title }}
    //- +e.label--bound.--for-select.LABEL(
    //-   for='sorting',
    //-   :class="{'d-control-input__label--active': Object.keys(checked).length }"
    //- ) {{ _('Сортировать по:') }}
</template>

<script>
export default {
  name: 'NativeSorting',
  props: {
    initValue: {},
    values: {},
  },
  data() {
    return {
      checked: this.initValue,
    }
  },
  watch: {
    initValue(nval) {
      this.checked = nval
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  div
    +b.section--shadowed.SECTION
      slot(name='title')
      gmap-map#map(
        :center='center',
        :zoom='zoom',
        :options='options'
      )
        gmap-cluster(
          :styles='clusterOptions',
          :minimumClusterSize='cluster',
          :zoomOnClick='true'
        )
          gmap-marker(
            :key="i"
            v-for="({ props },i) in markers",
            :position="{lat: parseFloat(props.latitude), lng: parseFloat(props.longitude)}",
            :clickable="true",
            @click="toggleInfoWindow(props,i)",
            :icon="props.category && props.category.props.img ? props.category.props.img.full_size : marker_image"
          )
        gmap-info-window(
          :options='infoOptions',
          :opened="infoWinOpen",
          @closeclick="infoWinOpen=false"
          :z-index='999',
          :position='{lat: parseFloat(infoContent.latitude), lng: parseFloat(infoContent.longitude)}'
        )
          +b.infowindow
            +b.ds-caption--size_md.--bold.P {{ infoContent.city }}
            +b.ds-caption--size_sm.P {{ infoContent.address }}
            +b.ds-caption--size_sm.P {{ infoContent.work_time }}
            +b.ds-caption--size_sm.P {{ infoContent.phone }}
            +b.ds-caption--size_sm.tt-link--appearance_hovered.--accent.A(
              :href="`http://www.google.com/maps/place/${infoContent.latitude},${infoContent.longitude}/@${infoContent.latitude},${infoContent.longitude},12z`",
              target="_blank"
            ) {{ _("Открыть в картах Google") }}
    .container
      +b.map-legend
        +b.map-icon(
          v-for='{ props } in categories'
        )
          +e.icon-wrap
            +e.icon.IMG(:src='props.image.full_size || marker_image')
          +e.desc
            +e.title.P {{ props.title }}
            +e.text(v-html='props.description')
    slot(
      name='branch',
      :branches='markers'
    )

</template>
<script>

import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { mapOptions } from '@app/UiElements/consts'

import {
  BrandsListResoure,
  BrandsCategoriesListResoure,
} from '@api/vendor.service'

const cluster_img = '/static/img/cluster.png'
const marker_image = '/static/img/marker-big.png'

export default {
  name: 'ContactsMap',
  components: {
    GmapCluster,
  },
  data() {
    return {
      marker_image,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      cluster: 5,
      clusterOptions: [{
        width: 53,
        height: 53,
        url: cluster_img,
        textColor: 'white',
        textSize: 12,
      }],
      center: { lat: 48.3830, lng: 31.1829 },
      zoom: 7,
      options: {
        styles: mapOptions,
      },
      markers: [],
      infoWinOpen: false,
      infoContent: {},
      categories: [],
    }
  },

  async created() {
    await this.getMarkers()
    await this.getCategories()
  },
  methods: {
    async getMarkers() {
      const { data: { items } } = (await BrandsListResoure.execute())
      this.markers = items
      this.infoContent = this.markers.find(el => el.is_main) || this.markers[0]
    },
    async getCategories() {
      const { data: { items } } = (await BrandsCategoriesListResoure.execute())
      this.categories = items
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = marker

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        // if different marker set infowindow
        // to open and reset current marker index
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },
  },

}
</script>

<template lang="pug">
  include /mixins
  +b.app-qr-code(
    :class="classes"
  )
    figure(class="app-qrcode")
      vue-qrcode(
        :value="value"
        tag="svg"
        :options="options"
      )
      img(
        class="app-qrcode__image"
        :src="image"
      )
</template>

<script>

export default {
  name: 'TTQrCode',
  props: ['value', 'image', 'size', 'dark', 'light', 'classes'],
  data() {
    return {
      options: {
        berrorCorrectionLevel: 'Q',
        color: {
          dark: this.dark || '#1183F6',
          light: this.light || '#68768500',
        },
      }
    }
  },
}
</script>
import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'special_offer'

const GET_ATTRS_URL = prefixAPI('/{id}/', MODEL)
const CREATE_OFFER_URL = prefixAPI('/create/', MODEL)

export const getAttrsResource = createResource(GET_ATTRS_URL)
export const createOffer = createResource(
  CREATE_OFFER_URL, postResource
)

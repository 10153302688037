<template lang='pug'>
  include /mixins
  +b.events-filters
    row(
    )
      cell.g-cols--spaced_vert-none(
        cols="12 5-xl"
      )
        +b.tt-space_pt--6
          +b.search-form--fully.event-search.TAG(
            :tag="$options.widgetsMap.search"
            :parameters="parameters"
            :value="value.search"
            :disabled="disabled"
            :filter="search_filter"
            @input="updateValue(search_filter.label, $event)"
            :size="['md', 'lg-lg']"
          )
      cell.g-cols--spaced_vert-none(
        cols="12 4-xl"
      )
        +b.new-event-filters(@click.prevent='toggleFilters')
          +e.title.P {{ _('Подбор семинаров') }}
          +e.el
            +e.text.P {{ _('Фильтров:') }} {{ filters_length }}
            +e.btn
              +e.icon.mdi.I(
                :class='[ show ? "mdi-chevron-up" : "mdi-chevron-down" ]'
              )
      cell.g-cols--spaced_vert-none(
        cols="12 3-xl"
      )
        ui-click-outside(
          :do='() => showCalendar = false'
        )
          .relative
            +b.new-event-filters(
              @click.prevent='toggleCalendar'
              :class='{ "is-active": showCalendar }'
            )
              +e.title.P {{ _('Календарь') }}
              +e.el
                +e.text.P {{ getDate() }}
                +e.btn
                  +e.icon.mdi.I(
                    :class='[ showCalendar ? "mdi-chevron-up" : "mdi-chevron-down" ]'
                  )
            +b.event-calendar(v-show='showCalendar')
              tag(
                :tag="$options.widgetsMap['date_range']"
                :parameters="parameters"
                :value="value.date_range || getMultiValueForDate('date_range')"
                :disabled="disabled"
                @input:multiple='updateSimpleMultipleValues("date_range", $event); showCalendar=false'
                @input="updateValue('date_range', $event)"
                :size="['md', 'lg-lg']"
                :params='{opens: "inline"}'
              )
    row(v-show='show')
      template(
        v-for="(filter, i) in baseFilters"
      )
        // rm date if
        cell.g-cols--spaced_vert-none(
          cols="12 3-xl"
          v-if='filter && filter.attribute && filter.attribute.values.length > 0'
          :key="`${i}test`"
        )
          +b.tt-space_mt--6
            tag(
              :tag="$options.widgetsMap[$options.TYPES_MAP[filter.attribute.label] || 'select'] "
              :parameters="parameters"
              :value="value[filter.attribute.label] || getMultiValueForDate(filter.attribute.label)"
              :disabled="disabled"
              :filter="filter.attribute"
              @input:multiple='updateSimpleMultipleValues(filter.attribute.label, $event)'
              @input="updateValue(filter.attribute.label, $event)"
              :size="['md', 'lg-lg']"
            )
      slot(
        name="prepend",
        cols="12"
      )
</template>

<script>
import UiMixin from '@app/FiltersSimple/mixins/UiMixin'

const SelectWidget = () => import('@app/Filters/Widgets/SelectWidget')
const DateRangeWidget = () => import('@app/Filters/Widgets/DateRangeWidget')
const SimpleMultiselectWidget = () => import('@app/Filters/Widgets/SimpleMultiselectWidget')
const SearchWidget = () => import('@app/Filters/Widgets/SearchWidget')

const WIDGETS_MAP = {
  date_range: DateRangeWidget,
  type: SelectWidget,
  select: SelectWidget,
  date: SearchWidget,
  search: SearchWidget,
  'simple-multiselect': SimpleMultiselectWidget,
}
const TYPES_MAP = {
  city: 'select',
  directions: 'select',
  speakers: 'simple-multiselect',
  date: 'select',
  date_range: 'date_range',
  type: 'type',
}

const EXCLUDED_KEYS = ['search', 'date_range_after', 'date_range_before']

export default {
  mixins: [UiMixin],
  widgetsMap: WIDGETS_MAP,
  TYPES_MAP,
  data() {
    return {
      labels: {
        city: this._('Город'),
        directions: this._('Направления'),
        speakers: this._('Спикер'),
        date_range: this._('Дата проведения'),
        date: this._('Дата проведения'),
        type: this._('Тип семинара'),
      },
      search_filter: {
        label: 'search',
        title: this._('Поиск по семинару'),
        id: 'search_id',
      },
      show: false,
      showCalendar: false,
    }
  },
  computed: {
    filters_length() {
      return Object.keys(this.value).filter(key => !EXCLUDED_KEYS.find(e => e === key)).reduce((acc, key) => {
        acc += this.value[key].length
        return acc
      }, 0)
    },
  },
  methods: {
    toggleFilters() {
      this.show = !this.show
    },
    toggleCalendar() {
      this.showCalendar = !this.showCalendar
    },
    test(label, event) {
    },
    getMultiValueForDate(key) {
      if ('date_range' != key) return
      if (!this.value[`${key}_after`] || !this.value[`${key}_before`]) return
      return [
        this.value[`${key}_after`],
        this.value[`${key}_before`],
      ]
    },
    getAvailableFilters(filters = {}) {
      if (!filters) return []

      // const refactor = Object.keys(filters).reduce((acc, obj, idx) => {
      //   acc = acc || []
      //   const ref = {}
      //   ref.type = TYPES_MAP[obj]
      //   ref.label = obj
      //   ref.title = this.labels[obj]
      //   console.log(obj, filters, ref);
      //   if ('date' === obj) {
      //     ref.values = filters[obj].reduce((a, o) => {
      //       a.push({
      //         id: o.label,
      //         label: o.label,
      //         caption: o.title,
      //         props: {
      //           title: o.title,
      //           label: o.label,
      //         },
      //       })
      //       return a
      //     }, [])
      //   } else {
      //     ref.values = filters[obj]
      //   }
      //   ref.id = idx
      //   acc.push(ref)
      //   return acc
      // }, [])
      return filters
      // .filter(filter => filter.type in this.$options.widgetsMap)
    },
    getDate() {
      if (this.getMultiValueForDate('date_range')) {
        const y = this.getMultiValueForDate('date_range').map(e => {
          const [value] = e
          if (value) {
            return this.prepareDate(value)
          }
        }).join('-')
        return y
      }
      return this.prepareDate().replace('.', '')
    },
    prepareDate(date = new Date()) {
      const options = {
        // year: 'numeric',
        month: 'long',
        day: '2-digit',
      }
      return date.toLocaleString(window.language, options)
    },
  },
}

</script>

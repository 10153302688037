import pipe from 'ramda/src/pipe'

function convertToBinary(number) {
  let num = number
  let binary = (num % 2).toString()
  for (let x = 0; 1 < num; x++) {
    num = parseInt(num / 2, 10)
    binary = (num % 2) + (binary);
  }
  return binary
}

export const OUT_OF_STOCK = 'OUT_OF_STOCK'
export const NOT_COSMETOLOGIST = 'NOT_COSMETOLOGIST'
export const NO_CONTRACT = 'NO_CONTRACT'
export const ALLOW_PRICE_WITHOUT_CONTRACT = 'ALLOW_PRICE_WITHOUT_CONTRACT'

const listOfVariant = {
  [OUT_OF_STOCK]: false,
  [NOT_COSMETOLOGIST]: false,
  [NO_CONTRACT]: false,
  [ALLOW_PRICE_WITHOUT_CONTRACT]: false,
}

function addZeros(i) {
  return i.padStart(Object.keys(listOfVariant).length, 0)
}

function splitAndReverse(val) {
  return val.split('').reverse()
}

export const availableInfo = value => {
  const r = pipe(convertToBinary, addZeros, splitAndReverse)(value)
  for (let x = 0; x < r.length; x++) {
    listOfVariant[Object.keys(listOfVariant)[x]] = !!Number(r[x])
  }
  return listOfVariant
}

window.availableInfo = availableInfo

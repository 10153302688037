<template lang="pug">
  include /mixins
  div
    +b.search-result-item.tt-link--variant_start.A(
      :href='link.props.url'
      v-for='link in results'
    )
      +e.info
        +e.line
        +e.line
          +e.content.ds-caption--size_xs.--appearance_uppercase.--medium.P
            span(v-html='findValueAndHighlightTerm(link.props.title, link.props.title, localsearch)')
        +e.line.tt-space_mt--2
          +e.content.ds-caption--size_2xs.--color_accent.--medium.--appearance_right.P
            span(v-html='findValueAndHighlightTerm(link.props.category.props.title, link.props.category.props.title, localsearch)')
</template>

<script>

export default {
  props: {
    findValueAndHighlightTerm: {},
    highlightTerm: {},
    results: {},
    localsearch: {},
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.app-feedback-form
    +e.wrapper
      ui-loader(v-if='isLoad')
      +b.VALIDATION-OBSERVER(
        ref="validator",
        v-slot="{ valid }",
        tag="form",
      )
        +b.g-row
          +b.g-cell.g-cols--12.tt-space_mt--6
            d-control-input(
              class="d-control-input"
              :rules="'required'",
              :input-label='_("Ваше ім‘я ")'
              v-model='form.name'
              id='name',
              name='name',
              type='text'
            )
          +b.g-cell.g-cols--12.tt-space_mt--6
            phone-input(
              :rules="'required|phone'",
              :input-label='_("Ваш номер телефону")'
              v-model='form.phone',
              name='phone'
            )
          +b.g-cell.g-cols--12.--9-lg.tt-space_mt--6
            +b.tt-button.--uppercase.tt-space_mb.tt-space_mb--2.BUTTON(
              type='submit'
              :disabled='isLoad',
              @click.prevent='prepareData(valid)'
            )
              +e.title.SPAN {{ _('Отримати консультацію') }}
</template>

<script>

import {
  createFeedback,
} from '@api/feedback.service'

import pathOr from 'ramda/src/pathOr'
import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'HelpToGetStarted',
  mixins: [FormMixin],
  data() {
    return {
      form: {
        name: '',
        phone: window.request_phone,
        subject: 103
      },
    }
  },

  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return createFeedback.execute({}, data)
        .then(res => {
          AnswerMessage(res.data, this)
          this.$emit('close')
          this.$refs.validator.reset()
          this.$refs.validator.$el.reset()
          this.form.name = ''
          this.form.phone = ''
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

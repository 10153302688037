<template lang="pug">
  include /mixins
  div.inner-speakers
    carousel-3d.speakers(
      :autoplay='true',
      :autoplay-timeout='2000',
      :display='3',
      :height='height'
      :autoplay-hover-pause='true'
      :clickable='true'
      :onMainSlideClick='goTo'
      :inverse-scaling="scale"
      :perspective='0'
      @before-slide-change="onBeforeSlideChange"
    )
      slide(
        v-for='(slide, i) in speakers',
        :index='i',
        :key='i'
      )
        +b.speaker
          +e.pic.PICTURE(v-if='slide.props.preview_main_page && slide.props.preview_main_page.full_size_webp')
            source(
              type="image/webp"
              :srcset="slide.props.preview_main_page.full_size_webp"
            )
            +e.img.IMG(
              :src='slide.props.preview_main_page.full_size'
            )
    +b.speaker-desc
      +e.title.P {{ current.caption }}
      +e.text.P {{ current.props.description }}

</template>

<script>
export default {
  name: 'SpeakersSlider',
  props: ['speakers'],
  data() {
    return {
      current: this.speakers[0],
    }
  },
  computed: {
    height() {
      const w_w = 1200
      const h = 500
      const m_h = 420
      return window.innerWidth > w_w ? h : m_h
    },
    scale() {
      const w_w = 1200
      const h = 200
      const m_h = 500
      return window.innerWidth > w_w ? h : m_h
    },
  },
  methods: {
    onBeforeSlideChange(idx) {
      this.current = this.speakers[idx]
    },
    goTo() {
      window.location.href = this.current.props.url
    },
  },

}
</script>

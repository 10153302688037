<template lang="pug">
  include /mixins
  +b.tt-button.BUTTON(
    @click.prevent='action'
    :disabled='load'
  )
    +e.title.P(v-if='!load') {{ title }}
    +e.loader(v-else)
      svg(
        xmlns="http://www.w3.org/2000/svg"
        fill='none'
        width='32'
        height='32'
        viewBox='0 0 32 32'
      )
        defs
          linearGradient(
            x1='35%'
            y1='0%'
            x2='100%'
            y2='80%'
            id='preloader-gradient'
          )
            stop(offset='20%' stop-color='var(--theme-relief-1)')
              animate(attributeName="stop-color" dur="2s" values='#1183F6; #fff;  #1183F6' repeatCount="indefinite")
            stop(offset='76%' stop-color='var(--theme-accent-blue)')
              animate(attributeName="stop-color" dur="2s" values='#fff; #1183F6; #fff' repeatCount="indefinite")
        +e.circle.CIRCLE(
          cx="50%"
          stroke='url(#preloader-gradient)'
          cy="50%"
          r='8'
          stroke-width='2'
        )
</template>
<script>
export default {
  name: 'LoaderBtn',
  props: {
    action: {
      type: Function,
      required: true,
      default: () => () => {},
    },
    load: {
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'calculate-procedure/'

const CONFIGURATOR_LIST_URL = prefixAPI('configurator/list/', MODEL)
const CALCULATE_URL = prefixAPI('calc/', MODEL)
export const CONFIGURATOR_VARIANTS_LIST_URL = prefixAPI('variant/list/{?offset,limit,search,type}', MODEL)

export const getConfiguratorList = createResource(
  CONFIGURATOR_LIST_URL
)

export const calculateProcedure = createResource(
  CALCULATE_URL, postResource
)

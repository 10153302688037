import SimpleRouterParametersController from './SimpleRouterParametersController'
import RouterParametersController from './RouterParametersController'

function install(Vue) {
  Vue.component(RouterParametersController.name, RouterParametersController)
  Vue.component(SimpleRouterParametersController.name, SimpleRouterParametersController)
}

export default {
  install,
}

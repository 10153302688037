<template lang="pug">
include /mixins
div
  slot(
    :create='createSubscription'
    :destroy='destroySubscription'
    :load='load'
    :can_create='create'
  )
    +b.tt-button.LOADER-BTN(
      @click.prevent='create ? createSubscription() : destroySubscription()'
      :action='create ? createSubscription : destroySubscription'
      :class='create ? "" : "tt-button--green"'
      :load='load'
      :title='getLabel()'
    )
</template>

<script>
import { mapState, mapActions } from 'vuex'

import {
  MakeSubscription,
  DeleteSubscription,
} from '@api/events.service'

import {
  AnswerMessage,
} from '@utils/submit'

import ParseErrorsMixin from '@app/Forms/mixins/ParseErrorsMixin'
import ResponseModal from '@app/Events/ResponseModal'
import { confirmWithPromise } from '@utils/helpers'

import {
  analyticEventSubscribe,
  analyticEventUnsubscribe,
} from '@analytic/event'

export default {
  name: 'EventSubscription',
  mixins: [ParseErrorsMixin],
  props: ['event', 'isDestroy', 'event_title'],
  data() {
    return {
      create: JSON.parse(this.isDestroy),
      load: false,
      labels: {
        create: this._('Записаться'),
        destroy: this._('Отписаться'),
      },
    }
  },
  computed: {
    ...mapState('comparison', ['events']),
  },
  watch: {
    events: {
      handler() {
        console.log(this.events);
      },
      deep: true,
    },
  },
  created() {
    this.changeValue()
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      if ('comparison/SET_EVENTS' === mutation.type) {
        setTimeout(() => {
          this.create = this.events[this.event]
        }, 500)
      }
    })
  },
  methods: {
    ...mapActions('comparison', ['setEvents']),
    getLabel() {
      return this.create ? this.labels.create : this.labels.destroy
    },
    async createSubscription() {
      this.load = true
      try {
        const { data } = (await MakeSubscription.execute({}, { event: this.event }))
        this.load = false
        this.create = false
        const meta = {
          info: {
            title: this._('Спасибо!'),
            title_second: this._('Ваша заявка на участие в семинаре успешно отправлена!'),
            text: this._('Наш менеджер свяжется с вами.'),
          },
          isSuccess: true,
        }
        this.openModal(meta)
        // window.location.href = data.redirect.location
        const action = this.create ? analyticEventUnsubscribe : analyticEventSubscribe
        action(this.event, this.event_title)
        this.changeValue()
      } catch (e) {
        this.load = false
        this.parseErrors(e, 'event')
      }
      // window.location.reload()
    },
    openModal(data) {
      this.$modal.show(
        () => import('@app/Events/ResponseModal'),
        {
          ...data,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
    changeValue() {
      this.setEvents({ value: this.create, id: this.event })
    },
    async destroySubscription() {
      const question = this._('Вы действительно хотите отменить запись?')
      this.load = true
      try {
        await confirmWithPromise(question, this)
        await DeleteSubscription.execute({ id: this.event }, {})
        this.load = false
        // window.location.href = window.event_unsubscribe_page
        const meta = {
          info: {
            title: this._('Заявка на участие отменена!'),
            text: this._('Спасибо, что были с нами'),
          },
          isSuccess: false,
        }
        this.openModal(meta)
        this.create = true
        const action = this.create ? analyticEventUnsubscribe : analyticEventSubscribe
        action(this.event, this.event_title)
        this.changeValue()
      } catch (e) {
        this.load = false
      }
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      create: () => this.createSubscription(),
      destroy: () => this.destroySubscription(),
      can_create: this.create,
      load: this.load,
    })
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.ds-panel
      +e.element--offset_vertical.--indent_inside
        +b.ds-caption--size_2xs.--size_sm-xl.P.tt-space_mt--6-xl {{ _('Если вы зарегистрированый клиент, введите Ваш номер телефона и пароль. ') }}
        +b.ds-caption--size_2xs.--size_sm-xl.tt-space_mt--4.--0-xl.P {{ _('Если вы еще не зарегистрированы на нашем сайте, то перейдите в раздел “Новый покупатель” и заполните информацию. ') }}
    +b.g-row
      +b.g-cell.g-cols--12.--6-xl.tt-space_mt--6
        phone-input(
          :rules="'required|phone'",
          :input-label='labels.phone'
          v-model='form.username',
          ref='phone'
          name='username'
          @animationstart='test'
          tabindex='1'
        )

      +b.g-cell.g-cols--12.--6-xl.tt-space_mt--6
        d-control-input(
          :rules="{ 'required': is_recovery }",
          :input-label='labels.password'
          v-model='form.password'
          autocomplete='new-password-5'
          id='password',
          type='password',
          name='password',
          tabindex='1'
          @enter='prepareData(valid)'
        )
        div
          token-confirmation-check(
            :token='form.username'
            :action='goToPasswordSetPage'
          )
            template(#default='{ is_confirmed, sendCode }')
              +b.tt-link--variant_end.--styling_default.--accent.A(
                @click.prevent='validate(sendCode)'
                tabindex='-1'
              )
                +e.text.ds-caption--size_xs.P.--color_accent {{ _('Забыли пароль?') }}
    .relative
      +b.non-field-error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    +b.g-row--justify_end
      +b.g-cell.g-cols--12.--4-xl.tt-space_mt--10
        +b.tt-button--for-input.BUTTON(
          type='submit'
          :disabled='isLoad',
          tabindex='1'
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('войти') }}

</template>
<script>
import loginMixin from '@app/Forms/mixins/login'

export default {
  name: 'OrderLogin',
  mixins: [loginMixin],
  data() {
    return {
      url: window.password_set_url,
      is_recovery: true,
    }
  },
  // mounted() {
  //   const state = new URLSearchParams(window.location.search).get('_state')
  //   console.log(JSON.parse(atob(state)), '>>>>>>>>>>>');
  //   const { phone } = JSON.parse(atob(state))
  //   this.form.username = phone
  // },
  methods: {
    test(e) {
      console.log(e, '!!!!!!!!!!!!!!');
    },
    async validate(action) {
      this.$refs.validator.reset()
      this.is_recovery = false
      this.$nextTick(async () => {
        const isValid = (await this.$refs.validator.validate())
        if (!isValid) return
        action()
      })
      // this.$emit('validate', { valid, step })
    },
    goToPasswordSetPage(id) {
      const data = {
        phone: this.form.username,
        id,
      }
      window.location.href = `${this.url}?_state=${btoa(JSON.stringify(data))}`
    },
  },
}
</script>

import { withDefaultProps } from '@aspectus/vue-utils'
// import DescriptorWithSmartLabel
// from './Layout/DescriptorWithSmartLabel'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'
import BoundLabel from '@app/UiElements/Controls/BoundLabel'
import StaticLabel from '@app/UiElements/Controls/StaticLabel'
import ErrorComponent from '@app/UiElements/Controls/ErrorComponent'
import DateRange from '@app/UiElements/Controls/DateRange'
import Calendar from '@app/UiElements/Controls/DatePicker'
import Captcha from '@app/UiElements/Controls/Captcha'

import UnifiedControlElement from './Elements/UnifiedControlElement'

const composerForInput = (input, label = BoundLabel) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
})(UnifiedControlElement)

const composer = (input, label = BoundLabel) => withDefaultProps({
  element: () => input,
  labelComponent: () => label,
  errorComponent: () => ErrorComponent,
  bails: true,
  controllerComponent: () => NoneController,
})(Descriptor)
// input with bound label
const InputWithLabel = composerForInput('Input')
const ControlInput = composer(InputWithLabel)
// input with static input
const InputWithStaticLabel = composerForInput('Input', StaticLabel)
const ControlStaticInput = composer(InputWithStaticLabel)
// multiselect
const MultiselectWithLabel = composerForInput('MultiSelect')
const ControlMultiselect = composer(MultiselectWithLabel)
// // multiselect
// const MultiselectWithStaticLabel = composerForInput('MultiSelect')
// const ControlMultiselect = composer(MultiselectWithLabel)
// textarea
const TextareaWithLabel = composerForInput('Textarea')
const ControlTextarea = composer(TextareaWithLabel)
// daterange
const DateRangeWithLabel = composerForInput(DateRange)
const ControlDateRange = composer(DateRangeWithLabel)
// daterange
const CalendarWithLabel = composerForInput('Calendar')
const ControlCalendar = composer(CalendarWithLabel)
// Recaptcha
const RecaptchaWithLabel = composerForInput('Captcha')
const ControlRecaptcha = composer(RecaptchaWithLabel)

export default function install(Vue, { prefix = 'DControl' } = {}) {
  Vue.component(`${prefix}Input`, ControlInput)
  Vue.component(`${prefix}StaticInput`, ControlStaticInput)
  // Vue.component(`${prefix}StaticMultiselect`, ControlStaticMultiselect)
  Vue.component(`${prefix}Multiselect`, ControlMultiselect)
  Vue.component(`${prefix}Textarea`, ControlTextarea)
  Vue.component(`${prefix}DateRange`, ControlDateRange)
  Vue.component(`${prefix}Calendar`, ControlCalendar)
  Vue.component(`${prefix}Recaptcha`, ControlRecaptcha)
}

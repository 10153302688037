import {
  createResource,
  deleteResource,
  postResource,
  postFormDataPrettifierResource,
} from '@resource/resource'

import {
  prefixAPI,
} from '@resource/api'

const REVIEW_MODEL = 'reviews'

// reviews
const REVIEW_CREATE_URL = prefixAPI('/create/', REVIEW_MODEL)
const REVIEW_LIST_URL = prefixAPI('/list/{?id,type,offset,limit}', REVIEW_MODEL)
const REVIEW_RETRIEVE_URL = prefixAPI('/retrieve/{id}/', REVIEW_MODEL)
const REVIEW_SELLER_LIST_URL = prefixAPI('/list/{review_type}/by-shop/{?filters*,id,type,offset,limit}', REVIEW_MODEL)
const REVIEW_PHOTO_CREATE_URL = prefixAPI('/photos/create/', REVIEW_MODEL)
const REVIEW_VIDEO_CREATE_URL = prefixAPI('/videos/create/', REVIEW_MODEL)
const REVIEW_PHOTO_REMOVE_URL = prefixAPI('/photos/destroy/{id}/', REVIEW_MODEL)
const REVIEW_VIDEO_REMOVE_URL = prefixAPI('/videos/destroy/{id}/', REVIEW_MODEL)
const REVIEW_MEDIA_URL = prefixAPI('/{key}/list/{?type,id}', REVIEW_MODEL)

// reviews
export const ReviewCreate = createResource(REVIEW_CREATE_URL, postResource)
export const ReviewPhotoCreate = createResource(REVIEW_PHOTO_CREATE_URL, postFormDataPrettifierResource)
export const ReviewVideoCreate = createResource(REVIEW_VIDEO_CREATE_URL, postResource)
export const ReviewPhotoRemove = createResource(REVIEW_PHOTO_REMOVE_URL, deleteResource)
export const ReviewVideoRemove = createResource(REVIEW_VIDEO_REMOVE_URL, deleteResource)
export const ReviewListResoure = createResource(REVIEW_LIST_URL)
export const ReviewRetrieveResoure = createResource(REVIEW_RETRIEVE_URL)
export const ReviewSellerListResoure = createResource(REVIEW_SELLER_LIST_URL)
export const ReviewMediaList = createResource(REVIEW_MEDIA_URL)

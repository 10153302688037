import {
  categoriesPrettifier,
} from '@analytic/utils'

export default function analyticCartAdd(
  name, id, price, quantity, brand = '', categories = ''
) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []

  const [category] = categories
  window.dataLayer.push({
    event: 'eec.add',
    ecommerce: {
      add: {
        actionField: {
          list: 'Shopping cart',
        },
        products: [{
          name,
          id,
          brand,
          category,
        }],
      },
    },
  })

  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: window.currency_code,
      items: [{
        item_name: name,
        item_id: id,
        price,
        item_brand: brand,
        ...categoriesPrettifier(categories),
        quantity,
      }],
    },
  })
}

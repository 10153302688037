export const toggleLoader = value => {
  const loader = document.getElementById('social-loader')
  if (!loader) return
  loader.style.display = value ? 'block' : 'none'
}

const ios = 'ios'
const macos = 'macos'

const DEVICES_LIST = {
  bsd: ['BSD'],
  android: ['Android'],
  [ios]: ['iPhone', 'iPad', 'iPod'],
  windows: ['Windows'],
  [macos]: ['Mac OS'],
  unix: ['x11'],
  webos: ['webOs'],
  tizen: ['Tizen'],
  harmonyos: ['HarmonyOS'],
  linux: ['Linux'],
}

export const APPLE_AVAILABLE_OS_LIST = [ios, macos]

export function getOs() {
  let os = ''
  const { userAgent } = window.navigator
  Object.keys(DEVICES_LIST).forEach(key => {
    DEVICES_LIST[key].forEach(r => {
      const regexp = new RegExp(`${r}`, 'img')
      const result = regexp.exec(userAgent)
      if (result) os = key
    })
  })
  return os
}

export function isIosSafari() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/iPod/i) || !!ua.match(/Mac OS/i);
  const webkit = !!ua.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  return iOSSafari;
}

<template lang="pug">

  include /mixins

  +b.TABLE.ui-table
    +e.THEAD.thead
      slot(name='table-head',
          :table-fields='tableFields')
        slot(name='table-head-grouping',
            :table-fields='tableFields')
        +e.TR.tr
          +e.TH.th(v-for='field in tableFields',
                      :class='cellClasses(field)')
            slot(:name='slotName("table-head-cell", field.key)',
                  :field='field')
              slot(name='table-head-cell',
                  :field='field') {{ field.label }}
          +e.TH.th.--last.--action(v-if=`hasSlot('table-body-row-actions')`)
            +b.icon.--cog.--s-bg
    +e.TBODY.tbody
      +b.ds-panel(
        v-if='items && !items.length'
      )
        +e.element--offset_full
          +b.ds-caption--size_sm.P(
          ) {{ _('Нет данных для отображения.') }}
      slot(v-for='(item, index) in items',
            name='table-body-row',
            :item='item',
            :table-fields='tableFields',
            :index='index')
        slot(name='table-body-row-before',
            :item='item',
            :table-fields='tableFields')
        +e.TR.tr(:class='rowClasses(item, index)',
                  :ref="'row'+index",
                  @click='$emit("changeVal", { index: index, event: $event })'
                  )
          +e.TD.td(v-for='(field) in tableFields',
                    :data-cell='field.label'
                    :class='cellClasses(field)')
            slot(:name='slotName("table-body-cell", field.key)',
                  :item='item',
                  :index='index'
                  :table-fields='tableFields',
                  :field='field',
                  :value='field.getValue(item)')
              slot(name='table-body-cell',
                  :item='item',
                  :table-fields='tableFields',
                  :field='field',
                  :value='field.getValue(item)') {{ field.getValue(item) }}

          +e.TD.td.--last.--action(v-if=`hasSlot('table-body-row-actions')`)
            slot(name='table-body-row-actions',
                :item='item',
                :table-fields='tableFields',
                :index='index')
        slot(name='table-body-row-after',
            :item='item',
            :table-fields='tableFields')

    +e.TFOOT.footer
      slot(name='table-footer',
          :table-fields='tableFields',
          :items='items')

</template>

<script>

// import { toggleActivity } from '@app/directives'
export default {
  name: 'UiTable',
  props: {
    tableFields: Array,
    items: [Array, Set],

    options: {
      type: Object,
      default() { return {} },
    },
  },
  // directives: {
  //   toggleActivity
  // },
  data() {
    return {
      double: 2,
    }
  },
  mounted() {

  },
  methods: {

    hasSlot(name) {
      return this.$slots[name] || this.$scopedSlots[name]
    },

    slotName(prefix, value) {
      return `${prefix}-${value}`
    },

    cellClasses(field) {
      return {
        [`list-table__cell--field-${field.key}`]: true,
      }
    },

    rowClasses(item, index) {
      return {
        'list-table__row--odd': (index % this.double) && this.options.striped,
        'list-table__row--clickable': this.options.clickable,
        'list-table__row--highlight': item.show,
        'list-table__row--is-new': item.is_unread,
      }
    },
  },
}

</script>

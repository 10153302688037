<template lang='pug'>
  include /mixins
  div
    sorting(
      v-if='needSorting'
      :values='sortlist'
      :disabled="disabled"
      :value="value.order || ['-date']"
      :needTitle='needTitle',
      :needSearch='needSearch',
      @input="updateValue('order', $event)"
    )
      template(
        #title='',
      )
        +b.ds-caption--size_sm.--light.P.is-visible-xl(

        ) {{ _('Найдено') }} {{ pagination.total }} {{ _('товаров') }}
      template(
        #search=''
      )
        div
          tag(
            :tag="$options.widgetsMap.search"
            :parameters="parameters"
            :value="value.search"
            :disabled="disabled"
            :filter="search_filter"
            @input="updateValue(search_filter.label, $event)"
            :size="['md', 'lg-lg']"
          )
    //-separate search
    .search-position
      row
        cell(cols="12 4-md")
          tag(
            v-if="!needSorting && needSearch"
            :tag="$options.widgetsMap.search"
            :parameters="parameters"
            :value="value.search"
            :disabled="disabled"
            :filter="search_filter"
            @input="debounceSearch(search_filter.label, $event)"
            :size="['md', 'lg-lg']"
          )
    tag(
      name="filters-modal",
      width='100%'
      height='100vh'
      :adaptive='true'
      @before-open='blockWindow'
      @before-close='unblockWindow'
      :tag='isDesktopView ? "div" : "modal"'
    )
      +b.filter-wrap
        +e.close.SPAN(
          @click.prevent='$modal.hide("filters-modal")'
        ) &times;
        +e.head

          +b.g-row--justify_between.--appearance_spaced.--space_xl.--align_center.is-disable-xl
            +b.g-cell.g-cols
              +b.ds-caption--size_sm.--bold.P {{ _('Фильтры') }}
            +b.g-cell.g-cols
              +b.tt-link--styling_default.--bordered_grey.BUTTON(
                @click.prevent='$emit("input", {})'
              )
                +e.title.SPAN {{ _('Очистить') }}
        +e.body

          slot(
            name="prepend",
            cols="12"
          )
          div(
            cols="12"
            v-for="filter in baseFilters"
            :key="filter.attribute.id"

          )
            template(v-if="filter.attribute.values.length")
              ui-accordion(
                :init='value[filter.attribute.label] || filter.is_expand'
                v-show='!hideMap[filter.attribute.label]'
              )
                template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
                  +b.tt-accordion--bordered(
                    :class='{"tt-accordion--is-open": activeState }',
                  )
                    +e.question.--simple.--spaced_bottom-none.--clear-all(
                      @click='toggleActive()',
                    )
                      +e.icon.mdi.I(:class='activeState ? "mdi-minus" : "mdi-plus"')
                      +e.text
                        +b.ds-caption--size_sm.--medium.--color_grey.SPAN  {{ filter.attribute.title }}
                    +e.description.--scroller(
                      :style='{maxHeight: "500px"}',
                      v-show='activeState'
                    )
                      +e.search.tt-space_mb--3(
                        v-if='"flat" == filter.view_type && filter.attribute.values.length > $options.ATTRIBUTES_FOR_SEARCH_COUNT'
                      )
                        +b.seller-param--variant_spaced(
                          :class='{"is-active": activeState}'
                        )
                          .relative.search-input
                            +b.A.search-trigger--left.--fixed-width
                              +e.loop.icon-search
                            +b.d-control-input--spaced_left.D-CONTROL-INPUT(
                              :name='`search_${filter.attribute.label}`',
                              :placeholder='_("Поиск")',
                              v-model='filter.search',
                              @input='searchValues(filter, filter.attribute)'
                            )
                      +b.tt-space_mb--3
                        tag(
                          :tag="widgetGetter(filter)"
                          :available="available"
                          :is-dynamic='!!filter.type'
                          :parameters="parameters"
                          :value="value && value[filter.attribute.label]"
                          :all='value'
                          :disabled="disabled",
                          :custom-check='$options.CUSTOM_DEPENDENCY == filter.attribute.label'
                          :filter="filter"
                          :label='label'
                          :base-url='baseUrl'
                          @input="updateValue(filter.attribute.label, $event)"
                          @category-hack='$emit("category-hack", $event)'
                          @hide='checkHideOptions'
                          :size="['md', 'lg-lg']"
                        )
          div(
            cols="12"
            key="prices"
            v-if='prices'
          )
            +b.tt-accordion(
              :class='"tt-accordion--is-open"',
            )
              +e.question.--simple.--spaced_bottom-none.--clear-all(
              )
                +e.text
                  +b.ds-caption--size_sm.SPAN  {{ _('Цена') }}
              +e.description(
                :style='{ maxHeight: "initial" }',
              )
                +b.tt-space_mb--3
                  tag(
                    :tag="$options.widgetsMap.prices"
                    :available="available"
                    :parameters="prices"
                    :value="getMultiValue('price')"
                    :disabled="disabled"
                    :filter="prices"
                    @input="updateMultipleValues('price', $event)"
                    :size="['md', 'lg-lg']"
                  )
          slot(
            name="append",
            cols="12"
          )
        +e.controls
           +b.ds-panel
            +e.element--offset_full
              +b.tt-button--mini.BUTTON(
                @click.prevent='$modal.hide("filters-modal")'
              )
                +e.title.SPAN {{ _('Показать') }}
</template>

<script>
import UiMixin from '@app/Filters/mixins/UiMixin'

const CUSTOM_DEPENDENCY = 'category'
const ATTRIBUTES_FOR_SEARCH_COUNT = 20

export default {
  mixins: [UiMixin],
  props: [
    'isDesktop',
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'needSorting',
    'needTitle',
    'needSearch',
  ],
  ATTRIBUTES_FOR_SEARCH_COUNT,
  CUSTOM_DEPENDENCY,
  data() {
    return {
      search: '',
      search_filter: {
        label: 'search',
        title: this._('Поиск'),
        id: 'search_id',
      },
      hideMap: {},
      windowWidth: 0,
    }
  },
  created() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => this.windowWidth = window.innerWidth)
  },
  computed: {
    isDesktopView() {
      return this.windowWidth > 1200
    },
  },
  methods: {
    blockWindow() {
      console.log('OPEN');
      this.$body.dataset.blocked = true
    },
    unblockWindow() {
      this.$body.dataset.blocked = false
      console.log('CLOSE')
    },
    widgetGetter(filter) {
      if (this.$options.CUSTOM_DEPENDENCY === filter.attribute.label && this.isStoreCatalog) {
        return this.$options.widgetsMap.category
      }
      return this.$options.widgetsTypeMap[filter.view_type] || this.$options.widgetsTypeMap.flat
    },
    checkHideOptions({ key, value }) {
      this.hideMap[key] = value
      // this.$emit('hide', { key, value })
    },
  },
}

</script>

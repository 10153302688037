<template lang="pug">
  include /mixins
  //- div
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.g-row
      template(
        v-for='{ rules, name, label, hasTip, mask, tag, condition, ...rest } in form_fields'
      )
        +b.g-cell.g-cols--12.tt-space_mt--6.relative(
          :class='`g-cols--${rest.cols}`'
          v-if='condition ? condition() : true'
        )
          tag(
            :tag='tag || "d-control-input"'
            :rules="rules",
            :input-label='label'
            v-model='form[name]',
            :ref='name'
            @input='$emit("update", form)'
            :name='name'
            v-bind='rest'
            v-on='rest.events'
          )
          +b.order-tips(
            v-if='hasTip'
            :title='tip'
          )
            +e.title.P {{ _('Для статуса заказа') }}
    +b.g-row--justify_end
      +b.g-cell.g-cols.tt-space_mt--10
        +b.fixed-order-btn(
        )
          token-confirmation-check(
            :token='authUser ? form.email : form.phone'
            :additional-data='getAdditionalData()'
            :action='(code) => validate(valid, code)'
          )
            template(#default='{ is_confirmed, sendCode }')
              +b.tt-button--for-input.BUTTON(
                :disabled='isLoad'
                v-if='valid && !authUser'
                @click.prevent='sendCode(true)'
              )
                +e.title--mini.P {{ _('далее') }}
              +b.tt-button--for-input.BUTTON(
                :disabled='isLoad'
                v-if='valid && authUser'
                @click.prevent='sendCode(true)'
              )
                +e.title--mini.P {{ _('далее') }}
              +b.tt-button--for-input.BUTTON(
                type='submit'
                :disabled='isLoad',
                v-if='!valid'
                @click.prevent='validate(valid)'
              )
                +e.title--mini.P {{ _('далее') }}
</template>

<script>

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
  UKRAINE_CODE,
} from '@app/consts'

export default {
  name: 'NewUser',
  props: ['fields'],
  UKRAINE_CODE,
  data() {
    return {
      isLoad: false,
      authUser: window.authent,
      tip: this._('Укажите Ваш e-mail. Уведомление об изменении статуса заказа будут приходить на указанный Вами электронный адрес.'),
      form_fields: [
        {
          name: 'last_name',
          label: this._('Ваша фамилия'),
          rules: 'required',
          cols: getCookie(COUNTRY_CODE_COOKIE_NAME) === UKRAINE_CODE ? '4-md' : '6-md',
        },
        {
          name: 'first_name',
          label: this._('Ваше имя'),
          rules: 'required',
          cols: getCookie(COUNTRY_CODE_COOKIE_NAME) === UKRAINE_CODE ? '4-md' : '6-md',
        },
        {
          name: 'middle_name',
          label: this._('Ваше отчество'),
          condition: () => getCookie(COUNTRY_CODE_COOKIE_NAME) === UKRAINE_CODE,
          rules: 'required',
          cols: getCookie(COUNTRY_CODE_COOKIE_NAME) === UKRAINE_CODE ? '4-md' : '6-md',
        },
        {
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone|min:9',
          disabled: !!window.authent,
          tag: 'phone-input',
          cols: '6-md',
          events: {
            input: value => {
              if (this.phone && this.phone !== value) {
                this.$emit('validate', { valid: false, step: 2 })
              }
            },
          },
        },
        {
          name: 'email',
          label: this._('Электронная почта'),
          rules: 'required|email',
          hasTip: true,
          cols: '6-md',
        },
      ],
      form: {
        first_name: this.fields.first_name,
        last_name: this.fields.last_name,
        middle_name: this.fields.middle_name,
        email: this.fields.email,
        phone: this.fields.phone,
        register: false,
        id_token: null,
      },
      offer: window.offer,
      rules: window.rules,
      phone: null,
      email: null,
    }
  },
  watch: {
    fields: {
      handler() {
        Object.keys(this.form).forEach(key => {
          this.form[key] = this.fields[key]
        })
        this.$emit('update', this.form)
      },
      immediate: false,
      deep: true,
    },
  },
  methods: {
    getAdditionalData() {
      return {
        is_new_user: !!!this.authUser,
        skip_user_verification_for_minor_users: true,
        is_change_user: true,
        automatic_login: true,
        verified: this.authUser ? this.phone || this.email : false,
        way: this.authUser ? 'email' : 'default',
      }
    },
    async validate(valid, code) {
      console.log('VALIDAYE');
      const step = 2
      const v = (await this.$refs.validator.validate())
      console.log(v);
      if (code) {
        this.form.id_token = code
      }
      this.phone = this.form.phone
      this.email = this.form.email
      this.$emit('update', this.form)
      this.$emit('validate', { valid: v, step })
    },
  },

}
</script>

<template lang="pug">
include /mixins
+b.g-row.--space_md.--space_xl-xl.--appearance_spaced.--justify_center-md.--justify_start-xl
  template(v-for='(item, index) in item.conditions')
    +b.g-cell.g-cols--12.--auto-md
      +b.trip-terms-element
        +e.head.tt-space_mb--4
          template(v-if='item.conditions && item.conditions.length > 1')
            +e.index.ds-caption--size_xs.--medium.tt-space_mr--2 {{ index + 1 }}
          +e.title.ds-caption--size_2md.--medium.P Выкуп всего ассортимента бренда Glymed на сумму 4000 евро!!!
        +e.deposit-block.tt-space_mb--4
          +e.sum-block
            +b.ds-caption--size_sm.--medium.tt-space_mb--1.P {{ _('Общая сумма') }}:
            +e.sum.P
              +b.deposit.ds-caption--size_lg.--semibold.SPAN(v-html='getSplittedHtml(item.props.actual_sale, "€")')
              +b.full-amount.ds-caption--size_sm.--medium.SPAN &nbsp; / &nbsp;
              +b.full-amount.ds-caption--size_sm.--medium.SPAN(v-html='getSplittedHtml(item.props.purchase_value, "€")')
          +b.percent.ds-caption--size_sm.--semibold.SPAN {{ getPercent(item.props.actual_sale, item.props.purchase_value) }}%
        +e.button
        +b.tt-button.A(
          href="/"
        )
          +e.title {{ _('Выбрать товары для этой закупки') }}
</template>

<script>

import { tripRetrieveResource } from '@api/vendor.service'

export default {
  props: {
    trip: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      conditions: [],
      condition: {
        trip: this.trip,
        name: '',
        description: '',
      },
      item: {},
    };
  },
  mounted() {
    this.getTripInfo();
  },
  methods: {
    async getTripInfo() {
      const { data } = (await tripRetrieveResource.execute({ label: this.trip }))
      this.item = data.props
    },
    getPercent(value, from) {
      return Math.round((value / from) * 100);
    },
    addCondition() {
      this.$emit('add-condition', this.condition);
      this.condition = {
        trip: this.trip,
        name: '',
        description: '',
      };
    },
  },
}

</script>

import bodymovin from 'lottie-web'

const breakpoint = 768

window.load_catalog_animation = () => {
  bodymovin.loadAnimation({
    container: document.getElementById('catalog_animation'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '/static/animation/catalog/data.json',
  })
}
window.load_intense_animation = () => {
  bodymovin.loadAnimation({
    container: document.getElementById('intense_animation'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '/static/animation/online/data.json',
  })
}
window.load_school_animation = () => {
  const speakers = bodymovin.loadAnimation({
    container: document.getElementById('school_animation'),
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: '/static/animation/speekers/data.json',
  })
  speakers.addEventListener('DOMLoaded', e => {
    speakers.renderer.layerElement.removeAttribute('clip-path')
  })
}

window.load_tree_animation = () => {
  if (window.innerWidth < breakpoint) {
    bodymovin.loadAnimation({
      container: document.getElementById('mobile-tree'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/static/animation/tree/data.json',
    })
  } else {
    const desc = bodymovin.loadAnimation({
      container: document.getElementById('tree'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/static/animation/d-tree/data.json',
      rendererSettings: {
        scaleMode: 'noScale',
        clearCanvas: false,
        progressiveLoad: true,
        hideOnTransparent: true,
      },
    })
    desc.addEventListener('DOMLoaded', e => {
      desc.renderer.layerElement.removeAttribute('clip-path')
    })
  }
}

const threshold = 0.7
const options = {
  root: null,
  rootMargin: '100px',
  threshold,
}
export const animationHandler = selector => {
  const targets = document.querySelectorAll(selector)

  function animationScroll(targets, self) {
    targets.forEach(entry => {
      if (entry.isIntersecting && !entry.target.classList.contains('played')) {
        const { id } = entry.target
        entry.target.classList.add('played')

        window[`load_${id}`]()
      }
    })
  }

  const observer = new IntersectionObserver(animationScroll, options)
  const ar = [].slice.call(targets)

  ar.forEach(target => {
    observer.observe(target)
  })
}

document.addEventListener('DOMContentLoaded', () => {
  animationHandler('.js-main-anim')
})

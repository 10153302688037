<script>
export default {
  name: 'GalleryProductView',
  props: {
    images: Array,
  },
  data() {
    return {
      activeImageIndex: 0,
      ready: true,
    }
  },
  computed: {
    activeImage() {
      console.log(this.activeImageIndex);
      return this.images[this.activeImageIndex]
    },
  },
  methods: {
    setActiveIndex(index) {
      this.activeImageIndex = index
      const zoom = [...document.getElementsByClassName('refszoom')]
      if (!zoom.length) return
      if (this.activeImage.images) {
        zoom.forEach(el => el.style.display = 'none')
      } else {
        zoom.forEach(el => el.style.display = 'flex')
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      active_index: this.activeImageIndex,
      set_active_index: this.setActiveIndex,
      active_image: this.activeImage,
      ready: this.ready,
    })
  },

}
</script>

<template lang="pug">
  include /mixins

  +b.ds-panel.tt-space_mt--10
    +e.element--offset_vertical
      +b.ds-caption--size_3md.--color_white.P {{ _('Страна') }}
      +b.g-row
        +b.g-cell.g-cols--12.tt-space_mt--4(
          :class='`g-cols--${country_config.size}-xl`'
        )
          tag.contact-branch-select(
            :tag='country_config.tag'
            v-model='form[country_config.name]'
            :input-label='country_config.title',
            :rules='country_config.rules'
            v-bind='country_config.props'
            v-on='country_config.events'
            :name='country_config.name'
          )
            template(#option="{ option }")
              +b.switcher-with-dropdown
                +e.current--dark
                  +e.img-wrap
                    +e.img.IMG(
                      :src='option.props.icon'
                    )
                  +e.link--dark.ds-caption--size_sm.--appearance_uppercase.SPAN {{ option.props.title }}
            template(#singleLabel="{ option }")
              +b.switcher-with-dropdown
                +e.current--dark
                  +e.img-wrap
                    +e.img.IMG(
                      :src='option.props.icon'
                    )
                  +e.link--dark.ds-caption--size_sm.--appearance_uppercase.SPAN {{ option.props.title }}
      template(
      )
        +b.ds-caption--size_3md.--color_white.P.tt-space_mt--4 {{ _('Выберите город') }}
        +b.g-row
          +b.g-cell.g-cols--12.tt-space_mt--4(
            :class='`g-cols--${city_config.size}-xl`'
          )
            tag.contact-branch-select(
              :tag='city_config.tag'
              v-model='form[city_config.name]'
              :input-label='city_config.title',
              :rules='city_config.rules'
              v-bind='city_config.props'
              v-on='city_config.events'
              :name='city_config.name'
            )
              template(
                #afterList=''
              )
                div(v-observe-visibility="city_config.afterList" v-if="city_config.props.pagination.next")
      template(
        v-if='form.city'
      )
        template(
          v-for='el in groups'
        )
          +b.branch-title.P(
          ) {{ el[0].props.category && el[0].props.category.props.title || "" }}
          template(
            v-for='{ props }, index in el'
          )
            +b.branch-view
              +e.title.P {{ props.city_title }}
              +e.info
                +e.text.P {{ props.address }}
                +e.text.P {{ props.work_time }}
                +e.text--as-link.A(
                  v-for='phone in getSplittedPhones(props.phone)'
                  :href='`tel:${phone}`'
                ) {{ phone }}
                +e.text--as-link.A(
                  :href='`mailto:${props.email}`'
                ) {{ props.email }}
                +e.text--as-link.A(
                  :href="`http://www.google.com/maps/place/${props.latitude},${props.longitude}/@${props.latitude},${props.longitude},12z`",
                  target="_blank"
                ) {{ _('Открыть в картах Google') }}
      template(
        v-if='organization && organization.props && organization.props.medical_consultant_phones'
      )
        +b.branch-title.P(
        ) {{ _('Медицинский консультант:') }}
        +b.branch-view(
          v-for='phone in getSplittedPhones(organization.props.medical_consultant_phones)'
        )
          +e.text--as-link.A(
            :href='`tel:${phone}`'
          ) {{ phone }}

</template>

<script>

import {
  BrandsListResoure,
  SettlementsListResoure,
  countriesListResource,
  OrganizationListResource,
} from '@api/vendor.service'

import {
  groupBy,
  pathGetter,
} from '@utils/helpers'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
  POLAND_CODE,
  UKRAINE_CODE,
  CZECH_CODE,
  MOLDOVA_CODE,
  SLOVAKIA_CODE,
  UZBEKISTAN_CODE,
} from '@app/consts'

const FIRST_PAGE_OFFSET = 0

const ISO_CODES = {
  [POLAND_CODE]: 'PL',
  [UKRAINE_CODE]: 'UK',
  [CZECH_CODE]: 'CS',
  [MOLDOVA_CODE]: 'MD',
  [SLOVAKIA_CODE]: 'SK',
  [UZBEKISTAN_CODE]: 'UZ',
}

export default {
  name: 'ContactsBranches',
  POLAND_CODE,
  UKRAINE_CODE,
  CZECH_CODE,
  MOLDOVA_CODE,
  SLOVAKIA_CODE,
  UZBEKISTAN_CODE,
  ISO_CODES,
  data() {
    return {
      medicalCenter: this._('38 095 278 15 15'),
      countries: '',
      code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      form: {
        city: '',
        country: '',
      },
      city_config: {
        size: '12',
        title: '',
        name: 'city',
        tag: 'd-control-multiselect',
        events: {
          open: $event => this.getCities($event, 'new_post_courier', 'city'),
          'search-change': $event => this.getCities($event, 'new_post_courier', 'city'),
          input: () => this.getBranches(),
        },
        afterList: visible => { this.getCities('', 'new_post_courier', 'city', true) },
        props: {
          pagination: {},
          options: [],
          label: 'caption',
          customLabel: ({ props }) => `${'ru' == window.language ? props.description_ru : props.description}`,
          searchable: true,
          preserveSearch: true,

        },
      },
      country_config: {
        size: '12',
        title: '',
        name: 'country',
        tag: 'd-control-multiselect',
        events: {
        //   open: $event => this.getCities($event, 'new_post_courier', 'country'),
        //   'search-change': $event => this.getCities($event, 'new_post_courier', 'country'),
          input: () => { this.clearMarkers(); this.showCountry() },
        },
        // afterList: visible => { this.getCities('', 'new_post_courier', 'country', true) },
        props: {
          pagination: {},
          options: [],
          label: 'caption',
          customLabel: ({ props }) => props.title,
          searchable: false,
          preserveSearch: false,
          trackBy: 'id',
          hideSelected: true,
        },
      },
      cityQuery: '',
      initOption: {
        $isDisabled: true,
        caption: this._('-выберите'),
        city: this._('-выберите'),
        props: {
          description: this._('-выберите'),
          description_ru: this._('-выберите'),
          address: this._('-выберите'),
          city: this._('-выберите'),
          streets_type: '',
        },
      },
      groups: [],
      organization: {},
    }
  },
  computed: {
    currentCountryCode() {
      return this.form.country?.props?.iso_code
    },
  },
  mounted() {
    this.getCountries()
    // this.setMarker(this.groups)
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.setMarker(this.groups)
      }, 300)
    })
  },
  methods: {
    getSplittedPhones(phone) {
      return phone.split(', ')
    },
    async getCountries() {
      const { data } = (await countriesListResource.execute())
      this.country_config.props.options = data
      this.form.country = this.country_config.props.options.find(e => e.props.iso_code === this.code)
      this.showCountry()
    },
    showCountry() {
      this.getOrganization()
      const label = document.getElementById('UK-label')
      const countries = Array.from(document.getElementsByClassName('country-map'))
      countries.forEach(c => c.classList.add('is-hidden'))
      countries.filter(c => c.id === `${ISO_CODES[this.currentCountryCode]}-map`).forEach(c => c.classList.remove('is-hidden'))
      console.log(label);
      label.firstChild.innerText = this.form.country.props.title
      console.log(this.form.country.props.title);
      this.form.city = null
      this.groups = []
      this.getBranches(this.currentCountryCode === POLAND_CODE)
    },
    async getCities(event, service, kye, push) {
      let offset = this.city_config.props.pagination.offset +
        this.city_config.props.pagination.limit
      if (!push) offset = FIRST_PAGE_OFFSET
      this.cityQuery = event

      const {
        data: {
          items, pagination,
        },
      } = (await SettlementsListResoure
        .execute({
          search: this.cityQuery,
          offset,
          country_available: this.currentCountryCode,
        }))
      this.city_config.props.pagination = pagination
      if (push) {
        this.city_config.props.options.push(...items)
      } else {
        this.city_config.props.options = items
        this.city_config.props.options.unshift(this.initOption)
      }
    },

    async getBranches(sendCountry) {
      const { data: { items } } = (await BrandsListResoure.execute({
        settlement: this.form.city?.id,
        country_available: sendCountry ? this.currentCountryCode || this.code : null,
      }))
      const groups = groupBy(items, pathGetter('props', 'category', 'id'))
      this.groups = Object.keys(groups).reduce((acc, x) => {
        const g = groups[x][0]
        if (g) {
          if (g.props.category) {
            acc[`${g.props.category.props.sort_order}${g.props.category.id}`] = groups[x]
          } else {
            acc[0] = groups[x]
          }
        }
        return acc
      }, {})
      this.setMarker(this.groups)
    },

    setMarker(groups) {
      const COUNTRY_CODE = this.currentCountryCode || this.code
      let dots = [...document.getElementsByClassName('region-marker')]
      const map = document.getElementById(`${ISO_CODES[COUNTRY_CODE]}-map`)

      const props = Object.keys(groups).reduce((acc, x) => {
        acc = groups[x][0].props
        return acc
      }, {})
      if (map) {
        const k = map.clientWidth / map.width.animVal.value
        const margin = (map.parentNode.clientWidth - map.clientWidth) / 2
        const regions = Array.from(map.getElementsByClassName('land'))
        regions.forEach(e => e.classList.remove('is-active'))

        const { region } = props
        const activeParent = map.getElementById(region)

        if (activeParent && (this.currentCountryCode === POLAND_CODE || this.currentCountryCode === UKRAINE_CODE && this.form.city)) {
          activeParent.classList.add('is-active')
        }
        const reg = new RegExp(`^${ISO_CODES[COUNTRY_CODE]}`, '')
        dots = dots.filter(d => reg.exec(d.dataset.dotRegion))
        dots.forEach(dot => {
          const {
            dotRegion,
            x,
            y,
          } = dot.dataset
          const parent = map.getElementById(dotRegion)
          dot.classList.remove('is-active')
          dot.classList.add('is-show')
          const position = parent.getBBox()
          const left = ((position.x + (position.width / 2)) * k) + parseFloat(x) + margin
          const top = ((position.y + (position.height / 2)) * k) + parseFloat(y)
          dot.style.setProperty('--left', `${left}px`)
          dot.style.setProperty('--top', `${top}px`)
          if (dotRegion === region) {
            dot.classList.add('is-active')
          }
        })
      }
    },
    clearMarkers() {
      const dots = [...document.getElementsByClassName('region-marker')]
      dots.forEach(dot => dot.classList.remove('is-show'))
      const regions = Array.from(document.getElementsByClassName('land'))
      regions.forEach(e => e.classList.remove('is-active'))
    },
    async getOrganization() {
      const { data: { items } } = (await OrganizationListResource.execute({ country_available: this.currentCountryCode }))
      const [data] = items
      this.organization = data
    },
  },
}
</script>

// import { getCLS, getFID, getLCP } from 'web-vitals'

const text = 'Created by https://webcase.com.ua/'

const styles = `
  padding: 32px 32px 32px 40px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMyIgaGVpZ2h0PSIzMyIgdmlld0JveD0iMCAwIDMzIDMzIj48Zz48Zz48cGF0aCBmaWxsPSIjODI3M2IyIiBkPSJNMTYuNDc3IDBjOS4wOTggMCAxNi40NzYgNy4zODkgMTYuNDc2IDE2LjVTMjUuNTc1IDMzIDE2LjQ3NyAzM0M3LjM3OCAzMyAwIDI1LjYxIDAgMTYuNVM3LjM3OCAwIDE2LjQ3NyAwek01LjcgMTYuNWMwIDUuOTU5IDQuODI2IDEwLjc5MiAxMC43NzcgMTAuNzkyIDUuOTUgMCAxMC43NzYtNC44MzMgMTAuNzc2LTEwLjc5MiAwLTUuOTYtNC44MjYtMTAuNzkyLTEwLjc3Ni0xMC43OTJDMTAuNTI2IDUuNzA4IDUuNyAxMC41NDEgNS43IDE2LjV6bTEwLjc3Ny01LjQ1NWE1LjQ1MiA1LjQ1MiAwIDAgMSA1LjQ0NyA1LjQ1NSA1LjQ1MiA1LjQ1MiAwIDAgMS01LjQ0NyA1LjQ1NSA1LjQ1MiA1LjQ1MiAwIDAgMS01LjQ0OC01LjQ1NSA1LjQ1MiA1LjQ1MiAwIDAgMSA1LjQ0OC01LjQ1NXoiLz48L2c+PC9nPjwvc3ZnPg==');
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: 0 50%;
  font-size: 12px;
  font-weight: bold;
  color: #12d5b8; 
  font-size: 18px; 
  font-weight: 900; 
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
`

export default function WEBCASEConsole() {
  console.log(`%c ${text} `, styles)
}

// getCLS(console.log, true)
// getFID(console.log)
// getLCP(console.log, true)

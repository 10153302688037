<template lang="pug">
  include /mixins
  +b.ds-panel--space_2xl.--space_3-xl.relative
    +e.element--offset_full
      +b.g-row--justify_center
        +b.g-cell.g-cols--12
          +b.ds-caption--size_lg.--size_xl-xl.--appearance_center.--semibold.--color_grey.H2.main-title {{ question }}
        +b.g-cell.g-cols.tt-space_mt--8
          +b.tt-button--bordered_main.modal-btn.A(
            @click.prevent='submitForm(false)'
          )
            +e.title.P {{ _("Нет") }}
        +b.g-cell.g-cols--1-xl
        +b.g-cell.g-cols.tt-space_mt--8
          +b.tt-button.modal-btn.A(
            @click.prevent='submitForm(true)'
          )
            +e.title.P {{ _('Да') }}
</template>
<script>
export default {
  name: 'Confirm',
  props: ['promise', 'question'],
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    submitForm(value) {
      this.promise(value)
      this.$emit('close')
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  div
    +b.g-row--appearance_spaced-till-xl.tt-space_pt--3-xl(
      v-if='!show'
    )
      +b.g-cell.g-cols--12.--6-md(
        v-for='({ count, title }) in steps'
      )
        +b.order-step(
          :class='{ "is-active": count == currentStep }',
          @click.prevent='changeStep(count)'
        )
          +e.number.SPAN {{ count }}
          +e.title.P {{ title }}
    slot(
      :current='currentStep',
      :change='changeStep'
    )
</template>
<script>
export default {
  name: 'StepsController',
  props: ['valid', 'show'],
  data() {
    return {
      currentStep: 1,
      steps: [
        {
          count: 1,
          title: this._('Контактные данные'),
        },
        {
          count: 2,
          title: this._('Информация о доставке'),
        },

      ],
    }
  },
  methods: {
    changeStep(count) {
      if (this.valid) {
        this.currentStep = count
      }
      this.$emit('stepChange', this.currentStep)
    },
  },
}
</script>

import {
  createResource,
  postResource,
  postFormDataResource,
  patchFormDataResource,
  patchResource,
  deleteResource,
  optionsResource,
  postFormDataPrettifierResource,

} from '@resource/resource'

import {
  simpleTransformer,
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const MODEL = 'staff'
const MODEL_PERSONAL = `${MODEL}/personal-profile`
const MODEL_PROFILE = `${MODEL}/profile`
const MODEL_INFO = `${MODEL}/info`
const MODEL_CONTACTS = `${MODEL_INFO}/contacts`
const MODEL_POSITIONS = `${MODEL_CONTACTS}/positions`
const MODEL_DOCUMENTS = `${MODEL}/documents`
const MODEL_EXTERNAL = 'external'
const MODEL_SHIPPING = 'shipping-addresses'

export const CONTRACT_REQUEST = prefixAPI('/contract/create/', MODEL)

export const CONNECTION_CHANNELS_LIST_URL = prefixAPI('/connection-channel/list/', MODEL)

export const USER_RETRIVE_URL = prefixAPI('/user/retrieve/', MODEL)
export const USER_UPDATE_URL = prefixAPI('/user/update/', MODEL)
export const USER_LEVEL_SETTINGS_URL = prefixAPI('/user/level/country-settings/', MODEL)
export const LEVEL_CHANGE_REQUEST_URL = prefixAPI('/user/level-change-request/create/', MODEL)
export const DOCUMENT_CHANGE_REQUEST_URL = prefixAPI('/user/level-change-request/documents/create/', MODEL)
export const STAFF_LEVELS_URL = prefixAPI('/levels/list/', MODEL)
export const STAFF_LEVELS_ADVANTAGES_URL = prefixAPI('/levels/advantages/list/', MODEL)
export const PERSONAL_PROFILE_URL = prefixAPI('/retrieve/', MODEL_PROFILE)
export const PERSONAL_PROFILE_UPDATE_URL = prefixAPI('/update/', MODEL_PROFILE)
export const PERSONAL_PROFILE_CREATE_URL = prefixAPI('/create/', MODEL_PROFILE)
export const STAFF_ORDERS_URL = prefixAPI('joint-orders/list/{?filters*,offset,limit}')
export const STAFF_BONUSES_URL = prefixAPI('/list-bonuses/', MODEL_EXTERNAL)
export const STAFF_BALANCE_URL = prefixAPI('/full-balance/', MODEL_EXTERNAL)
export const USER_DATA_UPDATE_URL = prefixAPI('/update-user/', MODEL_EXTERNAL)
export const PERSONAL_PROFILE_SPECIALIZATION_URL = prefixAPI('/specializations/list/', MODEL)

export const SOCIAL_NETWORK_LIST_URL = prefixAPI('organization/social_network/list/')

export const CREATE_TOP_UP_URL = '/payments/novapay-checkout/create/'

export const DOCUMENTS_CONFIGS_LIST = prefixAPI('/configs/list/', MODEL_DOCUMENTS)
export const DOCUMENTS_ENTITIES_LIST = prefixAPI('/entities/list/', MODEL_DOCUMENTS)
export const DOCUMENTS_ENTITIES_UPDATE = prefixAPI('/entities/update/', MODEL_DOCUMENTS)
export const DOCUMENTS_FILES_CREATE = prefixAPI('/files/create/', MODEL_DOCUMENTS)
export const DOCUMENTS_FILES_DESTROY = prefixAPI('/files/destroy/{id}/', MODEL_DOCUMENTS)

// phones
export const PERSONAL_PROFILE_PHONES_UPDATE_URL = prefixAPI('/phones/', MODEL_PROFILE)
export const PERSONAL_PROFILE_PHONES_SINGLE_URL = prefixAPI('/phones/{id}/', MODEL_PERSONAL)
// social
export const PERSONAL_PROFILE_SOCIAL_UPDATE_URL = prefixAPI('/social-links/', MODEL_PROFILE)
export const PERSONAL_PROFILE_SOCIAL_SINGLE_URL = prefixAPI('/social-links/{id}/', MODEL_PROFILE)
// diplomas
export const PERSONAL_PROFILE_DIPLOMAS_UPDATE_URL = prefixAPI('/diplomas/', MODEL_PROFILE)
export const PERSONAL_PROFILE_DIPLOMAS_SINGLE_URL = prefixAPI('/diplomas/{id}/', MODEL_PROFILE)
// works
export const PERSONAL_PROFILE_WORKS_UPDATE_URL = prefixAPI('/works/', MODEL_PROFILE)
export const PERSONAL_PROFILE_WORKS_SINGLE_URL = prefixAPI('/works/{id}/', MODEL_PROFILE)
// employee
export const PERSONAL_PROFILE_EMPLOYEES_UPDATE_URL = prefixAPI('/employees/', MODEL_PROFILE)
export const PERSONAL_PROFILE_EMPLOYEES_SINGLE_URL = prefixAPI('/employees/{id}/', MODEL_PROFILE)
// interiors
export const PERSONAL_PROFILE_INTERIORS_UPDATE_URL = prefixAPI('/interiors/', MODEL_PROFILE)
export const PERSONAL_PROFILE_INTERIORS_SINGLE_URL = prefixAPI('/interiors/{id}/', MODEL_PROFILE)
// schedule
export const PERSONAL_PROFILE_SCHEDULES_UPDATE_URL = prefixAPI('/work-schedule/', MODEL_PROFILE)
export const PERSONAL_PROFILE_SCHEDULES_SINGLE_URL = prefixAPI('/work-schedule/{id}/', MODEL_PROFILE)

// info
export const INFO_DESTROY_URL = prefixAPI('/destroy/', MODEL_INFO)
export const USER_INFO_TYPES_URL = prefixAPI('/types/list/', MODEL_INFO)
// contacts
export const CONTACTS_BASE_URL = prefixAPI('/', MODEL_CONTACTS)
export const CONTACTS_SINGLE_URL = prefixAPI('/{id}/', MODEL_CONTACTS)
// positions
export const POSITIONS_LIST_URL = prefixAPI('/list/', MODEL_POSITIONS)

export const SHIPPING_ADDRESSES_LIST_URL = prefixAPI('/', MODEL_SHIPPING)

export const userRetrieve = createResource(USER_RETRIVE_URL)
export const userUpdateRequest = createResource(
  USER_UPDATE_URL, patchResource
)
export const userUpdateFormRequest = createResource(
  USER_UPDATE_URL, patchFormDataResource
)
export const levelsList = createResource(STAFF_LEVELS_URL)
export const levelsAdvantagesList = createResource(STAFF_LEVELS_ADVANTAGES_URL)
export const specializationsList = createResource(
  PERSONAL_PROFILE_SPECIALIZATION_URL
)
export const personalProfileRetrieve = createResource(PERSONAL_PROFILE_URL)
export const personalProfileUpdate = createResource(
  PERSONAL_PROFILE_UPDATE_URL,
  patchFormDataResource
)
export const personalProfileCreate = createResource(
  PERSONAL_PROFILE_CREATE_URL,
  postFormDataResource
)
export const personalProfileJsonUpdate = createResource(
  PERSONAL_PROFILE_UPDATE_URL,
  patchResource
)
export const ordersList = createResource(STAFF_ORDERS_URL)
export const balanceList = createResource(STAFF_BALANCE_URL)
export const bonusesList = createResource(STAFF_BONUSES_URL)

// phones
export const personalProfilePhonesRetrieve = createResource(
  PERSONAL_PROFILE_PHONES_UPDATE_URL
)
export const personalProfilePhonesCreate = createResource(
  PERSONAL_PROFILE_PHONES_UPDATE_URL,
  postResource
)
export const personalProfilePhoneUpdate = createResource(
  PERSONAL_PROFILE_PHONES_SINGLE_URL,
  patchResource
)
export const personalProfilePhoneDelete = createResource(
  PERSONAL_PROFILE_PHONES_SINGLE_URL,
  deleteResource,
  simpleTransformer
)
// social
export const personalProfileSocialsRetrieve = createResource(
  PERSONAL_PROFILE_SOCIAL_UPDATE_URL
)
export const personalProfileSocialsCreate = createResource(
  PERSONAL_PROFILE_SOCIAL_UPDATE_URL,
  postResource
)
export const personalProfileSocialsOptions = createResource(
  PERSONAL_PROFILE_SOCIAL_UPDATE_URL,
  optionsResource
)
export const personalProfileSocialUpdate = createResource(
  PERSONAL_PROFILE_SOCIAL_SINGLE_URL,
  patchResource
)
export const personalProfileSocialDelete = createResource(
  PERSONAL_PROFILE_SOCIAL_SINGLE_URL,
  deleteResource,
  simpleTransformer
)

// diplomas

export const personalProfileDiplomasRetrieve = createResource(
  PERSONAL_PROFILE_DIPLOMAS_UPDATE_URL
)
export const personalProfileDiplomasCreate = createResource(
  PERSONAL_PROFILE_DIPLOMAS_UPDATE_URL,
  postFormDataPrettifierResource
)
export const personalProfileDiplomaUpdate = createResource(
  PERSONAL_PROFILE_DIPLOMAS_SINGLE_URL,
  patchResource
)
export const personalProfileDiplomaDelete = createResource(
  PERSONAL_PROFILE_DIPLOMAS_SINGLE_URL,
  deleteResource,
  simpleTransformer
)
// Works

export const personalProfileWorksRetrieve = createResource(
  PERSONAL_PROFILE_WORKS_UPDATE_URL
)
export const personalProfileWorksCreate = createResource(
  PERSONAL_PROFILE_WORKS_UPDATE_URL,
  postFormDataPrettifierResource
)
export const personalProfileWorkUpdate = createResource(
  PERSONAL_PROFILE_WORKS_SINGLE_URL,
  patchResource
)
export const personalProfileWorkDelete = createResource(
  PERSONAL_PROFILE_WORKS_SINGLE_URL,
  deleteResource,
  simpleTransformer
)

// employee
export const personalProfileEmployeesRetrieve = createResource(
  PERSONAL_PROFILE_EMPLOYEES_UPDATE_URL
)
export const personalProfileEmployeesCreate = createResource(
  PERSONAL_PROFILE_EMPLOYEES_UPDATE_URL,
  postFormDataPrettifierResource
)
export const personalProfileEmployeesUpdate = createResource(
  PERSONAL_PROFILE_EMPLOYEES_SINGLE_URL,
  patchResource
)
export const personalProfileEmployeesDelete = createResource(
  PERSONAL_PROFILE_EMPLOYEES_SINGLE_URL,
  deleteResource,
  simpleTransformer
)

// interiors
export const personalProfileInteriorsRetrieve = createResource(
  PERSONAL_PROFILE_INTERIORS_UPDATE_URL
)
export const personalProfileInteriorsCreate = createResource(
  PERSONAL_PROFILE_INTERIORS_UPDATE_URL,
  postFormDataPrettifierResource
)
export const personalProfileInteriorsUpdate = createResource(
  PERSONAL_PROFILE_INTERIORS_SINGLE_URL,
  patchResource
)
export const personalProfileInteriorsDelete = createResource(
  PERSONAL_PROFILE_INTERIORS_SINGLE_URL,
  deleteResource,
  simpleTransformer
)

// schedules
export const personalProfileSchedulesRetrieve = createResource(
  PERSONAL_PROFILE_SCHEDULES_UPDATE_URL
)
export const personalProfileSchedulesCreate = createResource(
  PERSONAL_PROFILE_SCHEDULES_UPDATE_URL,
  postFormDataPrettifierResource
)
export const personalProfileSchedulesUpdate = createResource(
  PERSONAL_PROFILE_SCHEDULES_SINGLE_URL,
  patchResource
)
export const personalProfileSchedulesDelete = createResource(
  PERSONAL_PROFILE_SCHEDULES_SINGLE_URL,
  deleteResource,
  simpleTransformer
)

// contacts
export const contactsRetrieve = createResource(CONTACTS_BASE_URL)
export const contactsCreate = createResource(
  CONTACTS_BASE_URL, postFormDataPrettifierResource
)
export const contactsUpdate = createResource(
  CONTACTS_SINGLE_URL, patchResource
)
export const contactsDelete = createResource(
  CONTACTS_SINGLE_URL, deleteResource, simpleTransformer
)

// positions
export const positionsRetrieve = createResource(POSITIONS_LIST_URL)

// infos
export const infoDelete = createResource(
  INFO_DESTROY_URL, deleteResource, simpleTransformer
)
// infos
export const getUserTypes = createResource(
  USER_INFO_TYPES_URL
)

// docs for level request
export const docForLevelRequestCreate = createResource(
  DOCUMENT_CHANGE_REQUEST_URL,
  postFormDataPrettifierResource
)
// level request
export const levelRequestCreate = createResource(
  LEVEL_CHANGE_REQUEST_URL,
  postResource
)

// User documents
export const documentsConfigsList = createResource(
  DOCUMENTS_CONFIGS_LIST
)
export const documentsEntitiesList = createResource(
  DOCUMENTS_ENTITIES_LIST
)
export const documentsEntitiesUpdate = createResource(
  DOCUMENTS_ENTITIES_UPDATE, patchResource
)
export const documentsFilesCreate = createResource(
  DOCUMENTS_FILES_CREATE, postFormDataPrettifierResource
)
export const documentsFilesDelete = createResource(
  DOCUMENTS_FILES_DESTROY, deleteResource, simpleTransformer
)

// transaction
export const liqpayRequestCreate = createResource(
  CREATE_TOP_UP_URL, postResource
)

export const addressBookList = createResource(
  SHIPPING_ADDRESSES_LIST_URL
)
// Price contract
export const contractRequest = createResource(
  CONTRACT_REQUEST, postResource
)

// Connection channels
export const connectionChannelsList = createResource(
  CONNECTION_CHANNELS_LIST_URL
)

export const getSocialNetworkList = createResource(
  SOCIAL_NETWORK_LIST_URL
)

export const getUserLevelSettings = createResource(
  USER_LEVEL_SETTINGS_URL
)
export const updateUserData = createResource(
  USER_DATA_UPDATE_URL
)

import pathOr from 'ramda/src/pathOr'

import {
  countriesListResource,
} from '@api/vendor.service'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

const SET_COUNTRY = 'SET_COUNTRY'
const SET_LIST = 'SET_LIST'

export function createStore(parameters) {
  return {
    namespaced: true,
    state: {
      iso_code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      selectedCountry: {},
      countriesList: [],
    },
    actions: {
      async getCountries({ commit, state, dispatch }) {
        const { data } = (await countriesListResource.execute())
        commit(SET_LIST, data)
        const country = data.find(c => c.props.iso_code === state.iso_code)
        dispatch('saveCountry', country)
        return country
      },
      saveCountry({ commit }, payload) {
        commit(SET_COUNTRY, payload)
      },
    },
    mutations: {
      [SET_COUNTRY](state, payload) {
        state.selectedCountry = payload
      },
      [SET_LIST](state, payload) {
        state.countriesList = payload
      },
    },
  }
}

<script>
import {
  createResource,
} from '@resource/resource'
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 12,
    },
    next: {

    },
    resource: {},
    search: {},
    category: {},
  },
  data() {
    return {
      offset: 0,
      localList: this.list,
      localNext: this.next,
    }
  },
  computed: {
    splicedList: {
      get() {
        return this.localList
      },
      set(e) {
        this.localList = e
      },
    },
  },
  methods: {
    getNext() {
      this.offset += this.limit
      this.fetchData()
    },
    getPrev() {
      this.offset -= this.limit
      this.fetchData()
    },
    async fetchData() {
      this.$emit('load:start')
      const { data: { items } } = (await createResource(this.resource).execute({
        search: this.search,
        limit: this.limit,
        offset_by_product: this.offset,
        // limit_by_category: this.limit,
        filters: {
          [this.category]: this.offset,
        },
      }))
      let result = items[0]
      console.log(this.category, items);
      if (this.category) {
        result = items.find(e => e.category.id === this.category)
      }
      console.log(result);
      this.splicedList = result.variants
      this.localNext = result.next
      this.$emit('load:end')
    },
  },
  render() {
    return this.$scopedSlots.default({
      splicedList: this.splicedList,
      hasNext: !!this.localNext,
      hasPrev: this.offset,
      getPrev: () => this.getPrev(),
      getNext: () => this.getNext(),
    })
  },
}
</script>

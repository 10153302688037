import {
  getComparisonList,
  getComparisonIdsList,
} from '@api/comparison.service'

export function createStore(parameters) {
  return {
    namespaced: true,
    state: {
      comparison_ids: [],
      list: [],
      events: {},
    },
    getters: {
      canCreateSubscription: state => id => state.events[id],
    },
    actions: {
      setEvents({ commit }, payload) {
        commit('SET_EVENTS', payload)
      },
      addId({ commit }, payload) {
        commit('ADD_TO_COMPARISON', payload)
      },
      async getList({ commit }, payload) {
        const { data: { items } } = (await getComparisonList.execute())
        commit('SET_LIST', items)
      },
      async getComparisonList({ commit }, payload) {
        const items = (await getComparisonIdsList.execute())
        commit('SET_IDS_LIST', items)
      },
    },
    mutations: {
      SET_EVENTS(state, payload) {
        state.events[payload.id] = payload.value
        console.log(state.events);
      },
      ADD_TO_COMPARISON(state, payload) {
        state.comparison_ids.push(payload)
      },
      SET_LIST(state, payload) {
        state.list = payload
        state.comparison_ids = state.list.map(el => el.id)
      },
      SET_IDS_LIST(state, payload) {
        state.comparison_ids = payload
      },
    },
  }
}

<template lang="pug">
include /mixins
div
  ui-loader(v-if='localload')
  template(v-for='item, index in cart.gifts')
    product-set-of-gifts-view(
      v-if='index == id '
      :set='item'
      :id='id'
    )
  product-set-view(
    :set='set'
    :id='id'
    v-on='productListeners'
    :gifts='cart.gifts'
  )

</template>
<script>
import { mapActions, mapState } from 'vuex'
import { confirmWithPromise } from '@utils/helpers'

import ProductSetView from './Variants/Set'
import ProductSetOfGiftsView from './Variants/SetOfGifts'

export default {
  name: 'ProductSetUi',
  components: {
    ProductSetView,
    ProductSetOfGiftsView,
  },
  props: {
    set: {
      default: () => { },
    },
    load: {},
    id: {},
  },
  data() {
    return {
      localload: false,
      inWork: false,
    }
  },

  computed: {
    ...mapState('cart', [
      'cart',
    ]),
    campaignInfo() {
      const [, id, number] = this.id.split('_')
      return this.cart.parameters.complects_info
        .find(i => i.campaign_id === Number(id) && i.number_complect === Number(number))
    },
    productListeners() {
      return {
        ...this.$listeners,
        removeFromCart: () => this.removeFromCart(),
        updateCart: event => this.updateCart(event),
      }
    },
    productProps() {
      const { relations, base, price } = this.product
      return {
        load: this.load,
        product: base,
        relations,
        price,
        disabled: this.$attrs.disabled,
      }
    },
  },
  methods: {
    ...mapActions('cart', [
      'changeCart',
    ]),
    async updateCart({ info, campaign }) {
      const changes = info.count - this.campaignInfo.complect_count
      console.log(changes);
      if (!changes) return
      if (this.inWork) return
      this.inWork = true
      this.localload = true
      const entities = this.set.reduce((acc, variant) => {
        const init = variant.base.quantity / this.campaignInfo.complect_count
        acc.push({
          element: {
            id: variant.base.element.id,
            type: variant.base.element.type,
          },
          item_id: variant.base.id,
          parameters: {
            number_complect: this.campaignInfo.number_complect,
          },
          quantity: changes * init,
        })
        return acc
      }, [])
      this.changeCart({ data: { entities }, retrieve: true })
        .then(() => {
          this.$nextTick(() => {
            this.localload = false
            this.$emit('cart:update')
            this.inWork = false
          })
        }, err => {
          this.inWork = false
          this.localload = false
        })
    },
    async removeFromCart() {
      const question = this._('Вы действительно хотите удалить товар с корзины?')
      try {
        await confirmWithPromise(question, this)
        const count = -1
        const obj = {
          count,
        }
        this.updateCart({ info: obj, campaign: { complect_count: 0 } }, true)
      } catch (e) {
      }
    },
  },
}
</script>

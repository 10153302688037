<template lang="pug">
  include /mixins
  +b.reviews
    +e.title
    div(v-if='list.length')
      +b.review-view--shadowed(
        v-for='review in list'
        :key='review.id'
      )
        +b.ds-panel--space_md
          +e.element--offset_full
            +b.g-row--justify_between.is-disable-xl
              +b.g-cell.g-cols--narrow
                +b.ds-caption--size_xs.--semibold.--appearance_uppercase.P {{ review.props.name }}
              +b.g-cell.g-cols--narrow
                +b.ds-caption--size_2xs.--appearance_right.P.review-date {{ review.props.created_at }}
            +b.ds-panel--space_md
              +e.element--offset_vertical
                +b.ds-caption--size_xs.--size_xsm-xl.P {{ review.props.comment }}
            .is-visible-xl
              +b.g-row
                +b.g-cell.g-cols--narrow
                  +b.ds-caption--size_xs.--size_xsm-xl.--semibold.--appearance_uppercase.P {{ review.props.name }}
                +b.g-cell.g-cols--narrow
                  +b.ds-caption--size_2xs.--appearance_right.P.is-visible-xl.review-date {{ review.props.created_at }}
        +b.line-h--light
      ui-limit-pagination(
        :pagination='meta'
        @input='offset = $event.offset; getList()'
      )
    +b.ds-caption--size_sm.--light.P(v-else)  {{ _('Нет ни одного отзыва') }}

</template>

<script>

import {
  ReviewListResoure,
} from '@api/vendor.service'

import AddReview from '@app/Review/Add'

export default {
  name: 'UserReviewList',
  props: {
    product_type: {
      type: String,
    },
    product_id: {
      type: String,
    },
  },
  data() {
    return {
      list: {},
      offset: 0,
      limit: 3,
      meta: {},
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const params = {
        type: this.product_type,
        id: this.product_id,
        offset: this.offset,
      }
      const { data: { items, pagination } } = (await ReviewListResoure
        .execute(params))
      this.list = items
      this.meta = pagination
    },
    reply(id, parent_id, name) {
      this.$modal.show(
        AddReview,
        {
          product_type: this.product_type,
          product_id: this.product_id,
          reply_name: name,
          reply: parent_id,
          id,
        }, {
          height: 'auto',
          classes: ['extralarge-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
</script>

import {
  createResource,
  postResource,
  putResource,
  deleteResource,

} from '@resource/resource'
import {
  simpleTransformer,
} from '@resource/transformer'

import { prefixAPI } from '@resource/api'

const MODEL_SHIPPING = 'shipping-addresses'

export const SHIPPING_ADDRESSES_LIST_URL = prefixAPI('/{?limit,offset,is_not_available,only_available,country_available,for_order,for_preorder,filters*}', MODEL_SHIPPING)
export const SHIPPING_ADDRESSES_RETRIEVE_URL = prefixAPI('/{id}/', MODEL_SHIPPING)

export const addressBookList = createResource(
  SHIPPING_ADDRESSES_LIST_URL
)
export const addressBookAdd = createResource(
  SHIPPING_ADDRESSES_LIST_URL,
  postResource
)

export const addressBookRetrieve = createResource(
  SHIPPING_ADDRESSES_RETRIEVE_URL
)
export const addressBookUpdate = createResource(
  SHIPPING_ADDRESSES_RETRIEVE_URL,
  putResource
)
export const addressBookDelete = createResource(
  SHIPPING_ADDRESSES_RETRIEVE_URL,
  deleteResource,
  simpleTransformer
)

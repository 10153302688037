<template lang="pug" functional>
  include /mixins
  +b.ds-panel--space_2md.--space_lg-xl(:class='data.staticClass')
    +b.close-popup.mdi.mdi-close.BUTTON(
      @click.prevent='listeners.close'
      v-if='!props.hideClose'
    )
    +e.element--offset_full
      //TODO: component dynamic import
      slot
        tag(
          :tag='props.info.tag',
          @close='listeners.close',
          :info='props.info'
          :classes='props.classes'
        )
</template>
<script>
export default {
  name: 'ModalWrapper',
  props: ['info', 'classes', 'hideClose'],
}
</script>

<template lang="pug">
  include /mixins
  resource-loader-controller(
    :resource="$options.getRequisitesList"
    ref='controller'
    :immediate='true'
  )
    template(#default='{ receive, result, loading }')
      div.relative()
        ui-loader(v-if='loading')
        template(
          v-if='result && result.length'
        )
          slot(name='content', :result='result')
            +b.ds-caption--size_md.--size_lg-xl.--size_xl-fhd.--medium.P {{ _('Реквізити для оплати') }}
            +b.requisite(
              v-for='item in result'
            )
              +e.data
                +e.placeholder.P {{ item.props.title }}
                +e.value.P {{ item.props.value }}
              +e.copy.mdi.mdi-content-copy(
                :title='_("Нажміть, щоб скопіювати")'
                v-clipboard:copy="item.props.value"
                v-clipboard:success="() => $notify({'title': _('Дані успішно скопійовані')})"
              )
        slot
</template>

<script>
import { getRequisitesList } from '@api/order.service'

export default {
  name: 'PaymentRequisites',
  getRequisitesList,
}
</script>

import VuejsClipper from 'vuejs-clipper'

import CreateResult from './Create'
import ListResult from './List'
import ResultLoader from './Loader'

export default function install(Vue) {
  Vue.component(CreateResult.name, CreateResult)
  Vue.component(ListResult.name, ListResult)
  Vue.component(ResultLoader.name, ResultLoader)
  Vue.use(VuejsClipper, {
    components: {
      clipperFixed: 'image-clipper-basic',
      clipperPreview: 'my-preview',
      clipperUpload: 'clipper-upload',
      clipperRange: 'clipper-range',
    },
  })
}

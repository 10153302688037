<template lang="pug">
  include /mixins
  +b.product-weight-trigger-option(v-if='option.props')
    +b.ds-caption--size_2xs.--size_xs-xl.--medium-xl.SPAN {{ option.props.title }}
    +b.product-weight-trigger--inline.SPAN(
    )
      template(v-if='option.props.weight.props.color || option.props.weight.props.img')
        +e.color--clear.IMG(
          :src='option.props.weight.props.img'
          v-if='option.props.weight.props.img'
        )
        +e.color(
          :style='{"--color": option.props.weight.props}'
          v-else
        )
        +e.value(
          v-if='option.props.weight.props.color_number'
        ) {{ option.props.weight.props.color_number }} ({{ option.props.weight.props.name }})
        +e.value(
          v-else
        ) {{ option.props.weight.props.name }}
        //- +else
      +b.ds-caption--size_2xs.--size_xs-xl.--medium-xl.P(
        v-else
      ) {{ option.props.weight.props.name }}

</template>
<script>
export default {
  name: 'ProductVariantOption',
  props: {
    option: {},
  },

  methods: {

  },
}
</script>

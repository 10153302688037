import './public-path';
import '@/js/directives'

// import $ from 'jquery'
import is from 'is_js_new'

import Vue from 'vue'
import Router from 'vue-router'
import I18n from 'vue-i18n'
import { Store } from 'vuex'

import 'es6-promise/auto'

import { createApp } from '@/app'
import {
  extend,
} from 'vee-validate'
import {
  required, confirmed, email,
  min, min_value as minValue, max, max_value as maxValue,
  ext, length,
} from 'vee-validate/dist/rules'

import { validate as UrlValidate } from '@/js/validation/url.js'

// import sentryInit from '@/sentry'
import { plugin } from '@/install'

import WEBCASEConsole from './webcase-console';

import '@/js/validation'
import './styles/index.sass';

Vue.config.productionTip = false

Vue.use(plugin)

WEBCASEConsole()

// Vue.mixin({
//   directives,
// })

const { app } = createApp({
  Vue, Router, I18n, Store,
})

// sentryInit(Vue)

window.is = is
// eslint-disable-next-line no-multi-assign

app.$mount('#app')

extend('password', {
  // eslint-disable-next-line no-unused-expressions
  validate: (value, { other }) => { value === other },
  message: app._('The password confirmation does not match.'),
  params: [{ name: 'other', isTarget: true }],
})

const MOBILEREG = /^[\+]\d{12}$/

extend('phone', {
  validate(value, { codeLength, numberAfterCode }) {
    // Regex for a international phone number
    const regexp = numberAfterCode.split('|').map(e => new RegExp(`^[\\+]\\d{${codeLength + Number(e)}}$`, 'img'))
    const result = regexp.some(t => t.test(value))
    return result
  },
  message: app._('Phone is not valid'),
  params: [{ name: 'codeLength', isTarget: false }, { name: 'numberAfterCode', isTarget: false }],
})
const CYRILLIC_CHARS = /^[а-яієїґА-ЯҐЄІЇ\s]+$/

extend('onlyCyrillic', {
  validate(value) {
    // Regex for cylirric characters
    return CYRILLIC_CHARS.test(value)
  },
  message: app._('Only Cyrillic characters'),
})

extend('email_phone', {
  validate(value) {
    // Regex for a international phone number
    const EMAILREG = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return EMAILREG.test(value) || MOBILEREG.test(value)
  },
  message: app._('Set correct phone or email'),
})

extend('url', {
  validate: value => UrlValidate(value),
  message: app._('Set correct url'),
})
extend('notNull', {
  ...required,
  message: app._('Введите корректный расход'),
})

extend('required', { ...required, message: app._('This field is required.') })
extend('confirmed', { ...confirmed, message: app._('Passwords must match.') })
extend('min', { ...min, message: app._('Value is too short.') })
extend('min_value', { ...minValue, message: app._('Minimum value is {min}.') })
extend('max', { ...max, message: app._('Value is too long.') })
extend('max_value', { ...maxValue, message: app._('Maximum value is {max}.') })
extend('max_value_bonuses', { ...maxValue, message: app._('Maximum bonus value is {max}.') })
extend('ext', { ...ext, message: app._('Invalid file format') })
extend('email', { ...email, message: app._('Email is invalid.') })
extend('length', { ...length, message: app._('Необходимо ввести {length} символа.')})

import {
  getUserWheels,
  getCustomResuts,
} from '@api/wheel.service'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

import UserWheelModal from '@app/FortuneWheel/UserWheelModal'
import GiftModal from '@app/FortuneWheel/GiftModal'
import CookiesAgreement from '@app/Cookies/Agreement'
import MobileAppsView from '@app/MobileApps/View'

const BLOCKED_URLS = [new RegExp('fortunes-wheel', '')]

async function setupWheel($this) {
  await getUserWheels.execute({
    country_available: getCookie(COUNTRY_CODE_COOKIE_NAME),
  }).then(res => {
    const [wheel] = res.data
    if (!BLOCKED_URLS.find(l => l.exec(window.location.href))) {
      if (wheel && wheel.props && wheel.props.twist_count) {
        $this.$modal.show(UserWheelModal, {
          info: wheel,
        }, {
          minWidth: 300,
          adaptive: true,
          scrollable: true,
          classes: ['videoPopup'],
        })
      }
    }
  })
}

export async function setupGiftModal($this) {
  await getCustomResuts.execute({
    is_showed: false,
    can_modal_show: true,
    country_available: getCookie(COUNTRY_CODE_COOKIE_NAME),
  }).then(({ data: { items } }) => {
    items.forEach(({ props }) => {
      $this.$modal.show(GiftModal, {
        info: props,
      }, {
        minWidth: 300,
        adaptive: true,
        scrollable: true,
        classes: ['videoPopup'],
      })
    })
  })
}
export async function cookieAgreementModal($this) {
  $this.$modal.show(CookiesAgreement, {
  }, {
    height: 'auto',
    minWidth: 300,
    adaptive: true,
    scrollable: true,
    clickToClose: false,
    classes: ['videoPopup'],
  })
}
export async function openMobileAppsModal($this) {
  $this.$modal.show(MobileAppsView, {
  }, {
    height: 'auto',
    width: 300,
    minWidth: 300,
    adaptive: true,
    scrollable: true,
    classes: ['defaultModal'],
  })
}

export function wheelModalTrigger($this) {
  if (window.authent) {
    setupWheel($this)
  }
}

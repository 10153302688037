var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('otp-component',{ref:"otpComponent",attrs:{"value":_vm.pin,"count":_vm.length},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var digits = ref.digits;
var oninput = ref.oninput;
var onpaste = ref.onpaste;
var onblur = ref.onblur;
return [_c('div',{staticClass:"otp-wrap"},[_c('otp-group',{staticClass:"flex space-x-2 p-2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var focusNext = ref.focusNext;
var focusPrev = ref.focusPrev;
return _vm._l((digits),function(digit,index){return _c('otp-group-input',{key:index,attrs:{"value":digit,"autofocus":"","placeholder":""},on:{"blur":onblur,"next":function($event){return focusNext(index)},"prev":function($event){return focusPrev(index)},"paste":function($event){onpaste(index, $event); _vm.$emit("input", _vm.pin)},"input":function($event){oninput(index, $event); _vm.$emit("input", _vm.pin)}}})})}}],null,true)})],1)]}}]),model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=$$v},expression:"pin"}})}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang='pug'>
  include /mixins
  +b.ds-panel--space_xs.--space_md-xl.relative
    +e.element--offset_full
      controller.relative(
        :localsearch='search'
        :searchResource='$options.searchResource'
        :preview='$options.preview'
      )
        template(#inputview='{ keydownEvent, keyupEvent, model, goToSearch }')
          .relative.search-form
            +b.search-trigger.--fixed-width.BUTTON(
              @click.prevent='keyupEvent'
              :aria-label=`_('Поиск')`
            )
              +e.loop.icon-search
            transition(name="fade")
              d-control-input(
                v-bem:d-control-input="{'appearance': 'bordered', 'appearance': 'large'}"
                type='search',
                :placeholder='_("Я ищу... ")',
                v-model='search',
                @keydown='keydownEvent'
                autocomplete='125выпывпфывп'
                ref='search-input'
                @keyup="keyupEvent",
              )

</template>
<script>
import { mapState } from 'vuex'
import { SEACRH_URL } from '@api/vendor.service'
import ProductPreview from '@app/Search/Previews/Product'
import Controller from './Controller'

const preview = ProductPreview

export default {
  name: 'SearchView',
  props: ['className', 'fieldId', 'url'],

  components: {
    Controller,
    ProductPreview,
  },
  preview,
  searchResource: SEACRH_URL,

  data() {
    return {
      search: '',
    }
  },
  watch: {
    global_search(nval) {
      this.search = nval
    },
  },

  computed: {
    ...mapState('search', [
      'global_search',
    ]),
  },

  mounted() {
    this.$store.subscribe((mutation) => {
      if ('search/SET_SEARCH' == mutation.type) {
        this.search = mutation.payload
      }
    })
  },
}

</script>

/**
 * return tree view ,
 * set 4 spaces before initital string if it has depth
 * @param {string} string - text in select label
 * @param {number} by - depth
 */
import Confirm from '@app/UiElements/Confirm'

// import Vue from 'vue'
const NOT_DEPTH = 1
const IDENTS_SIZE = 4
const INIT_VAL = 0

export function outstand(string, by) {
  if (by < NOT_DEPTH) {
    return string
  }

  const whitespace = '\u00A0'.repeat(IDENTS_SIZE)
  const marker = ''
  // const marker = '\uFE42'
  // const marker = '\u232A'

  return whitespace.repeat(by) + (by > INIT_VAL ? marker : '') + string
}

export function confirmWithPromise(question, $this) {
  return new Promise((res, rej) => {
    const pr = arg => new Promise((resolve, reject) => {
      if (true == arg) {
        resolve(arg)
      } else {
        reject(new Error('...'))
      }
    }).then(result => {
      res(result)
    }, err => {
      rej()
    })
    $this.$modal.show(
      Confirm,
      {
        question,
        promise: pr,
      }, {
        height: 'auto',
        width: '300px',
        classes: ['defaultModal'],
        adaptive: true,
      }
    )
  })
}

/**
 * @param {string} path - name of key(
 *  f.e. Object is -{key: {child_key: 'test'}}, path - key, child_key
 * )
 */
export function pathGetter(path) {
  if (is.not.array(path)) {
    path = Array.from(arguments)
  }

  return item => path.reduce((acc, x) => (acc ? acc[x] ? acc[x] : '-' : '-'), item)
}
export function pathGetterArray(path, emptyVal = '-') {
  if (is.not.array(path)) {
    path = Array.from(arguments)
  }
  return item => path.reduce((acc, x) => (acc ? acc[x] !== undefined ? acc[x] : emptyVal : emptyVal), item)
}

export class Field {
  constructor(key, label = null, getter = null, parameters = {}) {
    if (null !== label && is.function(label)) {
      label = null
      getter = label
      parameters = getter
    }

    if (null !== getter && is.not.function(getter)) {
      getter = null
      parameters = getter
    }

    this.key = key
    this.label = label || this.key.toTitle()
    this.getter = getter || pathGetter(this.key)
  }

  getValue(obj) {
    return this.getter(obj)
  }
}

export function debounce(f, ms) {
  let isCooldown = false

  return function () {
    if (isCooldown) return
    isCooldown = true
    setTimeout(() => isCooldown = false, ms)
    return f.apply(this, arguments)
  }
}

export const groupBy = (arr, fn) => arr.map('function' === typeof fn ? fn : val => val[fn]).reduce((acc, val, i) => {
  acc[val] = (acc[val] || []).concat(arr[i])
  return acc
}, {})

export const groupByOrNull = (arr, fn, fn2) => arr.map('function' === typeof fn ? fn : val => val[fn]).reduce((acc, val, i, a) => {
  if (null === val) return acc
  acc[val] = (acc[val] || []).concat(arr[i])
  return acc
}, {})

export function getId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  const eleven = 11;
  if (match && match[2].length === eleven) {
    return match[2];
  }
  return new Error('error parsed link');
}

export function decodeHTMLEntities(str = '') {
  const element = document.createElement('div');
  return str.replace(/&#(\d+);/g, (match, dec) => {
    element.innerHTML = `&#${ dec };`;
    return element.textContent || element.innerText;
  });
}

import installer from '@aspectus/vue-route-installer'

import {
  createRoutes as createUserRoutes,
} from '@app/Staff/User/router'

import {
  route,
  prefixRoutes,
} from '@app/Routing/helpers'

const CabinetInstaller = () => import('@app/Staff')
const Cabinet = () => import('@app/Staff/Views/Cabinet')

const Base = () => import('@app/Staff/User/Base')

const MOBILE_MENU = 'base/'
const BASE_CABINET = 'info/'

export const USER_BASE_NAME = 'user:base'

const DESKTOP = 1200
export const REDIRECT_PATH = window.innerWidth > DESKTOP ? BASE_CABINET : MOBILE_MENU

export function createRoutes(options) {
  const { Vue } = options
  return [
    route('', installer(Cabinet, CabinetInstaller, Vue), {
      redirect: REDIRECT_PATH,
    }, {
      children: [
        prefixRoutes('', createUserRoutes(options), {}),
        route(MOBILE_MENU, Base, USER_BASE_NAME, {}),
      ],
    }),
  ]
}

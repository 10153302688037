
<template lang="pug">
  include /mixins
  +b.ds-panel--space_xl
    +e.element--offset_vertical
      +b.g-row(
        :class='{"g-row--appearance_nowrap-xl": hasAddresses}'
      )
        +b.g-cell.g-cols--12.--6-xl(v-if='hasAddresses')
          user-address-book-list(
            :in-order='true'
            :country='default_code'
            :params='getAddressParams()'
            @address:select='selectAddress($event)'
            :is-free='isFreeDelivery'
            :shipping-price='shippingPrice'
            :delivery-description='deliveryDescription'
            ref='userAddress'
          )
        +b.g-cell.g-cols--12(
          :class='{"g-cols--6-xl": hasAddresses}'
        )
          +b.g-row
            +b.g-cell.g-cols--12(
              :class='{"g-cols--6-xl": !hasAddresses}'
            )
              tag(
                v-for='(tab, idx) in $options.widgetsMap'
                :key='idx',
                :tag='tab.tag',
                :initial='form'
                :has-init='hasAddresses'
                @update='simulate($event)'
                :info='info'
                :is_not_available='false'
                v-if='tab.condition && tab.condition(hasAddresses, isAuth)'
                :country='default_code'
                :user='user'
                :is-show='!hasAddresses || !isAuth'
                :order='order'
                :recipient='recipient'
                :is-free='isFreeDelivery'
                :shipping-price='shippingPrice'
                :delivery-description='deliveryDescription'
              )
            +b.g-cell.g-cols--12(
              :class='{"g-cols--6-xl": !hasAddresses}'
            )
              tag(
                v-for='(tab, idx) in $options.widgetsMapRight'
                :key='idx',
                :tag='tab.tag',
                :initial='form'
                :has-init='hasAddresses'
                @update='simulate($event)'
                :info='info'
                :is_not_available='false'
                :bonuses-info='bonusesInfo'
                :country='default_code'
                :user='user'
                :order='order'
                :isBonusesAvailable='isBonusesAvailable'
                :recipient='recipient'
                :is-free='isFreeDelivery'
              )
              +b.tt-space_mt--6
                tag(
                  tag='d-control-input'
                  name='email2',
                  v-model='form.email2',
                  :input-label='_("Электронная почта")'
                  rules='required'
                  @input='simulate({ email2: form.email2 })'
                  v-if='(!user.email || !order.email) && order.payment_gateway && order.payment_gateway.props && order.payment_gateway.props.is_email_required'
                )
              +b.tt-space_mt--6
                slot

</template>

<script>
/* eslint-disable camelcase */
import { mapState, mapActions } from 'vuex'

import Payment from '@app/Order/Steps/Parts/Payment'
import Delivery from '@app/Order/Steps/Parts/Delivery'
import Recipient from '@app/Order/Steps/Parts/Recipient'
import DateTime from '@app/Order/Steps/Parts/DateTime'

import {
  addressBookList,
} from '@api/staff/address.service'

import UserAddressBookList from '@app/Staff/AddressBook/List'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

import {
  AnswerMessage,
} from '@utils/submit'

const WIDGETS_MAP = {
  delivery: {
    tag: Delivery,
    condition: (hasAddresses, isAuth) => !hasAddresses || !isAuth,
  },
  recipient: {
    tag: Recipient,
    condition: (hasAddresses, isAuth) => !hasAddresses || !isAuth,
  },
}
const WIDGETS_MAP_RIGHT = {
  datetime: {
    tag: DateTime,
    condition: bonusesInfo => bonusesInfo,
  },
  payment: {
    tag: Payment,
    condition: () => true,
  },
}

export default {
  name: 'OrderPaymentAndDelivery',
  props: {
    user: {},
    info: {},
    order: {},
    bonusesInfo: {},
    isBonusesAvailable: {},
    cartChange: {},
    isFreeDelivery: {},
    shippingPrice: {},
    deliveryDescription: {},
  },
  widgetsMap: WIDGETS_MAP,
  widgetsMapRight: WIDGETS_MAP_RIGHT,
  components: {
    UserAddressBookList,
  },
  data() {
    return {
      default_code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      form: {
        shipping_service: null,
        payment_gateway: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        warehouse: '',
        email2: '',
        is_full: true,
      },
      hasAddresses: false,
      dummyAddress: {
        first_name: '',
        last_name: '',
        phone: '',
        shipping_service: '',
        city: '',
        street: '',
        warehouse: '',
        internal_warehouse: '',
        house: '',
        apartment: '',
        building: '',
      },
      recipient: {},
      isAuth: window.authent,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart',
    ]),
    ...mapState('staff', [
      'level',
    ]),
    freeShippingMessage() {
      return window.authent ? this.level?.props?.free_shipping_information : window.free_shipping_information_for_anonim
    },
  },
  watch: {
    'form.shipping_service': {
      handler(value) {
        if (!value) return
        this.checkFreeDelivery(value)
      },
      immediate: true,
    },
    cartChange: {
      handler() {
        console.log(this.$refs.userAddress, this.$refs.userAddress.parameters, 'CART CHANGE');
        if (this.$refs.userAddress) {
          this.$refs.userAddress.$refs.controller.$refs.paginationController.changeFilters(this.$refs.userAddress.parameters)
        }
        if (!this.form.shipping_service) return
        this.checkFreeDelivery(this.form.shipping_service)
      },
    },
  },
  async created() {
    if (window.authent) {
      await this.getUserLevelSettings()
    }
    this.checkUserAddresses()
  },
  methods: {
    ...mapActions('staff', [
      'getUserLevelSettings',
    ]),
    getAddressParams() {
      const params = {
        only_available: true,
        country_available: this.default_code,
        for_preorder: !!window.isPreorder,
      }
      return params
    },
    async checkFreeDelivery(value) {
      this.$emit('update:isFreeDelivery', value)
    },
    async checkUserAddresses() {
      if (!window.authent) return
      const { data: { items } } = (await addressBookList.execute({
        only_available: true,
        // is_not_available: false,
        country_available: this.default_code,
      }))
      if (items.length) this.hasAddresses = true
    },
    simulate(event) {
      const data = Object.assign(this.form, event)
      this.$emit('update', data)
    },
    clearAddress() {
      const form = {
        street: '',
        house: '',
        street_name: '',
        apartment: '',
        warehouse: '',
        shipping_service: '',
        building: '',
        zip_code: '',
        is_full: true,
      }
      this.simulate(form)
    },
    selectAddress({ props, is_full }) {
      this.clearAddress()
      const {
        first_name: delivery_first_name,
        last_name: delivery_last_name,
        middle_name: delivery_middle_name,
        phone: delivery_phone,
        ...other
      } = props

      const currentData = { ...other, is_full }
      // if (props.warehouse && props.warehouse.props.is_not_available) {
      //   delete currentData.warehouse
      // }
      // if (props.city && props.city.props.is_not_available) {
      //   delete currentData.city
      // }

      const data = {
        delivery_first_name,
        delivery_last_name,
        delivery_middle_name,
        delivery_phone,
      }
      this.simulate(currentData)
      this.simulate(data)
      this.recipient = data
      // this.simulate(this.user)
    },
    clearAll() {
      this.simulate(this.dummyAddress)
      this.simulate(this.user)
    },
  },
}
</script>

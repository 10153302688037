import {
  createResource,
  // postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const GET_MENU_CHILDREN = prefixAPI('menu/header/{id}/')
// const REMOVE_FROM_WAITING_URL = prefixAPI('/remove/', MODEL)
// const WAITINGS_COUNT_URL = prefixAPI('/count/', MODEL)
// const WAITINGS_LIST_URL = prefixAPI('/is/', MODEL)

// export const addToWaiting = createResource(
//   ADD_TO_WAITING_URL, postResource
// )
// export const removeFromWaiting = createResource(
//   REMOVE_FROM_WAITING_URL, postResource
// )
export const getMenuChildren = createResource(GET_MENU_CHILDREN)
// export const getWaitingsList = createResource(WAITINGS_LIST_URL)

<script>
export default {
  name: 'SingleStepController',
  data() {
    return {

    }
  },
  render(h) {
    return this.$scopedSlots.default({

    })
  },
}
</script>

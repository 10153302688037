<template lang='pug'>
  include /mixins
  div
    tag(
      name="filters-modal",
      width='100%'
      height='100vh'
      :adaptive='true'
      @before-open='blockWindow'
      @before-close='unblockWindow'
      :tag='isDesktopView ? "div" : "modal"'
    )
      +b.filter-wrap
        +e.close.SPAN(
          @click.prevent='$modal.hide("filters-modal")'
        ) &times;
        +e.head

          +b.g-row--justify_between.--appearance_spaced.--space_xl.--align_center.is-disable-xl
            +b.g-cell.g-cols
              +b.ds-caption--size_sm.--bold.P {{ _('Фильтры') }}
            +b.g-cell.g-cols
              +b.tt-link--styling_default.--bordered_grey.BUTTON(
                @click.prevent='$emit("input", {})'
              )
                +e.title.SPAN {{ _('Очистить') }}
        +e.body.ROW(
          :appearance="['spaced', 'column-till-xl' ]"
        )
          slot(
            name="prepend",
            cols="12"
          )
          cell.g-cols--spaced_vert-none(
            cols="12 3-xl"
          )
            +b.tt-space_mb--6
              div
                tag.search-form--fully(
                  v-if="needSearch"
                  :tag="$options.widgetsMap.search"
                  :parameters="parameters"
                  :value="value.search"
                  :disabled="disabled"
                  :filter="search_filter"
                  @input="debounceSearch(search_filter.label, $event)"
                  :size="['md', 'lg-lg']"
                )
          //- cell.g-cols--spaced_vert-none(cols='12 1-xl')
          cell.g-cols--spaced_vert-none(
            :cols="`12 ${filter.cols}-xl`"
            v-for="filter in baseFilters"
            :key="filter.attribute.id"

          )
            +b.tt-space_mb--6
              tag(
                :tag="$options.CUSTOM_DEPENDENCY == filter.attribute.label ? $options.widgetsMap.category : $options.widgetsMap[filter.type] || $options.widgetsMap.multiselect"
                :available="available"
                :parameters="parameters"
                :value="value[filter.attribute.label]"
                :disabled="disabled",
                :custom-check='$options.CUSTOM_DEPENDENCY == filter.attribute.label'
                :filter="filter"
                :label='label'
                @input="updateValue(filter.attribute.label, $event)"
                @category-hack='$emit("category-hack")'
                :size="['md', 'lg-lg']"
                :initbaseUrl='initbaseUrl'
              )
          slot(
            name="append",
            cols="12"
          )
        +e.controls
          +b.ds-panel
            +e.element--offset_full
              +b.tt-button--mini.BUTTON(
                @click.prevent='$modal.hide("filters-modal")'
              )
                +e.title.SPAN {{ _('Показать') }}
</template>

<script>
import UiMixin from '@app/FiltersSimple/mixins/UiMixin'

import CheckboxWidget from '@app/FiltersInlinesSimple/Widgets/CheckboxWidget'
import RangeWithVariantsWidget from '@app/FiltersInlinesSimple/Widgets/RangeWithVariantsWidget'
const CategoryWidget = () => import('@app/FiltersInlinesSimple/Widgets/CategoryWidget')
const SearchWidget = () => import('@app/Filters/Widgets/SearchWidget')
const TagsWidget = () => import('@app/FiltersInlinesSimple/Widgets/TagsWidget')

const CUSTOM_DEPENDENCY = 'category'
const ATTRIBUTES_FOR_SEARCH_COUNT = 20

const WIDGETS_MAP = {
  prices: RangeWithVariantsWidget,
  multiselect: CheckboxWidget,
  category: CategoryWidget,
  search: SearchWidget,
  tags: TagsWidget,
}

export default {
  mixins: [UiMixin],
  widgetsMap: WIDGETS_MAP,
  props: [
    'isDesktop',
    'disabled',
    'value',
    'filters',
    'available',
    'prices',
    'pagination',
    'needSorting',
    'needTitle',
    'needSearch',
  ],
  CUSTOM_DEPENDENCY,
  ATTRIBUTES_FOR_SEARCH_COUNT,
  data() {
    return {
      search: '',
      search_filter: {
        label: 'search',
        title: this._('Поиск'),
        id: 'search_id',
      },
      windowWidth: 0,
    }
  },
  created() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => this.windowWidth = window.innerWidth)
  },
  computed: {
    isDesktopView() {
      return this.windowWidth > 1200
    },
  },
  methods: {
    blockWindow() {
      console.log('OPEN');
      this.$body.dataset.blocked = true
    },
    unblockWindow() {
      this.$body.dataset.blocked = false
      console.log('CLOSE')
    },
  },
}

</script>

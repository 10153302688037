<template lang="pug">
  include /mixins

  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    +b.title.ds-caption--size_xs.--size_sm-xl.--light.P.tt-space_mt--8-xl
    .relative
      +b.error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    slot(name='auth-btn')
    +b.g-row
      +b.g-cell.g-cols--12
        phone-input(
          :rules="'required|phone'",
          :input-label='labels.phone'
          v-model='form.username',
          ref='phone'
          name='username'
          :disabled='true'
        )
      +b.g-cell.g-cols--12.tt-space_mt--6.--10-xl
        .relative
          d-control-input(
            :rules="'required'",
            autofocus='true'
            :input-label='labels.password '
            v-model='form.password'
            autocomplete='new-password-5'
            id='password',
            type='password',
            name='password',
          )
      +b.g-cell.g-cols--12.--10-xl.tt-space_mt--6(
      )
        validation-provider(
          :rules='{ required: { allowFalse: false } }',
          v-slot="{ errors }"
          name='agree_rules'
        )
          +b.d-control-input.--appearance_flexible
            +e.element.INPUT(
              type='checkbox'
              name='accept_rules'
              v-model='accept_rules'
              id='accept_rules'
            )

            +e.LABEL.label--static(
              for='accept_rules'
            )
              +b.ds-caption.--size_xs.--color_black.P {{ _('Я соглашаюсь с') }} &nbsp;
                +b.tt-link--inline.--styling_default.--black.A.ds-caption--size_xs(:href='offer', target='_blank') {{ _('политикой конфиденциальности') }}
                span &nbsp; {{ _('и') }} &nbsp;
                +b.tt-link--inline.--styling_default.--black.A.ds-caption--size_xs(:href='rules', target='_blank') {{ _('правилами сайта') }} 
            +b.error-label--top.LABEL(v-if="errors.length") {{ errors[0] }}
    +b.g-row--justify_between

    +b.g-row
      +b.g-cell.g-cols--12.tt-space_mt--5.tt-space_mt--10-xl
        +b.tt-button.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('Войти') }}
    +b.g-row
      +b.g-cell.g-cols--12.tt-space_mt--5.tt-space_mt--10-xl
        slot(name='register-btn')
</template>
<script>
import loginMixin from '@app/Forms/mixins/login'
import {
  marketLaunchPasswordSet,
} from '@api/auth.service'

export default {
  name: 'MailingAuth',
  mixins: [loginMixin],
  props: ['userPhone', 'code'],

  data() {
    return {
      labels: {
        phone: this._('Номер телефона'),
        password: this._('Введите ваш новый пароль'),
      },
    }
  },
  mounted() {
    this.form.username = this.userPhone
    this.form.code = this.code
  },
  methods: {
    send(data) {
      return marketLaunchPasswordSet.execute({}, data)
        .then(() => {
          window.location.assign('/')
        })
    },
  }
}

</script>

<template lang='pug'>
  include /mixins
  div()
    slot(
      name='inputview',
      :keyup-event='dynamicSearch'
      :go-to-search='goToSearch'
      :reset='reset',
      :active-search='activeSearch'
      :trigger_overlay='trigger_overlay'
    )
    ui-loader.mini-loading(v-if='load')
    +b.seacrh-result-wrapper
      transition-group(name="slidedown")
        +b.multisearch-results(
          v-if='results.length',
          key='results',
          :style='{ "--header-h": getTop() }'
        )

          +e.close.close-popup.A(
            @click.prevent='reset(); trigger_overlay()'
          ) &times;
          +b.list-multisearch
            tag(
              :trigger_overlay='() => {}'
              :results='results',
              :findValueAndHighlightTerm='findValueAndHighlightTerm',
              :highlightTerm='highlightTerm'
              :tag='preview'
              :localsearch='localsearch'
              :resource='searchResource'
              :load='load'
              :meta='meta'
              @reset='reset'
              @load:start='load = true'
              @load:end='load = false'
            )

      +b.search-results(
        v-if='meta.total == 0',
        key='withoutresults'
      )
        +b.ds-panel--space_xs.--space_md-xl
          +e.element--offset_full
            +b.ds-caption--size_6.--regular.P(
              v-if='meta.total == 0',
            ) {{ _("По вашему поисковому запросу ничего не найдено.") }}

</template>
<script>
// Highlight the query part of the search term
import { mapMutations, mapActions } from 'vuex'

import cancellablePromiseProxy from '@aspectus/cancellable-promise-proxy';

import debounce from 'lodash.debounce'

import {
  findValueAndHighlightTerm,
  highlightTerm,
} from '@utils/highlight'

import { createResource, getResource } from '@resource/resource'

const SEARCH_LIMIT = 2

export default {
  name: 'SearchController',
  props: ['url', 'localsearch', 'searchResource', 'preview'],
  data() {
    return {
      show: false,
      load: false,
      results: [],
      length: 1,
      meta: {},
      debounceAction: '',
      currentRequest: '',
    }
  },

  watch: {
    localsearch(nval) {
      if (!nval) {
        this.reset()
      }
    },
  },
  created() {
    const delay = 1000
    this.debounceAction = debounce(() => {
      this.executer()
    }, delay)
  },
  methods: {
    findValueAndHighlightTerm,
    highlightTerm,
    ...mapMutations('search', [
      'SET_SEARCH',
    ]),
    ...mapActions('search', [
      'trigger_overlay',
    ]),
    getTop() {
      const header = document.getElementById('header')
      const top = header.offsetTop + header.offsetHeight
      return `${top}px`
    },
    reset() {
      this.results = []
      this.$body.dataset.blocked = false
      this.trigger_overlay(false)
    },
    activeSearch() {
      this.show = !this.show
    },
    clearSearch() {
      this.search = ''
    },
    dynamicSearch() {
      // if ('Enter' === event.code) {
      //   this.goToSearch()
      //   return
      // }
      if (this.localsearch.length < SEARCH_LIMIT) {
        console.log(this.localsearch.length);
        this.reset()
        return
      }
      this.load = true
      // this.debounceExecuter()
      this.debounceAction()
    },
    debounceExecuter() {
      const time = 1000
      const action = debounce(this.executer, time)
      action()
    },
    executer() {
      if (this.currentRequest) this.currentRequest.cancel()
      const cancelController = new AbortController();
      const base = getResource.config('signal', cancelController.signal)
      const resource = createResource(this.searchResource, base)

      this.currentRequest = cancellablePromiseProxy(
        new Promise((resolve, reject) => {
          resource.execute({
            search: this.localsearch,
            limit: 6,
          })
            .then(resolve, reject);
        }),
        { cancelController }
      ).then(({ data: { items, pagination } }) => {
        this.trigger_overlay(true)
        this.results = items
        this.meta = pagination
        this.load = false
        this.$body.dataset.blocked = 0 < items.length
      })
      // this.searchResource.execute({
       
      // }).then(({ data: { items, pagination } }) => {
      //   this.trigger_overlay(true)
      //   this.results = items
      //   this.meta = pagination
      //   this.load = false
      //   this.$body.dataset.blocked = 0 < items.length
      // })
    },
    goToSearch() {
      this.trigger_overlay(false)
      if (window.is_main_page) {
        this.SET_SEARCH(this.localsearch)
      } else {
        window.location.pathname = `${window.baseUrl}filters/search=${this.localsearch}/`
      }
      this.reset()
    },
  },
}

</script>

<script>
import { mapActions } from 'vuex'
import CartView from '@app/Cart/View'
import analyticCartAdd from '@analytic/add.js'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'AddToCart',
  props: {
    id: {
      type: [String, Number],
    },
    type: {
      type: String,
    },
    amount: {
      type: [String, Number],
    },
    price: {},
    name: {},
  },
  data() {
    return {
      load: false,
    }
  },
  methods: {
    ...mapActions('cart', [
      'changeCart',
    ]),
    async addToCart(id, type, amount) {
      this.load = true
      const entity = {
        element: {
          id: this.id,
          type: this.type,
        },
        quantity: this.amount,
      }
      const entities = []
      entities.push(entity)
      try {
        await this.changeCart({ data: { entities }, retrieve: false }).then(({ data }) => {
          const element = data.groups.find(e => e.base.element.id === this.id)
          if (element) {
            const { brand: { props: { title: brand } }, categories, title: name } = element.base.element.props.product.props
            const { price } = data
            analyticCartAdd(
              name, this.id, price, this.amount, brand, categories.map(l => l.props.title)
            )
          }
        })
        this.openCart()
        this.load = false
      } catch (e) {
        console.log(e);
        this.load = false
        const { errors } = await e.json()
        const [error] = errors
        const [first] = error.state.non_field_errors
        const meta = {
          message: {
            header: this._('Максимальна кількость товарів перевищена. Даний товар вже є у кошику'),
            title: first.message,
          },
        }
        AnswerMessage(meta, this)
      }
    },
    openCart() {
      this.$modal.show(
        CartView,
        {
          retrieve: false,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      add: () => {
        this.addToCart()
      },
      load: this.load,
    })
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.ds-panel--space_2xl.--space_3-xl.relative
    +b.close-popup.mdi.mdi-close.A(
      @click='$emit("close")'
    )
    +e.element--offset_full(v-if='meta && meta.isPretty')
      +b.thanks-modal
        +e.img-wrap
          +e.img.IMG(src='/static/img/thanks.svg')
        +e.title.H2 {{ meta.message.title }}
        +e.text.P {{ meta.message.text }}
        +b.tt-button.A(
          v-if='meta.message.btn'
          @click.prevent='$emit("close")'
        )
          +e.title.SPAN {{ meta.message.btnText }}
    +e.element--offset_full(v-else)
      +b.ds-caption--size_2md.--appearance_center.--medium.--color_grey.H2 {{ meta.message.title }}
      +b.ds-caption--size_sm.--appearance_center.--light.P.tt-space_mt--1 {{ meta.message.text }}
      +b.tt-button--mini.A(
        v-if='meta.message.btn'
        :href='meta.message.btn'
      )
        +e.title.SPAN {{ meta.message.btnText }}
</template>
<script>
export default {
  name: 'UiMessageModal',
  props: {
    meta: {
      type: Object,
      required: true,
    },
    promise: {
      type: Function,
      required: false,
    },
    timeout: {
      type: Number,
      required: false,
    },
    isSimple: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      redirectTime: 3000,
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.promise != undefined) {
        this.promise('true')
      }
      // this.$emit('close')
      if (this.meta.redirect && this.meta.redirect.location) {
        window.location.pathname = this.meta.redirect.location
      }
    }, this.redirectTime)
  },
}
</script>

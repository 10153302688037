function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade"}},[(_vm.loading)?_c('div',{staticClass:"fake-sidebar-load"},[_c('ui-loader')],1):_vm._e()]),_c('ui-accordion',{attrs:{"init":false,"full-height":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var activeState = ref.activeState;
var toggleActive = ref.toggleActive;
var collapse = ref.collapse;
var ref_style = ref.style;
var icon = ref_style.icon;
var myHeight = ref_style.myHeight;
var isDesktop = ref.isDesktop;
var rest = objectWithoutProperties( ref, ["activeState", "toggleActive", "collapse", "style", "isDesktop"] );
var other = rest;
return [_c('div',{staticClass:"tt-accordion",class:{"tt-accordion--is-open": activeState}},[_c('ui-click-outside',{attrs:{"do":collapse}},[_c('div',[_c('div',{staticClass:"tt-accordion__question tt-accordion__question--clear-all is-disable-xl",class:{"is-small": _vm.needSorting},on:{"click":function($event){return _vm.$modal.show("filters-modal");}}},[_c('i',{staticClass:"tt-accordion__icon mdi",class:activeState ? "mdi-minus" : "mdi-plus"}),_c('div',{staticClass:"filter-title"},[_c('p',{staticClass:"ds-caption ds-caption--size_xl ds-caption--medium ds-caption--height_2xs tt-space_mr tt-space_mr--2"},[_c('span',{staticClass:"icon-filter_n"})]),_c('p',{staticClass:"ds-caption ds-caption--size_sm ds-caption--medium"},[_vm._v(_vm._s(_vm._('Фильтры')))])]),_c('portal-target',{attrs:{"name":"sorting"}})],1),_c('div',{staticClass:"tt-accordion__description",class:{"isFullMaxHeight": true},style:({maxHeight: myHeight})},[_c('div',{staticClass:"tt-accordion__content tt-accordion__content--no-pa"},[_c('inline-filter-ui',{attrs:{"disabled":_vm.loading,"value":_vm.prepared,"available":_vm.availableMap,"filters":_vm.filters,"need-sorting":_vm.needSorting,"need-search":_vm.needSearch,"need-title":_vm.needTitle,"pagination":_vm.pagination,"prices":_vm.prices,"sortlist":_vm.sortlist,"isDesktop":isDesktop,"label":_vm.label,"initbaseUrl":_vm.initbaseUrl},on:{"input":_vm.handleInputFilter,"category-hack":function($event){return _vm.$emit("category-hack")}}})],1)])])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
import {
  availableInfo,
  OUT_OF_STOCK,
  NOT_COSMETOLOGIST,
  NO_CONTRACT,
  ALLOW_PRICE_WITHOUT_CONTRACT,
} from '@utils/available_info'

export default {
  name: 'BtnsController',
  props: ['t'],
  data() {
    return {
      amount: 1,
    }
  },
  computed: {
    availability() {
      return availableInfo(this.t)
    },
    available_price() {
      return !this.t || (!this.out_of_stock && !this.no_contract && !this.not_cosmetologist && !this.available_without_contract)
    },
    out_of_stock() {
      return this.availability[OUT_OF_STOCK]
    },
    no_contract() {
      return this.availability[NO_CONTRACT]
    },
    not_cosmetologist() {
      return this.availability[NOT_COSMETOLOGIST]
    },
    available_without_contract() {
      return this.availability[ALLOW_PRICE_WITHOUT_CONTRACT]
    },
    show_price_with_btn_v1() {
      return this.available_without_contract && (!this.out_of_stock) && !(this.not_cosmetologist || this.no_contract)
    },
    show_price_with_btn_v2() {
      return this.available_without_contract && this.no_contract && !this.out_of_stock && !this.not_cosmetologist
    },
    show_preorder_V1() {
      return this.out_of_stock && !this.not_cosmetologist && !this.no_contract
    },
    show_preorder_V2() {
      return this.out_of_stock && this.no_contract && this.available_without_contract && !this.not_cosmetologist
    },
    is_available_v1() {
      return !(this.available_price || this.available_without_contract)
    },
    is_available_v2() {
      return this.available_price || (this.show_price_with_btn_v1 || this.show_price_with_btn_v2)
    },
    is_available_v3() {
      return this.available_without_contract && this.not_cosmetologist && this.no_contract
    },
    is_available_v4() {
      return this.show_preorder_V1 || this.show_preorder_V2
    },
    is_available_v5() {
      return this.available_price || (this.show_price_with_btn_v1 || this.show_price_with_btn_v2 || this.show_preorder_V1 || this.show_preorder_V2) 
    },
    show_price() {
      return this.available_price || (this.show_price_with_btn_v1 || this.show_price_with_btn_v2 || this.show_preorder_V1 || this.show_preorder_V2)
    },
    show_cosmetologist_desc() {
      return (this.not_cosmetologist || this.no_contract) && (this.is_available_v1 || !this.is_available_v5)
    },
    mix1() {
      return !this.is_available_v2 && !this.is_available_v4
    },
    show_not_cosm_or_no_contract() {
      return ((this.not_cosmetologist || this.no_contract) && this.is_available_v1) || this.is_available_v3 || this.mix1
    },
    has_no_contract_btn() {
      return this.show_not_cosm_or_no_contract && this.no_contract
    },
    has_cosmetologist_btn() {
      return this.show_not_cosm_or_no_contract && this.not_cosmetologist
    },
    has_cart_btn() {
      return this.available_price || (this.show_price_with_btn_v1 || this.show_price_with_btn_v2)
    },
    has_out_of_stock_btn() {
      return this.show_preorder_V1 || this.show_preorder_V2
    },
  },
  methods: {
    changeAmount(val) {
      this.amount = val.count
    },
  },
  render() {
    return this.$scopedSlots.default({
      has_no_contract_btn: this.has_no_contract_btn,
      has_cosmetologist_btn: this.has_cosmetologist_btn,
      has_cart_btn: this.has_cart_btn,
      has_out_of_stock_btn: this.has_out_of_stock_btn,
      show_price: this.show_price,
      show_cosmetologist_desc: this.show_cosmetologist_desc,
      availability: this.availability,
    })
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.variants-view(
    v-if='initial.shipping_service && initial.shipping_service.id && showForm'
  )
    //- +e.title
    //-   +b.variants-title
    //-     +e.icon.mdi.mdi-chevron-right
    //-     +e.text.P {{ _('Выберите удобный способ оплаты') }}

    +e.list
      +b.g-row
        +b.g-cell.g-cols--12.tt-space_mt--6(
          :class='`g-cols--${payment_config.size}-xl`'

        )
          //- v-if='payment_config.props.options.length > 1'
          tag(
            :tag='payment_config.tag'
            v-model='form[payment_config.name]'
            :input-label='payment_config.title',
            :rules='payment_config.rules'
            v-bind='payment_config.props'
            v-on='payment_config.events'
            :name='payment_config.name'
          )
          //- v-if='payment_config.props.options.length == 1'
          //- +b.da-caption--size_sm.P(
          //- ) {{ _('Способы оплаты не доступны') }}
          //- v-if='!payment_config.props.options.length'
          //- +b.da-caption--size_sm.P(
          //- ) {{ _('Способы оплаты не доступны') }}
          +b.ds-caption.--size_xs.P(
            v-if='showDesc'
          ) {{ _('* Реквізити для оплати будуть доступні після оформлення замолення') }}

</template>

<script>
import pathOr from 'ramda/src/pathOr'
import {
  getPayments,
} from '@api/order.service'

const PAYMENT_METHOD_WITH_DESC = 'prepayment_account'

const { isPreorder } = window

export default {
  name: 'OrderPayment',
  keyGetter: x => `${x.type}:${x.id}`,
  props: {
    initial: {},
    bonusesInfo: {
      default: () => ({ need_payment_gateway: true }),
    },
  },
  data() {
    return {
      initOption: {
        $isDisabled: true,
        caption: this._('-выберите'),
      },
      variants: [],
      payment_config: {
        size: '12',
        title: this._('payment service'),
        rules: 'required',
        name: 'payment_gateway',
        tag: 'd-control-multiselect',
        events: {
          input: () => this.$emit('update', this.form),
        },
        props: {
          options: [],
          label: 'caption',
        },
      },
      form: {
        payment_gateway: '',
      },
    }
  },
  computed: {
    showForm() {
      return this.bonusesInfo && this.bonusesInfo.need_payment_gateway
    },
    showDesc() {
      return pathOr(null, ['props', 'service', 'value'], this.form.payment_gateway) === PAYMENT_METHOD_WITH_DESC
    },
  },
  watch: {
    'initial.shipping_service': {
      handler() {
        this.form.payment_gateway = ''
        this.getPaymentsVariants()
      },
    },
  },
  created() {
  },
  methods: {
    async getPaymentsVariants() {
      const shipping_services = this.initial.shipping_service.id
      const params = isPreorder ? { available_for_preorder: true, shipping_services } : { shipping_services }
      const { data: { items } } = (await getPayments
        .execute(params))
      this.payment_config.props.options = items
      this.payment_config.props.options.unshift(this.initOption)
    },
  },
}
</script>

<script>
import { confirmWithPromise } from '@utils/helpers'
import { mapActions } from 'vuex'

export default {
  name: 'CartProduct',
  props: {
    product: {
      default: () => ({}),
    },
  },
  data() {
    return {
      load: false,
    }
  },
  beforeDestroy() {
    this.$emit('cart:update')
  },
  methods: {
    ...mapActions('cart', [
      'changeCart',
    ]),
    async updateCart(info) {
      console.log(info.count, 'CHANGE CART');
      const changes = info.count - this.product.base.quantity
      if (!changes) return
      this.load = true
      const data = {
        element: {
          id: this.product.base.element.id,
          type: this.product.base.element.type,
        },
        item_id: this.product.base.id,
        quantity: changes,
      }
      if (this.product.base.parameters && this.product.base.parameters.quality_type_id) {
        data.parameters = {
          quality_type_id: Number(this.product.base.parameters.quality_type_id)
        }
      }
      const entities = []
      entities.push(data)

      await this.changeCart({ data: { entities }, retrieve: true })
        .then(() => {
          this.load = false
          this.$emit('cart:update')
        }, err => {
          this.load = false
        })
    },
    async removeFromCart() {
      const question = this._('Вы действительно хотите удалить товар с корзины?')
      try {
        await confirmWithPromise(question, this)
        const count = -1
        const obj = {
          count,
        }
        this.updateCart(obj, true)
      } catch (e) {
      }
    },
  },

  render(h) {
    return this.$scopedSlots.default({
      removeFromCart: () => this.removeFromCart(),
      updateCart: event => this.updateCart(event),
      isProductload: this.load,
    })
  },
}
</script>

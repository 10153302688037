<template lang="pug">
  include /mixins
  div
    slot(
      :current-step='currentStep'
      :change-step='changeStep'
      :selected-tab='selectedTab'
      :select-tab='selectTab'
      :tabs="tabs"
      :anonim-steps-length="anonimStepsLength"
      :steps-length="stepsLength"
    )
</template>

<script>

export default {
  name: 'InstructionsForStarting',
  props: ['stepsLength','anonimStepsLength', 'steps'],
  data() {
    return {
      tabs: [
        { title: this._('Я новий клієнт'), key: 'anonim' },
        { title: this._('Існуючий клієнт'), key: 'user' },
      ],
      currentStep: 1,
      selectedTab: 'anonim',
    }
  },
  methods: {
    selectTab(key) {
      this.selectedTab = key
      this.currentStep = 1
    },
    changeStep(nextStep, lenght) {
      if(nextStep < 0 || nextStep > lenght) {
      console.log("🚀 ~ changeStep ~ e:", 'NO NO NO')
      return
      }
      this.currentStep = nextStep
      console.log("🚀 ~ changeStep ~ e:", nextStep)
    }
  },
}
</script>

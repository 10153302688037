import {
  userRetrieve,
  getUserTypes,
  getUserLevelSettings,
} from '@api/staff.service'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

const SET_USER_INFORMATION = 'SET_USER_INFO'
const SET_PART_OF_USER_INFORMATION = 'SET_PART_OF_USER_INFO'
const SET_INFO_TYPES = 'SET_INFO_TYPES'
const SET_LEVEL_SETTINGS = 'SET_LEVEL_SETTINGS'

export function createStore() {
  return {
    namespaced: true,
    state: {
      user: {},
      country: getCookie(COUNTRY_CODE_COOKIE_NAME),
      types: [],
      level: {},
    },
    getters: {
      current_level(state) {
        return state.user.props?.country_levels.find(e => e.props.country_available.props.iso_code === state.country).props.level.props.code
      },
      user_level(state) {
        return state.user.props?.country_levels.find(e => e.props.country_available.props.iso_code === state.country).props.level
      },
    },
    actions: {
      async getUserInfo({ commit }, payload) {
        return userRetrieve.execute().then(({ data }) => {
          commit(SET_USER_INFORMATION, data)
        })
      },
      async getUserInfoTypes({ commit }, payload) {
        return getUserTypes.execute().then(({ data }) => {
          commit(SET_INFO_TYPES, data)
        })
      },
      async getUserLevelSettings({ commit }, payload) {
        return new Promise(async (resolve, reject) => {
          const { data } = (await getUserLevelSettings.execute())
          commit(SET_LEVEL_SETTINGS, data)
          resolve()
        })
      },
      updateUserInfo({ commit }, payload) {
        commit(SET_PART_OF_USER_INFORMATION, payload)
      },
    },
    mutations: {
      [SET_USER_INFORMATION](state, payload) {
        state.user = payload
      },
      [SET_INFO_TYPES](state, payload) {
        state.types = payload
      },
      [SET_LEVEL_SETTINGS](state, payload) {
        state.level = payload
      },
      [SET_PART_OF_USER_INFORMATION](state, payload) {
        state.user = {
          ...state.user,
          props: {
            ...state.user.props,
            [payload.key]: payload.value,
          },
        }
      },
    },
  }
}

<template lang="pug">
  include /mixins
  div
    +b.seo-text.t-content(
      ref="text"
    )
      slot(:show='show')
    +b.A.tt-link--variant_start.--styling_default.tt-space_mt--3.--5-xl(
      type="button",
      @click="show = !show;"
    ) {{ show ?  _('Свернуть') : _(' Узнать больше ') }}
</template>

<script>
export default {
  name: 'BlocksTrigger',
  props: {
    isTrigger: {},
  },
  data() {
    return {
      show: false,
    }
  },
}
</script>

<template lang='pug'>
  include /mixins
  events-filter-ui(
    :disabled="loading"
    :value="prepared"
    :filters="filters"
    :pagination='pagination'
    @input="handleInputFilter"
  )
    template(
      #prepend=''
    )
      +b.g-cell.g-cols--12.--3-xl
        //- +b.tt-button--mini.A(
        //-   href='javascript.void(0)'
        //-   @click.prevent='handleInputFilter({})'
        //- )
        //-   +e.title--mini.P {{ _('Очистить фильтр') }}
        +b.tt-link--appearance_inline.--variant_start.ds-caption--size_sm.--appearance_underline.A.tt-space_mt--7(
          href='javascript.void(0)'
          @click.prevent='handleInputFilter({})'
        ) {{ _('Очистить фильтр') }}

</template>

<script>

import EventsFilterUi from '@app/Events/Filters/Ui'
import ControllerMixin from '@app/FiltersSimple/mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
  components: {
    EventsFilterUi,
  },
  created() {
    this.receive()
  },

}
</script>

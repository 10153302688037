import { mergeContext } from '@aspectus/vue-utils'

const b = 'loading-wrapper'

export default {
  name: 'loading-wrapper',

  functional: true,

  props: {
    variant: { default: 'default' },
    fullscreen: { default: false },
    loading: { default: false },
    size: { default: 'xl' },
    duration: {},
  },
  render(h, context) {
    const { variant, fullscreen, loading, size, duration } = context.props

    return h(
      'e',
      mergeContext(context.data, {
        attrs: {
          b,
          m: { variant, fullscreen },
          s: { loading },
        },
      }),
      [
        ...context.children,
        h('e', { attrs: { b, e: 'overlay' } }),
        h('e', { attrs: { b, e: 'indicator' } }, [
          h('loading', { attrs: { size, duration } }),
        ]),
      ]
    )
  },
}

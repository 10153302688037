<template lang="pug">

  include /mixins.pug
  +b.ds-panel--space_3xl.pagination-wrap(v-if='page && range.max > 1')
    +e.element--offset_full
      row(
        align='center',

      )
        cell(
          cols='12 3-xl'

        )
          row(v-if='needAddBtns')
            cell(
              cols='narrow'
            )
              +e.text.P.pagination__item {{ _("Показано") }} {{ pageObj.from || 0 }} {{ _('до') }} {{ (page.count < pageObj.to ? page.count : pageObj.to) || 0 }} {{ _('из') }} {{ page.count || 0 }}
            //- cell(
            //-   cols='narrow'
            //- )

            //-   template(v-if='page.count <= limit')
            //-     +b.pagination-legend(v-if='show')
            //-       +b.tt-link--variant_start.pagination__item.BUTTON(
            //-         v-if='page.count > page.limit'
            //-         @click.prevent='showAll({limit: page.count}), show=false'
            //-       ) {{ _('Показать все') }}
            //-     +b.tt-link--variant_start.pagination__item.BUTTON(
            //-       v-else
            //-       @click.prevent='showAll({limit: 12}), show=true'
            //-     ) {{ _('Сбросить') }}

        cell(
          cols='12 6-xl'
        )
          +b('ul').pagination(v-if='page && range.max > 1')
            +e.LI.item: +e.A.link.--prev(
              @click.prevent='previous()'
              :class='{"is-disabled": !page.hasPrevious()}'
              :href='getUrl(getPageNumber(page.hasPrevious() && page.previous()))'
            ).mdi.mdi-arrow-left
            template(v-if='page.hasPrevious()')
              +e.LI.item: +e.A.link(:href='getUrl(range.min )', @click.prevent='select(range.min)') {{ range.min }}
            +e.LI.item(v-if='gaps.left'): +e.link.SPAN ...
            +e.LI.item(v-for='page in fills.left'): +e.A.link(
              :href='getUrl(page)'
              @click.prevent='select(page)'
            ) {{ page }}
            +e.LI.item: +e.link.is-active.SPAN(v-text='page.getPageNumber()')
            +e.LI.item(v-for='page in fills.right'): +e.A.link(:href='getUrl(page)' @click.prevent='select(page)') {{ page }}
            +e.LI.item(v-if='gaps.right'): +e.link.SPAN ...
            template(v-if='page.hasNext()')
              +e.LI.item: +e.A.link(:href='getUrl(range.max)' @click.prevent='select(range.max)') {{ range.max }}
            +e.LI.item: +e.A.link.--next(
              :href='getUrl(getPageNumber(page.hasNext() && page.next()))'
              @click.prevent='next()'
              :class='{"is-disabled": !page.hasNext()}'
            ).mdi.mdi-arrow-right
        cell(
          cols='12 3-xl'
        )

</template>

<script>

import { LimitOffsetPagination } from './Pagination'

export default {
  template: '<div></div>',
  name: 'UiLimitPagination',
  props: {
    pagination: {},
    extra: {
      type: Number,
      default: 2,
    },
    needAddBtns: {
      type: Boolean,
      default: () => false,
    },
    reset: {

    },
    url: {
      default: '/',
    },
  },

  data() {
    return {
      page: {},
      range: {
        min: 0,
        max: Infinity,
      },
      gaps: {
        left: false,
        right: false,
      },
      fills: {
        left: [],
        right: [],
      },
      one: 1,
      zero: 0,
      limit: 200,
      show: true,
    }
  },

  created() {
    if (this.page) this.recalcRange()
  },
  computed: {
    pageObj() {
      const to = this.page.limit + this.page.offset
      const from = this.page.offset + this.one

      return { to, from }
    },
  },
  watch: {
    pagination: {
      deep: true,
      immediate: true,
      handler() {
        this.page = new LimitOffsetPagination(this.pagination)
        this.show = true
      },
    },
    page(nval, oval) { this.recalcRange() },
    reset(nval, oval) {
      if (nval) {
        this.show = true
      }
    },
  },

  methods: {
    showAll(e) {
      this.$emit('showAll', e)
    },
    recalcRange() {
      const [min, max] = this.page.getPageRange()
      const current = this.page.getPageNumber()
      const [start, end] = this.page.getPageRange(this.extra, current)
      // Some javascript magic going on here:
      // Filling up array with page numbers.
      const range = Array
        .apply(null, { length: end - start + this.one })
        .map((x, i) => i + start)
      const currentIndex = range.indexOf(current)
      if (currentIndex !== -this.one) {
        this.fills.left = range.slice(this.zero, currentIndex)
        const minIndex = this.fills.left.indexOf(min)
        if (minIndex >= this.zero) {
          this.fills.left.splice(minIndex, this.one)
        }
        this.fills.right = range.slice(currentIndex + this.one)
        const maxIndex = this.fills.right.indexOf(max)
        if (maxIndex >= this.zero) {
          this.fills.right.splice(maxIndex, this.one)
        }
      } else {
        this.fills.left = this.fills.right = []
      }

      this.gaps.left = (
        this.fills.left.length &&
        this.fills.left[0] !== min + this.one
      )
      this.gaps.right = (
        this.fills.right.length &&
        this.fills.right[this.fills.right.length - this.one] !== max - this.one
      )
      this.range.min = min
      this.range.max = max
    },

    select(number) {
      this.$emit('input', this.page.getParameters(number))
    },

    next() {
      this.$emit('input', this.page.next())
    },

    previous() {
      this.$emit('input', this.page.previous())
    },
    getPageNumber({ limit, offset }) {
      const shift = 1
      return Math.floor(offset / limit) + shift
    },
    getUrl(page) {
      if (page > 1) return `${this.url}page/${page}/`
      return this.url
    },
  },
}

</script>

<template lang="pug">
  include /mixins
  resource-loader-controller(
    :resource="$options.blogPreviewListResource"
    ref='controller'
    :immediate='true'
    @gotIt='updateSlider'
  )
    template(#default='{ receive, result, loading }')
      div.relative
        ui-loader(v-if='loading')
        div(
          v-show='!!(result && result.items && result.items.length)'
        )
          +b.g-container--variant_default
            +b.preview-posts
              +e.title.H2 {{ _('TOTIS Pharma') }}
              +e.text.P {{ _('Oficjalny dystrybutor produktów z zakresu medycyny estetycznej i kosmetyki ') }}
            .relative
              .preview-posts-wrap-inliner.preview-box(
                :style='{"--sizer": `${getDivisible(result && result.items && result.items.length, 6) * 274}px` }'
              )
                +b.category-swipe-wrapper.preview-posts-wrap.g-row.g-row--appearance_spaced.--appearance_nowrap.fake-slider(
                  data-snap-slider="blog-slider"
                )
                  +e.cell.g-cell.g-cols(
                    v-for='item in result && result.items'
                  )
                    preview-post(
                      :item='item'
                    )
                +b.main-slider-controls.snap-controls(
                  v-if='result && result.items && result.items.length > 3'
                  data-snap-slider-nav="blog-slider"
                )
                  +b.controls--variant_default.controls-left--static-x.mdi.mdi-chevron-left.BUTTON.is-disabled(
                    data-snap-slider-goto="right"
                  )
                  +b.controls--variant_default.controls-right--static-x.mdi.mdi-chevron-right.BUTTON(
                    data-snap-slider-goto="left"
                  )
</template>

<script>
import {
  blogPreviewListResource,
} from '@api/vendor.service'

import sliderControl from '@utils/slider-controls'

export default {
  name: 'BlogList',
  blogPreviewListResource,
  sliderControl,
  data() {
    return {
      params: {
        limit: 10,
      },
    }
  },
  methods: {
    updateSlider() {
      this.$nextTick(() => {
        sliderControl('preview-box')
      })
    },
    getDivisible(length, x) {
      let r = length
      if (r > 5) r = 5
      const l = r % x
      if (!l) return 1
      return l
    },
  },
}
</script>

export default function addHorizontalScrolling() {
  // Определение ширины окна
  const windowWidth = window.innerWidth;
  const MAX_WINDOW_WIDTH = 992;
  // Получение ссылки на элементы страницы
  let scrollPage = document.getElementById("js-horizontal-scroll-page");
  let forScrollElement = document.getElementById('js-horizontal');

  // Если ширина окна больше или равна MAX_WINDOW_WIDTH, функция не выполняется
  if (windowWidth >= MAX_WINDOW_WIDTH) return;


  // Переменные для отслеживания начальной позиции касания
  let startX, startY;

  // Переменная для отслеживания видимости элемента во вьюпорте
  let inViewport = false;

  // Функции для включения и отключения прокрутки тела страницы
  const bodyScrollDisable = () => {
    document.body.classList.add('no-scroll');
  };

  const bodyScrollEnable = () => {
    document.body.classList.remove('no-scroll');
  };

  // Настройки для IntersectionObserver
  let options = {
    root: null, // Использование вьюпорта как контейнера просмотра
    rootMargin: '0px 0px 0px 0px', // Добавление отступа
    threshold: 1 // Полное пересечение элемента
  };

  // Callback-функция для IntersectionObserver
  function callback(entries, observer) {
    entries.forEach(entry => {
      inViewport = entry.isIntersecting; // Обновление состояния видимости элемента
    });
  }
 
  if(scrollPage) {
    // Создание экземпляра IntersectionObserver
    let observer = new IntersectionObserver(callback, options);
    observer.observe(scrollPage); // Наблюдение за элементом scrollPage
  
    // Обновление наблюдения при изменении размера окна
    window.addEventListener('resize', () => {
      observer.disconnect();
      observer.observe(scrollPage);
    });
  }

  // Обработчик начала касания
  function onTouchStart(event) {
    const touch = event.touches[0];
    startX = touch.clientX; // Запись начальной позиции по X
    startY = touch.clientY; // Запись начальной позиции по Y
  }

  // Обработчик прокрутки
  function onScroll(event) {
    if (inViewport) {
      event.preventDefault(); // Предотвращение стандартного поведения
      bodyScrollDisable(); // Отключение прокрутки тела страницы
    }
  }

  // Обработчик движения касания
  function onTouchMove(event) {
    const touch = event.touches[0];
    const deltaX = touch.clientX - startX; // Разница по X между текущей и начальной позициями касания
    let deltaY = touch.clientY - startY; // Разница по Y между текущей и начальной позициями касания
    const END = forScrollElement.scrollLeft >= (forScrollElement.scrollWidth - forScrollElement.clientWidth); // Достигнут ли конец горизонтальной прокрутки
    const START = forScrollElement.scrollLeft === 0; // Достигнуто ли начало горизонтальной прокрутки

    const UP = deltaY > 0; // Определение направления движения: вверх
    const DOWN = deltaY < 0; // Определение направления движения: вниз

    const stop = () => {
      event.preventDefault(); // Предотвращение стандартного поведения прокрутки
      bodyScrollDisable(); // Отключение прокрутки тела страницы
      forScrollElement.scrollLeft -= deltaY; // Прокрутка элемента по горизонтали в зависимости от изменения по Y
      startX = touch.clientX; // Обновление начальной позиции по X для следующего движения
      startY = touch.clientY; // Обновление начальной позиции по Y для следующего движения
    };

    if (scrollPage && inViewport) {
      // Условие: если движение вниз
      if (DOWN) {
        if ((START && !END) || (!START && !END)) {
          // Если элемент в начале или в середине прокрутки, останавливаем прокрутку
          startX = null;
          startY = null;
          stop();
        } else {
          // Если элемент в конце прокрутки, включаем прокрутку тела страницы
          bodyScrollEnable();
        }
      }
      // Условие: если движение вверх
      if (UP) {
        if ((END && !START) || (!START && !END)) {
          // Если элемент в середине или в конце прокрутки, останавливаем прокрутку
          stop();
        } else {
          // Если элемент в начале прокрутки, включаем прокрутку тела страницы
          bodyScrollEnable();
        }
      }
    } else {
      // Если элемент не в области видимости, включаем прокрутку тела страницы
      bodyScrollEnable();
    }
}

  // Обработчик окончания касания
  function onTouchEnd(event) {
    startX = null;
    startY = null;
  }

  // Добавление обработчиков событий
  document.addEventListener('touchstart', onTouchStart, { passive: false });
  document.addEventListener('scroll', onScroll, { passive: false });
  document.addEventListener('touchmove', onTouchMove, { passive: false });
  document.addEventListener('touchend', onTouchEnd, { passive: true });
}
<template lang="pug">
  include /mixins
  +b.event-request
    +b.ds-panel--space_2xl
      +b.close-popup.mdi.mdi-close.A(
        @click='$emit("close")'
      )
      +e.element--offset_full
        .relative
          +b.error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
        +b.ds-caption.--size_md.--size_md-xl.--medium.--color_grey.--appearance_center.P.tt-space_mt--3.tt-space_mb--3 {{ _('Заполните пожалуйста данные для регистрации на семинар') }}
        +b.VALIDATION-OBSERVER(
          ref="validator",
          v-slot="{ valid }",
          tag="form",
        )
          ui-loader(v-if='isLoad')
          +b.g-row
            +b.tt-space_mt--6.CELL(
              v-for='{ name, label, tag, classname, errorClasses, mask, type, cols, ...other } in form_fields'
              :cols='cols'
              :key='name'
            )
              tag(
                :tag='tag || "d-control-input"'
                :input-label='label'
                v-model='form[name]',
                :ref='name'
                @input='$emit("update", form)'
                :type='type || "text"'
                :class='classname',
                :error-classes='errorClasses'
                v-bind='other'
              )
            +b.g-cell.g-cols--12.tt-space_mt--6
              d-control-static-input(
                :input-label='_("Я косметолог")'
                v-model='form.is_cosmetologist',
                ref='is_cosmetologist'
                id='is_cosmetologist'
                type='checkbox'
                name='is_cosmetologist'
              )
            +b.g-cell.g-cols--12.tt-space_mt--6
              +b.tt-button.BUTTON(
                type='submit'
                :disabled='isLoad',
                @click.prevent='prepareData(valid)'
              )
                +e.title.P {{ _('Записаться') }}

</template>
<script>
import {
  MakeSubscription,
} from '@api/events.service'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { decodeHTMLEntities } from '@utils/helpers'

import {
  AnswerMessage,
} from '@utils/submit'

import {
  analyticEventSubscribe,
} from '@analytic/event'

export default {
  name: 'EventSubscriptionForAnonim',
  mixins: [FormMixin],
  props: ['info'],
  data() {
    return {
      form_fields: [
        {
          cols: '6',
          name: 'date',
          label: this._('Дата'),
          disabled: true,
        },
        {
          cols: '6',
          name: 'city',
          label: this._('Город'),
          disabled: true,
        },
        {
          cols: '12',
          name: 'title',
          label: this._('Тема'),
          disabled: true,
          tag: 'd-control-textarea',
          rows: 2,
        },
        {
          cols: '6',
          name: 'first_name',
          label: this._('Имя'),
          rules: 'required',
        },
        {
          cols: '6',
          name: 'last_name',
          label: this._('Фамилия'),
          rules: 'required',
        },
        {
          cols: '12',
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          tag: 'phone-input',
        },
      ],
      form: {
        name: decodeHTMLEntities(window.request_name),
        email: window.request_email,
        phone: window.request_phone,
        city: this.info.event_city,
        date: this.info.event_date,
        title: this.info.event_title,
        is_cosmetologist: false,
        event: this.info.event,
      },
      non_field_errors: '',
      isLoad: false,
    }
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return MakeSubscription.execute({}, data)
        .then(res => {
          // AnswerMessage(res.data, this)
          const meta = {
            message: {
              title: this._('Ваша заявка на участие в семинаре успешно отправлена!'),
              text: this._('Наш менеджер свяжется с вами.'),
            },
          }
          AnswerMessage(meta, this)
          analyticEventSubscribe(this.info.event)
          // window.location.href = res.data.redirect.location
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
          this.$emit('close')
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

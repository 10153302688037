import VueSelectionController from '@aspectus/vue-selection-controller'
import OrderView from '@app/Order/View'
import PhoneConfirmationCheck from '@app/Order/PhoneConfirmationCheck'
import PaymentRequisites from '@app/Order/PaymentRequisites'
import PaymentRequisitesModal from '@app/Order/PaymentRequisitesModal'
import OrderStateLoader from '@app/Order/StateLoader'

export function install(Vue) {
  Vue.component(OrderView.name, OrderView)
  Vue.component(PhoneConfirmationCheck.name, PhoneConfirmationCheck)
  Vue.component(PaymentRequisites.name, PaymentRequisites)
  Vue.component(PaymentRequisitesModal.name, PaymentRequisitesModal)
  Vue.component(OrderStateLoader.name, OrderStateLoader)
  Vue.use(VueSelectionController, {
    name: 'v-selection-controller',
  })
}

export default { install }

import {
  createResource,
  postResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'fortunes-wheel'

const GET_WHEEL_SECTORS_LIST_URL = prefixAPI('/{id}/', MODEL)
const GET_TARGET_SECTOR_URL = prefixAPI('/start/{id}/', MODEL)
const GET_USER_WHEELS = prefixAPI('/available-user/{?country_available}', MODEL)
const GET_WHEELS_RESULTS = prefixAPI('/result/{?limit,offset}', MODEL)
const GET_CUSTOM_RESULTS_URL = prefixAPI('/custom-result/list/{?is_showed,limit,offset,can_modal_show,can_site_show,country_available}', MODEL)
const MAKE_AS_READ_URL = prefixAPI('/custom-result/hide/{id}/', MODEL)

export const getSectorsList = createResource(GET_WHEEL_SECTORS_LIST_URL)
export const getCurrentSector = createResource(GET_TARGET_SECTOR_URL)
export const getUserWheels = createResource(GET_USER_WHEELS)
export const getWheelsResuts = createResource(GET_WHEELS_RESULTS)
export const getCustomResuts = createResource(GET_CUSTOM_RESULTS_URL)
export const makeGiftAsRead = createResource(MAKE_AS_READ_URL, postResource)

<template lang="pug">
  include /mixins
  +b.comparison.tt-space_mt--10
    +e.head
      +b.g-row--align_center(
        :class='list.length ? "g-row--justify_start" : "g-row--justify_center"'
      )
        +b.g-cell.g-cols
          slot(
            name='title'
          )
        +b.g-cell.g-cols(v-if='list.length')
          +b.tt-button--bordered.--light.BUTTON(
            @click.prevent='clear'
          )
            +e.title.P {{ _('Очистить сравнение') }}

    +e.body.tt-space_mt--10(
      v-if='list.length && val.length'
    )
      .relative
        +b.first-column

          +e.el(
            :style='{"height": val[0].h }'
          )
            +b.add-to-compare.A.g-row--align_center.--appearance_nowrap(
              :href='baseUrl',

            )
              cell
                +e.img.IMG(
                  :src='list[0].props && list[0].props.product_variant && list[0].props.product_variant.props.img && list[0].props.product_variant.props.img.catalog_preview'
                )
              cell
                +e.title.P {{ _("Добавить еще один товар") }}
          +e.col--first
            +e.cell(
              :style='{"height": val[idx + 1].h }'
              @mouseenter='hoverEffect($event, idx + 1, "add")'
              @mouseleave='hoverEffect($event, idx + 1, "remove")'
              v-for='(el, idx) in initial_arrts'
            )
              +b.ds-caption--light.--size_sm.--color_grey.P {{ el.title }}
            +e.cell(
              :style='{"height": val[idx + 1 + initial_arrts.length].h }'
              v-for='(el, idx) in attributes'
              @mouseenter='hoverEffect($event, idx + 1 + initial_arrts.length, "add")'
              @mouseleave='hoverEffect($event, idx + 1 + initial_arrts.length, "remove")'
            )
              +b.ds-caption--light.--size_sm.--color_grey.P {{ el.attribute.title }}
        +b.comparion-view
          +b.comparison-scroll-head

            +e.el(
              v-for='(el, idx) in list',
              :key='el.id'
              :class='"cell"+idx'
              :style='{"height": val[0].h }'
            )
              +b.compare-el
                
                row(
                  :appearance='["nowrap"]'
                )
                  cell
                    +e.img.IMG(
                      :src='el.props && el.props.product_variant && el.props.product_variant.props.img && el.props.product_variant.props.img.catalog_preview'
                    )
                  cell.is-visible-xl.infinite-view
                    +b.ds-caption--light.--size_xs.--color_grey.--light.P {{ el.props.product_variant.props.product.props.brand.caption }}
                    +b.ds-caption--light.--size_sm.--medium.--no-decor.tt-link--variant_start.A(
                      :href='el.props.product_variant.props.url'
                    ) {{ el.props.product_variant.props.product.props.title }}
                    +b.ds-caption--light.--size_sm.--color_grey.P(v-html='el.props.product_variant.props.product.props.short_description')
                BtnsController(:t='getAvailability(el.props.product_variant.props.price_definition)')
                  template(#default='{ has_no_contract_btn, has_cosmetologist_btn, has_cart_btn, has_out_of_stock_btn, show_cosmetologist_desc, show_price }')
                    row(
                      align='center'
                    )
                      cell
                        +b.tt-button--bordered.--light.--mini.--no-space.--appearance_fake.P(
                          v-if="show_price"
                        )
                          +b.ds-caption--size_sm.--height_2xs.--medium.SPAN(
                            v-html='getPrice(el.props.product_variant.props.price_definition)'
                          )

                      cell.is-visible-xl
                        modal-trigger(
                          v-if="has_cosmetologist_btn"
                          url='UiElements/CosmetologPermissions',
                          name='CosmetologPermissions',
                          :classes='["cart-modal"]'
                          subtitle_auth="true",
                        )
                          template(#default='{ open }')
                            +b.tt-button--mini.--no-space.product-add-to-card.BUTTON(
                              @click='open()',
                            )
                              +e.title.P {{ _('Повысить уровень') }}
                        modal-trigger(
                          v-else-if="has_no_contract_btn"
                          url='UiElements/AccessPriceRequest',
                          name='AccessPriceRequest',
                          :classes='["defaultModal"]'
                          subtitle_auth="true",
                          :brand="el.props.product_variant.props.product.props.brand.id"
                          :user_name='userFirstname || ""'
                          :user_phone='userPhone || "" '
                        )
                          template(#default='{ open }')
                            +b.tt-button--mini.--no-space.product-add-to-card.BUTTON(
                              @click='open()',
                            )
                              +e.title.P {{ _('Заключить контракт') }}
                        add-to-cart(
                          v-if='has_cart_btn'
                          :id='el.props.product_variant.id',
                          :type='el.props.product_variant.type',
                          amount='1'
                          :name='el.props.product_variant.caption',
                        )
                          template(#default='{ add, load }')
                            +b.tt-button--mini.--no-space.product-add-to-card.BUTTON(
                              @click.prevent='add'
                              :disabled='load'
                              :title='_("Добавить в корзину")'
                            )
                              +e.icon.icon-cart.SPAN
                              +e.title.P {{ _('в корзину') }}
                        modal-trigger(
                          v-if='has_out_of_stock_btn'
                          :user_name='username'
                          :user_phone='phone'
                          url='Preorder/Modal',
                          name='PreOrderModal',
                          :id='el.props.product_variant.id'
                          :can_order='el.props.product_variant.props.allow_preorder_payment'
                          :img='el.props && el.props.product_variant && el.props.product_variant.props.img && el.props.product_variant.props.img.catalog_preview || "https://via.placeholder.com/150x150"'
                          :maintitle='el.props.product_variant.props.product.props.title'
                          :description='el.props.product_variant.props.product.props.short_description'
                          :weight='el.props.product_variant.props.weight.props.name'
                          :stock='el.props.product_variant.props.stock'
                          :type='el.props.product_variant.type',
                          :price='el.props.product_variant.props.price',
                          v-slot="{ open }"
                          :classes='["videoModal"]'
                        )
                          +b.tt-button--mini.--no-space.--appearance_free.--bordered_primary.product-preorder.BUTTON(
                            @click.prevent='open()'
                          )
                            +e.title.P {{ _('Предзаказ') }}
                      cell.is-visible-xl
                        row(align='center' justify='center')
                          cell
                            likes-view(
                              :is_auth='is_auth'
                              :id='el.props.product_variant.id'
                            )
                +e.remove.BUTTON(
                  @click.prevent='remove(el)'
                ) &times;

          +b.comparison-table
            +e.col(
              v-for='(item, idx) in list'
            )
              +e.cell(
                v-for='(el, i) in initial_arrts'
                :class='"cell"+idx'
                @mouseenter='hoverEffect($event, i + 1, "add")'
                @mouseleave='hoverEffect($event, i + 1, "remove")'
                :style='{"height": val[i + 1].h }'
              )
                +b.ds-caption--light.--size_sm.--color_grey.P(
                  v-if='el.mapper'
                ) {{ el.mapper(getValue(item, el.getter)) }}
                +b.ds-caption--light.--size_sm.--color_grey.P(
                  v-else
                ) {{ getValue(item, el.getter) }}
              +e.cell(
                v-for='(el, i) in attributes'
                @mouseenter='hoverEffect($event, i + 1 + initial_arrts.length, "add")'
                @mouseleave='hoverEffect($event, i + 1 + initial_arrts.length, "remove")'
                :class='"cell"+idx'
                :style='{"height": val[i + 1 + initial_arrts.length].h }'
              )
                +b.ds-caption--light.--size_sm.--color_grey.P(
                  v-if='hasAttribute(item.props.product_variant.props.product.props.attributes, el.attribute.id)'
                ) {{ getAttribute(item.props.product_variant.props.product.props.attributes, el.attribute.id) }}
                +b.ds-caption--light.--size_sm.--color_grey.P(
                  v-else
                ) -
    +e.body(
      v-else
    )
      +b.empty-comparison
        +e.img.IMG(src='/static/img/empty_comparison.svg')
        +b.title.ds-caption--size_md.--size_xl-xl.--medium.--appearance_center.H2 {{ _('В сравнении пока нет товаров') }}
        +b.tt-button--fixed.tt-space_mt--5.A(
          :href='baseUrl'
        )
          +e.title.P {{ _('Перейти в каталог') }}

</template>

<script>

import {
  mapState,
  mapActions,
} from 'vuex'

import {
  pathGetter,
  confirmWithPromise,
  decodeHTMLEntities,
} from '@utils/helpers'

import {
  onlyAttributesResource,
} from '@api/catalog.service'
import {
  removeFromComparison,
  clearComparison,
} from '@api/comparison.service'

import BtnsController from '@app/Purchase/BtnsController'

// import find from 'ramda/src/find'
// import propEq from 'ramda/src/propEq'

export default {
  name: 'ComparisonView',
  props: ['userFirstname', 'userPhone'],
  components: {
    BtnsController,
  },
  data() {
    return {
      baseUrl: window.baseUrl,
      currency: window.currency,
      is_auth: window.authent,
      initial_arrts: [
        {
          key: 'brand',
          getter: pathGetter('props', 'product_variant', 'props', 'product', 'props', 'brand', 'caption'),
          title: this._('Бренд'),
        },
        {
          key: 'categories',
          getter: pathGetter('props', 'product_variant', 'props', 'product', 'props', 'categories'),
          title: this._('Категория'),
          mapper: arr => arr.map(e => e.caption).join(', '),
        },
        {
          key: 'weight',
          getter: pathGetter('props', 'product_variant', 'props', 'weight', 'props', 'name'),
          title: this._('Объём'),
        },
        {
          key: 'procedures',
          getter: pathGetter('props', 'product_variant', 'props', 'product', 'props', 'procedures'),
          title: this._('Процедура'),
          mapper: arr => arr.map(e => e.caption).join(', '),
        },

      ],
      attributes: [],
      val: [],
      username: decodeHTMLEntities(window.request_name),
      phone: window.request_phone,
    }
  },
  computed: {
    ...mapState('comparison', [
      'list',
    ]),
  },
  async created() {
    await this.getAttributes()
    await this.getList()
    this.calcHeight()
    console.log(this.list);
  },
  mounted() {

  },
  methods: {
    ...mapActions('comparison', [
      'getList',
    ]),
    getAvailability(price) {
      const { user } = price
      return user.unavailable
    },
    getPrice(price) {
      const { user } = price
      const endPrice = user.ranged_prices[0].sale_price || user.ranged_prices[0].default_price
      return this.getSplittedHtml(endPrice)
    },
    async clear() {
      const question = this._('Очистить сравнение?')
      await confirmWithPromise(question, this)
      await clearComparison.execute()
      await this.getList()
    },
    hoverEffect(event, idx, action) {
      const one = 1
      Array.from(document.getElementsByClassName('first-column__cell'))[idx - one].classList[action]('is-active')
      this.list.forEach((el, i) => {
        Array.from(document.getElementsByClassName(`cell${i}`))[idx].classList[action]('is-active')
      })
    },
    getValue(obj, getter) {
      return getter(obj)
    },
    async remove(el) {
      await removeFromComparison.execute({ id: el.id })
      await this.getList()
      this.calcHeight()
    },
    async getAttributes() {
      const { data: { items } } = (await onlyAttributesResource.execute())
      this.attributes = items
    },
    getAttribute(arr, key) {
      return arr.filter(el => el.props.attribute == key).map(el => el.caption).join(', ')
    },
    hasAttribute(arr, key) {
      return arr.find(el => el.props.attribute == key)
    },
    calcHeight() {
      const one = 1
      const zero = 0
      this.val = []
      let total = this.initial_arrts.length + this.attributes.length
      total += one
      for (let x = zero; x < total; x++) {
        this.val.push({
          h: 'auto',
        })
      }
      if (this.val.length > zero) {
        this.val = JSON.parse(JSON.stringify(this.val))
        this.val.forEach((el, i) => {
          el = 'auto'
        })
      }
      this.isPageLoading = true
      const time = 500
      setTimeout(() => {
        const arr = []
        this.list.forEach((el, i) => {
          arr.push(Array.from(document.getElementsByClassName(`cell${i}`)).map((element, index) => ({ h: element.scrollHeight })))
        })
        this.val = JSON.parse(JSON.stringify(this.val))
        this.val = arr[0]
        for (let i = zero; i < arr.length; i++) {
          for (let j = zero; j < arr[i].length; j++) {
            if (this.val[j].h < arr[i][j].h) {
              this.val[j].h = arr[i][j].h
              this.$set(this.val[j], 'h', arr[i][j].h)
            }
          }
        }
        this.val = JSON.parse(JSON.stringify(this.val || []))
        this.val.forEach((el, i) => {
          // el = JSON.parse(JSON.stringify(el))
          el.h = `${el.h}px`
          this.$set(this.val[i], 'h', `${el.h}`)
        })
        if (total - one != this.val.length) {
          const other = total - this.val.length
          for (let x = zero; x < other; x++) {
            this.val.push({
              h: 'auto',
            })
          }
        }
        this.isPageLoading = false
      }, time)
    },
  },
}
</script>

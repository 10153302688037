<script>

import {
  DeleteSubscription,
} from '@api/feedback.service'

import {
  mapActions,
  mapState,
} from 'vuex'

import {
  confirmWithPromise,
} from '@utils/helpers'

export default {
  name: 'BrandSubscribePreview',
  props: [
    'id',
  ],
  data() {
    return {
    }
  },
  computed: {
    ...mapState('brand', [
      'ids',
      'count',
    ]),
    isLiked() {
      return this.ids.includes(this.id)
    },
  },
  methods: {
    ...mapActions('brand', [
      'addId',
      'removeId',
      'brandSubscriptionIdsList',
    ]),
    add() {
      const data = {
        product_variants: [this.id],
      }
      addToFavorite
        .execute({}, data)
        .then(() => {
          this.addId(this.id)
        })
    },
    async remove() {
      const question = this._('Удалить товар из избранного?')
      await confirmWithPromise(question, this)

      const data = {
        product_variants: [this.id],
      }
      DeleteSubscription
        .execute({}, data)
        .then(() => {
          // this.removeId(this.id)
          this.brandSubscriptionIdsList()
          this.$emit('hook:remove', true)
          return
          // if (this.notReload) {
          // }
          // window.location.reload()
        })
    },
  },
  render(h) {
    return this.$scopedSlots.default({
      add: () => this.add(),
      remove: () => this.remove(),
      is_added: this.isLiked,
    })
  },
}
</script>

import Auth from '@app/Auth/Auth'
import MailingAuth from '@app/Auth/MailingAuth'
import Logout from '@app/Auth/Logout'
import PasswordChange from '@app/Auth/PasswordChange'
import PasswordRestore from '@app/Auth/PasswordRestore'
import Registration from '@app/Auth/Registration'
import Verify from '@app/Auth/Verify'
import PasswordRestoreRequest from '@app/Auth/PasswordRestoreRequest'

export default function install(Vue) {
  Vue.component(Verify.name, Verify)
  Vue.component(Auth.name, Auth)
  Vue.component(MailingAuth.name, MailingAuth)
  Vue.component(Logout.name, Logout)
  Vue.component(Registration.name, Registration)
  Vue.component(PasswordChange.name, PasswordChange)
  Vue.component(PasswordRestore.name, PasswordRestore)
  Vue.component(PasswordRestoreRequest.name, PasswordRestoreRequest)
}

<template>
  <events-filter-controller
    :receive="allProps.receive"
    :all="allProps.result"
    :receive-available='() => {}'
    :receive-prices='() => {}'
    :displayed="displayedFilters"
    :pagination='pagination'
    :loading="allProps.loading"
    :value="value"
    @input="handleFilter"
  />
</template>

<script>

import {
  attributesResource,
} from '@api/events.service'

import EventsFilterController from '@app/Events/Filters/Controller'

import ViewMixin from '@app/FiltersSimple/mixins/ViewMixin'

export default {
  mixins: [ViewMixin],
  attributesResource,
  components: {
    EventsFilterController,
  },
}

</script>

function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validator",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(_vm.isLoad)?_c('ui-loader'):_vm._e(),_c('div',{staticClass:"g-row"},[_vm._l((_vm.form_fields),function(ref){
var rules = ref.rules;
var name = ref.name;
var label = ref.label;
var hasTip = ref.hasTip;
var mask = ref.mask;
var tag = ref.tag;
var condition = ref.condition;
var rest$1 = objectWithoutProperties( ref, ["rules", "name", "label", "hasTip", "mask", "tag", "condition"] );
var rest = rest$1;
return [(condition ? condition() : true)?_c('div',{staticClass:"g-cell g-cols g-cols--12 tt-space_mt tt-space_mt--6 relative",class:("g-cols--" + (rest.cols))},[_c('tag',_vm._g(_vm._b({ref:name,refInFor:true,attrs:{"tag":tag || "d-control-input","rules":rules,"input-label":label,"name":name},on:{"input":function($event){return _vm.$emit("update", _vm.form)}},model:{value:(_vm.form[name]),callback:function ($$v) {_vm.$set(_vm.form, name, $$v)},expression:"form[name]"}},'tag',rest,false),rest.events)),(hasTip)?_c('div',{staticClass:"order-tips",attrs:{"title":_vm.tip}},[_c('p',{staticClass:"order-tips__title"},[_vm._v(_vm._s(_vm._('Для статуса заказа')))])]):_vm._e()],1):_vm._e()]})],2),_c('div',{staticClass:"g-row g-row--justify_end"},[_c('div',{staticClass:"g-cell g-cols tt-space_mt tt-space_mt--10"},[_c('div',{staticClass:"fixed-order-btn"},[_c('token-confirmation-check',{attrs:{"token":_vm.authUser ? _vm.form.email : _vm.form.phone,"additional-data":_vm.getAdditionalData(),"action":function (code) { return _vm.validate(valid, code); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var is_confirmed = ref.is_confirmed;
var sendCode = ref.sendCode;
return [(valid && !_vm.authUser)?_c('button',{staticClass:"tt-button tt-button--for-input",attrs:{"disabled":_vm.isLoad},on:{"click":function($event){$event.preventDefault();return sendCode(true)}}},[_c('p',{staticClass:"tt-button__title tt-button__title--mini"},[_vm._v(_vm._s(_vm._('далее')))])]):_vm._e(),(valid && _vm.authUser)?_c('button',{staticClass:"tt-button tt-button--for-input",attrs:{"disabled":_vm.isLoad},on:{"click":function($event){$event.preventDefault();return sendCode(true)}}},[_c('p',{staticClass:"tt-button__title tt-button__title--mini"},[_vm._v(_vm._s(_vm._('далее')))])]):_vm._e(),(!valid)?_c('button',{staticClass:"tt-button tt-button--for-input",attrs:{"type":"submit","disabled":_vm.isLoad},on:{"click":function($event){$event.preventDefault();return _vm.validate(valid)}}},[_c('p',{staticClass:"tt-button__title tt-button__title--mini"},[_vm._v(_vm._s(_vm._('далее')))])]):_vm._e()]}}],null,true)})],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
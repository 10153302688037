<template lang="pug">
  include /mixins

  +b.VALIDATION-OBSERVER.tt-space_mt--4.--0-fhd(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    .control-stack
      +b.g-row.--space_none.--align_center.--appearance_nowrap
        +b.g-cell.g-cols.full-w

          +b.d-control-input--appearance_bordered.--appearance_stacked.D-CONTROL-INPUT.control-stack__element.control-stack__element--position_first(
            :rules="'required|email'",
            :input-label='labels.email'
            v-model='form.email',
            name='email'
            autocomplete='qwertqwtqwt'
            type='email'
          )
          .relative(v-if="non_field_errors")
            +b.error-label.LABEL {{ non_field_errors[0] }}

        +b.g-cell.g-cols(style='flex-shrink: 0')
          +b.tt-button--for-input.BUTTON.control-stack__element.control-stack__element--position_last(
            type='submit'
            :disabled='isLoad',
            @click.prevent='prepareData(valid)'
          )
            +e.title--mini.P {{ _('Подписаться') }}

</template>

<script>

import {
  createSiteSubscription,
  createBrandSubscription,
} from '@api/feedback.service'

import {
  AnswerMessage,
} from '@utils/submit'
import {
  mapActions,
} from 'vuex'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'Subscription',
  props: ['brand', 'userEmail'],
  mixins: [FormMixin],
  data() {
    return {
      labels: {
        email: this._('Ваш e-mail'),
      },
      form: {
        email: this.userEmail || window.request_email,
        is_subscribed: true,
        source: window.location.href,
      },
      non_field_errors: '',

    }
  },
  methods: {
    ...mapActions('brand', [
      'brandSubscriptionIdsList',
    ]),
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      if (this.brand) {
        const { brand } = this
        this.form = { ...this.form, brand }
      }
      this.submit(valid, this.form)
    },
    send(data) {
      const action = this.brand ? createBrandSubscription : createSiteSubscription
      return action.execute({}, data)
        .then(res => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: 'form_submit_subscribe' })
          AnswerMessage(res.data, this)
          this.form.email = ''
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
          this.$emit('success')
          if (this.brand) {
            this.brandSubscriptionIdsList()
          }
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.g-row
    +b.g-cell.g-cols--12.--6-md.--3-xl.--2-fhd(
      v-for='field in config'
    )
      tag(
        :tag='field.tag'
        v-bind='field.props'
        v-model='form[field.name]'
        :input-label='field.title',
        @input='setFilters'
        v-on='field.events'
        :name='field.name'
      )
</template>

<script>

import {
  categoriesResource,
  brandsResource,
} from '@api/catalog.service'

import pathOr from 'ramda/src/pathOr'

export default {
  name: 'DistibutorSidebar',
  props: ['changeFilters', 'filters', 'receive'],
  data() {
    return {
      form: {
        brand: '',
        category: '',
      },
      config: [
        {
          title: this._('Бренд'),
          value: '',
          tag: 'd-control-multiselect',
          name: 'brand',
          events: {
            open: $event => this.getOptions('brand', brandsResource),
          },
          props: {
            options: [],
            label: 'id',
            customLabel: ({ props }) => `${props.title}`,
          },
        },
        {
          title: this._('Категория'),
          value: '',
          tag: 'd-control-multiselect',
          name: 'category',
          events: {
            open: $event => this.getOptions('category', categoriesResource),
          },
          props: {
            options: [],
            label: 'id',
            customLabel: ({ props }) => `${props.title}`,
          },
        },
      ],
    }
  },
  methods: {
    setFilters() {
      const data = {}
      Object.keys(this.form).forEach(key => {
        data[key] = pathOr('', ['props', 'label'], this.form[key])
      })
      this.changeFilters(data)
      this.$emit('update', data)
    },
    async getOptions(name, resource) {
      const idx = this.config.findIndex(el => el.name == name)
      if (this.config[idx].props.options.length) return
      const { data: { items } } = (await resource.execute())
      this.config[idx].props.options = items
    },
  },
}
</script>

export function analyticEventSubscribe(
  seminar_id, seminar_title,
) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'event_registration',
    ecommerce: {
      seminar_id,
      seminar_title,
    },
  })
}

export function analyticEventUnsubscribe(
  seminar_id, seminar_title,
) {
  if (!window.is_analytic_enabled) return
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'event_cancel_registration',
    ecommerce: {
      seminar_id,
      seminar_title,
    },
  })
}

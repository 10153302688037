<template lang="pug">
  include /mixins
  +b.search-result-item(
  )
    +e.img-link.A(
      @click.prevent='goTo(link)'
      :href='link.props.url'
    )
      +e.mini-img.PICTURE
        template(v-if='link.props.img')
          source(
            type="image/webp"
            :srcset="link.props.img.catalog_preview_webp"
          )
          +e.element.IMG(
            :src='link.props.img.catalog_preview'
          )
        +e.element.IMG(
          v-else
          src='https://via.placeholder.com/75x75'
        )
    +e.info
      +e.line
      +e.line
        +e.title.ds-caption--size_xs.--medium.A(
          :href='link.props.url'
          @click.prevent='goTo(link)'
        )
          span(v-html='findValueAndHighlightTerm(link.props.product.props.title, link.props.product.props.title, localsearch)')
      +e.line.tt-space_mt--1
        +e.content.ds-caption--size_2xs.--color-relief.--medium.A(
          :href='link.props.url'
          @click.prevent='goTo(link)'
        )
          span(v-html='highlightTerm(link.props.product.props.short_description, localsearch )')
      +b.ds-caption--size_2xs.--color-relief.--medium.P.tt-space_mt--1 {{ link.props.weight.props.name }}
      BtnsController(:t='link.props.price_definition.user.unavailable')
        template(#default='{ has_no_contract_btn, has_cosmetologist_btn, has_cart_btn, has_out_of_stock_btn, show_cosmetologist_desc, show_price, availability }')
          +e.line
            +e.price.ds-caption--size_sm.--size_sm-xl.--color-relief.--bold.P(
              v-if="show_price"
              v-html='getSplittedHtml(getPrice(link.props.price_definition))'
            )
            +e.price--fake(v-else) &nbsp;
            
            template(
              v-if="has_cosmetologist_btn"
            )
              modal-trigger(
                url='UiElements/CosmetologPermissions',
                name='CosmetologPermissions',
                :classes='["cart-modal"]'
                subtitle_auth="true",
              )
                template(#default='{ open }')
                  +b.tt-button--mini.--appearance_free.tt-space_mb--2.BUTTON(
                    @click.stop='trigger_overlay(); open()',
                  )
                    +e.title.P {{ _('Повысить уровень') }}
            modal-trigger(
              v-else-if="has_no_contract_btn"
              url='UiElements/AccessPriceRequest',
              name='AccessPriceRequest',
              :classes='["defaultModal"]'
              subtitle_auth="true",
              :brand="link.props.product.props.brand.id"
              :user_name='username'
              :user_phone='phone'
            )
              template(#default='{ open }')
                +b.tt-button--mini.--appearance_free.tt-space_mb--2.BUTTON(
                  @click='trigger_overlay(); open()',
                )
                  +e.title.P {{ _('Заключить контракт') }}
            modal-trigger(
              v-if='has_out_of_stock_btn'
              :user_name='username'
              :user_phone='phone'
              url='Preorder/Modal',
              name='PreOrderModal',
              :id='link.id'
              :can_order='link.props.allow_preorder_payment'
              :img='link.props.image && link.props.image.catalog_preview || "https://via.placeholder.com/150x150"'
              :maintitle='link.props.product.props.title'
              :description='link.props.product.props.short_description'
              :weight='link.props.weight.props.name'
              :stock='link.props.stock'
              :price='link.props.price'
              :type='link.type',
              v-slot="{ open }"
              :classes='["videoModal"]'
            )
              +b.tt-button--mini.--appearance_free.--bordered_primary.product-preorder.tt-space_mb--2.BUTTON(
                @click.prevent='trigger_overlay(); open()'
              )
                +e.title.P {{ _('Предзаказ') }}
            add-to-cart(
              v-if='has_cart_btn'
              :id='link.id',
              :type='link.type',
              amount='1'
              :name='link.caption',
            )
              template(#default='{ add, load }')
                +b.tt-button--mini.--appearance_free.tt-space_mb--2.BUTTON(
                  @click.prevent='trigger_overlay(); add()'
                  :disabled='load'
                  :title='_("Купить")'
                )
                  +e.title.P {{ _('Купить') }}
</template>

<script>

import {
  availableInfo,
} from '@utils/available_info'
import {
  setCookie,
} from '@utils/cookies'

import BtnsController from '@app/Purchase/BtnsController'

import { ACTIVE_PRODUCT_COOKIE_NAME } from '@utils/directives'

import { decodeHTMLEntities } from '@utils/helpers'

const CURRENCY = window.currency

export default {
  props: {
    findValueAndHighlightTerm: {},
    highlightTerm: {},
    link: {},
    localsearch: {},
    trigger_overlay: {},
    t: {},
  },
  components: {
    BtnsController,
  },
  data() {
    return {
      username: decodeHTMLEntities(window.request_name),
      phone: window.request_phone,
    }
  },
  CURRENCY,
  methods: {
    getAvailability(price) {
      const { user } = price
      return availableInfo(user.unavailable)
    },
    getPrice(price) {
      const { user } = price
      const endPrice = user.ranged_prices[0].sale_price || user.ranged_prices[0].default_price
      return endPrice
    },
    goTo(item) {
      setCookie(ACTIVE_PRODUCT_COOKIE_NAME, item.props.id, 1)
      window.location.href = item.props.url
    },
  },
}
</script>

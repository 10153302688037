<template lang="pug">
  include /mixins
  div
    slot(
      name='btn',
      :open='() => showForm()'
    )
    transition(name='fade')
      .is-blured(
        v-if='show'
        @click.prevent='showForm()'
      )
    transition(name='slide')
      +b.mobile-sidebar(v-if='show')
        slot(
          :close='close'
          name='sidebar'
        )
</template>

<script>
export default {
  name: 'UiSidebar',
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showForm() {
      this.show = !this.show
      this.$body.dataset.blocked = this.show
    },
    close() {
      this.show = false
      this.$body.dataset.blocked = this.show
    },
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.loaderController,{key:_vm.$attrs.loaderKey,tag:"component",attrs:{"resource":_vm.resource,"immediate":_vm.immediate},on:{"gotIt":function($event){return _vm.$emit('gotIt')}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var receive = ref.receive;
var loading = ref.loading;
var result = ref.result;
return [_c(_vm.paginationController,{key:_vm.$attrs.paginationKey,ref:"paginationController",tag:"component",attrs:{"resultPagination":result && result.pagination,"receiver":receive,"parameters":_vm.parameters,"label":_vm.label},on:{"update:parameters":_vm.changeParameters},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var filters = ref.filters;
var changeFilters = ref.changeFilters;
var pagination = ref.pagination;
var changePagination = ref.changePagination;
return [_vm._t("default",null,{"parameters":_vm.parameters,"filters":filters,"changeFilters":changeFilters,"pagination":pagination,"changePagination":changePagination,"receive":receive,"loading":loading,"result":result})]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
  include /mixins

  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    .relative
      +b.error-label.LABEL(v-if="non_field_errors") {{ non_field_errors[0] }}
    row(
      :justify='["end", "between-xl"]'
      align='end'
    )
      +b.g-cell.g-cols--12
        +b.g-row
          +b.g-cell.g-cols--12.tt-space_mt--6(
            v-for='{ rules, name, label, mask, showForModels, tag } in form_fields'
          )
            tag(
              :tag='tag || "d-control-input"'
              :rules="rules",
              :input-label='label'
              :ref='name'
              :name='name'
              v-model='form[name]',
              v-if='showForModels.includes(modelName)'
            )

      +b.g-cell.g-cols--12.tt-space_mt--6
        +b.tt-button--redesign-h.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData(valid)'
        )
          +e.title.P {{ _('Отправить') }}

</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { createOffer } from '@api/offers.service'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'SpecialOffers',
  props: {
    offersVariant: Number,
    userName: String,
    userPhone: String,
    attributes: Array,
    brand: {},
    sendAttributes: Boolean,
    modelName: String,
  },
  mixins: [FormMixin],
  data() {
    return {
      form_fields: [
        {
          name: 'count',
          label: this._('Желаемое количество к заказу'),
          rules: 'required',
          showForModels: [
            'special_offer.equipment',
            'special_offer.branding',
          ],
        },
        {
          name: 'name',
          label: this._('Ваше имя'),
          rules: 'required',
          showForModels: [
            'special_offer.travel',
            'special_offer.equipment',
            'special_offer.branding',
          ],
        },
        {
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          tag: 'phone-input',
          showForModels: [
            'special_offer.travel',
            'special_offer.equipment',
            'special_offer.branding',
          ],
        },
      ],
      form: {
        special_offer: this.offersVariant,
        name: this.userName,
        phone: this.userPhone,
        brand: this.brand && this.brand.brand_id,
      },
      non_field_errors: '',
      isLoad: false,
    }
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      let sendData = data
      if (this.sendAttributes) {
        sendData = {
          ...data,
          metadata: this.getMetadata(),
        }
      }
      return createOffer.execute({}, sendData)
        .then(() => {
          this.$emit('success')
          const meta = {
            message: {
              title: this._('Offers success title'),
              text: this._('Offers success text'),
            },
          }
          AnswerMessage(meta, this)
        })
    },
    getMetadata() {
      const attrs = this.attributes.reduce((acc, obj) => {
        if (!obj.id) {
          acc.push([`${obj.attribute}: ${obj.title}`])
          return acc
        }
        const v = obj.values.map(e => (`${e.attribute}: ${e.title}`))
        acc.push(...v)
        return acc
      }, [])
      if ('special_offer.branding' === this.modelName && this.brand) {
        attrs.push(`${this._('Бренд')}: ${this.brand.brand}`)
      }
      return attrs.join(', ')
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

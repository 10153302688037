<template lang="pug">
  include /mixins

  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    row(
      justify='end g-row--justify_between-xl'
      align='end'
    )
      +b.g-cell.g-cols--12
        +b.g-row--align_center.--justify_between
          +b.g-cell.g-cols
            +b.ds-caption--bold.P {{ getSplittedHtml(getPrice) }}
          +b.g-cell.g-cols
            counter(
              :amount='amount',
              :disable='false'
              :max-count='create_preorder ? maxCount : 999'
              @immediately='changeAmount($event)'
              @change='changeCart($event)'
              :variant='info.id'
              :show-error='maxCount < amount'
            )
            +b.g-cell.g-cols--12
        +b.new-preorder-error(v-if='maxCount < amount')
          +e.title-wrap
            +e.title.P {{ _('Перевищено ліміт кількості товарів') }}
          +e.text.P {{ _('Для цього товару з передоплатою, максимальна кількість товарів -') }} {{ maxCount }}
        +b.g-row
          +b.g-cell.g-cols--12.tt-space_mt--6(
            v-for='{ rules, name, label, mask, tag } in form_fields'
          )
            tag(
              :tag='tag || "d-control-input"'
              :rules="rules",
              :input-label='label'
              v-model='form[name]',
              :ref='name'
              :name='name'
            )
        +b.new-preorder-payment(v-if='info.can_order')
          +e.title-wrap
            +e.title.P {{ _('Внести 100% предоплати') }}
            +b.g-row--align_center.--appearance_nowrap
              +b.g-cell.g-cols
                +b.switch.INPUT.tt-space_ml--3.tt-space_mr--3(
                  id="create_preorder"
                  v-model='create_preorder'
                  type="checkbox"
                  @change='changePreorder'
                )
              //- +b.g-cell.g-cols
                //- +b.ds-caption--size_xs.LABEL(for="create_preorder") {{ _('Післяплата') }}
          +e.text.P {{ _('Оплачені предзамовлення будуть опрацьовані відразу після оплати') }}
      +b.g-cell.g-cols--12.tt-space_mt--6
        +b.tt-button--redesign-h.BUTTON(
          type='submit'
          :disabled='isLoad || maxCount < amount',
          @click.prevent='processOrder'
          v-if='create_preorder'
        )
          +e.title.P {{ _('Отправить') }}
        +b.tt-button--redesign-h.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData'
          v-if='!create_preorder'
        )
          +e.title.P {{ _('Отправить') }}

</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { addToPreorder } from '@api/preorder.service'
import { changeCartEntity, clearCartEntity, getPreorderCount } from '@api/cart.service'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'PreOrderCart',
  props: {
    productVariant: Number,
    userName: String,
    userPhone: String,
    info: Object,
  },
  mixins: [FormMixin],
  data() {
    return {
      form_fields: [
        {
          name: 'name',
          label: this._('Ваше имя'),
          rules: 'required',
        },
        {
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          tag: 'phone-input',
        },
      ],
      form: {
        product_variant: this.productVariant,
        count: '',
        name: this.userName,
        phone: this.userPhone,
      },
      non_field_errors: '',
      isLoad: false,
      create_preorder: false,
      amount: 1,
      cart: {
        total_price: this.info.price,
      },
      maxCount: 999,
      oldCount: 1,
    }
  },
  computed: {
    getPrice() {
      if (this.create_preorder) {
        return this.cart.total_price
      }
      return this.info.price * this.amount
    },
  },
  mounted() {
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.form.count = this.amount
      this.submit(valid, this.form)
    },
    changeAmount({ count }) {
      this.amount = count
    },
    send(data) {
      return addToPreorder.execute({}, data)
        .then(() => {
          this.$emit('success')
          const meta = {
            message: {
              title: this._('Preorder success title'),
              text: this._('Preorder success text'),
            },
          }
          AnswerMessage(meta, this)
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    async getStockQuantity() {
      try {
        const { data: [{ stock }] } = await getPreorderCount.execute({ items: this.info.id })
        this.maxCount = stock
      } catch (e) {
        console.log(e)
      }
    },
    async changePreorder() {
      console.log(this.create_preorder);
      if (this.create_preorder) {
        await this.getStockQuantity()
        await clearCartEntity.execute({ for_preorder: true })
        await this.addToCart()
      }
    },
    async changeCart(info) {
      if (this.create_preorder) {
        const changes = info.count - this.oldCount
        console.log(changes, '>>>>>>>>.', info.count, '-', this.oldCount);
        if (!changes) return
        await this.addToCart(changes)
      }
    },
    async addToCart(count = this.amount) {
      console.log(count, 'CHANGE CART');
      this.isLoad = true
      const entity = {
        element: {
          id: this.info.id,
          type: this.info.type,
        },
        quantity: count,
      }
      const entities = []
      entities.push(entity)
      try {
        const { data } = (await changeCartEntity.execute({ for_preorder: true }, { entities }))
        this.cart = data
        this.oldCount = this.cart.groups.find(e => e.base.element.id === this.info.id).base.quantity
        console.log(data);
      } catch (e) {
        console.log(e);
      }
      this.isLoad = false
    },
    processOrder() {
      window.location.href = window.pre_order_page
    },
  },
}
</script>

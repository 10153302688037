var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"event-request"},[_c('div',{staticClass:"ds-panel ds-panel--space_2xl"},[_c('a',{staticClass:"close-popup mdi mdi-close",on:{"click":function($event){return _vm.$emit("close")}}}),_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_full"},[_c('p',{staticClass:"ds-caption ds-caption--size_lg ds-caption--size_xl-xl ds-caption--medium ds-caption--color_grey"},[_vm._v(_vm._s(_vm._('Предложить тему семинара')))]),_c('validation-observer',{ref:"validator",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(_vm.isLoad)?_c('ui-loader'):_vm._e(),_c('div',{staticClass:"g-row"},[_vm._l((_vm.form_fields),function(ref){
var rules = ref.rules;
var name = ref.name;
var label = ref.label;
var tag = ref.tag;
var classname = ref.classname;
var errorClasses = ref.errorClasses;
var mask = ref.mask;
var type = ref.type;
return _c('div',{staticClass:"g-cell g-cols g-cols--12 tt-space_mt tt-space_mt--6"},[_c('tag',{ref:name,refInFor:true,class:classname,attrs:{"tag":tag || "d-control-input","rules":rules,"input-label":label,"name":name,"type":type || "text","error-classes":errorClasses},on:{"input":function($event){return _vm.$emit("update", _vm.form)}},model:{value:(_vm.form[name]),callback:function ($$v) {_vm.$set(_vm.form, name, $$v)},expression:"form[name]"}})],1)}),_c('div',{staticClass:"g-cell g-cols g-cols--12 tt-space_mt tt-space_mt--6"},[_c('d-control-static-input',{ref:"is_cosmetologist",attrs:{"input-label":_vm._("Я косметолог"),"id":"is_cosmetologist","type":"checkbox","name":"is_cosmetologist"},model:{value:(_vm.form.is_cosmetologist),callback:function ($$v) {_vm.$set(_vm.form, "is_cosmetologist", $$v)},expression:"form.is_cosmetologist"}})],1),_c('div',{staticClass:"g-cell g-cols g-cols--12 tt-space_mt tt-space_mt--6"},[_c('button',{staticClass:"tt-button",attrs:{"type":"submit","disabled":_vm.isLoad},on:{"click":function($event){$event.preventDefault();return _vm.prepareData(valid)}}},[_c('p',{staticClass:"tt-button__title"},[_vm._v(_vm._s(_vm._('Отправит')))])])])],2)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
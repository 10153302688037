const mergeOptions = (result, options = null) => (
  null !== options ? Object.assign(result, options) : result
)

const pathToRegexpOptions = { strict: true }

export const Namespace = {
  name: 'router-namespace',
  render(h) {
    return h('router-view', {
      attrs: { ...this.$attrs },
      on: this.$listeners,
      scopedSlots: this.$scopedSlots,
    })
  },
}

export const ResolvedNamespace = Promise.resolve(Namespace)
export const ImportedNamespace = () => ResolvedNamespace

export function route(path, component, name, options = null) {
  return mergeOptions({ path, component, name, pathToRegexpOptions }, options)
}

export function prefixRoutes(path, children, options = null) {
  return mergeOptions({
    path,
    component: Namespace,
    pathToRegexpOptions,
    children,
  }, options)
}

<template lang="pug">
  include /mixins
  +b.ds-panel--space_sm.tt-space_mt--3
    +e.element--indent_outside.--offset_vertical
      ui-loader(
        v-if='isPageLoading'
      )
      +b.g-row--space_lg.--appearance_spaced
        template(v-for='(imagesitem, index) in list')
          +b.g-cell.g-cols--12(
            :class='cellSize'
          )
            slot(name='item', :item='imagesitem')
              component(
                :is='getTypeComponent(fileTypeChecker(imagesitem.file) || imagesitem.type)'
                :item='imagesitem'
                @removeItem='removeImage($event)'
                :idx='index'
                :extentions='extentions'
              )
        slot(
          v-if='limit > list.length'
          name='loader',
          :addVideo='addVideo'
          :addPhoto='addPhoto'
        )
          +b.g-cell.g-cols--12(
            :class='cellSize'
          )
            +b.default-full-btn.lined-onhover.LABEL(:for='id')
              +b.uploader-dummy-view
                +e.btn.mdi.mdi-plus
                +e.text.P {{ _('Загрузить') }}
              input.visually-hidden(
                type="file"
                name="img"
                :accept="accept"
                :invalid='!list.length'
                :data-max='sizeLimit'
                ref='img'
                @change='addPhoto($event)'
                :id='id',
                :multiple='limit > 1'
              )
        //- +b.g-cell.g-cols--12(v-if='imgLengthErr')
        //-   +b.LABEL.error--top(
        //-     v-if='imgLengthErr'
        //-     class="help"
        //-   )  {{ imgLengthErr }}
</template>
<script>
import mime from 'mime-types';

import {
  confirmWithPromise,
  getId,
} from '@utils/helpers'
import GalleryItem from './GalleryItem'
import FileViewer from './FileViewer'

const SPLITED_LENGTH = 1

export default {
  name: 'Gallery',
  components: {
    GalleryItem,
    FileViewer,
  },
  props: {
    id: {},
    filekey: {
      default: 'img',
    },
    limit: {
      type: Number,
      default: 5,
    },
    sizeLimit: {
      type: Number,
      default: 10240000,
    },
    resource: {
    },
    reset: {

    },
    additional: {},
    getResource: {},
    postResource: {},
    patchResource: {},
    deleteResource: {},
    profile: {},
    accept: {
      default: 'image/*',
    },
    cellSize: {
      default: 'g-cols--6-md g-cols--3-xl',
    },
    extentions: {
      default: () => ([]),
    },
  },
  data() {
    return {
      test: '',
      value: [],
      isPageLoading: false,
      list: [],
      nonFieldError: '',
      isBlocked: false,
      files: [],
      one: 1,
      full: 100,
      imgLengthErr: '',
    }
  },
  watch: {
    reset(nval) {
      if (nval) {
        this.list = []
        this.files = []
      }
    },
  },
  created() {
    this.getInit()
  },
  methods: {
    getTypeComponent(type) {
      console.log(type);
      return type.includes('image/') ? 'GalleryItem' : 'FileViewer'
    },
    fileTypeChecker(file) {
      return mime.lookup(file)
    },
    async getInit() {
      if (!this.getResource) return
      const { items } = (await this.getResource.execute())
      this.list = items.map(el => el.props)
    },
    addVideo(event) {
      this.$emit('inProcess', true)
      this.nonFieldError = ''
      this.isBlocked = true
      const video = getId(event)
      this.showVideoRow = false

      const image = `https://img.youtube.com/vi/${video}/0.jpg`
      this.list.push({
        img: image,
        progressBarWidth: 0,
        file: event,
        type: 'image/',
      })
      this.loader()
    },
    addPhoto(event) {
      // this.$emit('inProcess', true)
      this.nonFieldError = ''
      this.imgLengthErr = ''
      this.isBlocked = true

      const files = Array.from(event.target.files)

      const err = this._('Максимальный размер файла - ') + this.sizeLimit / 1024000 + this._('мб')

      this.files = files
      files.forEach(file => {
        this.list.push({
          img: window.URL.createObjectURL(file),
          progressBarWidth: 0,
          err: file.size > this.sizeLimit ? err : false,
          val: false,
          type: file.type,
          file,
        })
      })
      this.loader()
    },
    loader() {
      this.list.forEach((element, index) => {
        if (element.id) return
        if (this.postResource) {
          this.postResource.execute({}, {
            ...this.additional, [this.filekey]: element.file,
          }).then(({ data: { item: { id } } }) => {
            this.$set(this.list[index], 'id', id)
            this.$emit('update', this.list)
          })
          return
        }
        this.$emit('update', this.list)
      })
    },
    async removeImage(i) {
      const question = this._('Вы действительно хотите удалить?')
      try {
        await confirmWithPromise(question, this)
        if (this.list[i].id) {
          this.deleteResource.execute({ id: this.list[i].id })
        }
        this.list.splice(i, SPLITED_LENGTH)
        this.$emit('update', this.list)
      } catch (e) {
        console.log(e);
      }
    },
  },

}
</script>

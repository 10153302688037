<template lang="pug">
  include /mixins
  +b.tt-counter--bordered(
    :class='{"is-disabled": disable}'

  )
    +e.icon.BUTTON.mdi.mdi-minus(
      :class='{"is-active": showError}'
      @click.prevent='deleteMore()'
    )
    +e.number.INPUT(
      v-model.number='count',
      @keyup='debounceChecker'
      @change='debounceChecker'
      @blur='check'
      type='number'
      :class='{"is-invalid": showError, "is-active": cantIncrease}'
    )
    +e.icon.BUTTON.mdi.mdi-plus(
      :class='{"is-disabled": cantIncrease}'
      @click.prevent='addMore()'
    )
</template>
<script>

import debounce from 'lodash.debounce'

import { LostProfitResource } from '@api/store.service'

const INITIAL_COUNT = 1

const MIN_VALUE = 1
const MAX_VALUE = 999

const DECIMAL = /^[\d]+$/

export default {
  name: 'Counter',
  props: {
    amount: {
      type: [String, Number],
      default: () => INITIAL_COUNT,
    },
    disable: {
      default: () => false,
      type: Boolean,
    },
    maxCount: {
      type: [String, Number],
    },
    minCount: {
      type: Number,
      default: MIN_VALUE,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      count: this.amount,
      debounceChecker: '',
      debounceAction: '',
    }
  },
  computed: {
    cantIncrease() {
      return this.maxCount <= this.count
    },
  },
  watch: {
    amount: {
      handler(nval) {
        this.count = nval
      },
      deep: true,
    },
  },
  created() {
    const delay = 2000
    this.debounceAction = debounce(e => {
      this.emitter(e)
    }, delay)
    this.debounceChecker = debounce(e => {
      this.check(e)
    }, delay)
  },
  methods: {
    check(e) {
      if (e.target.value > this.maxCount && this.variant) {
        LostProfitResource.execute({}, {
          variant: this.variant,
          quantity: e.target.value,
        })
      }
      if (e.target.value && e.target.value === this.count) return
      if (e.target.value < this.minCount || '' === e.target.value) {
        this.count = this.minCount
        e.preventDefault()
      }
      if (this.cantIncrease) {
        this.count = this.maxCount
        e.preventDefault()
      }
      this.$emit('input', this.count)
      this.$emit('immediately', { count: this.count })
      this.emitter(e)
    },
    deleteMore() {
      this.count--
      if (this.count < this.minCount) {
        this.count = this.minCount
      }
      this.changeCount({ target: { value: this.count } })
    },
    addMore() {
      this.count++
      if (this.count > MAX_VALUE) {
        this.count = MAX_VALUE
      }
      this.changeCount({ target: { value: this.count } })
    },
    changeCount(e) {
      this.$emit('immediately', { count: this.count })
      this.$emit('input', this.count)
      this.debounceAction(e)
    },
    emitter() {
      this.$emit('change', { count: this.count })
    },
  },

}
</script>

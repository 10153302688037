<template lang="pug">
  include /mixins
  +b.event-request
    +b.ds-panel--space_2xl
      +b.close-popup.mdi.mdi-close.A(
        @click='$emit("close")'
      )
      +e.element--offset_full
        +b.ds-caption.--size_lg.--size_xl-xl.--medium.--color_grey.P {{ _('Предложить тему семинара') }}
        +b.VALIDATION-OBSERVER(
          ref="validator",
          v-slot="{ valid }",
          tag="form",
        )
          ui-loader(v-if='isLoad')
          +b.g-row
            +b.g-cell.g-cols--12.tt-space_mt--6(
              v-for='{ rules, name, label, tag, classname, errorClasses, mask, type } in form_fields'
            )
              tag(
                :tag='tag || "d-control-input"'
                :rules="rules",
                :input-label='label'
                v-model='form[name]',
                :ref='name'
                @input='$emit("update", form)'
                :name='name'
                :type='type || "text"'
                :class='classname',
                :error-classes='errorClasses'
              )
            +b.g-cell.g-cols--12.tt-space_mt--6
              d-control-static-input(
                :input-label='_("Я косметолог")'
                v-model='form.is_cosmetologist',
                ref='is_cosmetologist'
                id='is_cosmetologist'
                type='checkbox'
                name='is_cosmetologist'
              )
            +b.g-cell.g-cols--12.tt-space_mt--6
              +b.tt-button.BUTTON(
                type='submit'
                :disabled='isLoad',
                @click.prevent='prepareData(valid)'
              )
                +e.title.P {{ _('Отправит') }}

</template>
<script>
import {
  createEventRequest,
} from '@api/events.service'

import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import { decodeHTMLEntities } from '@utils/helpers'

export default {
  name: 'EventRequest',
  mixins: [FormMixin],
  data() {
    return {
      form_fields: [
        {
          name: 'name',
          label: this._('Ваше имя'),
          rules: 'required',
        },
        {
          name: 'email',
          label: this._('Электронная почта'),
          rules: 'required|email',
          type: 'email',
        },
        {
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          tag: 'phone-input',
        },
        {
          name: 'city',
          label: this._('Город'),
          rules: 'required',
        },
        {
          name: 'comment',
          label: this._(' Желаемое направление семинара'),
          rules: 'required',
          tag: 'd-control-textarea',
          class: 'is-textarea',
          errorClasses: 'error-label--top',
        },
      ],
      form: {
        name: decodeHTMLEntities(window.request_name),
        email: window.request_email,
        phone: window.request_phone,
        city: '',
        comment: '',
        is_cosmetologist: false,
      },
      non_field_errors: '',
      isLoad: false,
    }
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return createEventRequest.execute({}, data)
        .then(res => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: 'event_registration_topic' })
          AnswerMessage(res.data, this)
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
          this.$emit('close')
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

<template lang="pug">
  include /mixins
  +b.image-clipper
    +b.g-row--appearance_spaced
      +b.g-cell.g-cols--12.--6-md
        +b.g-row--appearance_column.--justify_between.full-h
          +b.g-cell.g-cols--12
            image-clipper-basic(
              ref="clipper"
              :src="imgURL"
              class="my-clipper"
              :rotate='rotate'
              preview='my-preview'
              :ratio='ratio'
              :scale='scale'
              :wrap-ratio='1'
              :initial='{ w, h }'
              :round='round'
            )
              div(
                v-bem:image-clipper.no-image=''
                slot="placeholder"
              ) {{ _('Изображение не загружено') }}
            .clipper-desc
              +b.ds-caption--size_3xs.P {{ _('* Удерживайте левую кнопку мыши и передвигайте курсор, чтобы выбрать подходящий участок изображения, либо воспользуйтесь ползунком справа') }}
              +b.ds-caption--size_3xs.P {{ _('** Воспользуйтесь колесиком мышки, чтобы выбрать маштаб изображения, либо воспользуйтесь ползунком справа') }}
          +b.g-cell.g-cols--12
            +b.tt-button--bordered_main.clipper-btn.CLIPPER-UPLOAD(
              v-model="imgURL"
            )
              +e.title.P {{ _('Выбрать новое') }}

      +b.g-cell.g-cols--12.--6-md()
        my-preview.my-clipper(
          name="my-preview"
          :class="`rate${rate}`"
        )
        +b.ds-caption--size_3xs.P {{ _('Move to change the rotation angle:') }}
        clipper-range(
          v-model='rotate'
          :max='360'
        )
        +b.ds-caption--size_3xs.P {{ _('Move to change a scale value:') }}
        clipper-range(
          v-model='scale'
          :max='2'
          :min='0.1'
        )

        +b.tt-button.A(
          href=''
          v-if='imgURL'
          @click.prevent='crop'
        )
          +e.title.P {{ _('Сохранить') }}
        .is-disable-md
          +b.tt-space_pt--10
    +b.close-popup.A(
      href='',
      @click.prevent='$emit("close")',
      :key='2'
    )
      +e.icon.mdi.mdi-close
</template>

<script>

export default {
  props: {
    preview: {},
    promise: {},
    ratio: {},
    rate: {},
    w: {},
    h: {},
    round: {
      default: () => false,
    },
  },
  name: 'ImageClipper',
  data() {
    return {
      imgURL: this.preview,
      rotate: 0,
      scale: 1,
    }
  },
  watch: {
    scale(val) {
      this.$refs.clipper.setWH$.next(val)
    },
  },
  mounted() {
    this.$refs.clipper.onChange$.subscribe(() => {
      // This happens whenever zooming, moving and rotating occur.
    })
  },
  methods: {
    crop() {
      const canvas = this.$refs.clipper.clip()
      const dataURL = canvas.toDataURL()
      const blobBin = atob(dataURL.split(',')[1])
      const array = []
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i))
      }
      const file = new Blob([new Uint8Array(array)], { type: 'image/png' })
      const newFile = new File([file], 'name.png', { type: 'image/png' })
      this.promise(newFile)
      this.$emit('close')
    },
  },
}
</script>

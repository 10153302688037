<template lang="pug">
  include /mixins
  +b.g-container--variant_default
    +b.new-callback-wrapper
      +e.form-wrap
        ui-loader(v-if='isLoad')
        +b.VALIDATION-OBSERVER(
          ref="validator",
          v-slot="{ valid }",
          tag="form",
        )
          +b.g-row
            +b.g-cell.g-cols--12.tt-space_mt--6
              d-control-input(
                :rules="'required'",
                :input-label='_("Ваше ім‘я ")'
                v-model='form.name'
                id='name',
                name='name',
              )
            +b.g-cell.g-cols--12.tt-space_mt--6
              d-control-input(
                :rules="'required|email'",
                :input-label='_("Email")'
                v-model='form.email',
                name='email'
                type='email'
              )
            +b.g-cell.g-cols--12.tt-space_mt--6
              phone-input(
                :rules="'required|phone'",
                :input-label='_("Ваш номер телефону")'
                v-model='form.phone',
                name='phone'
              )
            +b.g-cell.g-cols--12.tt-space_mt--6
              +b.tt-button.BUTTON(
                type='submit'
                :disabled='isLoad',
                @click.prevent='prepareData(valid)'
              )
                +e.title.P {{ _('ПЕРЕЗВОНИТЬ МНЕ') }}
      +e.img-wrap
        +e.img.IMG(
          src='/static/img/vacancies/form.svg'
        )
      +e.text-wrap
        +b.callback-form-description
          +e.title.H3 {{ _('У вас еще есть вопросы?') }}
          +e.text.P {{ _('Sed consequat sapien faus quam bibendum convallis quis in nulla. Pellentesque volutpat odio eget diam cursus semper.') }}
          +e.phone.A(
            :href='`tel:${_("+380 (00) 00-00-000")}`'
          ) {{ _('+380 (00) 00-00-000') }}
</template>

<script>

import {
  createFeedback,
} from '@api/feedback.service'

import pathOr from 'ramda/src/pathOr'
import {
  AnswerMessage,
} from '@utils/submit'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

export default {
  name: 'NewCallbackForm',
  mixins: [FormMixin],
  props: ['message'],
  data() {
    return {
      form: {
        name: '',
        subject: '',
        email: window.request_email,
        phone: window.request_phone,
        message: this.message,
        source: window.location.href,
      },
      subject: '',
      subjects: [],

    }
  },

  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.form.subject = pathOr('', ['id'], this.subject)
      this.submit(valid, this.form)
    },
    send(data) {
      return createFeedback.execute({}, data)
        .then(res => {
          AnswerMessage(res.data, this)
          this.$emit('close')
          this.$refs.validator.reset()
          this.$refs.validator.$el.reset()
          this.form.name = ''
          this.form.subject = ''
          this.form.email = ''
          this.form.phone = ''
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>

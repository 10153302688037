var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('menu-level-renderer',{ref:"renderer",attrs:{"item-id":_vm.itemId},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var list = ref.list;
return [_c('div',_vm._l((list),function(ref){
var item = ref.props;
return _c('ui-accordion',{key:item.id,attrs:{"init":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var activeState = ref.activeState;
var toggleActive = ref.toggleActive;
var collapse = ref.collapse;
return [_c('ui-click-outside',{attrs:{"do":collapse}},[_c('div',[_c('div',{staticClass:"g-cell g-cols g-cols--12"},[_c('div',{directives:[{name:"bem",rawName:"v-bem:menu-drop-down.item",value:({"baseline": ""}),expression:"{\"baseline\": \"\"}",arg:"menu-drop-down",modifiers:{"item":true}}],staticClass:"tt-space_pt tt-space_pt--1"},[_c('a',{staticClass:"tt-link tt-link--appearance_hovered tt-link--variant_start ds-caption ds-caption--size_xsm full-h tt-space_ml tt-space_ml--2",attrs:{"href":item.url,"target":item.redirect_policy}},[_c('p',{staticClass:"tt-link__text"},[_vm._v(_vm._s(item.title))])]),(item.has_child)?[_c('i',{directives:[{name:"bem",rawName:"v-bem:menu-drop-down.icon",arg:"menu-drop-down",modifiers:{"icon":true}}],staticClass:"mdi",class:activeState ? "mdi-minus" : "mdi-plus",on:{"click":function($event){$event.preventDefault();return toggleActive()}}})]:_vm._e()],2)]),(activeState)?_c('menu-level-renderer',{attrs:{"item-id":item.id,"state":activeState},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var childList = ref.list;
return [_c('div',_vm._l((childList),function(child){return _c('div',{staticClass:"g-cell g-cols g-cols--12"},[_c('div',{staticClass:"tt-space_pl tt-space_pl--4"},[_c('a',{staticClass:"tt-link tt-link--appearance_hovered tt-link--variant_start ds-caption ds-caption--size_xsm ds-caption--light tt-space_pt tt-space_pt--1 full-h",attrs:{"href":child.props.url,"target":child.props.redirect_policy}},[_c('p',{staticClass:"tt-link__text"},[_vm._v(_vm._s(child.props.title))])])])])}),0)]}}],null,true)}):_vm._e()],1)])]}}],null,true)})}),1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
  include /mixins
  +b.g-row.relative()
    ui-loader.mini-loading(v-if='load')
    +b.g-cell.g-cols--12.--3-md.multisearch-scroll
      +b.multisearch-result-item(
        v-if='meta.corrected'
      )
        +b.ds-caption--size_xs.P(
        ) {{ _('Показаны результаты по запросу') }} #[span(v-html='meta.corrected.highlight')]
      +b.multisearch-result-item.tt-link--variant_start.A(
        @click.prevent='setActive(); viewPopular()'
        :class='{"is-active": !activeCategory }'
      )
        +e.title.SPAN {{ _('Все результаты') }}
        +e.count.SPAN {{ totalCount }}
      +b.multisearch-result-item.tt-link--variant_start.A(
        v-for='link in results'
        @click.prevent='setActive(link);'
        :class='{"is-active": activeCategory && activeCategory.category.id == link.category.id }'
      )
        +e.title.SPAN {{ link.category.props.title }}
        +e.count.SPAN {{ link.count }}
    +b.g-cell.g-cols--12.--9-md(
      v-if='activeCategory'
    )
      +b.multisearch-preview
        +e.title--hidden.A(
          :href='activeCategory.category.props.url'
        ) {{ activeCategory.category.props.title }}
        +e.list
          paginated-view(
            :list='activeCategory.variants',
            :key='activeCategory.category.id',
            :next='activeCategory.next',
            :resource='resource',
            :search='localsearch',
            :limit='$options.LIMIT_BY_CATEGORY'
            :category='activeCategory.category.id'
            @load:start='$emit("load:start")'
            @load:end='$emit("load:end")'
          )
            template(
              #default='{ splicedList, hasNext, hasPrev, getNext, getPrev }'
            )
              div()
                +b.g-row
                  +b.g-cell.g-cols--6.--6-md.--4-xl(
                    v-for='(link, t) in splicedList'
                  )
                    product-preview(
                      :link='link',
                      :t='t'
                      :findValueAndHighlightTerm='findValueAndHighlightTerm'
                      :highlightTerm='highlightTerm'
                      :localsearch='localsearch'
                      :trigger_overlay='trigger_overlay'
                    )
                +b.g-row--justify_end
                  +b.g-cell.g-cols
                    +b.circle-btn--light.controls--variant_bordered.A(
                      @click.prevent='getPrev'
                      v-show='hasPrev'
                    )
                      +e.icon.mdi.mdi-chevron-left
                  +b.g-cell.g-cols
                    +b.circle-btn--light.controls--variant_bordered.A(
                      @click.prevent='getNext',
                      v-show='hasNext'
                    )
                      +e.icon.mdi.mdi-chevron-right
    +b.g-cell.g-cols--12.--9-md(
      v-else
    )
      +b.g-row.tt-space_mt--5
        +b.g-cell.g-cols--6.--6-md.--4-xl(
          v-for='line in list'

        )
          +b.multisearch-preview(
          )
            +e.title.A(
              :href='line.category.props.url'
            ) {{ line.category.props.title }}
            +e.list
              +b.g-row
                +b.g-cell.g-cols--12(
                  v-for='(link, t) in line.variants'
                )
                  product-preview(
                    :link='link',
                    :t='t'
                    :findValueAndHighlightTerm='findValueAndHighlightTerm'
                    :highlightTerm='highlightTerm'
                    :localsearch='localsearch',
                    :trigger_overlay='trigger_overlay'
                  )
            +e.text.A.tt-link--variant_end.A(
              v-show='line.count > $options.POPULAR_PRODUCTS_COUNT'
              @click.prevent.stop='getCategoryAndSet(line.category.id)'
            ) {{ _('Еще') }} {{ line.count - $options.POPULAR_PRODUCTS_COUNT }} ...
</template>

<script>
import ProductPreview from '@app/Search-v2/Ui/Previews/Product'
import PaginatedView from '@app/Search-v2/Ui/PaginatedView'

const POPULAR_CATEGORIES_COUNT = 3
const POPULAR_PRODUCTS_COUNT = 4
const LIMIT_BY_CATEGORY = 9

export default {
  props: {
    findValueAndHighlightTerm: {},
    highlightTerm: {},
    results: {},
    localsearch: {},
    resource: {},
    load: {},
    meta: {},
    trigger_overlay: {},
  },
  components: {
    ProductPreview,
    PaginatedView,
  },
  LIMIT_BY_CATEGORY,
  POPULAR_PRODUCTS_COUNT,
  data() {
    return {
      activeCategory: '',
      list: [],
    }
  },
  watch: {
    results: {
      handler() {
        this.viewPopular()
        this.activeCategory = null
      },
    },
    immediate: false,
    deep: true,
  },
  computed: {
    totalCount() {
      return this.results.reduce((acc, obj) => {
        acc += obj.count
        return acc
      }, 0)
    },
  },
  mounted() {
    this.viewPopular()
  },
  methods: {
    setActive(category) {
      this.list = []
      this.activeCategory = category
    },
    viewPopular() {
      this.list = JSON.parse(JSON.stringify(this.results)).splice(0, POPULAR_CATEGORIES_COUNT).reduce((acc, obj) => {
        obj.variants = obj.variants.splice(0, POPULAR_PRODUCTS_COUNT)
        acc.push(obj)
        return acc
      }, [])
    },
    getCategoryAndSet(id) {
      const result = this.results.find(({ category }) => category.id === id)
      this.setActive(result)
    },
  },
}
</script>

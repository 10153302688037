<template lang="pug">
include /mixins
+b.ds-panel--space_2md.--space_lg-xl
    +b.close-popup.mdi.mdi-close.BUTTON(
      @click.prevent='$emit("close")'
    )
    +e.element--offset_full
      ui-loader(v-if='isLoad')
      +b.VALIDATION-OBSERVER(
        ref="validator",
        v-slot="{ valid }",
        tag="form",
      )
        +b.tt-image-captcha
          +e.title.P {{ _('Код с картинки') }}
          +e.img-wrap
            +e.img.IMG(v-if='id' :src='getSrc')
          +e.icon(@click='getId')
            +e.icon-img.IMG(
              width='32'
              height='32'
              src='/static/img/rebranding/renew.svg'
            )
          +e.input
            d-control-input(
              name='value'
              rules='required|min:5|max:5'
              v-model='value'
              :input-label='_("Введите код с картинки")'
            )
          +e.error.SPAN(
            v-show="error"
          ) {{ error }}
          +e.button
            +b.tt-button.BUTTON(
              type='submit'
              @click.prevent='validate'
            )
              +e.title.SPAN {{ _('Отправить') }}

</template>
<script>
import { prefixAPI } from '@resource/api'
import {
  createCaptchaResource,
} from '@api/vendor.service'

export default {
  name: 'ImageCaptcha',
  props: {
    promise: {
      type: Function,
      default: () => {},
    },
    error: {
      default: '',
    },
  },
  data() {
    return {
      value: '',
      id: null,
      isLoad: true,
    }
  },
  computed: {
    getSrc() {
      return prefixAPI(`captcha/image/${this.id}.png`)
    },
  },
  async mounted() {
    this.getId()
  },
  methods: {
    async validate() {
      const isValid = (await this.$refs.validator.validate())
      console.log(isValid, '>>>>>');
      if (!isValid) return false
      this.promise({
        id: this.id,
        value: this.value,
      })
      this.getId()
      this.value = ''
      if (this.$refs.validator) this.$refs.validator.reset()
      return true
    },
    getId() {
      this.isLoad = true
      createCaptchaResource.execute()
        .then(response => {
          console.log(response);
          this.id = response.id
          this.isLoad = false
        })
    },
  },
}
</script>

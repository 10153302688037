<template lang='pug'>
  simple-router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initial"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    :kwargs='kwargs'
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="productsResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      :kwargs='kwargs'
      v-slot="paginatedProps"
    )
      component(
        :is='controller'
        ref="controller"
        :attributesResource='attributesResource'
        :availableAttributesResource='availableAttributesResource'
        v-bind="paginatedProps",
        :needPrices='needPrices'
        :needSorting='needSorting'
        :needTitle='needTitle'
        :needSearch='needSearch'
        :label='label',
        :kwargs='kwargs'
        :sortlist='sortlist||default_sort'
        @category-hack='categoryHack'
        :needBlocking='needBlocking'
        :initbaseUrl='initbaseUrl'
      )
        slot
</template>

<script>
import { mapMutations } from 'vuex'

import {
  friendlyUrlParser,
} from '@app/FiltersSimple/transformers'

import Controller from '@app/ListSimple/Controller'

export default {
  components: { Controller },
  props: {
    kwargs: {},
    initial: {

    },
    initUrl: {},
    initbaseUrl: {},
    productsResource: '',
    attributesResource: '',
    availableAttributesResource: '',
    needPrices: {
    },
    needSorting: {},
    needTitle: {},
    needSearch: {},
    initlabel: {},
    sortlist: {

    },
    needBlocking: {
      default: true,
    },
    controller: {},
  },
  data() {
    return {
      label: this.initlabel,
      baseUrl: this.initbaseUrl,
      default_sort: [
        {
          title: this._('возрастанию цены'),
          key: 'price',
        },
        {
          title: this._('убыванию цены'),
          key: '-price',
        },
        {
          title: this._('акционные'),
          key: '-sale',
        },
        {
          title: this._('топ продаж'),
          key: '-rating',
        },
        {
          title: this._('новинки'),
          key: '-date',
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations('search', [
      'SET_SEARCH',
    ]),
    categoryHack() {
      this.label = ''
      this.baseUrl = this.initUrl
    },
    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        label: this.label,
        ...this.kwargs,
      }
    },
    parametersFrom({ ...rest }) {
      const data = friendlyUrlParser()
      if (data.search) {
        this.SET_SEARCH(data.search[0])
      }
      return {
        ...rest,
        filters: data,
        ...this.kwargs,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>
